import React from "react";
import ReactDOM from "react-dom";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
} from "@mui/material";
import { StylesProvider, createGenerateClassName } from "@mui/styles";

interface MessageBoxOptions {
    containerElement?: string
    title?: string
    body: React.ReactNode,
    buttons?: string[],
    icon?: "none" | "info" | "warn" | "error" | "question",
    onClick: (index: number) => void
}

const defaultOptions: MessageBoxOptions = {
    containerElement: "#app-msgbox",
    title: "Dialog",
    body: <></>,
    buttons: ["Yes", "No"],
    icon: "none",
    onClick: (index) => { }
}

export enum EMessageBoxButton {
    ButtonConfirm = 0,
    ButtonCancel = 1
}

export default class MessageBox {
    static Show = (options: MessageBoxOptions = defaultOptions) => {
        const generateClassName = createGenerateClassName({
            productionPrefix: 'msgBox',
            seed: 'msgBox'
        });

        const opts = { ...defaultOptions, ...options };
        let containerElement = document.querySelector(opts.containerElement!);
        if (!containerElement) {
            containerElement =  document.createElement("div") as Element;
            containerElement.id = opts.containerElement!;
            // throw Error(`can't find container ${opts.containerElement}`)
        };

        const handleClose = (index: number) => {
            ReactDOM.unmountComponentAtNode(containerElement!);
            opts.onClick(index);
        };

        const iconStyle: React.CSSProperties = {
            marginRight: "25px",
            width: "40px",
            height: "40px"
        };

        ReactDOM.render(
            <StylesProvider generateClassName={generateClassName}>
                <Dialog
                    open={true}
                    onClose={_ => handleClose(-1)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    scroll={"paper"}
                >
                    <DialogTitle id="alert-dialog-title" style={{ fontSize: "18px" }}>{opts.title}</DialogTitle>
                    <DialogContent style={{ display: "flex" }}>
                        {opts.icon === "info" && <img src="../../../static/img/msgbox-icons/info-80px.png" alt="Information" style={iconStyle} />}
                        {opts.icon === "question" && <img src="../../../static/img/msgbox-icons/question-80px.png" alt="Question" style={iconStyle} />}
                        {opts.icon === "error" && <img src="../../../static/img/msgbox-icons/error-80px.png" alt="Error" style={iconStyle} />}
                        {opts.icon === "warn" && <img src="../../../static/img/msgbox-icons/attention-80px.png" alt="Warning" style={iconStyle} />}
                        <DialogContentText id="alert-dialog-description" style={{ fontSize: "16px" }}>
                            {opts.body}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        {opts.buttons!.map((text, index) =>
                            <Button onClick={_ => handleClose(index)} color="primary" style={{ fontSize: "14px" }}>
                                {text}
                            </Button>
                        )}
                    </DialogActions>
                </Dialog>
            </StylesProvider>

            , containerElement);

    }
}