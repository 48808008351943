import {BaseReply, ServiceBase} from "./ServiceBase";

interface ILiveServicesConfig {
    RegionId: number;
    HideProductionPlanning: boolean;
    ServiceName: string;
    allRegions: string[];
    allServiceNames: Record<string, string>;
}

class InternalConfigurationService extends ServiceBase {
    buildUrl(action: string): string {
        return action;
    }

    resolveValue<TIn, TOut>(reply: BaseReply<TIn>): TOut | undefined {
        return reply as unknown as TOut;
    }

    public async getLiveServicesConfig(): Promise<ILiveServicesConfig> {
        const result = await this.makePostCall<ILiveServicesConfig>("Configuration/GetLiveServicesConfiguration", {});

        return result;
    }
}

class InternalUserSettingsService extends ServiceBase {
    buildUrl(action: string): string {
        return `User/${action}`;
    }

    resolveValue<TIn, TOut>(reply: BaseReply<TIn>): TOut | undefined {
        let value = super.resolveValue<TIn, TOut>(reply)
            ?? ((reply as any).ReplyObject as unknown as TOut);

        return value;
    }

    public async getString(name: string): Promise<string> {
        const result = await this.makeGetCall<string>("GetString?name=" + name);

        return result;
    }

    public async setString(name: string, value: string): Promise<boolean> {
        const result = await this.makePostCall<boolean>("SetString", {name, value});

        return result;
    }
}

export default class UserSettings {
    public static readonly DefaultTimeZone: string = "Europe/Istanbul";
     
    public static async GetAllServiceNames() {
        await UserSettings.ensureLiveServicesConfig();
          
        return UserSettings.liveServicesConfig!.allServiceNames;  
    }

    private static liveServicesConfig?: ILiveServicesConfig; 

    private static readonly configService: InternalConfigurationService = new InternalConfigurationService();
    private static readonly userSettingsService: InternalUserSettingsService = new InternalUserSettingsService();

    static getUserTimeZone() {
        const userTimeZone: string = localStorage.getItem("UserTimeZoneId") ?? UserSettings.DefaultTimeZone;

        return userTimeZone;
    }

    public static async getRegions() {         
        await UserSettings.ensureLiveServicesConfig();
         
        return UserSettings.liveServicesConfig!.allRegions;        
    }

    public static async clear() {
        UserSettings.liveServicesConfig = undefined;
    }    
     
    private static async ensureLiveServicesConfig() {
        if (UserSettings.liveServicesConfig === undefined) {
            UserSettings.liveServicesConfig = await UserSettings.configService.getLiveServicesConfig();
        }
    }

    static async getUserRegion() {
        await UserSettings.ensureLiveServicesConfig();

        const regionId = UserSettings.liveServicesConfig!.RegionId;

        var regions = await UserSettings.getRegions();

        return regions[regionId];
    }

    static async getUserServiceName() {
        await UserSettings.ensureLiveServicesConfig();

        return UserSettings.liveServicesConfig!.ServiceName;
    }

    static async getUserSetting(name: string): Promise<string> {
        return await this.userSettingsService.getString(name);
    }

    static async setUserSetting(name: string, value: string): Promise<boolean> {
        return await this.userSettingsService.setString(name, value);
    }
    static async getLiveServicesConfig(): Promise<ILiveServicesConfig> {
        await UserSettings.ensureLiveServicesConfig();
        return  this.liveServicesConfig!;
    }
}
