import store from "../../stores";
import equal from "fast-deep-equal";

export const setSystemHealth = (systemHealth) => {
    const previousSystemHealth = store.getState().systemhealth.systemHealth;
    if (!equal(previousSystemHealth,systemHealth)) {
        store.dispatch({
            type: "SET_SYSTEM_HEALTH",
            systemHealth,
        });
    }
};

export const clearSystemHealth = () => {
    store.dispatch({
        type: "CLEAR_SYSTEM_HEALTH"
    });
};

export const getSystemHealth = () => {
    const currentState = store.getState();
    return currentState.systemhealth.systemHealth
};

