export function changeFeedbackLanguage(formatMessage: any) {
    const FeedBackEmojiTextChanger = () => {
        const e = document.getElementsByClassName(
            "_hj-1AEfg__EmotionOption__EmotionText _hj-2kfWD__EmotionOption__EmotionTextDefault"
        );

        for (let ix = 0; ix < 5; ix++) {
            e[ix].innerHTML = formatMessage({ id: `hotjar.emoji.${(ix++)}`});            
        }
    };
    const FeedBackFirstText = () => {
        document.getElementsByClassName(
            "_hj-Z5Onj__Title__Title _hj-9ZlKs__Title__compact"
        )[0].innerHTML = formatMessage({ id: "hotjar.feedback.message" });
    };
    const FeedBackContactText = () => {
        document.getElementsByClassName(
            "_hj-qnMJa__styles__primaryButton _hj-2wZPy__PrimaryButtonBase__primaryButton"
        )[0].innerHTML = formatMessage({ id: "hotjar.sendButton" });
        
        document.getElementsByClassName("_hj-wTRLt__Title__Title")[0].innerHTML =
            formatMessage({ id: "hotjar.sendMailText" });

        document.getElementsByClassName(
            "_hj-3OscV__styles__clearButton _hj-1baop__EmailStep__clearButton"
        )[0].innerHTML = formatMessage({ id: "hotjar.nextButtonLabel" });
    };
    const FeedBackButtonClick = document.getElementsByClassName(
        "_hj-1qvel__Feedback__feedback"
    )[0]; //ilk tıklandığında
    const FeedBackButtonClickEvent = () => {
        document.getElementsByClassName(
            "_hj-Z5Onj__Title__Title _hj-9ZlKs__Title__compact"
        )[0].innerHTML = formatMessage({ id: "hotjar.feedback.message" });

        const FirstEmojies = document.getElementsByClassName(
            "_hj-1A3sC__EmotionStep__EmotionOptions"
        )[0]; //1.emojilerin üstünde gezdiğimde
        FirstEmojies.addEventListener("mouseover", () => {
            FeedBackFirstText();
            FeedBackEmojiTextChanger();
        });

        //1.emojilerin üstünden çıktığımda
        FirstEmojies.addEventListener("mouseout", () => {
            FeedBackFirstText();
            FeedBackEmojiTextChanger();
        });

        const CloseButton = document.getElementsByClassName(
            "_hj-2JN4b__ExpandedWidget__closeButton"
        )[0]; //çıkış butonu
        CloseButton.addEventListener("click", () => {
            setTimeout(() => {
                document.getElementsByClassName(
                    "_hj-1uQd9__MinimizedWidgetMiddle__text"
                )[0].innerHTML = formatMessage({ id: "hotjar.feedback" });
            }, 200);
        });

        //1.emojilere tıklandığında
        FirstEmojies.addEventListener("click", () => {
            setTimeout(() => {
                FeedBackEmojiTextChanger();
            }, 200);

            (document.getElementsByClassName(
                //ilk metin- placeholder
                "_hj-2bByr__EmotionComment__textArea _hj-FAirH__styles__inputField"
            )[0] as HTMLInputElement).placeholder = formatMessage({
                id: "hotjar.feedback.innerMessage",
            });

            document
                .getElementsByClassName(
                    "_hj-1PeBw__EmotionComment__iconSelectElement _hj-2cKCe__styles__iconSelectElement _hj-3e6MN__styles__icon"
                )[0] //sol atttaki yer
                .addEventListener("mouseover", () => {
                    const e = document.getElementById("hj-element-selector-tooltip");

                    if (e !== null) {
                        e.innerHTML = formatMessage({ id: "hotjar.pageSelectButtonText" });
                    }                        
                });

            document
                .getElementsByClassName(
                    "_hj-1PeBw__EmotionComment__iconSelectElement _hj-2cKCe__styles__iconSelectElement _hj-3e6MN__styles__icon"
                )[0] //area seçme butonu
                .addEventListener("click", () => {
                    setTimeout(() => {
                        document.getElementsByClassName(
                            "_hj-hbIG7__ElementHighlighter__title"
                        )[0].innerHTML = formatMessage({
                            id: "hotjar.pageSelectButtonText",
                        });
                    }, 200);
                });

            document
                .getElementsByClassName(
                    "_hj-2bByr__EmotionComment__textArea _hj-FAirH__styles__inputField"
                )[0] //textarea değişirse
                .addEventListener("input", () => {
                    FeedBackEmojiTextChanger();
                    document.getElementsByClassName(
                        "_hj-qnMJa__styles__primaryButton _hj-2wZPy__PrimaryButtonBase__primaryButton"
                    )[0].innerHTML = formatMessage({ id: "hotjar.sendButton" });
                });

            document
                .getElementsByClassName(
                    "_hj-3PSb0__ExpandedWidget__innerContainer"
                )[0]
                .addEventListener("mouseover", () => {
                    //2.kısımdaki emojiler
                    FeedBackEmojiTextChanger();
                    document.getElementsByClassName(
                        "_hj-qnMJa__styles__primaryButton _hj-2wZPy__PrimaryButtonBase__primaryButton"
                    )[0].innerHTML = formatMessage({ id: "hotjar.sendButton" });
                });

            document
                .getElementsByClassName("_hj-SKiIV__CommentStep__emotionOptions")[0]
                .addEventListener("click", () => {
                    FeedBackEmojiTextChanger();
                    document.getElementsByClassName(
                        "_hj-qnMJa__styles__primaryButton _hj-2wZPy__PrimaryButtonBase__primaryButton"
                    )[0].innerHTML = formatMessage({ id: "hotjar.sendButton" });
                });

            document
                .getElementsByClassName(
                    "_hj-qnMJa__styles__primaryButton _hj-2wZPy__PrimaryButtonBase__primaryButton"
                )[0] //send butonu
                .addEventListener("click", () => {
                    FeedBackContactText();

                    const LastTextEvent = () => {
                        setTimeout(() => {
                            document.getElementsByClassName(
                                "_hj-1uQd9__MinimizedWidgetMiddle__text"
                            )[0].innerHTML = formatMessage({ id: "hotjar.feedback" });
                            document.getElementsByClassName(
                                "_hj-2ObUs__MinimizedWidgetMessage__messageText"
                            )[0].innerHTML = formatMessage({
                                id: "hotjar.feedBack.LastText",
                            });
                        }, 200);
                    }

                    document
                        .getElementsByClassName("_hj-5FX-x__EmailStep__buttons")[0] //en dıştaki butonlar
                        .addEventListener("click", () => {
                            LastTextEvent();
                            document
                                .getElementsByClassName(
                                    "_hj-1BgOO__MinimizedWidgetMessage__message _hj-21C9N__styles__roundedCorners _hj-2RQFa__MinimizedWidgetMessage__right"
                                )[0]
                                .addEventListener("mouseover", () => {
                                    LastTextEvent();
                                });

                            document
                                .getElementsByClassName("_hj-24J4R__MinimizedWidgetMessage__close _hj-CoyQX__styles__iconX _hj-3e6MN__styles__icon")[0] //son yazı kapama tuşu
                                .addEventListener("click", () => {
                                    setTimeout(() => {
                                        document.getElementsByClassName(
                                            "_hj-1uQd9__MinimizedWidgetMiddle__text"
                                        )[0].innerHTML = formatMessage({ id: "hotjar.feedback" });
                                    }, 200);
                                })
                        });

                    document
                        .getElementsByClassName("_hj-KMOYv__EmailStep__input")[0] //mail değişirse
                        .addEventListener("input", () => {
                            FeedBackContactText();
                        });



                });
        }); //2.emojilerin event sonu
    } //ilk tıklanmanın bitişi

    document.getElementsByClassName(
        "_hj-Dfxkw__MinimizedWidgetMiddle__text"
    )[0].innerHTML = formatMessage({ id: "hotjar.feedback" }); //anaekrana ilk geldiğinde
    FeedBackButtonClick.addEventListener("click", FeedBackButtonClickEvent);

    //FeedBackButtonClick.removeEventListener("click",FeedBackButtonClickEvent);
}