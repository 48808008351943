const defaultValues = {
    systemHealth: null,
    lastUpdateDate: null,
};

const systemhealth = (state = defaultValues, action) => {
    switch (action.type) {
        case "SET_SYSTEM_HEALTH":
            return { ...state, systemHealth: action.systemHealth, lastUpdateDate: new Date() };
        case "CLEAR_SYSTEM_HEALTH":
            return { ...state, ...defaultValues }
        default:
            return state;
    }
};

export default systemhealth;
