import _ from 'lodash';
import moment from 'moment';
import { PowerPlantTypes } from 'src/components/powerplant-picker/Types';
import { roundToDigits } from 'src/utils';

import {
  CellClassParams,
  CellStyle,
  ColDef,
  EditableCallbackParams,
  GridApi,
  ValueSetterParams,
} from '@ag-grid-community/core';

import { PF_DRD } from './constants';
import messages from './messages';
import {
  ICellInfo,
  IDelivery,
  IDeliveryLock,
  IForecastData,
  ILockedCell,
  ILockStatus,
  IPrediction,
  IPredictionHistoryDetails,
  IRowData,
  MeasureUnit,
} from './types';

export const getUtc = (date: Date, offset: number) => moment(date).subtract(offset, 'minutes');

export const generateDeliveryKey = (p: DeliveryType): string => {
  return `${moment(p.deliveryStart).format('HH:mm')}+${p.deliveryStartOffset}-${moment(
    p.deliveryEnd
  ).format('HH:mm')}+${p.deliveryEndOffset}}`;
};

export const getDistinctDeliveries = (forecastData: IForecastData[]): IDelivery[] => {
  return _.uniqBy(
    forecastData.flatMap((fd) => fd.predictions),
    (pv) => generateDeliveryKey(pv)
  )
    .map((pv) => {
      return <IDelivery>{
        deliveryStart: new Date(pv.deliveryStart),
        deliveryStartOffset: pv.deliveryStartOffset,
        deliveryEnd: new Date(pv.deliveryEnd),
        deliveryEndOffset: pv.deliveryEndOffset,
        period: getUtc(pv.deliveryEnd, pv.deliveryEndOffset ?? 0).diff(
          getUtc(pv.deliveryStart, pv.deliveryStartOffset ?? 0),
          'minutes'
        ),
      };
    })
    .sort((a, b) =>
      getUtc(a.deliveryStart, a.deliveryStartOffset ?? 0) ==
      getUtc(b.deliveryStart, b.deliveryStartOffset ?? 0)
        ? a.period - b.period
        : a.deliveryStart.getTime() - b.deliveryStart.getTime()
    );
};

export type DeliveryType = IDelivery | IDeliveryLock | IPrediction | IPredictionHistoryDetails;
export const isSameDelivery = (d1: DeliveryType, d2: DeliveryType): boolean => {
  return (
    moment(d1.deliveryStart)
      .utcOffset(d1.deliveryStartOffset)
      .isSame(moment(d2.deliveryStart).utcOffset(d2.deliveryStartOffset)) &&
    moment(d1.deliveryEnd)
      .utcOffset(d1.deliveryEndOffset)
      .isSame(moment(d2.deliveryEnd).utcOffset(d2.deliveryEndOffset))
  );
};

const getSameOffsetDateTimes = (d: DeliveryType) => {
  const ds = moment(d.deliveryStart);
  const de = moment(d.deliveryEnd);
  if (d.deliveryStartOffset === d.deliveryEndOffset) return [ds, de];

  // bring to same offset as deliverystart
  de.add(d.deliveryStartOffset - d.deliveryEndOffset, 'minutes');

  return [ds, de];
};

export const generateDisplayDeliveryString = (
  d: IDelivery | IPrediction | IPredictionHistoryDetails
): string => {
  const [ds, de] = getSameOffsetDateTimes(d);

  return `${ds.format('HH:mm')}-${de.format('HH:mm')}`;
};

export const generateConsecutiveUniqueDeliveryString = (
  delivery: IDelivery,
  deliveries: IDelivery[],
  direction: 'prev' | 'next'
) => {
  const period = delivery.period * (direction === 'prev' ? -1 : 1);
  const nextDeliveryStart = moment(delivery.deliveryStart).add(period, 'minutes');
  const nextDeliveryEnd = nextDeliveryStart.add(period, 'minutes');

  return `${nextDeliveryStart.format('HH:mm')}-${nextDeliveryEnd.format('HH:mm')}`;
};

export const findDeliveryPrediction = (
  delivery: IDelivery,
  predictions: IPrediction[]
): IPrediction | undefined => predictions.find((p) => isSameDelivery(p, delivery));

export const getColName = (ppId: number, dataName: 'prediction' | 'provider' | 'state'): string => {
  return `pp${ppId}_${dataName}`;
};

export const getHeaderClassWithIcons = (powerPlantType: PowerPlantTypes) => {
  let icon = 'text-center ';
  switch (powerPlantType) {
    case PowerPlantTypes.Hydro:
      icon += 'hydro-icon';
      break;
    case PowerPlantTypes.Thermal:
      icon += 'coal-icon';
      break;
    case PowerPlantTypes.Wind:
      icon += 'wind-icon';
      break;
    case PowerPlantTypes.Solar:
      icon += 'solar-icon';
      break;
    case PowerPlantTypes.NaturalGas:
      icon += 'gas-icon';
      break;
    case PowerPlantTypes.GeoThermal:
      icon += 'geothermal-icon';
      break;
    default:
      break;
  }
  return icon;
};

export const getCellLockStatus = (
  params:
    | CellClassParams
    | EditableCallbackParams
    | ValueSetterParams
    | {
        colDef: ColDef;
        data: IRowData;
      },
  lockedCells: ILockedCell[],
  deliveries: IDelivery[],
  checkNeighbors: boolean
): ILockStatus => {
  const lockStatus: ILockStatus = {
    isLocked: false,
    isTopNeighborLocked: false,
    isBottomNeighborLocked: false,
  };
  if (params.data.isUtilRow) return lockStatus;

  const currField = params.colDef.field!;

  if (
    lockedCells.length > 0 &&
    lockedCells.find(
      (lc) =>
        lc.fieldName === currField &&
        generateDeliveryKey(lc.delivery) === generateDeliveryKey(params.data.delivery!)
    )
  ) {
    lockStatus.isLocked = true;
    if (checkNeighbors) {
      lockStatus.isTopNeighborLocked = Boolean(
        lockedCells.find(
          (lc) =>
            lc.fieldName === currField &&
            generateDeliveryKey(lc.delivery) ===
              generateConsecutiveUniqueDeliveryString(params.data.delivery, deliveries, 'prev')
        )
      );
      lockStatus.isBottomNeighborLocked = Boolean(
        lockedCells.find(
          (lc) =>
            lc.fieldName === currField &&
            generateDeliveryKey(lc.delivery) ===
              generateConsecutiveUniqueDeliveryString(params.data.delivery, deliveries, 'next')
        )
      );
    }
  }

  return lockStatus;
};

export const checkPlantDiversion = (
  forecastData: IForecastData[] | null,
  delivery: IDelivery,
  newValue: number,
  measureUnit: MeasureUnit,
  powerPlantId: number
) => {
  if (forecastData == null || forecastData.length == 0) return false;

  const pd = forecastData.find((pd) => pd.powerPlantId === powerPlantId);
  if (!pd) return;

  newValue = measureUnit === MeasureUnit.MW ? convertToKWh(newValue)! : newValue; // convert to KWh for comparison with the original value

  let productionDiversity = pd.productionDiversity;
  const oldValue =
    pd.predictions.find((pv) => generateDeliveryKey(pv) === generateDeliveryKey(delivery))?.value ??
    0;
  if (productionDiversity == null || productionDiversity == '') return false;

  let delta = 0;
  if (productionDiversity.startsWith('%')) {
    productionDiversity = productionDiversity.replace('%', '');
    delta = (oldValue * parseFloat(productionDiversity)) / 100;
  } else delta = parseFloat(productionDiversity);

  const difference = Math.round(Math.abs(oldValue - newValue) * 100) / 100;

  return difference > delta;
};

export const convertRowDataValues = (rowData: IRowData[], multiplier: number) => {
  rowData.forEach((row) => {
    if (!['prediction', 'total'].includes(row.type)) return;

    Object.keys(row).forEach((key) => {
      if (key.endsWith('_prediction') && row[key]) {
        row[key] *= multiplier;
      }
    });
  });
};

export const getEmptyCells = (
  gridApi: GridApi | undefined,
  powerPlantIds: number[],
  lockedCells: ILockedCell[]
) => {
  const emptyCells: ICellInfo[] = [];
  gridApi?.forEachNode((node) => {
    const row: IRowData = node.data;
    if (row.type !== 'prediction') return;
    const rowId = generateDeliveryKey(row.delivery!);
    const lockedRowCells = lockedCells
      .filter((lc) => isSameDelivery(row.delivery!, lc.delivery))
      .map((lc) => lc.fieldName);

    const pcns = powerPlantIds?.map((ppId) => getColName(ppId, 'prediction'));
    Object.keys(row).forEach((key) => {
      if (lockedRowCells.includes(key)) return;
      if (pcns.length > 0) {
        if (pcns.includes(key) && row[key] == null) {
          emptyCells.push({
            rowId,
            cellKey: key,
            powerPlantId: Number(key.slice(2).split('_')[0]), // pp123_prediction
          });
        }
      } else if (key.endsWith('_prediction') && row[key] == null) {
        emptyCells.push({
          rowId,
          cellKey: key,
          powerPlantId: Number(key.slice(2).split('_')[0]), // pp123_prediction
        });
      }
    });
  });

  return emptyCells;
};

export const processValueRows = (
  deliveries: IDelivery[],
  forecastData: IForecastData[],
  defaultValue: number | null,
  formatMessage: Function
) => {
  const predictionRows: IRowData[] = [];
  const totalsRow: IRowData = {
    type: 'total',
    rowTitle: formatMessage(messages.total),
    cellStyle: {
      fontWeight: 'bold',
    } as CellStyle,
    isUtilRow: true,
  };

  const lockedCellsProvider: ILockedCell[] = [];
  const lockedCellsUser: ILockedCell[] = [];
  for (const d of deliveries) {
    const currRow: IRowData = {
      type: 'prediction',
      rowTitle: generateDisplayDeliveryString(d),
      delivery: d,
    };

    for (const fd of forecastData) {
      const pcn = getColName(fd.powerPlantId, 'prediction');
      const dp = findDeliveryPrediction(d, fd.predictions);

      if (totalsRow[pcn] == null) totalsRow[pcn] = 0;

      currRow[pcn] = dp?.value ?? defaultValue;

      if (dp) {
        const deliveryLock = fd.deliveryLocks.find((pl) => isSameDelivery(dp, pl));
        if (deliveryLock?.lockState === 1) {
          lockedCellsProvider.push({
            fieldName: pcn,
            delivery: d,
          });
        } else if (deliveryLock?.lockState === -1) {
          lockedCellsUser.push({
            fieldName: pcn,
            delivery: d,
          });
        }

        totalsRow[pcn] += dp?.value ?? 0;
      }
    }

    predictionRows.push(currRow);
  }

  return { predictionRows, totalsRow, lockedCellsProvider, lockedCellsUser };
};

export const convertToKWh = (value?: number | null, applyDefaultRounding = true) => {
  if (value == null) return null;

  const convertedValue = value * 1000;
  return applyDefaultRounding ? roundToDigits(convertedValue, PF_DRD) : convertedValue;
};

export const convertToMWh = (value?: number | null, applyDefaultRounding = true) => {
  if (value == null) return null;

  const convertedValue = value * 0.001;
  return applyDefaultRounding ? roundToDigits(convertedValue, PF_DRD) : convertedValue;
};

export const convertTo = (
  value: number | null | undefined,
  measureUnit: MeasureUnit,
  applyDefaultRounding = true
) =>
  measureUnit === MeasureUnit.MW
    ? convertToMWh(value, applyDefaultRounding)!
    : convertToKWh(value, applyDefaultRounding)!;

/**
 * Replaces locale's decimal seperator with '.'. If number is not a number, tries to replace ',' with '.' as a fallback __(unsafe,unreliable)__.
 * @param value value to parse
 * @param decimalSeperator locale's decimal seperator
 * @returns parsed number. 0 if value is null, undefined or empty string
 */
export const parseNumber = (value: string | null, decimalSeperator: string): number => {
  const swappedSeperator = value?.toString().replace(decimalSeperator, '.') ?? null;
  let parsedValue = Number(swappedSeperator);
  if (isNaN(parsedValue)) {
    // !fallback, unreliable
    parsedValue = Number(swappedSeperator?.replace(/,/g, '.') ?? null);
  }
  return parsedValue;
};
