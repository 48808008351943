import React from "react";
import {MessageDescriptor, WrappedComponentProps} from "react-intl";
import axios, {AxiosResponse, CancelTokenSource} from "axios";

import IIdentifiedComponent from "./IIdentifiedComponent";
import IServerCommunicationState from "./IServerCommunicationState";
import IServerCommunicationInfo from "./IServerCommunicationInfo";
import ILocale from "./ILocale";
import moment from 'moment';
import "moment-timezone";

import {IRouteProps} from "./IRouteProps";

import {PartialPath} from "history";

import * as CommonActions from "../../redux/common/action";

// @ts-ignore
import reduxStore from "../../redux/stores";

import {ApplicationContext, Severity} from "../ApplicationContext";

axios.defaults.withCredentials = true;

export class BaseComponent<P = {}, S = {}, SS = any> extends React.PureComponent<P & IIdentifiedComponent & IRouteProps & WrappedComponentProps & React.RefAttributes<HTMLDivElement>, S & IServerCommunicationState, SS> {
    static contextType = ApplicationContext;

    private addCommunication(path: string, data: any): IServerCommunicationInfo {
        const communications: IServerCommunicationInfo[] = (this.state.currentServerComminucations === undefined) ? [] : this.state.currentServerComminucations.map((x) => x);
        const cancellationTokenSource = ((this.state.cancellationTokenSource === undefined) ? axios.CancelToken.source() : this.state.cancellationTokenSource) as CancelTokenSource;
        const communication: IServerCommunicationInfo = {
            path: path,
            data: data,
            cancellationTokenSource: cancellationTokenSource
        };

        communications.push(communication);
        if (this.state.cancellationTokenSource === undefined) {
            this.setState({
                currentServerComminucations: communications as any,
                cancellationTokenSource: cancellationTokenSource as any
            });
        } else {
            this.setState({currentServerComminucations: communications as any});
        }

        return communication;
    }

    private removeCommunication(communication: IServerCommunicationInfo) {
        const communications: IServerCommunicationInfo[] = (this.state.currentServerComminucations === undefined) ? [] : this.state.currentServerComminucations.map((x) => x);

        this.setState({currentServerComminucations: communications.filter((x) => x !== communication) as any});
    }

    hasAnyCommunication(path: string): boolean {
        if (this.state.currentServerComminucations === undefined) {
            return false;
        } else {
            return this.state.currentServerComminucations.some((x) => x.path === path);
        }
    }

    getTheme() {
        let themeMode = 'light;'
        const settingsjson = localStorage.getItem('settings')
        if (settingsjson) {
            const settings = JSON.parse(settingsjson);
            themeMode = settings.themeMode;
        }

        return themeMode;
    }


    getTimeByTimeZone(date: any, format = "DD.MM.YYYY HH:mm:ss", convertFormat = ("DD.MM.YYYY HH:mm:ss ([UTC] Z)")) {
        const timeZone = localStorage.getItem('UserTimeZoneId') || 'Europe/Istanbul';

        const convertedTime = moment(date, format).tz(timeZone);

        return convertedTime.format(convertFormat);

    }

    private buildCallUrl = (path: string): string => {
        const nodeEnv = import.meta.env.MODE;
        const isProduction = nodeEnv === "production";
        const currentURL = new URL(window.location.href);
        return path.startsWith("http://") || path.startsWith("https://")
            ? path
            : new URL(isProduction
                ? path
                : `/s${path.startsWith("/") ? "" : "/"}${path}`, currentURL)
                .toString();
    }

    postAsync<D = any>(path: string, data: any): Promise<AxiosResponse<D, any>> {
        if ((this.state || null) === null) {
            return new Promise<AxiosResponse<any, any>>((_, reject) => {
                reject(new Error("The component was unmounted!"));
            });
        }
        const communication = this.addCommunication(path, data);

        return new Promise<AxiosResponse<any, any>>((resolve, reject) => {
            const urlAddress = this.buildCallUrl(path);

            axios.post(urlAddress, data, {cancelToken: communication.cancellationTokenSource.token})
                .then((response) => {
                    this.removeCommunication(communication);
                    if (response.data.Success === undefined || response.data.ResultMessage === undefined) {
                        resolve(response);
                    } else {
                        if (response.data.Success === true) {
                            resolve(response);
                        } else {
                            this.showUserMessage((response.data.ResultMessage || ""), Severity.error);
                            reject(new Error((response.data.ResultMessage || "")));
                        }
                    }
                })
                .catch((error) => {
                    this.removeCommunication(communication);
                    if (error instanceof Error) {
                        this.showUserMessage(error.message, Severity.error);
                    }
                    reject(error);
                });
        });
    }

    getAsync<D = any>(path: string): Promise<AxiosResponse<D, any>> {
        if ((this.state || null) === null) {
            return new Promise<AxiosResponse<any, any>>((_, reject) => {
                reject(new Error("The component was unmounted!"));
            });
        }
        const communication = this.addCommunication(path, null);

        return new Promise<AxiosResponse<any, any>>((resolve, reject) => {
            const urlAddress = this.buildCallUrl(path);

            axios.get(urlAddress, {cancelToken: communication.cancellationTokenSource.token})
                .then((response) => {
                    this.removeCommunication(communication);
                    if (response.data.Success === undefined || response.data.ResultMessage === undefined) {
                        resolve(response);
                    } else {
                        if (response.data.Success === true) {
                            resolve(response);
                        } else {
                            this.showUserMessage((response.data.ResultMessage || ""), Severity.error);
                            reject(new Error((response.data.ResultMessage || "")));
                        }
                    }
                })
                .catch((error) => {
                    this.removeCommunication(communication);
                    if (error instanceof Error) {
                        this.showUserMessage(error.message, Severity.error);
                    }
                    reject(error);
                });
        });
    }

    showUserMessage(message: string | MessageDescriptor, severity: Severity) {
        const {showUserMessage} = this.context;

        showUserMessage(message, severity);
    }

    getCurrentLocale(): string {
        //  return this.props.intl.locale;
        return (localStorage.getItem('locale') || "tr");
    }

    getLocales(): ILocale[] {
        return [{code: "tr", name: "Türkçe", englishName: "Turkish"}, {
            code: "en",
            name: "English",
            englishName: "English"
        }, {code: "es", name: "Español", englishName: "Spanish"}];
    }

    getResourceText(resourceId: any, values: {} = {}) {
        if (resourceId === undefined || resourceId === null) {
            return "";
        }
        const {formatMessage} = this.props.intl;

        if (typeof resourceId === "string") {
            if (resourceId === "") {
                return "";
            }
            return formatMessage({id: resourceId, defaultMessage: resourceId}, values);
        } else {
            if (undefined !== resourceId.id && undefined !== resourceId.defaultMessage)
                return formatMessage(resourceId, values);

            return resourceId;
        }
    }

    changeLocale(target: string | ILocale) {
        const targetCode = (typeof target === "string") ? target : target.code;
        const currentURL = new URL(window.location.href.replace("/#/", "/"));
        const currentSearch = new URLSearchParams(currentURL.search);
        console.log("t", targetCode)

        currentSearch.set("locale", targetCode);
        localStorage.setItem("locale", targetCode);
        if (this.props.history === undefined) {
            window.location.hash = `${currentURL.pathname}?${currentSearch.toString()}`;
        } else {
            this.props.history.push(`${currentURL.pathname}?${currentSearch.toString()}`);
        }
    }

    getCookieValue(name: string) {
        const matchedValues = document.cookie.match("(^|[^;]+)\\s*" + name + "\\s*=\\s*([^;]+)");

        return matchedValues ? matchedValues.pop() : "";
    }

    navigateTo(to: string | PartialPath) {
        if (typeof to === "string") {
            to = to + ((to.indexOf("?") > 0) ? "&locale=" + this.props.intl.locale : "?locale=" + this.props.intl.locale);

            if (this.props.history === undefined) {
                window.location.hash = to;
            } else {
                this.props.history.push(to);
            }
        } else {
            if (to.search === "") {
                to.search = "?locale=" + this.props.intl.locale;
            } else {
                to.search = to.search + "&locale=" + this.props.intl.locale;
            }
            if (this.props.history === undefined) {
                window.location.hash = `#${to.pathname}${to.search}`;
            } else {
                this.props.history.push(to);
            }
        }
    }

    getControlId(suffix: string = "") {
        if ((this.props.controlId || null) === null) {
            return undefined;
        }
        if (suffix === "") {
            return this.props.controlId;
        } else {
            return `${this.props.controlId}-${suffix}`;
        }
    }

    getReduxStore(name: string | string[] | null = null) {
        const store = reduxStore.getState();

        if (name === null) {
            return store;
        } else if (Array.isArray(name)) {
            return name.map(e => store[e]);
        } else {
            return store[name];
        }
    }

    componentWillUnmount() {
        if ((this.state || null) !== null) {
            if (this.state.cancellationTokenSource !== undefined) {
                this.state.cancellationTokenSource.cancel(`${this.props.controlId} component will unmount`);
            }
        }
        if (super.componentWillUnmount !== undefined) {
            super.componentWillUnmount();
        }
    }

    //type: 1:screen, 2:function
    isEligible(operationId: any, type = 1) {
        let commonStore = this.getReduxStore("common");

        if (commonStore === undefined || commonStore === null) {
            return false;
        } else {
            let eligibilityInfo = commonStore.eligibility;
            const operations =
                type === 1 ? eligibilityInfo.applications : eligibilityInfo.functions;

            if (operations !== null && operations.length > 0) {
                return operations.some(e => e === operationId);
            }
            return false;
        }
    }

    setUserProfileData(values: Array<{ key: any, value: any }>) {
        let commonStore = this.getReduxStore("common");
        let temp = [...commonStore.userProfileData];

        values
            .forEach((item) => {
                const storage = temp.find((a: any) => a.Name === item.key);

                if (storage != undefined) {
                    storage.Value = item.value;
                } else {
                    const newData = {
                        Name: item.key,
                        Value: item.value
                    }

                    temp.push(newData);
                }
            });

        return this.postAsync("Configuration/SetUserProfileData", temp)
            .then((reply) => {
                CommonActions.setUserProfileData(temp);
            })
            .catch((reply) => {
            });
    }

    getUserProfileData(key: any) {
        let commonStore = this.getReduxStore("common");
        if (commonStore === undefined || commonStore === null) {
            return;
        } else {
            var data = commonStore.userProfileData.find((a: any) => a.Name === key);

            if (data !== undefined && data !== null) {
                return data.Value;
            }
            return;
        }
    }

    setUserSettings(values: Array<{ Name: any, Value: any }>) {
        $("#loader").addClass("whirl");
        return this.postAsync("Configuration/SetUserSettings", values)
            .then((reply) => {
                $("#loader").removeClass("whirl");

            })
            .catch((reply) => {
                $("#loader").removeClass("whirl");
                this.showUserMessage((reply.data.ResultMessage || ""), Severity.error);
            });
    }

    getUserSettings() {
        this.postAsync("Configuration/GetUserSettings", {}).then((reply) => {
            if (reply.data === null) {


            } else {
                return reply.data;
            }
        }).catch((reply) => {
            this.showUserMessage((reply.data.ResultMessage || ""), Severity.error);
        });
    }
}
