import {
    red,
    pink,
    blue,
    green
} from "@mui/material/colors";

export const Red = red;
export const Pink = pink;
export const Blue = blue;
export const Green = green;