import { configureStore } from "@reduxjs/toolkit";

import appReducers from "../old/src/redux/reducers";
import productionForecast from "./slices/productionForecastSlice";
import { socketApi } from "src/old/src/redux/webSocket/gipPlanningWebSocket";

const store = configureStore({
   // @ts-expect-error ignore
   reducer: { productionForecast, ...appReducers },
   devTools: process.env.NODE_ENV !== "production",
   // @ts-expect-error ignore
   middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({ serializableCheck: false, immutableCheck: false }).concat(socketApi.middleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
