import '@ag-grid-community/core/dist/styles/ag-grid.css';
import '@ag-grid-community/core/dist/styles/ag-theme-balham-dark.css';
import '@ag-grid-community/core/dist/styles/ag-theme-balham.css';
import '@ag-grid-community/core/dist/styles/ag-theme-dark.css';

import {AgGridReact, AgGridReactProps} from "@ag-grid-community/react";
import {Box, Dialog} from "@mui/material";
import {FormattedMessage, defineMessages, injectIntl, useIntl} from 'react-intl';
import {GetRowIdFunc, GetRowNodeIdFunc} from "@ag-grid-community/core/dist/cjs/es5/entities/gridOptions";
import React, {forwardRef, useRef, useState} from "react";

import {AG_GRID_LOCALE_EN} from "../../locales/ag-grid-en";
import {AG_GRID_LOCALE_ES} from "../../locales/ag-grid-es";
import {AG_GRID_LOCALE_TR} from "../../locales/ag-grid-tr";
import {ErrorIcon} from "../../old/src/components/icons";
import {GetRowIdParams} from "@ag-grid-community/core/dist/cjs/es5/entities/iCallbackParams";
import {GridThemes} from "./GridThemes";
import useUserCulture from "../../hooks/useUserCulture";
import { GridOptions } from '@ag-grid-community/core';

export type IGridProps = AgGridReactProps & {
    /**
     * @deprecated Grid ignores this setting, instead it uses from localstorage
     **/
    theme?: string,
    height?: string | 0,
    width?: string,

    /**
     * @deprecated AgGrid ignores children, use columnDefs only
     **/
    children?: any;

    columnDefs?: any
    rowData?: any
    onGridReady?: any,
    onFirstDataRendered?: any,
    getRowNodeId?: any,
    onBodyScroll?: any,
    frameworkComponents?: any,
    rowClassRules?: any,
    masterDetail?: boolean,
    detailCellRenderer?: any,
    detailRowAutoHeight?: boolean,
    detailRowHeight?: number,
    onSelectionChanged?: any,
    onRowClicked?: any,
    headerHeight?: number,
    sideBar?: any,
    statusBar?: any,
    gridOptions?: GridOptions,
    debounceVerticalScrollbar?: boolean,
    suppressScrollOnNewData?: boolean,
    embedFullWidthRows?: boolean,
    detailCellRendererParams?: any,
    keepDetailRows?: boolean,
} &
    {
        intl?: any
    };

function getThemeName() {
    let themeMode = 'light';

    const settingsJson = localStorage.getItem('settings')
    if (settingsJson) {
        const settings = JSON.parse(settingsJson);
        themeMode = settings.themeMode;
    }

    const themeName = themeMode == 'dark'
        ? GridThemes.BalhamDark
        : GridThemes.Balham;

    return themeName;
}

function getLocaleTexts() {
    const locale = (localStorage.getItem('locale') || "tr");
    const localeText =
        locale == "tr" ? AG_GRID_LOCALE_TR :
            locale == "es" ? AG_GRID_LOCALE_ES :
                AG_GRID_LOCALE_EN;

    return localeText;
}

function createGetRowIdProxy(getRowNodeId: GetRowNodeIdFunc): GetRowIdFunc {
    return function (params: GetRowIdParams) {
        return getRowNodeId(params.data);
    }
}

const GridComponent = forwardRef<AgGridReact, IGridProps>((props: IGridProps, gridRef) => {
    const [warningPasteDialogOpen, setWarningPasteDialogOpen] = useState<boolean>(false);
    const {formatMessage} = useIntl();
    const gridComponentRef = useRef();
    const theme = getThemeName();
    const localeTexts = getLocaleTexts();

    const {
        height = "100%",
        width = "100%",
        components,
        frameworkComponents
    } = props;

    const allComponents = {
        ...components,
        ...frameworkComponents
    };

    const getRowIdFunction: GetRowIdFunc | undefined =
        props.getRowId ? props.getRowId :
            props.getRowNodeId ? createGetRowIdProxy(props.getRowNodeId)
                : undefined;


    const getContextMenuItems = () => {
        if (props?.gridOptions?.contextMenuItems && props?.gridOptions?.contextMenuItems.length > 0) {
            return props?.gridOptions?.contextMenuItems;
        }

        const result = [
            'copy',
            "copyWithHeaders",
            "copyWithGroupHeaders",
            {

                name: formatMessage({id: "ftpFileEditor.paste", defaultMessage: "Paste"}),
                action: () => {

                    setWarningPasteDialogOpen(true)

                },

                shortcut: "Ctrl V",
            },
            'separator',
            "export"
        ];
        return result;
    };

    if (gridComponentRef.current) {
        const key = Object.keys(gridComponentRef.current).find((key) => {
            return (
                key.startsWith("__reactFiber$") || // react 17+
                key.startsWith("__reactinternalinstance$")
            ); // react <17
        });
        if (key) {
            const domfiber = gridComponentRef.current[key];
            const getcompfiber = (fiber) => {
                //return fiber._debugowner; // this also works, but is __dev__ only
                let parentfiber = fiber.return;
                while (typeof parentfiber.type == "string") {
                    parentfiber = parentfiber.return;
                }
                return parentfiber;
            };
            if (domfiber) {
                let compfiber = getcompfiber(domfiber);
                for (let i = 0; i < 1; i++) {
                    compfiber = getcompfiber(compfiber);
                }
                console.log("gridComponentRef.current", compfiber?.type?.name);

            }
        }
    }
    return (
        <>
            <div ref={gridComponentRef} className={theme} style={{height: height, width: width}}>
                <AgGridReact
                    ref={gridRef}
                    {...props}
                    localeText={localeTexts}
                    onGridReady={props.onGridReady}
                    onFirstDataRendered={props.onFirstDataRendered}
                    //getRowNodeId={props.getRowNodeId}
                    getRowId={getRowIdFunction}
                    columnDefs={props.columnDefs}
                    rowData={props.rowData}
                    components={allComponents}
                    //frameworkComponents={props.frameworkComponents}
                    onBodyScroll={props.onBodyScroll}
                    rowClassRules={props.rowClassRules}
                    masterDetail={props.masterDetail}
                    detailCellRenderer={props.detailCellRenderer}
                    detailRowAutoHeight={props.detailRowAutoHeight}
                    detailRowHeight={props.detailRowHeight}
                    onSelectionChanged={props.onSelectionChanged}
                    onRowClicked={props.onRowClicked}
                    headerHeight={props.headerHeight}
                    onRowDataChanged={props.onRowDataChanged}
                    sideBar={props.sideBar}
                    statusBar={props.statusBar}
                    gridOptions={props.gridOptions}
                    suppressScrollOnNewData={props.suppressScrollOnNewData}
                    embedFullWidthRows={props.embedFullWidthRows}
                    debounceVerticalScrollbar={props.debounceVerticalScrollbar}
                    detailCellRendererParams={props.detailCellRendererParams}
                    keepDetailRows={props.keepDetailRows}
                    getContextMenuItems={props.getContextMenuItems ?? props.gridOptions?.getContextMenuItems ?? getContextMenuItems}
                />
            </div>
            {warningPasteDialogOpen &&
                <Dialog
                    open={warningPasteDialogOpen}
                    canAutoPosition={false}
                    onClose={() => setWarningPasteDialogOpen(false)}

                >
                    <Box
                        style={{
                            padding: '10px',
                            width: '400px',
                        }}
                    >

                        <h2 style={{fontSize: '16px', marginBottom: '5px'}}><ErrorIcon
                            style={{float: "left", color: "blue"}}/> {<FormattedMessage id="global.inform"
                                                                                        defaultMessage="Inform"/>}
                        </h2>
                        <br/>
                        <p style={{fontSize: '14px', lineHeight: '1.4', textAlign: 'left',}}>{<FormattedMessage
                            id="global.informmessage"/>}</p>
                        <br/>
                        <text style={{
                            float: "right",
                            color: "blue",
                            bottom: "10px",
                            right: "10px",
                            position: "absolute",
                            cursor: "pointer",
                        }} onClick={() => setWarningPasteDialogOpen(false)}>{<FormattedMessage id="global.close"
                                                                                               defaultMessage="Close"/>}</text>

                    </Box>
                </Dialog>
            }
        </>
    );
});

//? do not use injectIntl here, instead use useIntl hook in the component. Death too OOP! Long live FP!
export const Grid = GridComponent;