import { FormatDateOptions, FormatNumberOptions } from 'react-intl';

import { EnabledPeriods, MeasureUnit } from './types';

export const PF_FINAL_FORECAST = 'FinalForecast';
export const PF_USER_FORECAST = 'UserForecast';
export const PF_UNKNOWN_PROVIDER = '#unknown#';

/**
 * Default Measure Unit
 */
export const PF_DEFAULT_MEASURE_UNIT = MeasureUnit.MW;
export const PF_DEFAULT_PERIOD = EnabledPeriods.P60;

export const PF_EMPTY_CELL_SYMBOL = '-';
export const PF_DEFAULT_DESCRIPTION = 'Saved from SmartPulse Portal Production Forecast';
export const PF_UPDATE_INFO_SEPERATOR = '#';
export const PF_GRID_HEADER_HEIGHT = 20;
export const PF_GRID_ROW_HEIGHT = 20;
export const PF_GRID_UPDATE_ROW_HEIGHT = PF_GRID_ROW_HEIGHT * 3;
export const PF_GRID_SOURCE_ROW_HEIGHT = PF_GRID_ROW_HEIGHT * 1.25;

/** Default Rounding Digit, if 2 then 1.2323 => 1.23 */
export const PF_DRD = 2;
export const PF_HISTORY_COOLDOWN_SECONDS = 3;

/** Keep lock popover open if action is has more than this number of units */
export const PF_LOCK_UNIT_THRESHOLD = 8;

export const PF_DATE_FORMAT = 'yyyy-MM-DD';
export const PF_DATE_TIME_FORMAT = 'yyyy-MM-DDTHH:mm:ss';
export const PF_FILE_DATE_FORMAT = 'yyyy-MM-DD';

export const pfFormatOptions = Object.freeze({
  date: {
    updateDate: {
      day: '2-digit',
      month: '2-digit',
      year: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      timeZoneName: 'short',
    },
    uploadFileDate: {
      day: '2-digit',
      month: '2-digit',
      year: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      timeZoneName: 'short',
    },
  } as Record<string, FormatDateOptions>,
  number: {
    prediction: {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
      useGrouping: false,
    },
    predictionKWh: {
      maximumFractionDigits: 0,
      minimumFractionDigits: 0,
      useGrouping: false,
    },
  } as Record<string, FormatNumberOptions>,
});
