const defaultValues = {};

const groupreport = (state = defaultValues, action) => {
    switch (action.type) {
        case "HANDLE_REPORT_GET":
            return {...state, urls: action.reply};
        default:
            return state;
    }
};

export default groupreport;