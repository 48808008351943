const defaultValues = { 
    log:[] 
};

const dashboard  = (state = defaultValues , action) => {
    switch (action.type) { 
        case 'SET_LOG':
            return {...state, log: action.log};
        default:
            return state
    }
};

export default dashboard;