// @ts-ignore
import {API} from "./backend";

export interface BaseReply<TData = null> {
    isAuthorizationError?: boolean,
    isError?: boolean,
    ErrorMessage?: string,
    replyObject?: TData
    errorCode?: string,
    errorArguments: any[]
}

const nodeEnv = import.meta.env.MODE;
const isDevelopment = nodeEnv === "development";

export abstract class ServiceBase {

    abstract buildUrl(action: string): string;

    /**
     * Some server methods do not use BaseReply<T> and return their own types. This method is intended
     * to solve this issue by allowing caller to resolve their intended type, of course assuming
     * all the methods will use same structure.
     * @param reply Reply object to resolve
     * @returns Resolve value object that was supposed to be in replyObject property of reply.
     */
    protected resolveValue<TIn, TOut>(reply: BaseReply<TIn>): TOut | undefined {
        return reply.replyObject as unknown as TOut;
    }

    private static async getUrl<T>(request: RequestInfo): Promise<T> {
        const response = await fetch(request);
        const body = await response.json();
        return body;
    }

    private buildUrlInternal(action: string): string {
        let url = this.buildUrl(action);
        if (isDevelopment && url && !url.startsWith('http') ) {
            url = '/s/' + url;
        }
        return url;
    }

    protected async makeGetCall<TReturn>(action: string): Promise<TReturn> {
        const promise = new Promise<TReturn>(async (accept, reject) => {
            const url = this.buildUrlInternal(action);

            try {
                const result = await ServiceBase.getUrl<BaseReply<TReturn> & { errorMessage?: string }>(url);

                if (result.isError === true) {
                    reject(result.ErrorMessage ?? result.errorMessage);
                    return;
                }

                const value = this.resolveValue(result) as TReturn;
                accept(value);
            } catch (err) {
                reject(err);
            }
        });

        return promise;
    }

    protected async makePostCallWithRawResult<TReturn>(action: string, data: any, noLoader: boolean = false): Promise<BaseReply<TReturn> & { errorMessage?: string }> {
        const promise = new Promise<BaseReply<TReturn> & { errorMessage?: string }>((accept, reject) => {

            const url = this.buildUrl(action);

            API.PostAPI(data, url, noLoader, noLoader)
               .fail((reason: string) => reject(reason))
               .done((result: BaseReply<TReturn> & { errorMessage?: string }) => {
                   accept(result);
               });
        });

        return promise;
    }

    protected async makePostCall<TReturn>(action: string, data: any, noLoader: boolean = false): Promise<TReturn> {
        const promise = new Promise<TReturn>((accept, reject) => {

            const url = this.buildUrl(action);

            API.PostAPI(data, url, noLoader, noLoader)
                .fail((reason: string) => reject(reason))
                .done((result: BaseReply<TReturn> & { errorMessage?: string }) => {
                    if (result.isError === true) {
                        if (result.errorCode) {
                            reject({message: result.ErrorMessage ?? result.errorMessage, code: result.errorCode});
                        } else {
                            reject(result.ErrorMessage ?? result.errorMessage);
                        }
                    } else {
                        accept(this.resolveValue(result)!);
                    }
                });
        });

        return promise;
    }
}