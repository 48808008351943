import React from "react";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

type SvgIconComponent = typeof SvgIcon;

import {
    ZoomOut as MaterialZoomOutIcon,
    ZoomIn as MaterialZoomInIcon,
    KeyboardArrowLeft as  MaterialKeyboardArrowLeftIcon,
    KeyboardArrowRight as  MaterialKeyboardArrowRightIcon,
    AssignmentTurnedIn as MaterialAssignmentTurnedInIcon,
    AssignmentLate as MaterialAssignmentLateIcon,
    Save as MaterialSaveIcon,
    Notifications as MaterialNotificationsIcon,
    Alarm as MaterialNotificationsActiveIcon,
    NotificationsNone as MaterialNotificationsNoneIcon,
    Refresh as MaterialRefreshIcon,
    CloudDownload as MaterialCloudDownloadIcon,
    SaveAlt as MaterialSaveAltIcon,
    VerticalAlignBottom as MaterialVerticalAlignBottomIcon,
    CloudUpload as MaterialCloudUploadIcon,
    Info as MaterialInfoIcon,
    QuestionMark as MaterialQuestionMarkIcon,
    Announcement as MaterialAnnouncementIcon,
    AccessTime as MaterialAccessTimeIcon,
    Accessibility as MaterialAccessibilityIcon,
    LockOpen as MaterialLockOpenIcon,
    KeyboardArrowDown as MaterialKeyboardArrowDownIcon,
    Alarm as MaterialAlarmIcon,
    Flag as MaterialFlagIcon,
    LocalOffer as MaterialLocalOfferIcon,
    PlayArrow as MaterialPlayArrowIcon,
    Delete as MaterialDeleteIcon,
    LayersClear as MaterialLayersClearIcon,
    PauseCircleOutline as MaterialPauseCircleOutlineIcon,
    ArrowRight as MaterialArrowRightIcon,
    ArrowLeft as MaterialArrowLeftIcon,
    NavigateBefore as MaterialNavigateBeforeIcon,
    NavigateNext as MaterialNavigateNextIcon,
    Add as MaterialAddIcon,
    MailOutline as MaterialMailOutlineIcon,
    Settings as MaterialSettingsIcon,
    Send as MaterialSendIcon,
    ScheduleSend as MaterialScheduleSendIcon,
    Start as MaterialStartIcon,
    KeyboardTab as MaterialKeyboardTabIcon,
    VerticalSplitOutlined as MaterialVerticalSplitOutlinedIcon,
    Title as MaterialTitleIcon,
    OpenInNew as MaterialOpenInNewIcon,
    MoreVert as MaterialMoreVertIcon,
    Search as MaterialSearchIcon,
    ExitToApp as MaterialExitToAppIcon,
    Edit as MaterialEditIcon,
    Clear as MaterialClearIcon,
    Close as MaterialCloseIcon,
    DeleteSweep as MaterialDeleteSweepIcon,
    Security as MaterialSecurityIcon,
    Warning as MaterialWarningIcon,
    TravelExplore as MaterialDetailsIcon,
    ManageSearch as MaterialManageSearchIcon,
    ExpandMore as MaterialExpandMoreIcon,
    Help as MaterialHelpIcon,
    Error as MaterialErrorIcon,
    CastConnected as MaterialCastConnectedIcon,
    Cast as MaterialCastIcon,
    CheckCircle as MaterialDirectionsRunIcon,
    Assessment as MaterialAssessmentIcon,
    Publish as MaterialPublishIcon,
    GetApp as MaterialGetAppIcon,
    Circle as MaterialCircleIcon,
    RemoveCircle as MaterialRemoveCircleIcon,
    AddCircle as MaterialAddCircleIcon,
    FlipToBack as MaterialFlipToBackIcon,
    FlipToFront as MaterialFlipToFrontIcon,
    Badge as MaterialBadgeIcon,
    ContentCopy as MaterialContentCopyIcon,
    History as MaterialHistoryIcon,
    HourglassEmpty as MaterialHourglassEmptyIcon,
    WatchLater as MaterialWatchLaterIcon,
    AvTimer as MaterialAvTimerIcon,
    DoneAll as MaterialDoneAllIcon,
    AcUnit as MaterialAcUnitIcon,
    ExpandLess as MaterialExpandLessIcon,
    Warehouse as MaterialWarehouseIcon,
    AddCircleOutline as MaterialAddCircleOutlineIcon,
    Done as MaterialDoneIcon,
    ClearAll as MaterialClearAllIcon,
    SendOutlined as MaterialSendOutlinedIcon,
    Build as MaterialBuildIcon,
    Block as MaterialBlockIcon,
    Loyalty as MaterialLoyaltyIcon,
    Label as MaterialLabelIcon,
    LabelImportant as MaterialLabelImportantIcon,
    TrendingUp as MaterialTrendingUpIcon,
    TrendingDown as MaterialTrendingDownIcon,
    PlayForWork as MaterialPlayForWorkIcon,
    FastForward as MaterialFastForwardIcon,
    PlaylistAdd as MaterialPlaylistAddIcon,
    Update as MaterialUpdateIcon,
    TableChart as MaterialTableChartIcon,
    GridOn as MaterialGridOnIcon,
    BarChart as MaterialBarChartIcon,
    Restore as MaterialRestoreIcon,
    DateRange as MaterialDateRangeIcon,
    Visibility as MaterialVisibilityIcon,
    Business as MaterialBusinessIcon,
    Code as MaterialCodeIcon,
    TextFields as MaterialTextFieldsIcon,
    Dialpad as MaterialDialpadIcon,
    Money as MaterialMoneyIcon,
    SwitchLeft as MaterialSwitchLeftIcon,
    Attractions as MaterialAttractionsIcon,
    PlaylistPlay as MaterialPlaylistPlayIcon,
    Airplay as MaterialAirplayIcon,
    Toc as MaterialTocIcon,
    List as MaterialListIcon,
    PriorityHigh as MaterialPriorityHighIcon,
    Login as MaterialLoginIcon,
    ArrowForward as MaterialArrowForwardIcon,
    KeyboardArrowUp as MaterialKeyboardArrowUpIcon,
    Logout as MaterialLogoutIcon,
    Home as MaterialHomeIcon,
    Fullscreen as MaterialFullscreenIcon,
    FullscreenExit as MaterialFullscreenExitIcon,
    ScreenShare as MaterialScreenShareIcon,
    Dashboard as MaterialDashboardIcon,
    Timer as MaterialTimerIcon,
    EvStation as MaterialEvStationIcon,
    SettingsBackupRestore as MaterialSettingsBackupRestoreIcon,
    Hearing as MaterialHearingIcon,
    RestartAlt as MaterialRestartAltIcon,
    Password as MaterialPasswordIcon,
    AccountBox as MaterialAccountBoxIcon,
    SupervisedUserCircle as MaterialSupervisedUserCircleIcon,
    LastPage as MaterialLastPageIcon,
    FirstPage as MaterialFirstPageIcon,
    Calculate as MaterialCalculateIcon,
    Reorder as MaterialReorderIcon,
    LocalMall as MaterialLocalMallIcon,
    RateReview as MaterialRateReviewIcon,
    TaskAlt as MaterialTaskAltIcon,
    DynamicFeed as MaterialDynamicFormIcon,
    SystemUpdateAlt as MaterialSystemUpdateAltIcon,
    Timeline as MaterialTimelineIcon,
    ViewTimeline as MaterialViewTimelineIcon,
    HistoryToggleOff as MeterialHistoryToggleOffIcon,
    TextSnippet as MaterialTextSnippetIcon,
    PlayLesson as MaterialPlayLessonIcon,
    ViewAgenda as MaterialViewAgendaIcon,
    FeaturedPlayList as MaterialFeaturedPlayListIcon,
    Work as MaterialWorkIcon,
    Menu as MaterialMenuIcon,
    BookmarkAdd as MaterialBookmarkAddIcon,
    BookmarkRemove as MaterialBookmarkRemoveIcon,
    Subscriptions as MaterialSubscriptionsIcon,
    PieChart as MaterialPieChartIcon,
    Launch as MaterialLaunchIcon,
    Notes as MaterialNotesIcon,
    Receipt as MaterialReceiptIcon,
    BusinessCenter as MaterialBusinessCenterIcon,
    Report as MaterialPreviewIcon,
    Language as MaterialLanguageIcon,
    Star as MaterialStarIcon,
    NewReleases as MaterialNewReleasesIcon,
    Person as MaterialPersonIcon,
    Payment as MaterialPaymentIcon,
    BatchPrediction as MaterialBatchPredictionIcon,
    NotificationImportant as MaterialNotificationImportantIcon,
    NotificationImportantTwoTone as MaterialNotificationImportantTwoToneIcon,
    QuestionAnswer as MaterialQuestionAnswerIcon,
    InsertChart as MaterialInsertChartIcon,
    ShowChart as MaterialShowChartIcon,
    ShoppingCart as MaterialShoppingCartIcon,
    TrackChanges as MaterialTrackChangesIcon,
    Lock as MaterialLockIcon,
    FilterList as MaterialFilterListIcon,
    MiscellaneousServices as MaterialMiscellaneousServicesIcon,
    Healing as MaterialHealingIcon,
    SignalWifiStatusbar4Bar as MaterialSignalWifiStatusbar4BarIcon,
    SignalWifiStatusbarConnectedNoInternet4 as MaterialSignalWifiStatusbarConnectedNoInternet4Icon,
    WaterfallChart as MaterialWaterfallChartIcon,
    Storefront as MaterialStorefrontIcon,
    Analytics as MaterialAnalyticsIcon,
    HearingDisabled as MaterialHearingDisabledIcon,
    AccountBalance as MaterialAccountBalanceIcon,
    FilterAlt as MaterialFilterAltIcon,
    Check as MaterialCheckIcon,
    HighlightOff as MaterialHighlightOffIcon,
    WbSunny as MaterialWbSunnyIcon,
    Water as MaterialWaterIcon,
    Air as MaterialAirIcon,
    Public as MaterialPublicIcon,
    Event as MaterialEventIcon,
    RemoveCircleOutline as MaterialRemoveCircleOutlineIcon,
    Dvr as MaterialDvrIcon,
    PhoneAndroid as MaterialPhoneAndroidIcon,
    MarkChatRead as MaterialMarkChatReadIcon,
    ChevronRight as MaterialCollapseIcon,
    Folder as MaterialFolderIcon,
    AccessAlarm as MaterialAccessAlarmIcon,
    Cancel as MaterialCancelIcon,
    Link as MaterialLinkIcon,
    AutoAwesomeMotion as MaterialAutoAwesomeMotionIcon,
    ArrowBack as MaterialArrowBackIcon,
    MoreHoriz as MaterialMoreHorizIcon,
    Factory as MaterialFactoryIcon,
    ManageAccounts as MaterialManageAccountsIcon,
    LowPriority as MaterialLowPriorityIcon,
    ArrowForwardIos as MaterialArrowForwardIosIcon,
    Handshake as MaterialHandshakeIcon,
    Balance as MaterialBalanceIcon,
    ArrowDropDown as MaterialArrowDropDownIcon,
    ArrowDropUp as MaterialArrowDropUpIcon,
    Undo as MaterialUndoIcon,
    Autorenew as MaterialAutoRenewIcon,
    Forward as MaterialForwardIcon,
    SouthWest as MaterialSouthWestIcon,
    SouthEast as MaterialSouthEastIcon,
    NorthEast as MaterialNorthEastIcon,
    NorthWest as MaterialNorthWestIcon,
    ContentPaste as MaterialContentPasteIcon,
    FormatListNumbered as MaterialFormatListNumberedIcon,
    Subject as MaterialSubjectIcon,
    Sort as MaterialSortIcon,

    CheckBoxOutlineBlank as MaterialCheckBoxOutlineBlankIcon,
    CheckBox as MaterialCheckBoxIcon,
    Tune as MaterialTuneIcon,
    Category as MaterialCategoryIcon,
    CheckCircleOutline,
    CorporateFare,
    Science as MaterialScienceIcon
} from "@mui/icons-material";

export const ZoomOutIcon: SvgIconComponent = MaterialZoomOutIcon;
export const ZoomInIcon: SvgIconComponent = MaterialZoomInIcon;
export const KeyboardArrowRightIcon: SvgIconComponent = MaterialKeyboardArrowRightIcon;
export const KeyboardArrowLeftIcon: SvgIconComponent = MaterialKeyboardArrowLeftIcon;
export const ArrowDropDownIcon: SvgIconComponent = MaterialArrowDropDownIcon;
export const ArrowDropUpIcon: SvgIconComponent = MaterialArrowDropUpIcon;
export const ArrowForwardIosIcon: SvgIconComponent = MaterialArrowForwardIosIcon;
export const LowPriorityIcon: SvgIconComponent = MaterialLowPriorityIcon;
export const FactoryIcon: SvgIconComponent = MaterialFactoryIcon;
export const MoreHorizIcon: SvgIconComponent = MaterialMoreHorizIcon;
export const ArrowBackIcon: SvgIconComponent = MaterialArrowBackIcon;
export const AutoAwesomeMotionIcon: SvgIconComponent = MaterialAutoAwesomeMotionIcon;
export const LinkIcon: SvgIconComponent = MaterialLinkIcon;
export const DecreaseNumberIcon: SvgIconComponent = MaterialRemoveCircleOutlineIcon;
export const SaveIcon: SvgIconComponent = MaterialSaveIcon;
export const AssignmentTurnedInIcon : SvgIconComponent = MaterialAssignmentTurnedInIcon;
export const AssignmentLateIcon : SvgIconComponent = MaterialAssignmentLateIcon;
export const NotificationsIcon: SvgIconComponent = MaterialNotificationsIcon;
export const RefreshIcon: SvgIconComponent = MaterialRefreshIcon;
export const DownloadFileIcon: SvgIconComponent = MaterialCloudDownloadIcon;
export const SaveAltIcon: SvgIconComponent = MaterialSaveAltIcon;
export const VerticalAlignBottomIcon: SvgIconComponent = MaterialVerticalAlignBottomIcon;
export const UploadFileIcon: SvgIconComponent = MaterialCloudUploadIcon;
export const InfoIcon: SvgIconComponent = MaterialInfoIcon;
export const QuestionMarkIcon: SvgIconComponent = MaterialQuestionMarkIcon;
export const AnnouncementIcon: SvgIconComponent = MaterialAnnouncementIcon;
export const AccessTimeIcon: SvgIconComponent = MaterialAccessTimeIcon;
export const LockOpenIcon: SvgIconComponent = MaterialLockOpenIcon;
export const KeyboardArrowDownIcon: SvgIconComponent = MaterialKeyboardArrowDownIcon;
export const KeyboardArrowUpIcon: SvgIconComponent = MaterialKeyboardArrowUpIcon;
export const AlarmIcon: SvgIconComponent = MaterialAlarmIcon;
export const FlagIcon: SvgIconComponent = MaterialFlagIcon;
export const LocalOfferIcon: SvgIconComponent = MaterialLocalOfferIcon;
export const PlayArrowIcon: SvgIconComponent = MaterialPlayArrowIcon;
export const DeleteIcon: SvgIconComponent = MaterialDeleteIcon;
export const LayersClearIcon: SvgIconComponent = MaterialLayersClearIcon;
export const PauseCircleOutlineIcon: SvgIconComponent = MaterialPauseCircleOutlineIcon;
export const ArrowRightIcon: SvgIconComponent = MaterialArrowRightIcon;
export const ArrowLeftIcon: SvgIconComponent = MaterialArrowLeftIcon;
export const NavigateBeforeIcon: SvgIconComponent = MaterialNavigateBeforeIcon;
export const NavigateNextIcon: SvgIconComponent = MaterialNavigateNextIcon;
export const AddIcon: SvgIconComponent = MaterialAddIcon;
export const MailOutlineIcon: SvgIconComponent = MaterialMailOutlineIcon;
export const SettingsIcon: SvgIconComponent = MaterialSettingsIcon;
export const SendIcon: SvgIconComponent = MaterialSendIcon;
export const ScheduleSendIcon: SvgIconComponent = MaterialScheduleSendIcon;
export const OpenInNewIcon: SvgIconComponent = MaterialOpenInNewIcon;
export const StartIcon: SvgIconComponent = MaterialStartIcon;
export const KeyboardTabIcon: SvgIconComponent = MaterialKeyboardTabIcon;
export const VerticalSplitOutlinedIcon: SvgIconComponent = MaterialVerticalSplitOutlinedIcon;
export const TitleIcon: SvgIconComponent = MaterialTitleIcon;
export const MoreVertIcon: SvgIconComponent = MaterialMoreVertIcon;
export const SearchIcon: SvgIconComponent = MaterialSearchIcon;
export const ExitToAppIcon: SvgIconComponent = MaterialExitToAppIcon;
export const EditIcon: SvgIconComponent = MaterialEditIcon;
export const ClearIcon: SvgIconComponent = MaterialClearIcon;
export const CloseIcon: SvgIconComponent = MaterialCloseIcon;
export const DeleteSweepIcon: SvgIconComponent = MaterialDeleteSweepIcon;
export const SecurityIcon: SvgIconComponent = MaterialSecurityIcon;
export const WarningIcon: SvgIconComponent = MaterialWarningIcon;
export const DetailsIcon: SvgIconComponent = MaterialDetailsIcon;
export const ManageSearchIcon: SvgIconComponent = MaterialManageSearchIcon;
export const NotificationsActiveIcon: SvgIconComponent = MaterialNotificationsActiveIcon;
export const NotificationsNoneIcon: SvgIconComponent = MaterialNotificationsNoneIcon;
export const ExpandMoreIcon: SvgIconComponent = MaterialExpandMoreIcon;
export const HelpIcon: SvgIconComponent = MaterialHelpIcon;
export const ErrorIcon: SvgIconComponent = MaterialErrorIcon;
export const CastConnectedIcon: SvgIconComponent = MaterialCastConnectedIcon;
export const ConnectedIcon: SvgIconComponent = MaterialCastConnectedIcon;
export const CastIcon: SvgIconComponent = MaterialCastIcon;
export const DisconnectedIcon: SvgIconComponent = MaterialCastIcon;
export const DirectionsRunIcon: SvgIconComponent = MaterialDirectionsRunIcon;
export const RunningIcon: SvgIconComponent = MaterialDirectionsRunIcon;
export const AssessmentIcon: SvgIconComponent = MaterialAssessmentIcon;
export const PublishIcon: SvgIconComponent = MaterialPublishIcon;
export const BuyIcon: SvgIconComponent = MaterialPublishIcon;
export const GetApp: SvgIconComponent = MaterialGetAppIcon;
export const SellIcon: SvgIconComponent = MaterialGetAppIcon;
export const Circle: SvgIconComponent = MaterialCircleIcon;
export const RemoveCircleIcon: SvgIconComponent = MaterialRemoveCircleIcon;
export const AddCircleIcon: SvgIconComponent = MaterialAddCircleIcon;
export const IncreaseNumberIcon: SvgIconComponent = MaterialAddCircleOutlineIcon;
export const FlipToBackIcon: SvgIconComponent = MaterialFlipToBackIcon;
export const MakePassiveIcon: SvgIconComponent = MaterialFlipToBackIcon;
export const FlipToFrontIcon: SvgIconComponent = MaterialFlipToFrontIcon;
export const MakeActiveIcon: SvgIconComponent = MaterialFlipToFrontIcon;
export const BadgeIcon: SvgIconComponent = MaterialBadgeIcon;
export const TagsIcon: SvgIconComponent = MaterialLabelIcon;
export const ContentCopyIcon: SvgIconComponent = MaterialContentCopyIcon;
export const HistoryIcon: SvgIconComponent = MaterialHistoryIcon;
export const HourglassEmptyIcon: SvgIconComponent = MaterialHourglassEmptyIcon;
export const WaitingIcon: SvgIconComponent = MaterialHourglassEmptyIcon;
export const AvTimerIcon: SvgIconComponent = MaterialAvTimerIcon;
export const WatchLaterIcon: SvgIconComponent = MaterialWatchLaterIcon;
export const SingleContractIcon: SvgIconComponent = MaterialAvTimerIcon;
export const BlockContractIcon: SvgIconComponent = MaterialWatchLaterIcon;
export const DoneAllIcon: SvgIconComponent = MaterialDoneAllIcon;
export const TradesIcon: SvgIconComponent = MaterialDoneAllIcon;
export const AcUnitIcon: SvgIconComponent = MaterialAcUnitIcon;
export const IcebergOfferIcon: SvgIconComponent = MaterialAcUnitIcon;
export const ExpandLessIcon: SvgIconComponent = MaterialExpandLessIcon;
export const WarehouseIcon: SvgIconComponent = MaterialWarehouseIcon;
export const AddCircleOutlineIcon: SvgIconComponent = MaterialAddCircleOutlineIcon;
export const DoneIcon: SvgIconComponent = MaterialDoneIcon;
export const ClearAllIcon: SvgIconComponent = MaterialClearAllIcon;
export const SendOutlinedIcon: SvgIconComponent = MaterialSendOutlinedIcon;
export const BuildIcon: SvgIconComponent = MaterialBuildIcon;
export const BlockIcon: SvgIconComponent = MaterialBlockIcon;
export const DisabledIcon: SvgIconComponent = MaterialBlockIcon;
export const LoyaltyIcon: SvgIconComponent = MaterialLoyaltyIcon;
export const SystemTagsIcon: SvgIconComponent = MaterialLabelImportantIcon;
export const LabelIcon: SvgIconComponent = MaterialLabelIcon;
export const LabelImportantIcon: SvgIconComponent = MaterialLabelImportantIcon;
export const TrendingUpIcon: SvgIconComponent = MaterialTrendingUpIcon;
export const TrendingDownIcon: SvgIconComponent = MaterialTrendingDownIcon;
export const PlayForWorkIcon: SvgIconComponent = MaterialPlayForWorkIcon;
export const FastForwardIcon: SvgIconComponent = MaterialFastForwardIcon;
export const PlaylistAddIcon: SvgIconComponent = MaterialPlaylistAddIcon;
export const UpdateIcon: SvgIconComponent = MaterialUpdateIcon;
export const TableChartIcon: SvgIconComponent = MaterialTableChartIcon;
export const GridOnIcon: SvgIconComponent = MaterialGridOnIcon;
export const BarChartIcon: SvgIconComponent = MaterialBarChartIcon;
export const RestoreIcon: SvgIconComponent = MaterialRestoreIcon;
export const DateRangeIcon: SvgIconComponent = MaterialDateRangeIcon;
export const PeriodIcon: SvgIconComponent = MaterialDateRangeIcon;
export const VisibilityIcon: SvgIconComponent = MaterialVisibilityIcon;
export const BusinessIcon: SvgIconComponent = MaterialBusinessIcon;
export const CompanyIcon: SvgIconComponent = MaterialBusinessIcon;
export const CodeIcon: SvgIconComponent = MaterialCodeIcon;
export const TextFieldsIcon: SvgIconComponent = MaterialTextFieldsIcon;
export const AlphanumericIcon: SvgIconComponent = MaterialTextFieldsIcon;
export const DialpadIcon: SvgIconComponent = MaterialDialpadIcon;
export const NumericIcon: SvgIconComponent = MaterialDialpadIcon;
export const MoneyIcon: SvgIconComponent = MaterialMoneyIcon;
export const CurrencyIcon: SvgIconComponent = MaterialMoneyIcon;
export const SwitchLeftIcon: SvgIconComponent = MaterialSwitchLeftIcon;
export const BooleanIcon: SvgIconComponent = MaterialSwitchLeftIcon;
export const AttractionsIcon: SvgIconComponent = MaterialAttractionsIcon;
export const SmartBotActionsIcon: SvgIconComponent = MaterialAttractionsIcon;
export const SmartBotExecuteIcon: SvgIconComponent = MaterialPlayArrowIcon;
export const PlaylistPlayIcon: SvgIconComponent = MaterialPlaylistPlayIcon;
export const SmartBotListIcon: SvgIconComponent = MaterialPlaylistPlayIcon;
export const AirplayIcon: SvgIconComponent = MaterialAirplayIcon;
export const SmartBotEvaluateIcon: SvgIconComponent = MaterialAirplayIcon;
export const TocIcon: SvgIconComponent = MaterialTocIcon;
export const SmartBotMatrixValuesIcon: SvgIconComponent = MaterialTocIcon;
export const ListIcon: SvgIconComponent = MaterialListIcon;
export const SmartBotExecutionStepsIcon: SvgIconComponent = MaterialListIcon;
export const PriorityHighIcon: SvgIconComponent = MaterialPriorityHighIcon;
export const LoginIcon: SvgIconComponent = MaterialLoginIcon;
export const ArrowForwardIcon: SvgIconComponent = MaterialArrowForwardIcon;
export const LogoutIcon: SvgIconComponent = MaterialLogoutIcon;
export const HomeIcon: SvgIconComponent = MaterialHomeIcon;
export const FullscreenIcon: SvgIconComponent = MaterialFullscreenIcon;
export const FullscreenExitIcon: SvgIconComponent = MaterialFullscreenExitIcon;
export const ScreenShareIcon: SvgIconComponent = MaterialScreenShareIcon;
export const DashboardIcon: SvgIconComponent = MaterialDashboardIcon;
export const TimerIcon: SvgIconComponent = MaterialTimerIcon;
export const EvStationIcon: SvgIconComponent = MaterialEvStationIcon;
export const PowerPlantIcon: SvgIconComponent = MaterialEvStationIcon;
export const CorporateFareIcon: SvgIconComponent = CorporateFare;
export const SettingsBackupRestoreIcon: SvgIconComponent = MaterialSettingsBackupRestoreIcon;
export const BacktestIcon: SvgIconComponent = MaterialSettingsBackupRestoreIcon;
export const HearingIcon: SvgIconComponent = MaterialHearingIcon;
export const SmartBotListenersIcon: SvgIconComponent = MaterialHearingIcon;
export const RestartAltIcon: SvgIconComponent = MaterialRestartAltIcon;
export const SmartBotRestartIcon: SvgIconComponent = MaterialRestartAltIcon;
export const PasswordIcon: SvgIconComponent = MaterialPasswordIcon;
export const AccountBoxIcon: SvgIconComponent = MaterialAccountBoxIcon;
export const SupervisedUserCircleIcon: SvgIconComponent = MaterialSupervisedUserCircleIcon;
export const LastPageIcon: SvgIconComponent = MaterialLastPageIcon;
export const FirstPageIcon: SvgIconComponent = MaterialFirstPageIcon;
export const CalculateIcon: SvgIconComponent = MaterialCalculateIcon;
export const ReorderIcon: SvgIconComponent = MaterialReorderIcon;
export const LocalMallIcon: SvgIconComponent = MaterialLocalMallIcon;
export const IntradayMarketIcon: SvgIconComponent = MaterialLocalMallIcon;
export const RateReviewIcon: SvgIconComponent = MaterialRateReviewIcon;
export const DPPIcon: SvgIconComponent = MaterialRateReviewIcon;
export const TaskAltIcon: SvgIconComponent = MaterialTaskAltIcon;
export const DynamicFormIcon: SvgIconComponent = MaterialDynamicFormIcon;
export const DynamicColumnIcon: SvgIconComponent = MaterialDynamicFormIcon;
export const SystemUpdateAltIcon: SvgIconComponent = MaterialSystemUpdateAltIcon;
export const TimelineIcon: SvgIconComponent = MaterialTimelineIcon;
export const ViewTimelineIcon: SvgIconComponent = MaterialViewTimelineIcon;
export const HistoryToggleOffIcon: SvgIconComponent = MeterialHistoryToggleOffIcon;
export const TextSnippetIcon: SvgIconComponent = MaterialTextSnippetIcon;
export const PlayLessonIcon: SvgIconComponent = MaterialPlayLessonIcon;
export const ViewAgendaIcon: SvgIconComponent = MaterialViewAgendaIcon;
export const SmartBotBasicIcon: SvgIconComponent = MaterialViewAgendaIcon;
export const FeaturedPlayListIcon: SvgIconComponent = MaterialFeaturedPlayListIcon;
export const SmartBotAdvancedIcon: SvgIconComponent = MaterialDashboardIcon;
export const WorkIcon: SvgIconComponent = MaterialWorkIcon;
export const SmartBotFlowIcon: SvgIconComponent = MaterialWorkIcon;
export const MenuIcon: SvgIconComponent = MaterialMenuIcon;
export const BookmarkAddIcon: SvgIconComponent = MaterialBookmarkAddIcon;
export const BookmarkRemoveIcon: SvgIconComponent = MaterialBookmarkRemoveIcon;
export const SubscriptionsIcon: SvgIconComponent = MaterialSubscriptionsIcon;
export const PieChartIcon: SvgIconComponent = MaterialPieChartIcon;
export const LaunchIcon: SvgIconComponent = MaterialLaunchIcon;
export const NotesIcon: SvgIconComponent = MaterialNotesIcon;
export const ReceiptIcon: SvgIconComponent = MaterialReceiptIcon;
export const BusinessCenterIcon: SvgIconComponent = MaterialBusinessCenterIcon;
export const ContractManagementIcon: SvgIconComponent = MaterialBusinessCenterIcon;
export const PreviewIcon: SvgIconComponent = MaterialPreviewIcon;
export const LanguageIcon: SvgIconComponent = MaterialLanguageIcon;
export const StarIcon: SvgIconComponent = MaterialStarIcon;
export const NewFeaturesIcon: SvgIconComponent = MaterialStarIcon;
export const NewReleasesIcon: SvgIconComponent = MaterialNewReleasesIcon;
export const PersonIcon: SvgIconComponent = MaterialPersonIcon;
export const PaymentIcon: SvgIconComponent = MaterialPaymentIcon;
export const BatchPredictionIcon: SvgIconComponent = MaterialBatchPredictionIcon;
export const NotificationImportantIcon: SvgIconComponent = MaterialNotificationImportantIcon;
export const NotificationImportantTwoToneIcon: SvgIconComponent = MaterialNotificationImportantTwoToneIcon;
export const QuestionAnswerIcon: SvgIconComponent = MaterialQuestionAnswerIcon;
export const InsertChartIcon: SvgIconComponent = MaterialInsertChartIcon;
export const ShowChartIcon: SvgIconComponent = MaterialShowChartIcon;
export const LineChartIcon: SvgIconComponent = MaterialShowChartIcon;
export const ShoppingCartIcon: SvgIconComponent = MaterialShoppingCartIcon;
export const TrackChangesIcon: SvgIconComponent = MaterialTrackChangesIcon;
export const LockIcon: SvgIconComponent = MaterialLockIcon;
export const FilterListIcon: SvgIconComponent = MaterialFilterListIcon;
export const MiscellaneousServicesIcon: SvgIconComponent = MaterialMiscellaneousServicesIcon;
export const SmartBotOperationsIcon: SvgIconComponent = MaterialMiscellaneousServicesIcon;
export const HealingIcon: SvgIconComponent = MaterialHealingIcon;
export const UnhealthIcon: SvgIconComponent = MaterialHealingIcon;
export const SignalWifiStatusbar4BarIcon: SvgIconComponent = MaterialSignalWifiStatusbar4BarIcon;
export const SignalWifiStatusbarConnectedNoInternet4Icon: SvgIconComponent = MaterialSignalWifiStatusbarConnectedNoInternet4Icon;
export const AccountConnectedIcon: SvgIconComponent = MaterialSignalWifiStatusbar4BarIcon;
export const AccountDisconnectedIcon: SvgIconComponent = MaterialSignalWifiStatusbarConnectedNoInternet4Icon;
export const WaterfallChartIcon: SvgIconComponent = MaterialWaterfallChartIcon;
export const CandleGraphsChartIcon: SvgIconComponent = MaterialWaterfallChartIcon;
export const StorefrontIcon: SvgIconComponent = MaterialStorefrontIcon;
export const AnalyticsIcon: SvgIconComponent = MaterialAnalyticsIcon;
export const HearingDisabledIcon: SvgIconComponent = MaterialHearingDisabledIcon;
export const SmartBotDisableListenersIcon: SvgIconComponent = MaterialHearingDisabledIcon;
export const AccountBalanceIcon: SvgIconComponent = MaterialAccountBalanceIcon;
export const HoldingCompanyIcon: SvgIconComponent = MaterialAccountBalanceIcon;
export const FilterIcon: SvgIconComponent = MaterialFilterAltIcon;
export const CheckIcon: SvgIconComponent = MaterialCheckIcon;
export const CheckCircleOutlineIcon: SvgIconComponent = CheckCircleOutline;
export const HighlightOffIcon: SvgIconComponent =  MaterialHighlightOffIcon;
export const WbSunnyIcon: SvgIconComponent = MaterialWbSunnyIcon;
export const SolarPowerPlantIcon: SvgIconComponent = MaterialWbSunnyIcon;
export const WaterIcon: SvgIconComponent = MaterialWaterIcon;
export const WaterPowerPlantIcon: SvgIconComponent = MaterialWaterIcon;
export const AirIcon: SvgIconComponent = MaterialAirIcon;
export const WindPowerPlantIcon: SvgIconComponent = MaterialAirIcon;
export const PublicIcon: SvgIconComponent = MaterialPublicIcon;
export const GeothermalPowerPlantIcon: SvgIconComponent = MaterialPublicIcon;
export const EventIcon: SvgIconComponent = MaterialEventIcon;
export const DatePickerIcon: SvgIconComponent = MaterialEventIcon;
export const DvrIcon: SvgIconComponent = MaterialDvrIcon;
export const ApplicationIcon: SvgIconComponent = MaterialDvrIcon;
export const PhoneAndroidIcon: SvgIconComponent = MaterialPhoneAndroidIcon;
export const MobileIcon: SvgIconComponent = MaterialPhoneAndroidIcon;
export const MarkChatReadIcon: SvgIconComponent = MaterialMarkChatReadIcon;
export const MarkNotificationReadIcon: SvgIconComponent = MaterialMarkChatReadIcon;
export const AccessibilityIcon: SvgIconComponent = MaterialAccessibilityIcon;
export const CollapseIcon: SvgIconComponent = MaterialCollapseIcon;
export const FolderIcon: SvgIconComponent = MaterialFolderIcon;
export const AccessAlarmIcon: SvgIconComponent = MaterialAccessAlarmIcon;
export const CancelIcon: SvgIconComponent = MaterialCancelIcon;
export const ManageAccountsIcon: SvgIconComponent = MaterialManageAccountsIcon;
export const HandshakeIcon: SvgIconComponent = MaterialHandshakeIcon;
export const BalanceIcon: SvgIconComponent = MaterialBalanceIcon;
export const UndoIcon: SvgIconComponent = MaterialUndoIcon;
export const AutoRenewIcon: SvgIconComponent = MaterialAutoRenewIcon;
export const ForwardIcon: SvgIconComponent = MaterialForwardIcon;
export const SouthWestIcon: SvgIconComponent = MaterialSouthWestIcon;
export const SouthEastIcon: SvgIconComponent = MaterialSouthEastIcon;
export const NorthEastIcon: SvgIconComponent = MaterialNorthEastIcon;
export const NorthWestIcon: SvgIconComponent = MaterialNorthWestIcon;
export const ContentPasteIcon: SvgIconComponent = MaterialContentPasteIcon;
export const FormatListNumberedIcon: SvgIconComponent = MaterialFormatListNumberedIcon;
export const SubjectIcon: SvgIconComponent = MaterialSubjectIcon;
export const SortIcon: SvgIconComponent = MaterialSortIcon;
export const CheckBoxOutlineBlankIcon: SvgIconComponent = MaterialCheckBoxOutlineBlankIcon;
export const CheckBoxIcon: SvgIconComponent = MaterialCheckBoxIcon;
export const TuneIcon: SvgIconComponent = MaterialTuneIcon;
export const CategoryIcon: SvgIconComponent = MaterialCategoryIcon;
export const ScienceIcon: SvgIconComponent = MaterialScienceIcon;

export const Icons = {
    ZoomOutIcon: ZoomOutIcon,
    ZoomInIcon: ZoomInIcon,
    KeyboardArrowLeftIcon: KeyboardArrowLeftIcon,
    KeyboardArrowRightIcon: KeyboardArrowRightIcon,
    ArrowForwardIos: ArrowForwardIosIcon,
    LowPriority: LowPriorityIcon,
    Factory: FactoryIcon,
    MoreHoriz: MoreHorizIcon,
    ArrowBack: ArrowBackIcon,
    Save: SaveIcon,
    AssignmentTurnedIn: AssignmentTurnedInIcon,
    AssignmentLate: AssignmentLateIcon,
    Notifications: NotificationsIcon,
    Refresh: RefreshIcon,
    DownloadFile: DownloadFileIcon,
    SaveAltIcon: SaveAltIcon,
    VerticalAlignBottom: VerticalAlignBottomIcon,
    UploadFile: UploadFileIcon,
    Info: InfoIcon,
    QuestionMark: QuestionMarkIcon,
    AnnouncementIcon: AnnouncementIcon,
    AccessTime: AccessTimeIcon,
    LockOpen: LockOpenIcon,
    KeyboardArrowDown: KeyboardArrowDownIcon,
    KeyboardArrowUp: KeyboardArrowUpIcon,
    Alarm: AlarmIcon,
    Flag: FlagIcon,
    LocalOffer: LocalOfferIcon,
    PlayArrow: PlayArrowIcon,
    Delete: DeleteIcon,
    PauseCircleOutline: PauseCircleOutlineIcon,
    ArrowRight: ArrowRightIcon,
    ArrowLeft: ArrowLeftIcon,
    NavigateBefore: NavigateBeforeIcon,
    NavigateNext: NavigateNextIcon,
    Add: AddIcon,
    MailOutline: MailOutlineIcon,
    Settings: SettingsIcon,
    Send: SendIcon,
    ScheduleSend: ScheduleSendIcon,
    OpenInNew: OpenInNewIcon,
    Start: StartIcon,
    KeyboardTab: KeyboardTabIcon,
    VerticalSplitOutlined: VerticalSplitOutlinedIcon,
    Title: TitleIcon,
    MoreVert: MoreVertIcon,
    Search: SearchIcon,
    ExitToApp: ExitToAppIcon,
    Edit: EditIcon,
    Clear: ClearIcon,
    Close: CloseIcon,
    DeleteSweep: DeleteSweepIcon,
    Security: SecurityIcon,
    Warning: WarningIcon,
    TravelExplore: DetailsIcon,
    ManageSearch: ManageSearchIcon,
    NotificationsActive: NotificationsActiveIcon,
    NotificationsNone: NotificationsNoneIcon,
    ExpandMore: ExpandMoreIcon,
    Help: HelpIcon,
    Error: ErrorIcon,
    CastConnected: CastConnectedIcon,
    Connected: ConnectedIcon,
    Cast: CastIcon,
    Disconnected: DisconnectedIcon,
    CheckCircleIcon: DirectionsRunIcon,
    CheckCircleTrueIcon: CheckCircleOutlineIcon,
    Running: RunningIcon,
    Assessment: AssessmentIcon,
    Publish: PublishIcon,
    Buy: BuyIcon,
    GetApp: GetApp,
    Sell: SellIcon,
    RemoveCircle: RemoveCircleIcon,
    RemoveCircleOutline: DecreaseNumberIcon,
    AddCircle: AddCircleIcon,
    IncreaseNumber: IncreaseNumberIcon,
    FlipToBack: FlipToBackIcon,
    MakePassive: MakePassiveIcon,
    FlipToFront: FlipToFrontIcon,
    MakeActive: MakeActiveIcon,
    Badge: BadgeIcon,
    Tags: TagsIcon,
    ContentCopy: ContentCopyIcon,
    History: HistoryIcon,
    HourglassEmpty: HourglassEmptyIcon,
    Waiting: WaitingIcon,
    AvTimer: AvTimerIcon,
    WatchLater: WatchLaterIcon,
    SingleContract: SingleContractIcon,
    BlockContract: BlockContractIcon,
    DoneAll: DoneAllIcon,
    Trades: TradesIcon,
    AcUnit: AcUnitIcon,
    IcebergOffer: IcebergOfferIcon,
    ExpandLess: ExpandLessIcon,
    Warehouse: WarehouseIcon,
    AddCircleOutline: AddCircleOutlineIcon,
    Done: DoneIcon,
    ClearAll: ClearAllIcon,
    SendOutlined: SendOutlinedIcon,
    Build: BuildIcon,
    Block: BlockIcon,
    Disabled: DisabledIcon,
    Loyalty: LoyaltyIcon,
    SystemTags: SystemTagsIcon,
    Label: LabelIcon,
    LabelImportant: LabelImportantIcon,
    TrendingUp: TrendingUpIcon,
    TrendingDown: TrendingDownIcon,
    PlayForWork: PlayForWorkIcon,
    FastForward: FastForwardIcon,
    PlaylistAdd: PlaylistAddIcon,
    Update: UpdateIcon,
    TableChart: TableChartIcon,
    GridOn: GridOnIcon,
    BarChart: BarChartIcon,
    Restore: RestoreIcon,
    DateRange: DateRangeIcon,
    Period: PeriodIcon,
    Visibility: VisibilityIcon,
    Business: BusinessIcon,
    Company: CompanyIcon,
    Code: CodeIcon,
    TextFields: TextFieldsIcon,
    Alphanumeric: AlphanumericIcon,
    DialPad: DialpadIcon,
    Numeric: NumericIcon,
    Money: MoneyIcon,
    Currency: CurrencyIcon,
    SwitchLeft: SwitchLeftIcon,
    Boolean: BooleanIcon,
    Attractions: AttractionsIcon,
    SmartBotActions: SmartBotActionsIcon,
    SmartBotExecute: SmartBotExecuteIcon,
    PlaylistPlay: PlaylistPlayIcon,
    SmartBotList: SmartBotListIcon,
    Airplay: AirplayIcon,
    SmartBotEvaluate: SmartBotEvaluateIcon,
    Toc: TocIcon,
    SmartBotMatrixValues: SmartBotMatrixValuesIcon,
    List: ListIcon,
    SmartBotExecutionSteps: SmartBotExecutionStepsIcon,
    PriorityHigh: PriorityHighIcon,
    Login: LoginIcon,
    ArrowForward: ArrowForwardIcon,
    Logout: LogoutIcon,
    Home: HomeIcon,
    Fullscreen: FullscreenIcon,
    FullscreenExit: FullscreenExitIcon,
    ScreenShare: ScreenShareIcon,
    Dashboard: DashboardIcon,
    Timer: TimerIcon,
    EvStation: EvStationIcon,
    PowerPlant: PowerPlantIcon,
    SettingsBackupRestore: SettingsBackupRestoreIcon,
    Backtest: BacktestIcon,
    Hearing: HearingIcon,
    SmartBotListeners: SmartBotListenersIcon,
    RestartAlt: RestartAltIcon,
    SmartBotRestart: SmartBotRestartIcon,
    Password: PasswordIcon,
    AccountBox: AccountBoxIcon,
    SupervisedUserCircle: SupervisedUserCircleIcon,
    LastPage: LastPageIcon,
    FirstPage: FirstPageIcon,
    Calculate: CalculateIcon,
    Reorder: ReorderIcon,
    LocalMall: LocalMallIcon,
    IntradayMarket: IntradayMarketIcon,
    RateReview: RateReviewIcon,
    DPP: DPPIcon,
    TaskAlt: TaskAltIcon,
    DynamicFeed: DynamicFormIcon,
    DynamicColumn: DynamicColumnIcon,
    SystemUpdateAlt: SystemUpdateAltIcon,
    Timeline: TimelineIcon,
    ViewTimeline: ViewTimelineIcon,
    HistoryToggleOff: HistoryToggleOffIcon,
    TextSnippet: TextSnippetIcon,
    PlayLesson: PlayLessonIcon,
    ViewAgenda: ViewAgendaIcon,
    SmartBotBasic: SmartBotBasicIcon,
    FeaturedPlayList: FeaturedPlayListIcon,
    SmartBotAdvanced: SmartBotAdvancedIcon,
    Work: WorkIcon,
    SmartBotFlow: SmartBotFlowIcon,
    Menu: MenuIcon,
    BookmarkAdd: BookmarkAddIcon,
    BookmarkRemove: BookmarkRemoveIcon,
    Subscriptions: SubscriptionsIcon,
    PieChart: PieChartIcon,
    Launch: LaunchIcon,
    Notes: NotesIcon,
    Receipt: ReceiptIcon,
    BusinessCenter: BusinessCenterIcon,
    ContractManagement: ContractManagementIcon,
    Report: PreviewIcon,
    Preview: PreviewIcon,
    Language: LanguageIcon,
    Star: StarIcon,
    NewFeatures: NewFeaturesIcon,
    NewReleases: NewReleasesIcon,
    Person: PersonIcon,
    Payment: PaymentIcon,
    BatchPrediction: BatchPredictionIcon,
    NotificationImportant: NotificationImportantIcon,
    NotificationImportantTwoTone: NotificationImportantTwoToneIcon,
    QuestionAnswer: QuestionAnswerIcon,
    InsertChart: InsertChartIcon,
    ShowChart: ShowChartIcon,
    LineChart: LineChartIcon,
    ShoppingCart: ShoppingCartIcon,
    TrackChanges: TrackChangesIcon,
    Circle: Circle,
    Lock: LockIcon,
    FilterList: FilterListIcon,
    MiscellaneousServices: MiscellaneousServicesIcon,
    SmartBotOperations: SmartBotOperationsIcon,
    Healing: HealingIcon,
    Unhealthy: UnhealthIcon,
    SignalWifiStatusbar4Bar: SignalWifiStatusbar4BarIcon,
    SignalWifiStatusbarConnectedNoInternet4: SignalWifiStatusbarConnectedNoInternet4Icon,
    AccountConnected: AccountConnectedIcon,
    AccountDisconnected: AccountDisconnectedIcon,
    WaterfallChart: WaterfallChartIcon,
    CandleGraphsChart: CandleGraphsChartIcon,
    Storefront: StorefrontIcon,
    Analytics: AnalyticsIcon,
    HearingDisabled: HearingDisabledIcon,
    SmartBotDisableListeners: SmartBotDisableListenersIcon,
    AccountBalance: AccountBalanceIcon,
    HoldingCompany: HoldingCompanyIcon,
    Filter: FilterIcon,
    Check: CheckIcon,
    HighlightOff: HighlightOffIcon,
    WbSunny: WbSunnyIcon,
    SolarPowerPlant: SolarPowerPlantIcon,
    Water: WaterIcon,
    WaterPowerPlant: WaterPowerPlantIcon,
    Air: AirIcon,
    WindPowerPlant: WindPowerPlantIcon,
    Public: PublicIcon,
    GeothermalPowerPlant: GeothermalPowerPlantIcon,
    DatePicker: DatePickerIcon,
    Dvr: DvrIcon,
    Application: ApplicationIcon,
    PhoneAndroid: PhoneAndroidIcon,
    Mobile: MobileIcon,
    MarkChatRead: MarkChatReadIcon,
    MarkNotificationRead: MarkNotificationReadIcon,
    Accessibility: AccessibilityIcon,
    Collapse: CollapseIcon,
    Folder: FolderIcon,
    AccessAlarm: AccessAlarmIcon,
    Cancel: CancelIcon,
    Link: LinkIcon,
    Event: EventIcon,
    AutoAwesome: AutoAwesomeMotionIcon,
    ManageAccounts: ManageAccountsIcon,
    HandshakeIcon: HandshakeIcon,
    BalanceIcon: BalanceIcon,
    ArrowDropDownIcon: MaterialArrowDropDownIcon,
    ArrowDropUpIcon: MaterialArrowDropUpIcon,
    Undo: MaterialUndoIcon,
    AutoRenew: AutoRenewIcon,
    Forward: ForwardIcon,
    SouthWest: SouthWestIcon,
    SouthEast: SouthEastIcon,
    NorthEast: NorthEastIcon,
    NorthWest: NorthWestIcon,
    ContentPaste: ContentPasteIcon,
    FormatListNumbered: FormatListNumberedIcon,
    Subject: SubjectIcon,
    CheckBoxOutlineBlank: CheckBoxOutlineBlankIcon,
    CheckBox: CheckBoxIcon,
    Tune: TuneIcon,
    Category: CategoryIcon,
    Sort: SortIcon,
    Science: ScienceIcon
};

export type IconKeys = keyof typeof Icons;

export function renderIconByName(name: string, props?: Partial<SvgIconProps>) {
    if (name === "") {
        return null;
    }
    const keyName = name as IconKeys;
    const component = Icons[keyName];

    if (component === undefined) {
        return null;
    } else {
        return React.createElement(component, props || {});
    }
}

export default Icons;
