import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
// hooks
import useSettings from '../../hooks/useSettings';
import useResponsive from '../../hooks/useResponsive';
import useCollapseDrawer from '../../hooks/useCollapseDrawer';
// config
import { HEADER, NAVBAR } from '../../config';
//
import DashboardHeader from './header';
import NavbarVertical from './navbar/NavbarVertical';
import NavbarHorizontal from './navbar/NavbarHorizontal';

import { VerticalMenuViewOption } from 'src/components/settings/type';

// ----------------------------------------------------------------------

type MainStyleProps = {
  collapseClick: boolean;
  viewOption: VerticalMenuViewOption;
};

const MainStyle = styled('main', {
  shouldForwardProp: (prop) => prop !== 'collapseClick' && prop !== 'viewOption',
})<MainStyleProps>(({ collapseClick, viewOption, theme }) => ({
  flexGrow: 1,
  paddingTop: HEADER.MOBILE_HEIGHT + 24,
  paddingBottom: HEADER.MOBILE_HEIGHT + 24,
  [theme.breakpoints.up('lg')]: {
    paddingLeft: 5,
    paddingRight: 5,
    paddingTop: 60,
    paddingBottom: HEADER.DASHBOARD_DESKTOP_HEIGHT + 24,
    width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH}px)`,
    transition: theme.transitions.create('margin-left', {
      duration: theme.transitions.duration.shorter,
    }),
    ...(collapseClick && {
      marginLeft: viewOption === VerticalMenuViewOption.Closed ? 0 : NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
    }),
  },
}));

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const { isCollapse, collapseClick, changeCollapse } = useCollapseDrawer();

  const { themeLayout } = useSettings();

  const isDesktop = useResponsive('up', 'lg');

  const {
    verticalMenuViewOption,
    onChangeVerticalMenuViewOption,
    onToggleVerticalMenuViewOption,
    onNextVerticalMenuViewOption,
  } = useSettings();

  const verticalLayout = themeLayout === 'vertical';

  useEffect(() => {
    verticalMenuViewOption === VerticalMenuViewOption.Open && changeCollapse(false);
    // console.log(themeLayout)
    // console.log(verticalLayout)
  }, []);

  const showMenu = window.location.href.indexOf('nomenu=true') < 0;

  const handleChangeMenuViewOption = () =>
    isDesktop ? onNextVerticalMenuViewOption() : onToggleVerticalMenuViewOption();

  const handleVerticalMenuClose = () =>
    onChangeVerticalMenuViewOption(VerticalMenuViewOption.Closed);

  if (verticalLayout) {
    return (
      <>
        {showMenu && (
          <>
            <DashboardHeader
              onChangeSidebarViewOption={handleChangeMenuViewOption}
              viewOption={verticalMenuViewOption}
              verticalLayout={verticalLayout}
            />

            {isDesktop ? (
              <NavbarHorizontal />
            ) : (
              <NavbarVertical onCloseSidebar={handleVerticalMenuClose} />
            )}
          </>
        )}

        <Box
          component="main"
          sx={{
            px: { lg: 2 },
            pt: {
              xs: `${HEADER.MOBILE_HEIGHT + 24}px`,
              lg: `${HEADER.DASHBOARD_DESKTOP_HEIGHT + 80}px`,
            },
            pb: {
              xs: `${HEADER.MOBILE_HEIGHT + 24}px`,
              lg: `${HEADER.DASHBOARD_DESKTOP_HEIGHT + 24}px`,
            },
          }}
        >
          <Outlet />
        </Box>
      </>
    );
  }

  return (
    <Box
      sx={{
        display: { lg: 'flex' },
        minHeight: { lg: 1 },
      }}
    >
      {showMenu && (
        <>
          <DashboardHeader
            isCollapse={isCollapse}
            onChangeSidebarViewOption={handleChangeMenuViewOption}
          />

          <NavbarVertical
            onCloseSidebar={handleVerticalMenuClose}
          />
        </>
      )}

      <MainStyle collapseClick={collapseClick} viewOption={verticalMenuViewOption}>
        <Outlet />
      </MainStyle>
    </Box>
  );
}
