const defaultValues = {
    EakDate: new Date(1980,1,1), 
    EakDataRows:[],
    EakData:[],
    statu:false,
    selectedId:0
};

const eaksending  = (state = defaultValues , action) => {
    switch (action.type) { 
        case 'SET_STATUS':
            return {...state, status: action.status};
        case 'SET_SELECTED_ID':
            return {...state, selectedId: action.selectedId};
        case 'HANDLE_GET_EAK_RESPONSE':
            return {...state, EakDataRows: action.rows,EakData:action.EakData, selectedId: action.selectedId};
        case 'SET_EAK_DATE':
            return {...state, EakDate: action.date};
        case 'HANDLE_SAVE_EAK_RESPONSE':
            return {...state, saveOk: action.reply.saveOk};
        case 'HANDLE_SAVE_SEND_EAK_RESPONSE':
            return {...state, saveOk: action.reply.saveOk}; 
        default:
            return state;
    }
}

export default eaksending;