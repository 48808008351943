export enum PowerPlantTypes {
    None = 0,
    Unknown = 1,
    NaturalGas = 2,
    Hydro = 3,
    Thermal = 4,
    Hybrid = 5,
    Wind = 6,
    Solar = 7,
    GeoThermal = 8,
    Data = 9,
    BioMass = 10,
    WasteGas = 11,
    Channel = 12,
    Reservoir = 13,
    ChannelAndReservoir = 14,
    OrdinaryRegime = 15,
    ThermoSolar = 16,
    CoGeneration = 17
}

export interface IPowerPlant {
    id: number;
    name: string;
    installedPower: number;
    type: PowerPlantTypes;
}

export interface IPowerPlantWithCompanyInfo extends IPowerPlant {
    companyId: number;
    companyName: string;
    companyFullName: string;
}

export interface ICompany {
    id: number,
    name: string | null,
    fullName: string | null,
    installedPower: number,
    powerPlants: IPowerPlant[]
}

export enum PowerPlantPickerGroupBy {
    None,
    ByCompany,
    ByType,
    Both
}
