export enum EWidgetType {
  DepthWidget = 1,
  OrderWidget = 2,
  TickerWidget = 3,
  BoardWidget = 4,
  HistoryWidget = 5,
  MyOffers = 6,
  Bots = 7,
  ProductionPlanning = 8,
  RegionCapacities = 9,
}
