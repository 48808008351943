const defaultValues = { 
    PredictionDate: null,
    WeatherSources: [],
    WeatherSourceName: "",
    GopPredictionRawData: [],
    GopPredictions: [],
    PowerPlants: [],
    LockedCells: [],
    LockedCellOpenUpdate:[],
    saveOk: false
};

const HANDLE_GOP_PROVIDER_RESPONSE = (state = defaultValues , action) => {
    let providerGopPredictions = state.GopPredictions;
    if (state.ProviderChangedCells === undefined || state.ProviderChangedCells.length <= 0)
        state.ProviderChangedCells = JSON.parse(JSON.stringify(state.GopPredictions));/*new Array(state.GopPredictions.length); */
    let providerChangedCells = state.ProviderChangedCells;

    const powerPlant = state.PowerPlants.find(a =>
        a.CompanyId === action.companyId
        && a.PowerPlantId === action.powerPlantId);

    const ppIndex = state.PowerPlants.indexOf(powerPlant);
    const fieldName = "pp" + ppIndex + "_prediction";
    let total = 0;
    const PREDICTION_ROW_START = 3;

    if (action.reply.length > 0) {
        let currentDate = new Date();
        let isLocked;
        let actionReplyCount=0;
        for (let i = 3; i < 27; i++) {
            var gopPredictionHour = parseInt(state.GopPredictions[i]["hour"].split("-")[0].split(":")[0]);
            if(action.reply[actionReplyCount] === undefined || gopPredictionHour !== action.reply[actionReplyCount]["Hour"]) {
                const pred = state.GopPredictions[i][fieldName] || 0;
                total += parseFloat(pred.toString());
                continue;
            };
            const prediction = action.reply[actionReplyCount];
            const rowIndex = i;
            var hr = currentDate.getHours();
            var datePre = new Date(action.reply[actionReplyCount].DateString)
            actionReplyCount++;

            if (datePre === currentDate) {
                isLocked = (state.LockedCells.find(a => a.row === i - hr) === undefined);
            } else if (datePre >= currentDate) {
                isLocked = false;
            }

            var date = new Date(prediction.Date.match(/\d+/)[0] * 1);
            var dateStr = date.toISOString().slice(0, 10);
            dateStr += " " + date.getUTCHours() + ":" + "00";
            providerGopPredictions[0]["pp" + ppIndex + "_weather"] = "Meteologica Tahmin Saati : " + dateStr;
            if (currentDate.toDateString() === state.PredictionDate.toDateString()) {
                var currentHour = currentDate.getHours();
                var pr = providerGopPredictions[rowIndex];
                var prhour = parseInt(pr.hour.split("-")[0].split(":")[0]);
                if (prhour < currentHour) {
                    const pred = state.GopPredictions[rowIndex][fieldName] || 0;
                    total += parseFloat(pred.toString());
                    continue;
                }
            }

            if (!isLocked) {
                if (prediction.absoluteValue != null) {
                    providerGopPredictions[rowIndex][fieldName] = action.mwState
                        ? parseFloat(prediction.absoluteValue.toString())
                        : parseInt(parseFloat(prediction.absoluteValue.toString()) * 1000);
                } else {
                    providerGopPredictions[rowIndex][fieldName] = prediction.Value == null ? null
                        : action.mwState ? parseFloat(prediction.Value.toString())
                            : parseInt(parseFloat(prediction.Value.toString()) * 1000);
                }
                providerChangedCells[rowIndex][fieldName] = {state: true, class: "redish"};
            } else
                providerChangedCells[rowIndex][fieldName] = {state: false, class: ""};

            const pred = state.GopPredictions[rowIndex][fieldName] || 0;
            total += parseFloat(pred.toString());
        }
    }
    else {
        for (let i = 0, rowIndex = PREDICTION_ROW_START; i < 24; i++, rowIndex++) {
            providerGopPredictions[rowIndex][fieldName] = null;
            providerChangedCells[rowIndex][fieldName] = {state: false, class: ""};
        }
    }
    providerGopPredictions[27][fieldName] = total;
    providerGopPredictions[2]["pp" + ppIndex + "_provider"] = action.providerId;

    return {
        ...state,
        GopPredictions: providerGopPredictions,
        ProviderChangedCells: providerChangedCells
    };
}

const gopprediction  = (state = defaultValues , action) => {
    let pre;
    let nGopPredictions;
    let weatherSourceName;
    let providerGopPredictions;
    let providerSources;
    let providerChangedCells;
    switch (action.type) { 
         case 'INCOMING_GOP_PREDICTION_RESPONSE':
            pre = action.incomig;
            nGopPredictions = state.GopPredictions;
            weatherSourceName = state.WeatherSourceName;
            let nLockedCells =[];

            for (let j = 0; j < pre.length; j++){

                let wiData = []
                if( pre[ j ].WhetherInfo && pre[ j ].WhetherInfo.Data){
                    wiData = pre[ j ].WhetherInfo.Data;
                }

                for (let p = 0 ; p < state.PowerPlants.length; p++){
                    if(state.PowerPlants[p].PowerPlantId == pre[j].PowerPlantId){

                        for (let l = 0; l < state.LockedCells.length; l++){ 
                            if(!state.LockedCells[l].fieldName.startsWith('pp' + p)){
                                nLockedCells.push(state.LockedCells[l]);
                            } 
                        }

                        for (let l = 0; l < 24; l++){ 
                            if(pre[j].predictionlock[l] == 1){
                                nLockedCells.push({fieldName: 'pp' + p + '_prediction', row: l})
                            } 
                        }

                        nGopPredictions[0]['pp' + p + '_prediction'] = pre[ j ].Description;
                        nGopPredictions[1]['pp' + p + '_weather'] = pre[ j ].UpdateTime  + " - " +  pre[ j ].UpdateUsername;


                        for (let w = 3; w < 27; w++){  
                            let propt = 'pp' + p + '_prediction';
                            nGopPredictions[w][ propt ] = pre[ j ].predictionvalues[w-3];

                            nGopPredictions[w]['pp' + p + '_weather'] = '/*//*//*//*//*/';
                            var whetherfound=false;
                            for (let k = 0; k < wiData.length; k++){
                                if(weatherSourceName === wiData[k].SourceName){
                                    nGopPredictions[w]['pp' + p + '_weather'] = wiData[k].WheatherType[w-3]+ "/*/" + 
                                                                                wiData[k].WindDirection[w-3] + "/*/" + 
                                                                                wiData[k].WindSpeed[w-3] + "/*/" + 
                                                                                wiData[k].WheatherPressure[w-3]+ "/*/" +
                                                                                wiData[k].WheatherDegrees[w-3]+ "/*/" +
                                                                                wiData[k].WheatherRainFall[w-3];
                                    whetherfound=true;
                                    break;
                                }
                            }
                            if(!whetherfound){
                                  nGopPredictions[w]['pp' + p + '_weather'] =   pre[ j ].WheatherType[w-3]+ "/*/" + 
                                                                                pre[ j ].WindDirection[w-3] + "/*/" + 
                                                                               pre[ j ].WindSpeed[w-3] + "/*/" + 
                                                                               pre[ j ].WheatherPressure[w-3]+ "/*/" +
                                                                               pre[ j ].WheatherDegrees[w-3]+ "/*/" +
                                                                               pre[ j ].WheatherRainFall[w-3];
                            }
                        }   
                    }
                }
            }

            return {
                ...state, 
                LockedCells: nLockedCells, 
                GopPredictions: nGopPredictions,
                GopPredictionRawData: action.incomig
            };
        case 'HANDLE_GET_GOP_PREDICTION_RESPONSE':

             if(state.ProviderChangedCells!==undefined)
                {
                    for (var i = 0; i < state.PowerPlants.length; i++) {
                        state.ProviderChangedCells.forEach(a=>{a["pp"+i+"_prediction"]=""});
                    }
                    
                }

            return {
                ...state, 
                GopPredictions: action.GopPredictions,
                GopPredictionRawData: action.GopPredictionRawData, 
                PowerPlants: action.PowerPlants,
                LockedCells: action.LockedCells,
                LockedCellOpenUpdate:action.LockedCellOpenUpdate,
                WeatherSources: action.WeatherSources,
                WeatherSourceName: action.WeatherSourceName,
                ProviderChangedCells : state.ProviderChangedCells
            };
        case 'SET_GOP_PREDICTION_DATE':
            return {
                ...state, 
                PredictionDate: action.date,
                LockedCells: [] 
            };
        case 'SET_GOP_PREDICTION_WEATHER_SOURCE':
            pre = state.GopPredictionRawData;
            nGopPredictions = state.GopPredictions;            
            weatherSourceName = action.sourceName;
            
            for (let j = 0; j < pre.length; j++){

                let wiData = []
                if( pre[ j ].WhetherInfo && pre[ j ].WhetherInfo.Data){
                    wiData = pre[ j ].WhetherInfo.Data;
                }

                for (let p = 0 ; p < state.PowerPlants.length; p++){
                    if(state.PowerPlants[p].PowerPlantId == pre[j].PowerPlantId){

                        for (let w = 3; w < 27; w++){  
                            let propt = 'pp' + p + '_prediction';
                            //nGopPredictions[w][ propt ] = pre[ j ].predictionvalues[w-3];

                            nGopPredictions[w]['pp' + p + '_weather'] = '/*//*//*//*//*/';
                            for (let k = 0; k < wiData.length; k++){
                                if(weatherSourceName === wiData[k].SourceName){
                                    nGopPredictions[w]['pp' + p + '_weather'] = wiData[k].WheatherType[w-3]+ "/*/" + 
                                                                                wiData[k].WindDirection[w-3] + "/*/" + 
                                                                                wiData[k].WindSpeed[w-3] + "/*/" + 
                                                                                wiData[k].WheatherPressure[w-3]+ "/*/" +
                                                                                wiData[k].WheatherDegrees[w-3]+ "/*/" +
                                                                                wiData[k].WheatherRainFall[w-3];
                                    break;
                                }
                            }
                        }   
                    }
                }
            }

            return {
                ...state, 
                WeatherSourceName: weatherSourceName,
                GopPredictions: nGopPredictions
            };
        case 'CLEAR_GOP_PREDICTION_SCREEN': 
            return {...state, ...defaultValues};

        case 'HANDLE_GOP_PROVIDER_RESPONSE':
            return HANDLE_GOP_PROVIDER_RESPONSE(state, action);

        case 'HANDLE_SAVE_GOP_PREDICTION_RESPONSE':
            return {...state, saveOk: action.reply.saveOk};
        case 'HANDLE_LOCK_CELLS_RESPONSE': {
            if(action.reply.saveOk){
                let newLockedCells = state.LockedCells.slice();

                //Expected Values: action.currentLockedColName = 'pp1'
                //                 action.currentlyLockedCells = [0,0,1,1,1,0,0,0....]
                for (let i = 0; i < action.currentlyLockedCells.length; i++){
                    if(action.currentlyLockedCells[i] === 1){
                        if(!state.LockedCells.some(e => e.fieldName === action.currentlyLockedColName && e.row === i)){
                            newLockedCells.push({fieldName: action.currentlyLockedColName, row: i})
                        }
                    }
                }

                //Previous Value -> LockedCells: []
                //Expected Result -> [{fieldName: 'pp1', row:2},{fieldName: 'pp1', row:3},{fieldName: 'pp1', row:4}]
                return {...state, saveOk: action.reply.saveOk, LockedCells: newLockedCells};
            } else {
                return {...state, saveOk: action.reply.saveOk};
            }
        } 
        case 'HANDLE_UNLOCK_CELLS_RESPONSE':{
            if(action.reply.saveOk){
                let newLockedCells = []

                //Expected Values: action.currentUnlockedColName = 'pp1'
                //                 action.currentlyUnlockedCells = [0,0,1,1,1,0,0,0....]
                for (let i = 0; i < state.LockedCells.length; i++){

                    let cell = state.LockedCells[i];
                    if(!(cell.fieldName === action.currentlyUnlockedColName && action.currentlyUnlockedCells[cell.row] === 1)){
                        newLockedCells.push(cell)
                    }
                }

                //Previous Value -> LockedCells: [{fieldName: 'pp0', row:9}, {fieldName: 'pp1', row:2},{fieldName: 'pp1', row:3},{fieldName: 'pp1', row:4}]
                //Expected Result -> [{fieldName: 'pp0', row:9}]
                return {...state, saveOk: action.reply.saveOk, LockedCells: newLockedCells};
            } else {
                return {...state, saveOk: action.reply.saveOk};
            }
        }
        case 'PREPARE_PROVIDER_LIST':
            providerSources = [];
            for (var i = 0; i < action.reply.Predictions.length; i++) {
                var el = action.reply.Predictions[i];
                providerSources.push({CompanyId : el.CompanyId,PowerplantId: el.PowerPlantId, options:['<option key="Meteologica" value="Meteologica"></option>']});
            }
            return {...state, providerSources:providerSources};
        default:
            return state;
    }
 }

export default gopprediction;