import { createSlice } from '@reduxjs/toolkit';

const alertRulesSlice = createSlice({
    name: 'alertRulesData',
    initialState: { alertRulesData: [], libraryScripts: [] },
    reducers: {
        setAlertRules(state, action) {
            state.alertRulesData = action.payload;
        },
        setLibraryScripts(state, action) {
            state.libraryScripts = action.payload;
        },
        clearAlertRules(state) {
            state.alertRulesData = []
        },
        clearLibraryScripts(state) {
            state.libraryScripts = []
        },
    }
});

const { actions, reducer } = alertRulesSlice;

export const { setAlertRules, clearAlertRules, setLibraryScripts, clearLibraryScripts } = actions;

export default reducer;