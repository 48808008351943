import { Badge, IconButton, ListItemButton, ListItemIcon } from '@mui/material';
import { injectIntl } from 'react-intl';
import { BaseComponent } from '../../baseComponent';
import { ConnectedIcon, DisconnectedIcon, UnhealthIcon } from '../../icons';
import { SystemHealth } from '../types';
import { connect } from 'react-redux';
import { Tooltip } from '@mui/material';

export interface SystemHealthButtonProps {
  intl: any;
  systemHealth: SystemHealth | null;
  services: ServiceStatus[];
  onClick: (event: React.BaseSyntheticEvent) => void;
  healthyColor?: any;
  unhealthyColor?: any;
  companyConnectionErrorColor?: any;
  pureCertificate?: any;
  size?: 'small' | 'large' | 'medium';
  iconFontSize?: 'small' | 'inherit' | 'large' | 'medium';
}
export interface ServiceStatus {
  title: string;
  regionId: number | null;
  isConnected: boolean;
  isHealthy: boolean;
  message: string | null;
  lastUpdateDate: string;
}

interface SystemHealthButtonState {}

class SystemHealthButton extends BaseComponent<SystemHealthButtonProps, SystemHealthButtonState> {
  pureCertificate: any;
  constructor(props: SystemHealthButtonProps) {
    super(props);
    this.pureCertificate = this.getUserProfileData('CertificateInfo');
  }

  render() {
    const { formatMessage } = this.props.intl;
    let rejectedCompanyCount = 0;

    const omieService = this.props.systemHealth?.services.find((x) => x.title === 'Omie');
    const isOmie = omieService !== undefined && omieService !== null;

    const certificateIsConnected =
      (omieService?.companyConnections?.connectedCompanies?.length == 0 &&
        omieService?.companyConnections?.rejectedCompanies?.length > 0) ||
      this.pureCertificate == null
        ? false
        : true;

    this.props.systemHealth?.services.forEach(
      (s) => (rejectedCompanyCount += s.companyConnections?.rejectedCompanies.length ?? 0)
    );
    let badge =
      (!certificateIsConnected && isOmie) || (this.pureCertificate == null && isOmie)
        ? '!'
        : rejectedCompanyCount;
    return (
      <Tooltip title={formatMessage({ "id": "global.connection" })}>
        <IconButton
            component="div" // fixes tooltip warning for disabled child
            size={this.props.size ?? 'medium'}
            onClick={this.props.onClick}
            disabled={!Boolean(this.props.systemHealth)}
        >
          <Badge
            invisible={rejectedCompanyCount === 0 && this.pureCertificate != null}
            badgeContent={badge}
            color="error"
          >
            {!this.props.systemHealth?.isSystemHealthy ? (
              <UnhealthIcon
                fontSize={this.props.iconFontSize}
                sx={{
                  color: (theme) =>
                    !this.props.systemHealth
                      ? theme.palette.grey
                      : this.props.unhealthyColor ?? theme.palette.error.main,
                }}
              />
            ) : !certificateIsConnected && isOmie ? (
              <ConnectedIcon
                fontSize={this.props.iconFontSize}
                sx={{
                  color: 'orange',
                }}
              />
            ) : (
              <ConnectedIcon
                fontSize={this.props.iconFontSize}
                sx={{
                  color: (theme) =>
                    (rejectedCompanyCount > 0
                      ? this.props.companyConnectionErrorColor ?? theme.palette.text.secondary
                      : this.props.healthyColor) ?? theme.palette.primary.main,
                }}
              />
            )}
          </Badge>
        </IconButton>
      </Tooltip>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    systemHealth: state.systemhealth.systemHealth,
  };
};

export default injectIntl(connect(mapStateToProps)(SystemHealthButton));
