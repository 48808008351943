import { useEffect, useState } from 'react';
// @mui
import { MenuItem, Stack, Tooltip, Typography } from '@mui/material';
import useLocales from '../../../hooks/useLocales';
// components
import Image from '../../../components/Image';
import MenuPopover from '../../../components/MenuPopover';
import { IconButtonAnimate } from '../../../components/animate';
import LanguageIcon from '@mui/icons-material/Language';
// config
import { allLangs } from '../../../config';
import { useSearchParams } from 'react-router-dom';
import { IconButton } from '@mui/material';
import { IBasePageProps } from 'src/old/src/base/basepage';
import { injectIntl } from 'react-intl';

// ----------------------------------------------------------------------

function LanguagePopover({ intl }: IBasePageProps) {
  const { allLangs, currentLang, onChangeLang } = useLocales();
  const [open, setOpen] = useState<HTMLElement | null>(null);
  const [searchParams, setSearchParams] = useSearchParams();

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleChangeLang = (newLang: string) => {
    onChangeLang(newLang);
    let updatedSearchParams = new URLSearchParams(searchParams.toString());
    updatedSearchParams.set('locale', newLang);
    setSearchParams(updatedSearchParams.toString());
    localStorage.setItem("locale", newLang);
    handleClose();
  };

  useEffect(() => {
    document.documentElement.lang = currentLang.value;
  }, [currentLang])

  return (
    <>
      <Tooltip title={intl.formatMessage({ "id": "global.changelanguage" })}>
        <IconButton
          onClick={handleOpen}
          sx={{
            borderRadius: 0.75
            //   ...(open && { bgcolor: 'action.selected' }),
          }}
        >
          <LanguageIcon sx={{ mr: 0.5 }} />
          <Typography variant='subtitle1' >
            ({(currentLang.value).toUpperCase()})
          </Typography>
          {/* <Image disabledEffect src={allLangs[0].icon} alt={allLangs[0].label} /> */}
        </IconButton>
      </Tooltip>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        arrow='top-center'
        sx={{
          mt: 1.5,
          ml: 0.75,
          width: 120,
          '& .MuiMenuItem-root': { px: 1, typography: 'body2', borderRadius: 0.75 },
        }}
      >
        <Stack spacing={0.75}>
          {allLangs.map((option) => (
            <MenuItem
              key={option.value}
              selected={option.value === currentLang.value}
              onClick={() => handleChangeLang(option.value)}
            >
              {/* <Image
                disabledEffect
                alt={option.label}
                src={option.icon}
                sx={{ width: 28, mr: 2 }}
              /> */}

              {option.label}
            </MenuItem>
          ))}
        </Stack>
      </MenuPopover>
    </>
  );
}

export default injectIntl(LanguagePopover);