import { useState } from "react";
import { useNavigate } from "react-router-dom";

// @mui
import { Divider, Typography, Stack, MenuItem, IconButton, Tooltip } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import LogoutIcon from "@mui/icons-material/Logout";

// components
import MenuPopover from "../../../components/MenuPopover";
import * as AuthService from "../../../services/auth/auth.service";
import useLocales from "../../../hooks/useLocales";
import { IBasePageProps } from "src/old/src/base/basepage";
import { injectIntl } from "react-intl";
import { Flags } from "../../../utils/Flags";
import useResponsive from "src/hooks/useResponsive";

// ----------------------------------------------------------------------

const MENU_OPTIONS = [
   {
      label: "pages.profile",
      linkTo: "/profile",
   },
   {
      label: "pages.settings",
      linkTo: "/profile/settings",
   },
];

// ----------------------------------------------------------------------

let hotjarScript;
let languageStore = localStorage.getItem("locale");
const languages = { tr: "3527683", en: "2624482", es: "3527685" };
let selectedLanguage = languages[languageStore || "tr"];

function loadHotjar() {
   const showMenu = window.location.href.indexOf("nomenu=true") < 0;

   if (showMenu && hotjarScript == null) {
      (function (h: any, o: any, t: any, j: any, a?: any, r?: any) {
         h.hj =
            h.hj ||
            function () {
               (h.hj.q = h.hj.q || []).push(arguments);
            };
         h._hjSettings = { hjid: selectedLanguage, hjsv: 6 };
         // h._hjSettings = {hjid: 1389613, hjsv: 3};
         a = o.getElementsByTagName("head")[0];
         r = o.createElement("script");
         r.async = 1;
         r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
         a.appendChild(r);
         hotjarScript = r;
      })(window, document, "https://static.hotjar.com/c/hotjar-", ".js?sv=");
   }

   //@ts-ignore
   //Site.BaseUrl = window.location.origin;
   //@ts-ignore
   //Site.ServiceWorker.Init();
}

function disableHotjar() {
   if (hotjarScript && hotjarScript.parentNode) {
      hotjarScript.parentNode.removeChild(hotjarScript);
   }
   hotjarScript = null;
}

// ----------------------------------------------------------------------

function AccountPopover({ intl }: IBasePageProps) {
   const [open, setOpen] = useState<HTMLElement | null>(null);
   const navigate = useNavigate();
   const { translate } = useLocales();
   const { formatMessage } = intl;
   const isMobile = useResponsive("down", "md");

   const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
      setOpen(event.currentTarget);
   };

   const handleClose = () => {
      setOpen(null);
   };

   const goToPage = (page: string) => {
      setOpen(null);
      navigate(page, { replace: true });
   };

   const handleLogout = () => {
      if (Flags.isNewSsoFlow() && document.cookie.indexOf("id_token=") >= 0) {
         location.href = "/Login/PassportLogout";
      } else {
         AuthService.logout().finally(() => {
            navigate("/auth/login", { replace: true });
            AuthService.resetAllUserSettings();
         });
         disableHotjar();
      }
   };

   // loadHotjar();

   return (
      <>
         <Tooltip title={formatMessage({ id: "login.accountpreferences", defaultMessage: "Account Preferences" })}>
            <IconButton
               onClick={handleOpen}
               sx={{
                  borderRadius: 0.75,
                  justifyContent: "center",
               }}
            >
               <PersonIcon sx={{ mx: 0.5 }} />
               {!isMobile && (
                  <Typography variant="subtitle2" noWrap>
                     {AuthService.getActiveUser().appusername}
                  </Typography>
               )}
            </IconButton>
         </Tooltip>
         <MenuPopover
            open={Boolean(open)}
            anchorEl={open}
            onClose={handleClose}
            arrow="top-center"
            sx={{
               p: 0,
               mt: 1.5,
               ml: 0.75,
               "& .MuiMenuItem-root": {
                  typography: "body2",
                  borderRadius: 0.75,
               },
            }}
         >
            <MenuItem sx={{ m: 1 }} onClick={() => window?.open("https://docs.smartpulse.io/login", "_blank")?.focus()}>
               <MenuBookIcon />
               {translate("general.docs")}
               <OpenInNewIcon sx={{ ml: 1.5 }} />
            </MenuItem>
            <Divider sx={{ borderStyle: "dashed" }} />
            <Stack sx={{ p: 1 }}>
               <MenuItem key={MENU_OPTIONS[0].label} onClick={() => goToPage(MENU_OPTIONS[0].linkTo)}>
                  <ManageAccountsIcon />
                  {translate(MENU_OPTIONS[0].label)}
               </MenuItem>
               <MenuItem key={MENU_OPTIONS[1].label} onClick={() => goToPage(MENU_OPTIONS[1].linkTo)}>
                  {translate(MENU_OPTIONS[1].label)}
               </MenuItem>
            </Stack>
            <Divider sx={{ borderStyle: "dashed" }} />

            <MenuItem onClick={() => handleLogout()} sx={{ m: 1 }}>
               <LogoutIcon />
               {translate("general.logout")}
            </MenuItem>
         </MenuPopover>
      </>
   );
}

export default injectIntl(AccountPopover);
