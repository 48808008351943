const defaultValues = { 
    configs: [
        {
            CompanyName:"",
            CompanyId:0,
            AutoReport:false,
            InfoMails:"",
            LastExecuteDate:"",
            ReportHour:""
        }
    ]  
};

const gopautosettings = (state = defaultValues, action) => {
    switch(action.type) {
        case 'GET_SETTINGS':
            return {...state, configs:action};
        default:
            return state;
    }
}

export default gopautosettings;