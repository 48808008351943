import { createContext, useEffect, useState, Context } from 'react';
import { TimeZoneModel } from "../models/time-zone.model";
import { GroupModel } from "../models/group.model";
import { CompanyModel } from "../models/company.model";
import { PlantModel } from "../models/plant.model";
import reduxStore from "../old/src/redux/stores";
import * as ProfileService from "../services/profile/profile.service";
import { PermissionModel } from "../models/permission.model";
import { IntlShape, createIntl, createIntlCache } from 'react-intl';

type GroupInfoModel = { timeZoneInfo?: TimeZoneModel } & GroupModel;
type CompanyInfoModel = { timeZoneInfo?: TimeZoneModel } & CompanyModel;
type PlantInfoModel = { timeZoneInfo?: TimeZoneModel } & PlantModel;

const defaultDisplayLanguage: string = "en";
const defaultUserCulture: string = "en-US";
const defaultUserTimeZone: TimeZoneModel = new TimeZoneModel();

export type UserCultureContextValue = {
    DisplayLanguage: string;
    UserCulture: string;
    UserTimeZone: TimeZoneModel;
    Groups?: GroupInfoModel[];
    Companies?: CompanyInfoModel[];
    Plants?: PlantInfoModel[];
    IntlCurrent?: IntlShape,
};

const UserCultureContext: Context<UserCultureContextValue> = createContext<UserCultureContextValue>({
    DisplayLanguage: defaultDisplayLanguage,
    UserCulture: defaultUserCulture,
    UserTimeZone: defaultUserTimeZone,
});

function UserCultureProvider({ children }) {
    const [timeZones, setTimeZones] = useState([] as TimeZoneModel[]);

    const reduxState = reduxStore.getState();

    const cultureContext: UserCultureContextValue = {
        DisplayLanguage: localStorage.getItem('locale')
            ?? localStorage.getItem('i18nextLng')
            ?? defaultDisplayLanguage,
        UserCulture: defaultUserCulture,
        UserTimeZone: defaultUserTimeZone,
    };

    // @ts-ignore
    const { IsUserLoggedIn, userProfileData, permissions } = reduxState.common;
    const { groups, companies, plants } = permissions as PermissionModel;

    useEffect(() => {
        let ignoreCall = false;
        if (IsUserLoggedIn && timeZones.length <= 0) {
            ProfileService
                .getTimeZoneList()
                .then((response) => {
                    if (!ignoreCall) {
                        if (!response.isError) {
                            setTimeZones(response.ReplyObject);
                        }
                    }
                })
                .catch((error) => console.log(error));
        }
        return () => {
            ignoreCall = true;
        };
    });

    if (IsUserLoggedIn) {
        const userCultureConfig = (userProfileData || []).find(i => i.Name === "appculture.id");
        const userTimezoneConfig = (userProfileData || []).find(i => i.Name === "timezone.id");

        cultureContext.UserCulture = userCultureConfig && userCultureConfig.Value
            ? userCultureConfig.Value
            : cultureContext.UserCulture;
        cultureContext.UserTimeZone = (timeZones || []).find(i => i.zoneId == userTimezoneConfig.Value)
            ?? cultureContext.UserTimeZone;

        cultureContext.Groups = (groups || []).map(g => {
            return {
                companies: g.companies,
                id: g.id,
                isWeatherCellVisible: g.isWeatherCellVisible,
                name: g.name,
                timezone: g.timezone,
                timeZoneInfo: (timeZones || []).find(i => i.zoneId == g.timezone)
            };
        });

        cultureContext.Companies = (companies || []).map(g => {
            return {
                fullname: g.fullname,
                id: g.id,
                name: g.name,
                powerplants: g.powerplants,
                timezone: g.timezone,
                timeZoneInfo: (timeZones || []).find(i => i.zoneId == g.timezone)
            };
        });

        cultureContext.Plants = (plants || []).map(g => {
            return {
                id: g.id,
                isSfc: g.isSfc,
                name: g.name,
                power: g.power,
                typeid: g.typeid,
                timezone: g.timezone,
                timeZoneInfo: (timeZones || []).find(i => i.zoneId == g.timezone)
            };
        });

        const cultureIntlCache = createIntlCache();

        cultureContext.IntlCurrent = createIntl({
            // @ts-ignore
            locale: cultureContext.UserCulture,
            defaultLocale: cultureContext.UserCulture
        }, cultureIntlCache);;
    }

    return (
        <UserCultureContext.Provider
            value={{
                ...cultureContext
            }}>
            {children}
        </UserCultureContext.Provider>
    );
}

export { UserCultureContext, UserCultureProvider };
