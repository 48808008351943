const defaultValues = {
};

const goppredictionplanner = (state = defaultValues, action) => {
    switch (action.type) {
        case "PLANNER_DEFAULT_ACTION":
            return state;
        default:
            return state;
    }
};

export default goppredictionplanner;