import {useEffect} from "react";

export interface IRedirectOptions {
    path: string
    message?: string
}

export const Redirect = (props: IRedirectOptions) => {
    useEffect(() => {
        const timeout = setTimeout(() => {
            window.location.replace(props.path);
        }, 10);

        return () => clearTimeout(timeout);
    }, []);

    return (<>{(props.message) ?? (<>Will redirect in 3 seconds...</>)}</>);
}