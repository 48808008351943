export const useStyles = (theme: any) => ({
    root: {
        width: 250,
    },
    '& > * + *': {
        marginTop: theme.spacing(3),
    },
    formControl: {
        margin: theme.spacing(1),
        zIndex: 10
    },
});
