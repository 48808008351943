import { Box, CssBaselineProps, Dialog, DialogContent, styled } from "@mui/material";
import { DialogActions } from "@mui/material";
import { Button } from "@mui/material";
import { DialogContentText } from "@mui/material";
import { DialogTitle } from "@mui/material";
import { CSSProperties, createContext, useCallback, useContext, useRef, useState } from "react";
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from "@mui/material";
import ErrorIcon from '@mui/icons-material/ErrorOutline';
import { GRAY_V2, PRIMARY_V2 } from "src/theme/palette";

const ConfirmDialog = createContext({} as any);

const SpDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        // padding: theme.spacing(4),
    },
    '& .MuiDialogActions-root': {
        // padding: theme.spacing(0),
    }
}));

const BoxWithErrorIcon = styled(Box)(({theme}) => ({
    backgroundColor: " #DFDFE6",
    borderRadius: "50%",
    marginRight: "1rem",
    height: "48px",
    width: "48px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "16px",
    "& .MuiSvgIcon-root": {
        color: "black"
    }
}));

export type TActionButton = "decline" | "confirm";

export const ActionButton = styled(Button, {
    shouldForwardProp: (props) => props !== "buttonType"
})<{buttonType: TActionButton}>(({theme, buttonType}) =>({
    display: buttonType,
    backgroundColor: buttonType === "decline" ? '#F1F1F1' : PRIMARY_V2[500],
    borderRadius: "48px",
    width: "100%",
    height: "56px",
    color: buttonType === "decline" ? '#23262F' : '#FFFFFF',
    fontSize: "18px",
    textTransform: "capitalize",
    fontWeight: "normal",
    "&:hover": {
        backgroundColor: buttonType === "decline" ? '#AAAEB3': PRIMARY_V2[600],
    },
}));

export function ConfirmDialogProvider({ children }) {
    const [state, setState] = useState({ isOpen: false } as any)
    const [closeButtonColor, setCloseButtonColor] = useState(GRAY_V2[900]);

    const fn = useRef<any>();

    const confirmDialog = useCallback((data: any) => {
        return new Promise((resolve: any) => {
            setState({ ...data, isOpen: true });
            fn.current = (choice: boolean) => {
                resolve(choice);
                setState({ ...state, isOpen: false });
            }
        })
    }, [setState]);

    return (
        <>
            <ConfirmDialog.Provider value={confirmDialog}>
                {children}
                {
                    state.isOpen &&
                    <SpDialog
                        open={state.isOpen}
                        onClose={() => fn.current(false)}
                        PaperProps={{
                            style: { padding: "24px" }
                        }}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        maxWidth={"sm"}
                        slotProps={{
                            backdrop: {
                                style: {
                                    backgroundColor: "#000000",
                                    opacity: "0.6"
                                }
                            }
                        }}
                    >
                        {
                            (state?.showTitle ?? true) &&
                            <DialogTitle id="alert-dialog-title" sx={{ color: theme => theme.palette.mode === "light" ? GRAY_V2[900] : GRAY_V2[500], m:0, pb: "0", px:"0", fontSize: "20px" }}>
                                {state.title}
                                {/* <IconButton
                                    aria-label="close"
                                    onClick={() => fn.current(false)}
                                    sx={{
                                        float: "right"
                                    }}
                                >
                                    <CloseIcon onMouseOver={() => setCloseButtonColor('#C62727')}
                                        onMouseOut={() => setCloseButtonColor('#6E727A')}
                                        style={{ color: closeButtonColor }} />
                                </IconButton> */}
                            </DialogTitle>
                        }
                    <DialogContent sx={{ my: "24px", display:"flex", alignItems: "center", m:"24px 0", p:"0" }}>
                        <BoxWithErrorIcon>
                            <ErrorIcon />
                        </BoxWithErrorIcon>
                        <DialogContentText id="alert-dialog-description" sx={{ color: theme => theme.palette.mode === "light" ? GRAY_V2[900] : GRAY_V2[500], fontSize: "16px", fontWeight: "light"}}>
                            {state.message}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions sx={{display: "flex", gap: "1rem", justifyContent: "center", alignItems: "center", width: "100%", m:0, p:"0 !important" }}>
                        <ActionButton onClick={() => fn.current(false)} buttonType="decline">
                            {state.declineText}
                        </ActionButton>
                        <ActionButton onClick={() => fn.current(true)} buttonType="confirm" autoFocus>
                            {state.confirmText}
                        </ActionButton>
                    </DialogActions>
                </SpDialog>
                }
            </ConfirmDialog.Provider>
        </>
    )
}

export default function useConfirmDialog() {
    return useContext(ConfirmDialog)
}