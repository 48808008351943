import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box, BoxProps } from '@mui/material';

// ----------------------------------------------------------------------

interface Props extends BoxProps {
    disabledLink?: boolean;
}

export default function LogoSmall({ disabledLink = false, sx }: Props) {

    const logo = (
        <Box sx={{ width: 75, ...sx }}>
            <img src='/images/logo.png' style={{ width: 75 }} />
        </Box>
    );

    if (disabledLink) {
        return <>{logo}</>;
    }

    return <RouterLink to="/home">{logo}</RouterLink>;
}
