import { memo, useEffect, useMemo, useRef, useState } from 'react';
// @mui
import { Stack } from '@mui/material';
// type
import { NavSectionProps } from '../type';
//
import { NavListRoot } from './NavList';
import { isMobileDevice } from 'src/utils';

// ----------------------------------------------------------------------

const S_SO = 48; // scroll start offset
const S_S = 10; // scroll speed

const hideScrollbar = {
  msOverflowStyle: 'none',
  scrollbarWidth: 'none',
  overflowY: 'scroll',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
} as const;

function NavSectionHorizontal({ navConfig }: NavSectionProps) {
  const isMobile = useMemo(() => isMobileDevice(true), []);

  const navListRef = useRef<HTMLDivElement>(null);

  const [scrollInterval, setScrollInterval] = useState<NodeJS.Timer | null>(null);
  const [scrollDirection, setScrollDirection] = useState<'left' | 'right'>('left');
  const [isDragging, setIsDragging] = useState(false);
  const [dragStartScrollLeft, setDragStartScrollLeft] = useState(0);
  const [dragStartX, setDragStartX] = useState(0);

  useEffect(() => {
    if (navListRef.current) navListRef.current.scrollLeft = S_SO;
  }, []);

  useEffect(() => {
    window.addEventListener('mouseup', cancelDragging);

    window.removeEventListener('mousemove', handleDragging);
    if (isDragging) window.addEventListener('mousemove', handleDragging);

    return disposeComponent;
  }, [isDragging]);

  const disposeComponent = () => {
    scrollInterval && clearInterval(scrollInterval);
    window.removeEventListener('mouseup', cancelDragging);
    window.removeEventListener('mousemove', handleDragging);
  };

  const cancelDragging = () => {
    setIsDragging(false);
  };

  const handleDragging = (e: MouseEvent) => {
    if (!navListRef.current) return;

    navListRef.current.scrollTo({
      left: dragStartScrollLeft + dragStartX - e.clientX,
    });
  };

  const startDragging = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setIsDragging(true);
    setDragStartX(e.clientX);
    setDragStartScrollLeft(navListRef.current?.scrollLeft ?? 0);
  };

  const handleAutoScroll = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (!navListRef.current) {
      setScrollInterval(null);
      return;
    }

    const navList = navListRef.current;
    const navListRect = navList.getBoundingClientRect();

    const px = e.clientX;

    let scrollOffset = S_S;
    // const le = navListRect.left; // left edge
    // const re = navListRect.right; // right edge
    const lsp = navListRect.left + S_SO; // left start pos
    const rsp = navListRect.right - S_SO; // right start pos

    if (px < lsp) {
      scrollOffset *= -1; // * ((le + S_SO - px) / S_SO); // scroll faster as mouse gets closer to the edge
      setScrollDirection('left');
    } else if (px > rsp) {
      // scrollOffset = S_S; // * (1 - Math.abs(px - re) / S_SO);
      setScrollDirection('right');
    } else {
      cancelScroll();
      return;
    }

    scrollInterval && clearInterval(scrollInterval);

    navList.scrollTo({
      left: navList.scrollLeft + scrollOffset,
    });
    setScrollInterval(
      setInterval(() => {
        navList.scrollTo({
          left: navList.scrollLeft + scrollOffset,
        });
      }, 10)
    );
  };

  const cancelScroll = () => {
    if (scrollInterval) {
      clearInterval(scrollInterval);
      setScrollInterval(null);
    }
  };

  return (
    <Stack
      direction="row"
      justifyContent="center"
      sx={{
        bgcolor: 'background.neutral',
        borderRadius: 1,
        px: 0.5,
        transition: 'box-shadow .2s ease-in-out',
        boxShadow: (theme) => {
          const isScrollingLeft = Boolean(scrollInterval) && scrollDirection === 'left';
          const isScrollingRight = Boolean(scrollInterval) && scrollDirection === 'right';
          const lpx = isScrollingLeft ? 12.5 : 10;
          const rpx = isScrollingRight ? -12.5 : -10;
          const leftColor = theme.palette.primary.main + (isScrollingLeft ? '' : '80');
          const rightColor = theme.palette.primary.main + (isScrollingRight ? '' : '80');

          return `inset ${lpx}px 0 10px -10px ${leftColor}, inset ${rpx}px 0 10px -10px ${rightColor}`;
        },
      }}
    >
      <Stack
        direction="row"
        sx={{ ...hideScrollbar, py: 1, px: `${S_SO + 4}px` }}
        ref={navListRef}
        onMouseMove={isMobile ? undefined : handleAutoScroll}
        onMouseLeave={isMobile ? undefined : cancelScroll}
        onMouseDown={scrollInterval ? undefined : startDragging}
      >
        {navConfig.map((group) => (
          <Stack key={group.subheader} direction="row" flexShrink={0}>
            {group.items.map((list) => (
              <NavListRoot
                key={list.title + list.path}
                list={list}
                hideMenu={Boolean(scrollInterval)}
              />
            ))}
          </Stack>
        ))}
      </Stack>
    </Stack>
  );
}

export default memo(NavSectionHorizontal);
