import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import equal from "fast-deep-equal";
import { EWidgetType } from "src/enums/widget-type.enum";
import { UserWidgetLimit } from "src/models/gip-planning/user-widget-limit";
import { GridLayoutModel, GridWidgetModel, GridWorkspaceModel } from "src/models/grid-layout.model";

export interface ActiveBoardWidgetInfo {
   id: string;
   order: number;
   contract?: string;
}

export interface IWidgetRecord {
   [key: string]: GridWidgetModel;
}

export interface WorkSpaceData {
   workSpaceData: GridWorkspaceModel[];
   selectedWorkspaceId: string;
   widgetRecord: IWidgetRecord;
   activeBoardWidgetIdList: ActiveBoardWidgetInfo[];
   userWidgetLimits: UserWidgetLimit[];
   /** 
   Workspace oluşturma aşamasında olup olmadığını gösteriyor.
   */
   isOpenPreviewSection: boolean;
}

const initialState: WorkSpaceData = {
   workSpaceData: [],
   selectedWorkspaceId: "",
   widgetRecord: {},
   activeBoardWidgetIdList: [],
   userWidgetLimits: [],
   isOpenPreviewSection: false,
};

const getActiveBoardWidgetInfos = (
   workSpaceData: GridWorkspaceModel[],
   wsId: string,
   prevData: ActiveBoardWidgetInfo[]
) => {
   const boardWidgetIdList: ActiveBoardWidgetInfo[] = [];
   workSpaceData.forEach((workspace) => {
      if (workspace.id !== wsId) return;
      workspace.layouts.forEach((layout) => {
         layout.widgets.forEach((widget) => {
            if (widget.widgetType === EWidgetType.BoardWidget) {
               const beforeSelectedContract = prevData.find((x) => x.id === widget.id)?.contract;
               boardWidgetIdList.push({
                  id: widget.id,
                  order: widget.order,
                  contract: beforeSelectedContract,
               });
            }
         });
      });
   });
   return boardWidgetIdList;
};

const getWidgetRecord = (wsData: GridWorkspaceModel[]) =>
   wsData.reduce(
      (prev, next) => {
         next.layouts.forEach((widget) => {
            widget.widgets.forEach((w) => {
               Object.assign(prev, { [w.id]: w });
            });
         });
         return prev;
      },
      {} as typeof initialState.widgetRecord
   );

const workSpaceSlice = createSlice({
   name: "workSpaceData",
   initialState,
   reducers: {
      setWorkSpaceData(state, action: PayloadAction<GridWorkspaceModel[]>) {
         state.workSpaceData = action.payload;
         state.widgetRecord = getWidgetRecord(action.payload);
         const newActiveBoardWidgetIdList = getActiveBoardWidgetInfos(
            action.payload,
            state.selectedWorkspaceId,
            state.activeBoardWidgetIdList
         );
         if (!equal(state.activeBoardWidgetIdList, newActiveBoardWidgetIdList)) {
            state.activeBoardWidgetIdList = newActiveBoardWidgetIdList;
         }
      },
      setLayoutData(state, action: PayloadAction<{ wsId: string; newLayout: GridLayoutModel[] }>) {
         const newWsData = state.workSpaceData.map((ws) => {
            if (ws.id === action.payload.wsId) {
               ws.layouts = action.payload.newLayout;
            }
            return ws;
         });
         if (!equal(newWsData, state.workSpaceData)) {
            state.workSpaceData = newWsData;
            state.widgetRecord = getWidgetRecord(state.workSpaceData);
            const newActiveBoardWidgetIdList = getActiveBoardWidgetInfos(
               newWsData,
               state.selectedWorkspaceId,
               state.activeBoardWidgetIdList
            );
            if (!equal(state.activeBoardWidgetIdList, newActiveBoardWidgetIdList)) {
               state.activeBoardWidgetIdList = newActiveBoardWidgetIdList;
            }
         }
      },
      clearWorkSpaceData(state) {
         state.workSpaceData = [];
         state.selectedWorkspaceId = "";
         state.activeBoardWidgetIdList = [];
      },
      setWorkSpaceId(state, action: PayloadAction<string>) {
         state.selectedWorkspaceId = action.payload;
         const newActiveBoardWidgetIdList = getActiveBoardWidgetInfos(
            state.workSpaceData,
            state.selectedWorkspaceId,
            state.activeBoardWidgetIdList
         );
         if (!equal(state.activeBoardWidgetIdList, newActiveBoardWidgetIdList)) {
            state.activeBoardWidgetIdList = newActiveBoardWidgetIdList;
         }
      },
      setBoardWidgetIdList(state, action: PayloadAction<ActiveBoardWidgetInfo[]>) {
         if (!equal(state.activeBoardWidgetIdList, action.payload)) {
            state.activeBoardWidgetIdList = action.payload;
         }
      },
      findAndSetBoardWidgetContract(state, action: PayloadAction<{ widgetId: string; newContract: string }>) {
         const newList = [...state.activeBoardWidgetIdList];
         const boardWidget = newList.find((c) => c.id === action.payload.widgetId);
         if (!boardWidget || boardWidget.contract === action.payload.newContract) return;

         boardWidget.contract = action.payload.newContract;
         state.activeBoardWidgetIdList = newList;
      },
      setUserWidgetLimits(state, action: PayloadAction<UserWidgetLimit[]>) {
         state.userWidgetLimits = action.payload;
      },
      addWorkspace(state, action: PayloadAction<GridWorkspaceModel>) {
         const newValue = [...state.workSpaceData, action.payload];
         state.widgetRecord = getWidgetRecord(newValue);
         const newActiveBoardWidgetIdList = getActiveBoardWidgetInfos(
            newValue,
            state.selectedWorkspaceId,
            state.activeBoardWidgetIdList
         );
         state.workSpaceData = newValue;
         if (!equal(state.activeBoardWidgetIdList, newActiveBoardWidgetIdList)) {
            state.activeBoardWidgetIdList = newActiveBoardWidgetIdList;
         }
      },
      deleteWorkspace(state, action: PayloadAction<{wsId: string}>) {
         const newValue = state.workSpaceData.filter(x => x.id !== action.payload.wsId);
         state.widgetRecord = getWidgetRecord(newValue);
         const newActiveBoardWidgetIdList = getActiveBoardWidgetInfos(
            newValue,
            state.selectedWorkspaceId,
            state.activeBoardWidgetIdList
         );
         state.workSpaceData = newValue;
         if (!equal(state.activeBoardWidgetIdList, newActiveBoardWidgetIdList)) {
            state.activeBoardWidgetIdList = newActiveBoardWidgetIdList;
         }
      },
      setOpenPreviewSection(state, action: PayloadAction<boolean>) {
         state.isOpenPreviewSection = action.payload;
      }
   },
});

const { actions, reducer } = workSpaceSlice;

export const {
   setWorkSpaceData,
   setLayoutData,
   clearWorkSpaceData,
   setWorkSpaceId,
   setBoardWidgetIdList,
   findAndSetBoardWidgetContract,
   setUserWidgetLimits,
   addWorkspace,
   deleteWorkspace,
   setOpenPreviewSection
} = actions;

export default reducer;
