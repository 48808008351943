// @mui
import { Box, Card, Container, FormControlLabel, Stack, Switch, Typography } from '@mui/material';

// components
import Page from '../../components/Page';
import { useEffect } from 'react';
import * as Utils from '../../utils'

import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import ReactGridLayout from 'react-grid-layout';
import _ from "lodash";
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import useLocales from '../../hooks/useLocales';
import React from 'react';

// ----------------------------------------------------------------------

const GridLayoutPage = () => {

    const { translate } = useLocales();
    const themeStretch = false

    const generateLayout = () => {
        return _.map(_.range(0, 25), function (item, i) {
            var y = Math.ceil(Math.random() * 4) + 1;
            return {
                x: Math.round(Math.random() * 5) * 2,
                y: Math.floor(i / 6) * y,
                w: 2,
                h: y,
                i: i.toString(),
                static: Math.random() < 0.05
            };
        });
    }

    const layout = generateLayout();


    const generateDOM = () => {
        return _.map(layout, function (l, i) {
            return (
                <div key={i} className={l.static ? "static" : ""}>
                    {l.static ? (
                        <span
                            className="text"
                            title="This item is static and cannot be removed or resized."
                        >
                            Static - {i}
                        </span>
                    ) : (
                        <span className="text">{i}</span>
                    )}
                </div>
            );
        });
    }

    return (
        // <Page title={"Grid Layout"}>
            <Container maxWidth={themeStretch ? false : 'lg'}>
                <HeaderBreadcrumbs
                    heading={"Test"}
                    links={[
                        { name: translate('pages.home'), href: '/home' },
                        { name: "Grid Layout" }
                    ]}
                />
                <Box>
                    <ReactGridLayout
                        className="layout"
                        layout={layout}
                        cols={12}
                        rowHeight={30}
                        width={1200}
                    >
                        {
                            generateDOM()
                        }
                    </ReactGridLayout>
                </Box>
            </Container>
        // </Page>
    )
};

export default GridLayoutPage;