import { createSlice } from '@reduxjs/toolkit';
import { Constants } from 'src/constants';

const contractDetailSlice = createSlice({
    name: 'contractDetailData',
    initialState: { contractDetailData: {} },
    reducers: {
        setContractDetail(state, action) {
            state.contractDetailData = action.payload.reduce((accumulator, current) => ({...accumulator, [current.ContractDataKey]: current }), {});
        },
        updateContractDetail(state, action) {
            state.contractDetailData = {
                ...state.contractDetailData,
                [action.payload.ContractDataKey]: action.payload
            }
        },
        updateActiveContractDetail(state, action) {
            state.contractDetailData = {
                ...state.contractDetailData,
                [Constants.intraday.activeContractKey]: action.payload
            }
        },
        clearContractDetail(state) {
            state.contractDetailData = {}
        }
    }
});

const { actions, reducer } = contractDetailSlice;

export const { setContractDetail, updateContractDetail, updateActiveContractDetail, clearContractDetail } = actions;

export default reducer;