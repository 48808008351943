import { Color, Palette, PaletteMode } from '@mui/material';
import { PaletteColor, alpha } from '@mui/material/styles';
import {
  ChartPaletteOptions,
  GradientsPaletteOptions,
  TypeLockedCell,
  TypeTable,
} from '@mui/material/styles/createPalette';

// ----------------------------------------------------------------------

function createGradient(color1: string, color2: string) {
  return `linear-gradient(to bottom, ${color1}, ${color2})`;
}

interface ColorOptions {
  50: string;
  100: string;
  200: string;
  300: string;
  400: string;
  500: string;
  600: string;
  700: string;
  800: string;
  900: string;
}

export type ColorSchema = 'primary' | 'secondary' | 'info' | 'success' | 'warning' | 'error';

declare module '@mui/material/styles/createPalette' {
  //#region EXTENSIONS
  interface TypeBackground {
    neutral: string;
  }
  interface TypeAction {
    autofill: string;
  }
  interface SimplePaletteColorOptions {
    lighter: string;
    darker: string;
  }
  interface PaletteColor {
    lighter: string;
    darker: string;
  }
  interface Palette {
    gradients: GradientsPaletteOptions;
    chart: ChartPaletteOptions;
    table: TypeTable;
    lockedCell: TypeLockedCell;
  }
  //#endregion

  //#region CUSTOM TYPES
  //#region TypeTable
  interface TypeTableRowBackground {
    odd: string;
    even: string;
  }
  interface TypeTableRow {
    background: TypeTableRowBackground;
  }
  interface TypeTableHeader {
    background: string;
  }
  interface TypeTable {
    row: TypeTableRow;
    header: TypeTableHeader;
  }

  interface TypeLockedCell {
    background: string;
  }
  //#endregion

  interface GradientsPaletteOptions {
    primary: string;
    info: string;
    success: string;
    warning: string;
    error: string;
  }

  interface ChartPaletteOptions {
    violet: string[];
    blue: string[];
    green: string[];
    yellow: string[];
    red: string[];
  }
  //#endregion
}

declare module '@mui/material' {
  interface Color {
    0: string;
    500_8: string;
    500_12: string;
    500_16: string;
    500_24: string;
    500_32: string;
    500_48: string;
    500_56: string;
    500_80: string;
  }
}

// SETUP COLORS
const GREY: Color = {
  0: '#FFFFFF',
  50: '#F9FAFB', // TODO
  100: '#F9FAFB',
  200: '#F4F6F8',
  300: '#DFE3E8',
  400: '#C4CDD5',
  500: '#919EAB',
  600: '#637381',
  700: '#454F5B',
  800: '#212B36',
  900: '#161C24',
  500_8: alpha('#919EAB', 0.08),
  500_12: alpha('#919EAB', 0.12),
  500_16: alpha('#919EAB', 0.16),
  500_24: alpha('#919EAB', 0.24),
  500_32: alpha('#919EAB', 0.32),
  500_48: alpha('#919EAB', 0.48),
  500_56: alpha('#919EAB', 0.56),
  500_80: alpha('#919EAB', 0.8),
  A100: '#F9FAFB',
  A200: '#F4F6F8',
  A400: '#C4CDD5',
  A700: '#454F5B',
};

const PRIMARY: PaletteColor = {
  lighter: '#C8FACD',
  light: '#5BE584',
  main: '#558B2F',
  dark: '#446f26',
  darker: '#446f26',
  contrastText: '#fff',
};
const SECONDARY: PaletteColor = {
  lighter: '#FFCDD2',
  light: '#EF9A9A',
  main: '#FF1744',
  dark: '#B71C1C',
  darker: '#880E4F',
  contrastText: '#fff',
};
const INFO: PaletteColor = {
  lighter: '#D0F2FF',
  light: '#74CAFF',
  main: '#1890FF',
  dark: '#0C53B7',
  darker: '#04297A',
  contrastText: '#fff',
};
const SUCCESS: PaletteColor = {
  lighter: '#E9FCD4',
  light: '#AAF27F',
  main: '#54D62C',
  dark: '#229A16',
  darker: '#08660D',
  contrastText: GREY[800],
};
const WARNING: PaletteColor = {
  lighter: '#FFF7CD',
  light: '#FFE16A',
  main: '#FFC107',
  dark: '#B78103',
  darker: '#7A4F01',
  contrastText: GREY[800],
};

// @ts-ignore //!remove after contrastText is added
const ERROR: PaletteColor = {
  lighter: '#FFE7D9',
  light: '#FFA48D',
  main: '#FF4842',
  dark: '#B72136',
  darker: '#7A0C2E',
  // contrastText: ?, // TODO
};

const SMARTGRAY: PaletteColor = {
  dark: '#585b62',
  darker: '#585b62', // TODO
  light: '#8b8e95',
  lighter: '#8b8e95', // TODO
  main: '#6E727A',
  contrastText: '#ebebed',
};

const SMARTHIGHLIGHT: PaletteColor = {
  dark: '#c2c3c4',
  darker: '#c2c3c4', // TODO
  light: '#f5f6f7',
  lighter: '#f5f6f7', // TODO
  main: '#F3F4F5',
  contrastText: '#6E727A',
};

const GRADIENTS: GradientsPaletteOptions = {
  primary: createGradient(PRIMARY.light, PRIMARY.main),
  info: createGradient(INFO.light, INFO.main),
  success: createGradient(SUCCESS.light, SUCCESS.main),
  warning: createGradient(WARNING.light, WARNING.main),
  error: createGradient(ERROR.light, ERROR.main),
};

const CHART_COLORS: ChartPaletteOptions = {
  violet: ['#826AF9', '#9E86FF', '#D0AEFF', '#F7D2FF'],
  blue: ['#2D99FF', '#83CFFF', '#A5F3FF', '#CCFAFF'],
  green: ['#2CD9C5', '#60F1C8', '#A4F7CC', '#C0F2DC'],
  yellow: ['#FFE700', '#FFEF5A', '#FFF7AE', '#FFF3D6'],
  red: ['#FF6C40', '#FF8F6D', '#FFBD98', '#FFF2D4'],
};

const TABLECOLORS: Record<PaletteMode, TypeTable> = {
  light: {
    row: {
      background: {
        odd: '#FFFFFF',
        even: '#F9F9F9',
        readOnly: '#F6F6F6',
      },
    },
    header: {
      background: GREY[200],
    },
  },
  dark: {
    row: {
      background: {
        odd: '#161C24',
        even: '#F212B36',
        readOnly: '#161C24',
      },
    },
    header: {
      background: '#161C24',
    },
  },
};

const LOCKEDCELLS: Record<PaletteMode, TypeLockedCell> = {
  light: {
    background: "#C7EDFC"
  },
  dark: {
    background: "#212B36"
  }
}

const COMMON = <Palette>{
  common: { black: '#000', white: '#fff' },
  primary: { ...PRIMARY, contrastText: '#fff' },
  secondary: { ...SECONDARY, contrastText: '#fff' },
  info: { ...INFO, contrastText: '#fff' },
  success: { ...SUCCESS, contrastText: GREY[800] },
  warning: { ...WARNING, contrastText: GREY[800] },
  error: { ...ERROR, contrastText: '#fff' },
  grey: GREY,
  gradients: GRADIENTS,
  chart: CHART_COLORS,
  divider: GREY[500_24],
  action: {
    hover: GREY[500_8],
    selected: GREY[500_16],
    disabled: GREY[500_80],
    disabledBackground: GREY[500_24],
    focus: GREY[500_24],
    active: GREY[600],
    autofill: GREY[500_8],
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,

    // TODO
    // activatedOpacity: ?,
    // focusOpacity: ?,
    // selectedOpacity: ?,
  },
  smartGray: SMARTGRAY,
  smartHighlight: SMARTHIGHLIGHT,
};

const palette: Record<PaletteMode, Palette> = {
  light: {
    ...COMMON,
    mode: 'light',
    text: { primary: GREY[800], secondary: GREY[600], disabled: GREY[500], active:"#000000", medium:"#9BA4B5" },
    background: { paper: GREY[200], default: GREY[200], neutral: '#fff' },
    action: COMMON.action,
    table: TABLECOLORS.light,
    chartBackground: '',
    tableBorder:"#dde3ef",
    nominationCell:{byUserRowGroup:'#e5efe2', byMarketRowGroup:'#c0dbfc'},
    lockedCell: LOCKEDCELLS.light
  },
  dark: {
    ...COMMON,
    mode: 'dark',
    text: { primary: '#fff', secondary: GREY[500], disabled: GREY[600] ,active:"#ffffff",medium:"#ffffff"},
    background: { paper: GREY[800], default: "#212B36", neutral: GREY[500_16] },
    action: { ...COMMON.action, active: GREY[500], autofill: GREY[700] },
    table: TABLECOLORS.dark,
    chartBackground: '#2B3948',
    tableBorder:"#333841",
    nominationCell:{byUserRowGroup:'#094508', byMarketRowGroup:'#0C2B4E'},
    lockedCell: LOCKEDCELLS.dark
  },
} as const;

/**
 * TODO: Change main palette and use these colors
 */
//#region New Palette Colors 

export const DARKMODE_V2: ColorOptions = {
  50: "#E4F1FB",
  100: "#CCDBE6",
  200: "#B8C4CD",
  300: "#9CA9B3",
  400: "#87929B",
  500: '#67727C',
  600: '#4B555D',
  700: '#3D4850',
  800: '#29333B',
  900: '#1E272E'
};
export const BLACK_V2: ColorOptions = {
  50: "#F4F4F6",
  100: "#E6E6E9",
  200: "#DEDEDE",
  300: "#B9B9B9",
  400: "#8B8B8B",
  500: '#414650',
  600: '#32363E',
  700: '#2A2A2A',
  800: '#1E1D1D',
  900: '#000000'
};
export const GRAY_V2: ColorOptions = {
  50: '#F9F9F9',
  100: '#F2F3F5',
  200: '#EBEDF0',
  300: '#E1E3E6',
  400: '#E3E3E3',
  500: '#D7D7D7',
  600: '#AAAEB3',
  700: '#909499',
  800: '#7A7F84',
  900: '#6E727A'
};
export const PRIMARY_V2: ColorOptions = {
  50: '#D0FAB2',
  100: '#C5FE9D',
  200: '#B2F484',
  300: '#9AE366',
  400: '#7ECC46',
  500: '#558B2F',
  600: '#3C6B1A',
  700: '#2B540F',
  800: '#1F4703',
  900: '#1A3C02'
};
export const SECONDARY_V2: ColorOptions = {
  50: '#FFF1F2',
  100: '#FFE4E6',
  200: '#FECDD3',
  300: '#FDA4AF',
  400: '#FB7185',
  500: '#F43F5E',
  600: '#E11D48',
  700: '#BE123C',
  800: '#9F1239',
  900: '#881337'
};
export const WARNING_V2: ColorOptions = {
  50: '#FEFCE8',
  100: '#FEF9C3',
  200: '#FEF08A',
  300: '#FDE047',
  400: '#FACC15',
  500: '#EAB308',
  600: '#CA8A04',
  700: '#A16207',
  800: '#854D0E',
  900: '#713F12'
};
export const INFO_V2: ColorOptions = {
  50: '#CAF0F8',
  100: '#ADE8F4',
  200: '90E0EF',
  300: '#48CAE4',
  400: '#00B4D8',
  500: '#04ADCF',
  600: '#0096C7',
  700: '#0077B6',
  800: '#023E8A',
  900: '#03045E'
};
export const SUCCESS_V2: ColorOptions = {
  50: '#F5FFF9',
  100: '#B8F1CF',
  200: '#7BE4A5',
  300: '#3DD67B',
  400: '#00C851',
  500: '#00A945',
  600: '#008B38',
  700: '#006C2C',
  800: '#004D1F',
  900: '#013A18'
};
export const ERROR_V2: ColorOptions = {
  50: '#FFECEA',
  100: '#FFBFB8',
  200: '#F88275',
  300: '#F75241',
  400: '#E63421',
  500: '#D11B08',
  600: '#B61302',
  700: '#9F1507',
  800: '#7C0F04',
  900: '#490700'
};
export const BLUE_V2: ColorOptions = {
  50: '#EFF6FF',
  100: '#DBEAFE',
  200: '#BBD9FB',
  300: '#93C5FD',
  400: '#60A5FA',
  500: '#3B82F6',
  600: '#2563EB',
  700: '#1D4ED8',
  800: '#1E40AF',
  900: '#1E3A8A'
};
export const TURQUOISE_V2: ColorOptions = {
  50: '#99E2B4',
  100: '#88D4AB',
  200: '#78C6A3',
  300: '#67B99A',
  400: '#469D89',
  500: '#4E9B92',
  600: '#358F80',
  700: '#248277',
  800: '#14746F',
  900: '#036666'
};
export const RED_V2: ColorOptions = {
  50: '#FEF2F2',
  100: '#FEE2E2',
  200: '#FCA5A5',
  300: '#F19A9A',
  400: '#F87171',
  500: '#EF4444',
  600: '#DC2626',
  700: '#B91C1C',
  800: '#991B1B',
  900: '#7F1D1D'
};
export const GREEN_V2: ColorOptions = {
  50: '#F0FDF4',
  100: '#DCFCE7',
  200: '#BBF7D0',
  300: '#86EFAC',
  400: '#4ADE80',
  500: '#22C55E',
  600: '#16A34A',
  700: '#15803D',
  800: '#166534',
  900: '#14532D'
};


//#endregion

export default palette;
