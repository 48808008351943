const defaultValues = {
    username: "",
    password: "",
    rememberme: false
};

const login = (state = defaultValues , action) => {
    switch (action.type) { 
        case 'SET_USERNAME':
            return {...state, username: action.username};
        case 'SET_PASSWORD':
            return {...state, password: action.password}; 
        case 'SET_REMEMBERME':
            return {...state, rememberme: action.rememberme}; 
        case 'SET_TWOFACTORAUTH':
            return {...state, TwoFactorAuth: action.twofactorauth}; 
        case 'SET_SMSCODE':
            return {...state, smscode: action.smscode}; 
        case 'SET_USERCODE':
            return {...state, usercode: action.usercode}; 
        case 'SET_REPLY':
            return {...state, reply: action.reply}; 
        default:
            return state;
    }
};

export default login;