import moment from "moment";

import trLocale from "date-fns/locale/tr";
import esLocale from "date-fns/locale/es";
import enLocale from "date-fns/locale/en-GB";
import {
    NavigateBeforeIcon,
    NavigateNextIcon,
    DatePickerIcon
} from "../../icons/index";

import {
    TextField,
    IconButton,
    Tooltip,
    InputAdornment
} from "@mui/material";
import {
    LocalizationProvider,
    DatePicker,
} from "@mui/x-date-pickers";
import {
    CalendarPickerView
} from "@mui/x-date-pickers/internals/models/views";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

import { IPickerComponentProps, IPickerComponentState } from "../PickerInterfaces";

import { BaseValuedComponent, withRequirements } from "../../baseComponent";
import { DatePickerSlotsComponentsProps } from "@mui/x-date-pickers/DatePicker/DatePicker";
import { CSSProperties } from "react";

type DatePickerComponentProps = IPickerComponentProps & {
    showNavigationButtons?: boolean;
    showTodayButton?: boolean;
    views?: CalendarPickerView[];
    confirm?: boolean;
    format?: string;
    disabledPrevButton?: boolean;
    disabledNextButton?: boolean;
    componentsProps?: Partial<DatePickerSlotsComponentsProps> | undefined,
    iconStyle?: CSSProperties;
    inputStyle?: CSSProperties;
    disableUnderline?: boolean;
}

class DatePickerComponent extends BaseValuedComponent<Date, DatePickerComponentProps, IPickerComponentState> {
    static defaultProps: DatePickerComponentProps = {
        showNavigationButtons: true,
        disabled: false,
        showTodayButton: true,
        format: "dd/MM/yyyy",
        maxValue: moment().add(1, "years").toDate(),
        minValue: moment().add(-1, "years").toDate(),
        onChange: (date) => { },
        style: { maxWidth: "180px" },
        hideLabel: false,
        disabledPrevButton: false,
        disabledNextButton: false,
        componentsProps: undefined,
    }

    state: IPickerComponentState = {
        selectedValue: this.getDateValue(this.props.value),
        pickerDialogIsOpen: false
    }

    private getDateValue(value: Date | null | undefined): Date | null {
        if (value === undefined || value === null) {
            return null;
        } else {
            if (this.props.views === undefined || this.props.views === null || this.props.views.length === 0) {
                return moment(value).startOf("days").toDate();
            } else {
                if (this.props.views.some(e => e === "day")) {
                    return moment(value).startOf("days").toDate();
                } else if (this.props.views.some(e => e === "month")) {
                    return moment(value).startOf("months").toDate();
                } else {
                    return moment(value).startOf("years").toDate();
                }
            }
        }
    }

    add(value: number) {
        let newValue = this.getDateValue(this.state.selectedValue);

        if (newValue === null) {
            newValue = this.getDateValue(moment().toDate());
        } else {
            if (this.props.views === undefined || this.props.views === null || this.props.views.length === 0 || this.props.views.some(e => e === "day")) {
                newValue = this.getDateValue(moment(newValue).add(value, "days").toDate());
            } else if (this.props.views.some(e => e === "month")) {
                newValue = this.getDateValue(moment(newValue).add(value, "months").toDate());
            } else {
                newValue = this.getDateValue(moment(newValue).add(value, "years").toDate());
            }
        }
        this.setValueState(newValue);  
    }

    render() {
        let language = this.getCurrentLocale()
        let currentlocale = language === "tr" ? trLocale : language === "es" ? esLocale : enLocale;
        let controlLabel = ((this.props.label || null) === null) ? this.getResourceText("global.date") : this.getResourceText(this.props.label);
        const prevDayLabel = this.getResourceText("global.previousday");
        const nextDayLabel = this.getResourceText("global.nextday");
        let inputFormat = "";

        if (this.props.views === undefined || this.props.views === null || this.props.views.length === 0 || this.props.views.some(e => e === "day")) {
            if (this.props.format != undefined && this.props.format != null) {
                inputFormat = this.props.format;
            }
            else {
                inputFormat = "dd/MM/yyyy";
            }
        } else if (this.props.views.some(e => e === "month")) {
            inputFormat = "LLLL yyyy";
        } else {
            inputFormat = "yyyy";
        }
        return (<LocalizationProvider locale={currentlocale} dateAdapter={AdapterDateFns}>
            <DatePicker
                key={this.getControlId("picker")}
                label={!this.props.hideLabel ? controlLabel : null}
                value={this.state.selectedValue}
                disabled={this.props.disabled}
                // showTodayButton={this.props.showTodayButton}
                autoFocus={true}
                maxDate={this.props.maxValue}
                minDate={this.props.minValue}
                open={this.state.pickerDialogIsOpen}
                views={this.props.views}
                onOpen={() => this.setState({ pickerDialogIsOpen: true })}
                onClose={() => this.setState({ pickerDialogIsOpen: false })}
                inputFormat={inputFormat} /* Burası tamamı geçince kaldırılacak bu sayede herkesin kendi browser dilinin formatında görünecek */
                toolbarTitle={controlLabel}
                onChange={(newValue) => {
                    const selectedNewValue = newValue || null;

                    this.setValueState(selectedNewValue);
                }}
                componentsProps={this.props.componentsProps}
                renderInput={(params) => {
                    return (<TextField
                        key={this.getControlId("input")}
                        id={this.getControlId("input")}
                        variant="standard"
                        onKeyDown={(event: any) => {
                            if (!event.shiftKey && event.ctrlKey && !event.altKey) {
                                if (event.key === " ") {
                                    event.preventDefault();
                                    event.stopPropagation();
                                    this.setState({ pickerDialogIsOpen: true });
                                }
                            }
                        }}
                        {...params}
                        sx={{ padding: 0, ...this.props.style }}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">
                                {this.props.showNavigationButtons && <Tooltip title={prevDayLabel}>
                                    <IconButton
                                        key={this.getControlId("prev")}
                                        id={this.getControlId("prev")}
                                        disabled={this.props.disabledPrevButton || this.props.disabled}
                                        sx={{ p: 0 }}
                                        onClick={() => this.add(-1)}>
                                        <NavigateBeforeIcon style={this.props.iconStyle} />
                                    </IconButton>
                                </Tooltip>}
                            </InputAdornment>,
                            endAdornment: <InputAdornment position="end">
                                <Tooltip title={this.getResourceText("global.selectdate")}>
                                    <IconButton
                                        key={this.getControlId("openpicker")}
                                        id={this.getControlId("openpicker")}
                                        disabled={this.props.disabled}
                                        sx={{ p: 0 }}
                                        onClick={() => this.setState({ pickerDialogIsOpen: true })}>
                                        <DatePickerIcon style={this.props.iconStyle} />
                                    </IconButton>
                                </Tooltip>
                                {this.props.showNavigationButtons && <Tooltip title={nextDayLabel}>
                                    <IconButton
                                        key={this.getControlId("next")}
                                        id={this.getControlId("next")}
                                        disabled={this.props.disabledNextButton || this.props.disabled}
                                        sx={{ p: 0 }}
                                        onClick={() => this.add(1)}>
                                        <NavigateNextIcon style={this.props.iconStyle}/>
                                    </IconButton>
                                </Tooltip>}
                            </InputAdornment>,
                            disableUnderline: this.props.disableUnderline,
                            style: this.props.inputStyle,
                        }}
                    />);
                }}
            />
        </LocalizationProvider>);
    }
}

export default withRequirements(DatePickerComponent);
