import { PaletteMode } from '@mui/material';

type HostEnvironmentOverrides = {
  displayName?: string;
  palette?: Record<PaletteMode, EnvPalette>;
  logo: LogoDetails;
  shouldLogUnhandledErrors?: boolean;
  testFeats?: TestFeats;
};

type EnvPalette = {
  headerColor?: string;
};

type TestFeats = {
  isThemeLogoShortcutEnabled?: boolean;
};

type LogoDetails = {
  src: string;
  tooltipText?: string;
};

type HostEnvironment = 'DEMO' | 'STAGING' | 'ALPHA' | 'PRODUCTION' | 'LOCALHOST' | 'DEV';

const defaultOverrides: HostEnvironmentOverrides = {
  logo: {
    src: '/images/logo-full.png',
  },
};

const HostEnvironmentOverrideCollection: Record<HostEnvironment, HostEnvironmentOverrides> = {
  DEV: {
    ...defaultOverrides,
    displayName: 'DEV',
    shouldLogUnhandledErrors: true,
    // palette: {
    //   dark: {
    //     headerColor: '#1812A780',
    //   },
    //   light: { headerColor: '#1812A780' },
    // },
  },
  DEMO: {
    logo: {
      src: '/images/smartPulse_Demo_Logo.png',
      tooltipText: 'smartPulse Portal | DEMO',
    },
    displayName: 'Demo',
    palette: {
      dark: {
        headerColor: '#003166cc',
      },
      light: { headerColor: '#3c35e2' },
    },
  },
  STAGING: {
    ...defaultOverrides,
    displayName: 'Test',
    shouldLogUnhandledErrors: true,
    palette: {
      dark: { headerColor: '#730500' },
      light: { headerColor: '#cf2721' },
    },
    testFeats: {
      isThemeLogoShortcutEnabled: true,
    },
    logo: {
      src: '/images/logo-full-test.png',
      tooltipText: 'smartPulse Portal | Test',
    },
  },
  ALPHA: {
    ...defaultOverrides,
    displayName: 'Alpha',
    // palette: {
    //   dark: {
    //     headerColor: '#F2B34480',
    //   },
    //   light: { headerColor: '#F2B34480' },
    // },
  },
  LOCALHOST: {
    logo: {
      src: '/images/smartPulse_Localhost_Logo.png',
      tooltipText: 'smartPulse Portal | Localhost',
    },
    displayName: 'localhost',
    // palette: {
    //   dark: { headerColor: '#730500' },
    //   light: { headerColor: '#cf2721' },
    // },
    testFeats: {
      isThemeLogoShortcutEnabled: true,
    },
  },
  PRODUCTION: { ...defaultOverrides, shouldLogUnhandledErrors: true },
};

let hostEnvironment: HostEnvironment | null = null;

export const getHostEnvironment = (): HostEnvironment => {
  if (hostEnvironment) return hostEnvironment;

  const hostname = window.location.hostname;
  if (hostname.toLowerCase() == 'localhost') hostEnvironment = 'LOCALHOST';
  else if (hostname == '127.0.0.1') hostEnvironment = 'LOCALHOST';
  else if (hostname === 'portal.smartpulse.io') hostEnvironment = 'PRODUCTION';
  else if (hostname === 'portal.dev.smartpulse.io') hostEnvironment = 'DEV';
  else {
    const hostnameSections = hostname.split('.');
    hostEnvironment = hostnameSections[1].toUpperCase() as HostEnvironment;
  }

  return hostEnvironment;
};

export const getHostEnvironmentOverrides = (): HostEnvironmentOverrides => HostEnvironmentOverrideCollection[getHostEnvironment()] ?? defaultOverrides;
