const defaultValues = {
    IsMenuBarOpen:false,  
    IsUserBarOpen:false,
    MenuOpen:[],
    OffsideBarTabKey: 1
};
 
const menu  = (state = defaultValues , action) => {
    switch (action.type) { 
        case 'SET_MENU_OPEN':
            return {...state, MenuOpen: action.MenuOpen };
        case 'SET_USER_BAR_OPEN':
            return {...state, IsUserBarOpen: action.IsUserBarOpen }; 
        case 'SET_MENU_BAR_OPEN':
            return {...state, IsMenuBarOpen: action.IsMenuBarOpen };
        case 'SET_MENU_ITEM_OPEN': 
            var newMenuOpen = [...state.MenuOpen];
            var found=false;
            for (let i = 0;i < newMenuOpen.length; i++ ){
                if(newMenuOpen[i].key===action.key){
                    newMenuOpen[i].value=action.value;
                    found=true;
                    break;
                }
            }
            if(!found)
                newMenuOpen.push( { key: action.key, value:action.value }); 
            return {...state, MenuOpen: newMenuOpen };
        case 'SET_OFFSIDE_BAR_TAB_KEY': 
            return {...state, OffsideBarTabKey: action.key };
        default:
            return state
    }
 }

export default menu;