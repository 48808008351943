import React from "react";
import { injectIntl, WrappedComponentProps } from "react-intl";
import reduxStore from "../../redux/stores";

import {
    withTheme,
} from "@mui/styles";

import { BaseComponent as BaseComponentClass } from "./baseComponent";
import { BaseValuedComponent as BaseValuedComponentClass } from "./baseValuedComponent";

export function withRequirements<T extends WrappedComponentProps>(component: React.ComponentType<T>) {
    //@ts-ignore
    return injectIntl(withTheme(component));
}
type environment ={
    name:string,
    color?:string,
    imgURL?:string
}
interface ENVINFOS {
    DEMO : environment,
    STAGING : environment,
    ALPHA : environment,
    PRODUCTION:environment,
    DEV:environment
}
export const ENV_INFO : ENVINFOS= {
    DEV: {
        name:"STAGING",
        color:"#C02121",
        imgURL:"/static/img/logo_test.png"
    },
    DEMO: {
        name:"DEMO",
        color: "#1812A7",
        imgURL:"/static/img/logo_demo.png"
    },
    STAGING: {
        name:"STAGING",
        color:"#C02121",
        imgURL:"/static/img/logo_test.png"
    },
    ALPHA: {
        name : "ALPHA",
        color:'#F2B344',
        imgURL:"/static/img/logo_alpha.png"
    },
    PRODUCTION:{
        name : "PRODUCTION",
        imgURL:"/static/img/sp-rgb-web-color-horizontal.png"
    }
};



export function getTimeOutValue(){
    const store = reduxStore.getState();
    let commonStore = store["common"];
    var data = commonStore.userProfileData.find((a: any) => a.Name === "timeOutValue");
    return data;
}

export function getCurrentEnvironment(){
    var hostName = window.location.hostname;

    if(hostName === "localhost") return "";

    var hostNameSplited = hostName.split(".");
    var env  = hostNameSplited[1].toUpperCase();

    if(hostNameSplited.length === 3 && hostNameSplited.includes("smartpulse")) return ENV_INFO.PRODUCTION;

    return Object.values(ENV_INFO).find(a => a.name === env) ?? "";
}
export const BaseComponent = BaseComponentClass;
export const BaseValuedComponent = BaseValuedComponentClass;
