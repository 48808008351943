import actionType from './actionType';

// @ts-ignore
import store from '../stores';

export const setUserInformation = (appuserid: number, appusername: string, userfullname: string, useravatar: string) => {
  store.dispatch({
    type: actionType.SET_APP_USER_INFO,
    appuserid,
    appusername,
    userfullname,
    useravatar,
  });
};

export const setUserPermissions = (permissions: any) => {
  store.dispatch({
    type: actionType.SET_USER_PERMISSONS,
    permissions,
  });
};

export const setUserProfileData = (userProfileData: any) => {
  store.dispatch({
    type: actionType.SET_USER_PROFILEDATA,
    userProfileData,
  });
};

export const SetIsUserLoggedIn = (IsUserLoggedIn: boolean) => {
  store.dispatch({
    type: actionType.SET_IS_USER_LOGGED_IN,
    IsUserLoggedIn,
  });
};

export const SetConfirmLeavingPage = (ShouldConfirmLeavingPage: boolean, ConfirmMessage: string) => {
  store.dispatch({
    type: actionType.SET_CONFIRM_LEAVING_PAGE,
    ShouldConfirmLeavingPage,
    ConfirmMessage,
  });
};

export const setUserSettings = (userSettings: any) => {
  store.dispatch({
    type: actionType.SET_USER_SETTINGS,
    userSettings,
  });
};
export const setUserRegion = (userRegion: any) => {
  store.dispatch({
    type: actionType.USER_REGION,
    userRegion,
  });
};
export const setUserRegionTimezone = (userRegionTimezone: any) => {
  store.dispatch({
    type: actionType.USER_REGION_TIMEZONE,
    userRegionTimezone,
  });
};
export const setUserCompanies = (userCompanies: any) => {
  store.dispatch({
    type: actionType.USER_COMPANIES,
    userCompanies,
  });
};
export const setUserGroups = (userGroups: any) => {
  store.dispatch({
    type: actionType.USER_GROUPS,
    userGroups,
  });
};
export const setUserPlants = (userPlants: any) => {
  store.dispatch({
    type: actionType.USER_PLANTS,
    userPlants,
  });
};

export const getUserRegion = () => {
  const currentState = store.getState();
  return currentState.common.userRegion;
};
export const getUserSettings = () => {
  const currentState = store.getState();
  return currentState.common.userSettings;
};
export const getUserInfo = () => {
  const currentState = store.getState();
  return currentState.common;
};
export const getUserRegionTimezone = () => {
  const currentState = store.getState();
  return currentState.common.userRegionTimezone;
};
export const getUserCompanies = () => {
  const currentState = store.getState();
  return currentState.common.userCompanies;
};

export const getUserGroups = () => {
  const currentState = store.getState();
  return currentState.common.permissions?.groups??[];
};
export const getUserPlants = () => {
  const currentState = store.getState();
  return currentState.common.permissions?.powerplants??[];
};

export const getPermissions = () => {
  const currentState = store.getState();
  return currentState.common.permissions;
};
export const getUserInformation = () => {
  const currentState = store.getState();
  return {
    userId: currentState.common.appuserid,
    userName: currentState.common.appusername,
  };
};

export const getRoles = () => {
  const currentState = store.getState();
  return currentState.common.roles;
};

export const getUserProfileData = () => {
  const currentState = store.getState();
  return currentState.common.userProfileData;
};
export const getUserProfileDataWithKey = (key: string) => {
  const currentState = store.getState();

  return currentState.common.userProfileData?.find((a: any) => a.Name === key)?.Value;
};
