// @mui
import { styled } from '@mui/material/styles';
import { List, Box, ListSubheader, TextField, InputAdornment, IconButton, Tooltip } from '@mui/material';
// type
import { NavSectionProps } from '../type';
//
import { NavListRoot } from './NavList';
import useLocales from '../../../hooks/useLocales';
import { ChangeEvent, useState } from 'react';
import { Close, Search } from '@mui/icons-material';
import { useIntl, defineMessages } from 'react-intl';

// ----------------------------------------------------------------------

export const ListSubheaderStyle = styled((props) => (
  <ListSubheader disableSticky disableGutters {...props} />
))(({ theme }) => ({
  ...theme.typography.overline,
  paddingTop: theme.spacing(3),
  paddingLeft: theme.spacing(2),
  paddingBottom: theme.spacing(1),
  color: theme.palette.text.primary,
  transition: theme.transitions.create('opacity', {
    duration: theme.transitions.duration.shorter,
  }),
}));

const messages = defineMessages({
  search: {
    id: 'global.search',
    defaultMessage: 'Search',
  },
  delete: {
    id: "global.clear",
    defaultMessage: "Clear"
  }
});

// ----------------------------------------------------------------------

export default function NavSectionVertical({
  navConfig,
  isCollapse = false,
  ...other
}: NavSectionProps) {
  const { translate } = useLocales();
  const { formatMessage } = useIntl();

  const [search, setSearch] = useState<string>('');

  const turkishToEnglish = (turkishText) => {
    return turkishText
      .replace('Ğ','g')
      .replaceAll('Ü','u')
      .replaceAll('Ş','s')
      .replaceAll('I','i')
      .replaceAll('İ','i')
      .replaceAll('Ö','o')
      .replaceAll('Ç','c')
      .replaceAll('ğ','g')
      .replaceAll('ü','u')
      .replaceAll('ş','s')
      .replaceAll('ı','i')
      .replaceAll('ö','o')
      .replaceAll('ç','c');
  };

  const searchRegex = new RegExp(turkishToEnglish(search), 'i');

  const filtersMenuHandler = (menu) => {
    
    if (search === '' || search === null) {
      return menu;
    }
    return menu
      .map((x) => {
        if (x.children === undefined) {
          const hasSearchParams = searchRegex.test(formatMessage({ id: x.title }));
          if (hasSearchParams) {
            return x;
          }
          return null;
        } else {
          const children = x.children.filter(item =>
            searchRegex.test(turkishToEnglish(formatMessage({ id: item.title })))
          );
          if (children.length > 0) {
            return { ...x, children };
          }
          return null;
        }
      }).filter(x => x);
  };

  const menuItems = navConfig.map((x) => {
    return {
      subheader: x.subheader,
      items: filtersMenuHandler(x.items),
    };
  });

  const isSearching = search.length > 0;
  return (
    <Box {...other}>
      {!isCollapse && (
        <Box component="form">
          <TextField
            placeholder={formatMessage(messages.search)}
            value={search}
            onKeyPress={(event) => {
              if (event.key === "Enter") {
                event.preventDefault();
                event.stopPropagation();                
              }
            }}
            onChange={(event: ChangeEvent<HTMLInputElement>): void => {
              setSearch(event.target.value);
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                  sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                >
                  {search.length > 0 && (
                    <Tooltip title={formatMessage(messages.delete)}>
                      <IconButton
                        onClick={() => {
                          setSearch('');
                        }}
                        sx={{ padding: 0.5 }}
                        >
                        <Close />
                      </IconButton>
                    </Tooltip>
                  )}
                  <Search />
                </InputAdornment>
              ),
            }}
            autoComplete="off"
            autoFocus={false}
            role="presentation"
            sx={{ px: 2, m:0 }}
            fullWidth
          />
        </Box>
      )}
      {menuItems.map((group) => (
        <List key={group.subheader} disablePadding sx={{ px: 2 }}>
          <ListSubheaderStyle
            sx={{
              ...(isCollapse && {
                opacity: 0,
              }),
            }}
          >
            {translate(group.subheader)}
          </ListSubheaderStyle>

          {group.items.map((list) => (
            <NavListRoot
              key={list.title + list.path}
              list={list}
              isCollapse={isCollapse}
              isSearching={isSearching}
            />
          ))}
        </List>
      ))}
    </Box>
  );
}
