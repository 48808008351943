import React from "react";

// import { ModuleRegistry, AllModules } from "@ag-grid-enterprise/all-modules";
import { LicenseManager } from "@ag-grid-enterprise/core";

import ApplicationWithRouted from "./ApplicationWithRouted";
import { ModuleRegistry } from "@ag-grid-community/core";

// ModuleRegistry.registerModules(AllModules);
LicenseManager.setLicenseKey("[SmartPulse][v1]_MTY3Mjc0MzYwMDAwMA==e4029f746a42cb643d793eea3591ffa9");

export class Application extends React.PureComponent<{}, {}> {
    render() {
        return (<ApplicationWithRouted>
            {this.props.children}
        </ApplicationWithRouted>);
    }
}

export default Application;