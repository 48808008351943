const defaultValues = {
    selectedId:0,
    BADaBAResults:[],
    BARows:[],
    Companies:[],
    SellerCompanies:[]
}
 
const biateralagreement = (state = defaultValues, action) => {
    switch (action.type) {
        case "SET_SELECTED_ID":
            return { ...state, selectedId: action.selectedId };
        case "SET_BA_DATE":
            return { ...state, BADate: action.date };
        case "HANDLE_GET_BA_RESPONSE":
            return { ...state, BAResults: action.BAResults, BARows: action.BARows, SellerCompanies: action.SellerCompanies };
        case "HANDLE_SAVE_BA_RESPONSE":
            return { ...state, saveOk: action.reply.saveOk };
        case "HANDLE_SAVE_SEND_BA_RESPONSE":
            return { ...state, saveOk: action.reply.saveOk };
        case "HANDLE_GET_COMPANY_RESPONSE":
            return { ...state, Companies: action.Companies, selectedId: action.selectedId };
        case "HANDLE_CLEAR_STATE":
            return { ...state, ...defaultValues };
        case "HANDLE_COPYDATA_TO_BA":
            let rows = [...state.BARows];

            if(action.companyIndex === -1) {
                for (var i = 0; i < state.SellerCompanies.length; i++) {
                    const colPrefix = "pp" + i;

                    rows.forEach((rowItem) => {
                        rowItem[colPrefix + "_ba"] = rowItem[colPrefix + "_prediction"];
                    });    
                }
            } else {
                const colPrefix = "pp" + action.companyIndex;

                rows.forEach((rowItem) => {
                    rowItem[colPrefix + "_ba"] = rowItem[colPrefix + "_prediction"];
                });
            }

            return {...state, BARows: rows};
        case "HANDLE_TOTAL_CHANGE":
            let rowsForTotal = [...state.BARows];
            rowsForTotal[24][action.field] = action.total;
            return {...state, BARows: rowsForTotal};
        default:
            return state;
    }
};

export default biateralagreement;