"use strict";

import React, { CSSProperties } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from "@mui/styles";
import { TextField } from "@mui/material";
import { Autocomplete, Chip } from "@mui/material";

import { FormattedMessage, injectIntl } from "react-intl";

import { AutocompleteRenderOptionState } from "@mui/material/Autocomplete/Autocomplete";
import { FilledInputProps } from '@mui/material';
import { OutlinedInputProps } from '@mui/material';
import { InputProps } from '@mui/material';
import { KeyboardArrowDownIcon } from '../icons';

const useStyles = (theme: any) => ({
    root: {
        width: 250,
    },
    '& > * + *': {
        marginTop: theme.spacing(3),
    },
});

interface ChipPropsType {
    style?: any
}

interface InjectedProps {
    intl: any | { formatMessage: (args: any) => string }
    classes: { root?: any }
    theme?: any
    style?: any
}

/**
 * A simple option container to hold information about a single Select item.
 */
export interface IOption<T = any> {
    /**
     * Value of the option
     */
    value: T
    /**
     * Text to be displayed to represent this option
     */
    label: string
}

type SmartPulseSelectWithSearchProps = {
    placeholder?: string | { props: any }
    options: IOption[]

    /**
     * Value of the component. If this is not an {@link IOption}, you may want use isOptionEqualToValue
     */
    value?: any
    ChipProps?: ChipPropsType
    isDisabled?: boolean
    multiple?: boolean
    /**
     * @deprecated Please use {@link label} property
     */
    Label?: React.ReactNode //string |  React.Component<{}, {}, any>
    label?: React.ReactNode //string |  React.Component<{}, {}, any>
    onChange: (newValue: (IOption | IOption[])) => void
    renderTags?: (value: any, getTagProps: any) => React.ReactNode
    renderOption?: (props: any, option: IOption, state: AutocompleteRenderOptionState) => React.ReactNode

    /**
     * When {@link value} is not an instance of {@link IOption}, this function can be provided as an equality operator
     * so component can find the selected option
     * @param option Option to be tested
     * @param value value to compare
     * @remarks
     *  Components default implementation tries to fully compare option and value, `option.value == value.value`, `option.value == value`
     */
    isOptionEqualToValue?: (option: IOption, value: IOption) => boolean

    limit?: number

    disableClear?: boolean

    autoFocus?: boolean
    textFieldStyle?: CSSProperties;
    InputProps?: Partial<FilledInputProps> | Partial<OutlinedInputProps> | Partial<InputProps>;
    popupIcon?: React.ReactNode;
} & InjectedProps

interface SmartPulseSelectWithSearchState {
    focused?: boolean
}

class SmartPulseSelectWithSearch extends React.PureComponent<SmartPulseSelectWithSearchProps, SmartPulseSelectWithSearchState> {
    constructor(props: SmartPulseSelectWithSearchProps) {
        super(props);

        this.state = {
            focused: false,
        };
    }

    render() {
        const { classes, style } = this.props;
        const { formatMessage } = this.props.intl;
        let chipProps: ChipPropsType = {};
        let placeholderText = React.isValidElement(this.props.placeholder) ? formatMessage(this.props.placeholder.props) : this.props.placeholder as string;
        let selectedValue = (this.props.value || null);
        let options = (this.props.options === undefined) ? null : this.props.options;

        if ((this.props.ChipProps || null) !== null) {
            chipProps = { ...this.props.ChipProps };

            chipProps.style = {}
            Object.assign(chipProps, this.props.ChipProps);
            chipProps.style = { ...(chipProps.style || {}) };
        }
        if (this.props.multiple) {
            if (options === null) {
                options = [];
            }
            if (this.state.focused) {
                chipProps.style.maxWidth = "150px";
            }
            if (selectedValue === null) {
                selectedValue = [];
            } else if (!Array.isArray(selectedValue)) {
                selectedValue = [selectedValue];
            }
            if (selectedValue.length > 0) {
                placeholderText = "";
            }
            if (selectedValue.length === 1) {
                chipProps.style.maxWidth = "150px";
            }
        }

        return (
            <div className={classes.root} style={style}>
                <Autocomplete
                    disableCloseOnSelect={this.props.multiple}
                    multiple={this.props.multiple}
                    limitTags={this.props.limit != undefined ? this.props.limit : 2}
                    size={"small"}
                    value={selectedValue}
                    disabled={this.props.isDisabled}
                    options={options as any[]}
                    renderOption={this.props.renderOption}
                    disableClearable={this.props.disableClear === true}
                    clearText={formatMessage({id:"global.clear", defaultMessage: "Clear"})}
                    closeText={formatMessage({id:"global.close", defaultMessage: "Close"})}
                    openText={formatMessage({id:"global.openDropDown", defaultMessage: "Open"})}
                    popupIcon={this.props.popupIcon}
                    getOptionLabel={(option: any): string => {
                        if (option === undefined || option === null) {
                            return "";
                        }
                        let localOption = option;

                        if (Array.isArray(option)) {
                            localOption = option.filter(e => e !== undefined && e !== null).find(_ => 1 === 1) || null;
                        }

                        if (localOption === null || localOption.label === undefined || localOption.label === null) {
                            return "";
                        } else if (typeof (localOption.label) !== "object") {
                            return String(localOption.label);
                        } else {
                            return formatMessage(localOption.label.props);
                        }
                    }}
                    renderTags={(value, getTagProps) => {
                        if (this.props.renderTags){
                            var customResult = this.props.renderTags(value, getTagProps);
                            if (customResult)
                                return customResult;
                        }
                        const numTags = value.length;
                        const limitTags = this.props.limit != undefined ? this.props.limit : 2;

                        if(value.length < limitTags) {
                            return value.map((e, i) => <Chip
                                {...getTagProps({index: i})}
                                key={`listgroup-chip-${i}`}
                                id={`listgroup-chip-${i}`}
                                label={e.label}
                                size={"small"}
                                style={{maxWidth: "150px"}}
                            />);
                        }else {
                            return [
                                <Chip
                                    {...getTagProps({index: 0})}
                                    key={`listgroup-chipmultiple`}
                                    id={`listgroup-chipmultiple`}
                                    label={formatMessage({ id: "global.itemsselected"}, {count: numTags})}
                                    size={"small"}
                                    onDelete={undefined}
                                />,
                            ];
                        }
                    }}
                    renderInput={(params) => (
                        <TextField 
                            autoFocus={this.props.autoFocus} 
                            {...params} 
                            variant="standard" 
                            label={this.props.label ?? this.props.Label}
                            placeholder={placeholderText} 
                            InputLabelProps={{ shrink: true }}
                            InputProps={{ ...params.InputProps, ...this.props.InputProps}}
                            style={this.props.textFieldStyle}
                        />
                    )}
                    ChipProps={chipProps}
                    isOptionEqualToValue={(option, value) => {
                        if (this.props.isOptionEqualToValue)
                            return this.props.isOptionEqualToValue(option, value);

                        if (typeof (option) == "object" && typeof (value) === "object") {
                            if (option === value) {
                                return true;
                            } else {
                                return option.value === value.value;
                            }
                        } else if (typeof (option) === "object" && typeof (value) !== "object") {
                            return option.value === value;
                        } else {
                            return option === value;
                        }
                    }}
                    onChange={(event, newValue) => {
                        if (typeof (this.props.onChange) === "function") {
                            if (Array.isArray(newValue))
                                this.props.onChange(newValue as IOption[]);
                            else
                                this.props.onChange(newValue as IOption);
                        }
                    }}
                    onFocus={() => this.setState({ focused: true })}
                    onBlur={() => this.setState({ focused: false })}
                />
            </div>
        );
    }
}

// @ts-ignore
SmartPulseSelectWithSearch.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
    options: PropTypes.array.isRequired,
    Label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    ChipProps: PropTypes.object,
    value: PropTypes.any,
    multiple: PropTypes.bool,
    isDisabled: PropTypes.bool,
    disableClear: PropTypes.bool,
    limit: PropTypes.number
};

// @ts-ignore
SmartPulseSelectWithSearch.defaultProps = {
    Label: <FormattedMessage id="smartbot.filterexpression" defaultMessage="Filter" />,
    label: undefined, //<FormattedMessage id="smartbot.filterexpression" defaultMessage="Filter"/>,
    placeholder: <FormattedMessage id="global.all" defaultMessage="All" />,
    ChipProps: { style: { maxWidth: "60px" } },
    multiple: true,
    isDisabled: false,
    disableClear: false,
    limit: 2
};


export default withStyles(useStyles, { withTheme: true })(injectIntl(SmartPulseSelectWithSearch));
