import { Theme } from '@mui/material/styles';

export default function TextField(theme: Theme) {
  return {
    MuiTextField: {
      defaultProps: {
        variant: "standard"
      },
    },
  };
}
