import { WorkspacePremium } from "@mui/icons-material";
import equal from "fast-deep-equal";
import _ from "lodash";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { WidgetSettingsEnum } from "src/enums/widget-settings-enum";
import { GridWidgetSettingsModel } from "src/models/grid-layout.model";
import { StoreState } from "src/old/src/redux/reducers";
import { setWorkSpaceData } from "src/old/src/redux/slice/workspaceData";
import { setUserProfileDataByKey } from "src/services/profile/profile.service";
import * as Utils from "src/utils";
import { setPreviewWidgetSettingsToLocalStorage } from "src/utils/intradayUtils";

interface IWidgetSettingsHook<T> {
   widgetId: string;
   key: WidgetSettingsEnum;
   defaultValue: T;
   showPopUp?: boolean;
}

const userSettingsWorkspaces = "intraday_workspaces";

const useWidgetSettings = <T>(args: IWidgetSettingsHook<T>) => {
   const dispatch = useDispatch();
   const intl = useIntl();
   const widgetSettings = useSelector<StoreState.All, GridWidgetSettingsModel | undefined>(
      (state) => state.workspaceData.widgetRecord[args.widgetId]?.settings,
      equal
   );

   const isOpenPreviewSection = useSelector<StoreState.All, boolean>(
      (state) => state.workspaceData.isOpenPreviewSection,
      equal
   );
   const showPopUp = args.showPopUp ?? false;

   const value: T = (widgetSettings && widgetSettings[args.key]) ?? args.defaultValue;

   const setValue = async (newValue: T): Promise<void> => {
      if (equal(value, newValue)) return;

      if (isOpenPreviewSection) {
         setPreviewWidgetSettingsToLocalStorage(args.widgetId, args.key, newValue);
         return;
      }

      try {
         let workSpaceList = await Utils.getUserWorkSpaces();

         if (workSpaceList.length === 0) throw new Error("Workspace is not found");
         const oldWorkspaceList = _.cloneDeep(workSpaceList);

         workSpaceList = workSpaceList.map((ws) => ({
            ...ws,
            layouts: ws.layouts.map((layout) => ({
               ...layout,
               widgets: layout.widgets.map((widget) => {
                  if (widget.id !== args.widgetId) return widget;

                  if (newValue === args.defaultValue && widget.settings) {
                     delete widget.settings[args.key];
                     return widget;
                  }

                  return {
                     ...widget,
                     settings: {
                        ...widget.settings,
                        [args.key]: newValue,
                     },
                  };
               }),
            })),
         }));

         if (equal(oldWorkspaceList, workSpaceList)) return;

         const response = await setUserProfileDataByKey({
            Name: userSettingsWorkspaces,
            Value: JSON.stringify(workSpaceList),
         });

         if (response.isError) throw new Error(response.ErrorMessage);
         if (showPopUp) {
            Utils.showSuccessMessage(intl.formatMessage({ id: "global.warning.saved" }));
         }
         dispatch(setWorkSpaceData(workSpaceList));
      } catch (error) {
         let message = "";
         if (error instanceof Error) message = error.message;
         Utils.showErrorMessage(message);
      }
   };

   return [value, setValue] as const;
};

export default useWidgetSettings;
