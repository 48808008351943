import { ReactElement, useMemo } from "react";
import { Link as RouterLink } from "react-router-dom";
// @mui
import { Box, Link, Typography, BreadcrumbsProps, Breadcrumbs as MUIBreadcrumbs } from "@mui/material";
import * as Utils from "src/utils";
import useResponsive from "src/hooks/useResponsive";

// ----------------------------------------------------------------------

export type TBreadcrumbLink = {
   href?: string;
   name: string;
   icon?: ReactElement;
};

export interface Props extends BreadcrumbsProps {
   links: TBreadcrumbLink[];
   activeLast?: boolean;
}

export default function Breadcrumbs({ links, activeLast = true, ...other }: Props) {
   if (links.length === 0) return null;

   const currentLink = links[links.length - 1].name;

   const breadcrumbList = links.map((link) => (
      <div key={link.name}>
         {!activeLast && link.name === currentLink ? (
            <Typography
               variant="body2"
               sx={{
                  maxWidth: 260,
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  color: "text.disabled",
                  textOverflow: "ellipsis",
               }}
            >
               {currentLink}
            </Typography>
         ) : (
            <LinkItem link={link} isCurrent={link.name === currentLink} />
         )}
      </div>
   ));

   return (
      <MUIBreadcrumbs
         separator={
            <Box component="span" sx={{ width: 4, height: 4, borderRadius: "50%", bgcolor: "text.disabled" }} />
         }
         {...other}
      >
         {breadcrumbList}
      </MUIBreadcrumbs>
   );
}

// ----------------------------------------------------------------------

type LinkItemProps = {
   link: TBreadcrumbLink;
   isCurrent: boolean;
};

function LinkItem({ link, isCurrent }: LinkItemProps) {
   const { href, name, icon } = link;
   const isMobile = useResponsive("down", "md");

   return href || isCurrent ? (
      <Link
         key={name}
         variant="body2"
         component={RouterLink}
         to={href || "#"}
         sx={{
            lineHeight: 2,
            display: "flex",
            alignItems: "center",
            color: "text.primary",
            "& > div": { display: "inherit" },
         }}
      >
         {icon && <Box sx={{ mr: 1, "& svg": { width: 20, height: 20 } }}>{icon}</Box>}
         { isMobile && Utils.getFirstLettersUppercase(name)}
         {
           !isMobile && name
         }
      </Link>
   ) : (
      <Typography
         variant="body2"
         sx={{
            maxWidth: 260,
            overflow: "hidden",
            whiteSpace: "nowrap",
            color: "text.disabled",
            textOverflow: "ellipsis",
         }}
      >
         { isMobile && Utils.getFirstLettersUppercase(name)}
         {
           !isMobile && name
         }
      </Typography>
   );
}
