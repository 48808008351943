// Example locale file for English, give this to your locale team to translate

export const AG_GRID_LOCALE_ES = {
    // Set Filter
    selectAll: '(Seleccionar todo)',
    selectAllSearchResults: '(Seleccionar todos los resultados de búsqueda)',
    searchOoo: 'Búsqueda...',
    blanks: '(Espacios en Blanco)',
    noMatches: 'No hay coincidencias',

    // Number Filter & Text Filter
    filterOoo: 'Filtrar...',
    equals: 'Igual',
    notEqual: 'No es igual',
    blank: 'en Blanco',
    notBlank: 'No en blanco',
    empty: 'Elige uno',

    // Number Filter
    lessThan: 'Menos que',
    greaterThan: 'Mas grande que',
    lessThanOrEqual: 'Menor o igual',
    greaterThanOrEqual: 'Mayor que o igual',
    inRange: 'En el rango',
    inRangeStart: 'de',
    inRangeEnd: 'a',

    // Text Filter
    contains: 'Contiene',
    notContains: 'No contiene',
    startsWith: 'Comienza con',
    endsWith: 'Termina con',

    // Date Filter
    dateFormatOoo: 'yyyy-mm-dd',

    // Filter Conditions
    andCondition: 'Y',
    orCondition: 'O',

    // Filter Buttons
    applyFilter: 'Aplicar',
    resetFilter: 'Reiniciar',
    clearFilter: 'Claro',
    cancelFilter: 'Cancelar',

    // Filter Titles
    textFilter: 'Filtro de Texto',
    numberFilter: 'Filtro de Número',
    dateFilter: 'Filtro de Decha',
    setFilter: 'Establecer Filtro',

    // Side Bar
    columns: 'Columnas',
    filters: 'Filtros',

    // columns tool panel
    pivotMode: 'Modo de Pivote',
    groups: 'Grupos de Filas',
    rowGroupColumnsEmptyMessage: 'Arrastre aquí para establecer grupos de filas',
    values: 'Valores',
    valueColumnsEmptyMessage: 'Arrastre aquí para agregar',
    pivots: 'Etiquetas de Columna',
    pivotColumnsEmptyMessage: 'Arrastre aquí para establecer etiquetas de columna',

    // Header of the Default Group Column
    group: 'Grupo',

    // Row Drag
    rowDragRows:'Filas',

    // Other
    loadingOoo: 'Cargando...',
    noRowsToShow: 'No hay filas para mostrar',
    enabled: 'Activado',

    // Menu
    pinColumn: 'Columna de alfileres',
    pinLeft: 'Anclar a la Izquierda',
    pinRight: 'Anclar a la Derecha',
    noPin: 'Sin Alfiler',
    valueAggregation: 'Agregación de valor',
    autosizeThiscolumn: 'Tamaño automático de esta columna',
    autosizeAllColumns: 'Tamaño automático de todas las columnas',
    groupBy: 'Agrupar por',
    ungroupBy: 'Desagrupar por',
    addToValues: 'Agregar ${variable} a los valores',
    removeFromValues: 'Eliminar ${variable} de los valores',
    addToLabels: 'Agregar ${variable} a las etiquetas',
    removeFromLabels: 'Eliminar ${variable} de las etiquetas',
    resetColumns: 'Restablecer columnas',
    expandAll: 'Expandir Todo',
    collapseAll: 'Cierra Todo',
    copy: 'Copiar',
    ctrlC: 'Ctrl+C',
    copyWithHeaders: 'Copiar Con Encabezados',
    copyWithGroupHeaders: 'Copiar Con Encabezados De Grupo',
    paste: 'Pegar',
    ctrlV: 'Ctrl+V',
    export: 'Exportar',
    csvExport: 'Exportación CSV',
    excelExport: 'Exportación de Excel',

    // Enterprise Menu Aggregation and Status Bar
    sum: 'Suma',
    min: 'Mínimo',
    max: 'Máx',
    none: 'Nada',
    count: 'Contar',
    avg: 'Promedio',
    filteredRows: 'Filtrado',
    selectedRows: 'Seleccionado',
    totalRows: 'Filas Totales',
    totalAndFilteredRows: 'Filas',
    more: 'Más',
    to: 'a',
    of: 'de',
    page: 'Página',
    nextPage: 'Siguiente Página',
    lastPage: 'Última Página',
    firstPage: 'Primera Página',
    previousPage: 'Pagina Anterior',

    // Pivoting
    pivotColumnGroupTotals: 'Total',

    // Enterprise Menu (Charts)
    pivotChartAndPivotMode: 'Gráfico Dinámico y Modo De Pivote',
    pivotChart: 'Gráfico Dinámico',
    chartRange: 'Rango De Gráfico',

    columnChart: 'Columna',
    groupedColumn: 'Agrupado',
    stackedColumn: 'Apilado',
    normalizedColumn: '100% Apilado',

    barChart: 'Bar',
    groupedBar: 'Agrupados',
    stackedBar: 'Apilado',
    normalizedBar: '100% Apilado',

    pieChart: 'Tarta',
    pie: 'Tarta',
    doughnut: 'Rosquilla',

    line: 'Línea',

    xyChart: 'X Y (Dispersión)',
    scatter: 'Dispersión',
    bubble: 'Burbuja',

    areaChart: 'Área',
    area: 'Área',
    stackedArea: 'Apilado',
    normalizedArea: '100% Apilado',

    histogramChart: 'Histograma',

    combinationChart: 'Combinación',
    columnLineCombo: 'Columna y Línea',
    AreaColumnCombo: 'Área y Columna',

    // Charts
    pivotChartTitle: 'Gráfico Dinámico',
    rangeChartTitle: 'Gráfico de rango',
    settings: 'Ajustes',
    data: 'Datos',
    format: 'Formato',
    categories: 'Categorías',
    defaultCategory: '(Ninguno)',
    series: 'Serie',
    xyValues: 'Valores XY',
    paired: 'Modo Emparejado',
    axis: 'Eje',
    navigator: 'Navegador',
    color: 'Color',
    thickness: 'Espesor',
    xType: 'Tipo X',
    automatic: 'Automático',
    category: 'Categoría',
    number: 'Número',
    time: 'Tiempo',
    xRotation: 'Rotación X',
    yRotation: 'Rotación Y',
    ticks: 'Garrapatas',
    width: 'Ancho',
    height: 'Altura',
    length: 'Longitud',
    padding: 'Relleno',
    spacing: 'Espaciado',
    chart: 'Cuadro',
    title: 'Título',
    titlePlaceholder: 'Título del gráfico: haga doble clic para editar',
    background: 'Fondo',
    font: 'Fuente',
    top: 'Cima',
    right: 'Derecho',
    bottom: 'Fondo',
    left: 'Izquierdo',
    labels: 'Etiquetas',
    size: 'Tamaño',
    minSize: 'Talla Minima',
    maxSize: 'Talla Máxima',
    legend: 'Leyenda',
    position: 'Posición',
    markerSize: 'Tamaño del Marcador',
    markerStroke: 'Trazo de Marcador',
    markerPadding: 'Relleno de Marcador',
    itemSpacing: 'Espaciado de Elementos',
    itemPaddingX: 'Artículo Relleno X',
    itemPaddingY: 'Artículo Relleno Y',
    layoutHorizontalSpacing: 'Espaciado Horizontal',
    layoutVerticalSpacing: 'Espaciado Vertical',
    strokeWidth: 'Anchura del Trazo',
    offset: 'Compensar',
    offsets: 'Compensaciones',
    tooltips: 'Información sobre herramientas',
    callout: 'Gritar',
    markers: 'Marcadores',
    shadow: 'Sombra',
    blur: 'Difuminar',
    xOffset: 'Desplazamiento X',
    yOffset: 'Desplazamiento Y',
    lineWidth: 'Ancho de Línea',
    normal: 'Normal',
    bold: 'Audaz',
    italic: 'Itálico',
    boldItalic: 'Negrita Cursiva',
    predefined: 'Predefinido',
    fillOpacity: 'Opacidad de relleno',
    strokeOpacity: 'Opacidad de Línea',
    histogramBinCount: 'Recuento de Contenedores',
    columnGroup: 'Columna',
    barGroup: 'Bar',
    pieGroup: 'Tarta',
    lineGroup: 'Línea',
    scatterGroup: 'X Y (Dispersión)',
    areaGroup: 'Área',
    histogramGroup: 'Histograma',
    combinationGroup: 'Combinación',
    groupedColumnTooltip: 'Agrupados',
    stackedColumnTooltip: 'Apilado',
    normalizedColumnTooltip: '100% Apilado',
    groupedBarTooltip: 'Agrupados',
    stackedBarTooltip: 'Apilado',
    normalizedBarTooltip: '100% Apilado',
    pieTooltip: 'Tarta',
    doughnutTooltip: 'Rosquilla',
    lineTooltip: 'Línea',
    groupedAreaTooltip: 'Área',
    stackedAreaTooltip: 'Apilado',
    normalizedAreaTooltip: '100% Apilado',
    scatterTooltip: 'Dispersión',
    bubbleTooltip: 'Burbuja',
    histogramTooltip: 'Histograma',
    columnLineComboTooltip: 'Columna y Línea',
    areaColumnComboTooltip: 'Área y Columna',
    customComboTooltip: 'Combinación Personalizada',
    noDataToChart: 'No hay datos disponibles para ser graficados.',
    pivotChartRequiresPivotMode: 'El gráfico dinámico requiere que el modo pivote esté habilitado.',
    chartSettingsToolbarTooltip: 'Menú',
    chartLinkToolbarTooltip: 'Vinculado a la cuadrícula',
    chartUnlinkToolbarTooltip: 'Desvinculado de la cuadrícula',
    chartDownloadToolbarTooltip: 'Descargar Gráfico',
    seriesChartType: 'Tipo de gráfico de serie',
    seriesType: 'Tipo de serie',
    secondaryAxis: 'Eje secundario',

    // ARIA
    ariaHidden: 'oculto',
    ariaVisible: 'visible',
    ariaChecked: 'comprobado',
    ariaUnchecked: 'desenfrenado',
    ariaIndeterminate:'indeterminado',
    ariaDefaultListName: 'Lista',
    ariaColumnSelectAll: 'Toggle Seleccionar todas las columnas',
    ariaInputEditor: 'Editor de entrada',
    ariaDateFilterInput: 'Entrada de filtro de fecha',
    ariaFilterList: 'Lista de filtros',
    ariaFilterInput: 'Entrada de filtro',
    ariaFilterColumnsInput: 'Entrada de columnas de filtro',
    ariaFilterValue: 'Valor de filtro',
    ariaFilterFromValue: 'Filtrar por valor',
    ariaFilterToValue: 'Filtrar por valor',
    ariaFilteringOperator: 'Operador de filtrado',
    ariaColumn: 'Columna',
    ariaColumnList: 'Lista de columnas',
    ariaColumnGroup: 'Grupo de columnas',
    ariaRowSelect: 'Presiona ESPACIO para seleccionar esta fila',
    ariaRowDeselect: 'Presiona ESPACIO para anular la selección de esta fila',
    ariaRowToggleSelection: 'Presione la barra espaciadora para alternar la selección de filas',
    ariaRowSelectAll: 'Presione Espacio para alternar la selección de todas las filas',
    ariaToggleVisibility: 'Presiona ESPACIO para alternar la visibilidad',
    ariaSearch: 'Búsqueda',
    ariaSearchFilterValues: 'Valores de filtro de búsqueda',

    ariaRowGroupDropZonePanelLabel: 'Grupos de filas',
    ariaValuesDropZonePanelLabel: 'Valores',
    ariaPivotDropZonePanelLabel: 'Etiquetas de Columna',
    ariaDropZoneColumnComponentDescription: 'Presione ELIMINAR para eliminar',
    ariaDropZoneColumnValueItemDescription: 'Presione ENTER para cambiar el tipo de agregación',

    // ARIA Labels for Dialogs
    ariaLabelColumnMenu: 'Menú de Columna',
    ariaLabelCellEditor: 'Editor Celular',
    ariaLabelDialog: 'Diálogo',
    ariaLabelSelectField: 'Seleccionar Campo',
    ariaLabelTooltip: 'Información sobre herramientas',
    ariaLabelContextMenu: 'Menú de Contexto',
    ariaLabelSubMenu: 'Submenú',
    ariaLabelAggregationFunction: 'Función de Agregación',

    // Number Format (Status Bar, Pagination Panel)
    thousandSeparator: '.',
    decimalSeparator: ','

}