import React from "react";
import {FormattedMessage, injectIntl} from "react-intl";
import {BasePage} from "../../../base/basepage";
import {
    Grid,
    Card,
    CardContent,
    FormControl,
    Button,
    IconButton,
    Badge,
    Modal,
    Fade,
    Pagination,
    Box,
} from "@mui/material";
import ForumIcon from "@mui/icons-material/Forum";
import moment from "moment";
import {Icons} from "../../../components/icons";
import SpeakerNotesOffIcon from "@mui/icons-material/SpeakerNotesOff";
import store from "../../../redux/stores/index";
import {Tooltip} from "@mui/material";

class Announcement extends BasePage {
    constructor(props) {
        super(props);
        this.connect(["common"]);
        this.lastCheckTimeConfigKey = "lastCheckTime";

        const allState = store.getState();
        this.isAuth = allState.common.roles?.includes("Announcement");

        this.state = {
            ...this.state,
            IsNew: true,
            ShowDialog: false,
            openDetailPopup: false,
            userMessages: [],
            userMessagesDetail: [],
            unReadMessageCount: 0,
            page: 1,
            messageIsReaded: false,
            lastCheckTimeValue: this.getUserProfileData(this.lastCheckTimeConfigKey) // string
        };
        this.PER_PAGE = 7;
    }

    componentDidMount() {
        if (this.isAuth) {
            this.checkHaveNewMessage();
            this.checkNewMessages = setInterval(
                () => this.checkHaveNewMessage(),
                120000
            ); //300000
        }
    }

    componentWillUnmount() {
        if (this.isAuth) {
            if (this.checkNewMessages) {
                clearInterval(this.checkNewMessages);
            }
        }
    }

    checkHaveNewMessage() {
        const {formatMessage} = this.props.intl;
        const momentLastCheckTime = moment.utc(
            this.state.lastCheckTimeValue,
            "YYYY-MM-DD HH:mm:ss Z"
        );
        this.setState({messageIsReaded: false});

        const now = moment.utc();

        if (!Boolean(this.state.lastCheckTimeValue)) {
            //ilk defa kontrol ediliyor
            this
                .getUserMessage()
                .done(() => {
                    if (
                        this.state.userMessages != null &&
                        this.state.userMessages.length > 0
                    ) {
                        this.setState({ShowDialog: true, messageIsReaded: false});
                    }
                });

            this.setLastCheckTime(now);
        } else {
            this.unReadMessageCount = 0;
            this
                .getUserMessage()
                .done(() => {
                    if (
                        this.state.userMessages != null &&
                        this.state.userMessages.length > 0
                    ) {
                        var userMessages = this.state.userMessages;
                        userMessages.forEach((item) => {
                            item.isNew = false;
                            var marketCreateDate = moment.utc(
                                item.MarketCreateDate,
                                "YYYY-MM-DD THH:mm:ss Z"
                            );

                            if (marketCreateDate >= momentLastCheckTime) {
                                //this.setState({ShowDialog:true});
                                this.unReadMessageCount++;
                                item.isNew = true;
                            }
                        });

                        this.setState({
                            unReadMessageCount: this.unReadMessageCount,
                            userMessages: userMessages,
                        });

                        var lastMessageTime = moment.utc(
                            this.state.userMessages[0].MarketCreateDate,
                            "YYYY-MM-DDTHH:mm:ssZ"
                        );

                        //  var diff = momentLastCheckTime.diff(lastMessageTime, 'minutes');
                        if (momentLastCheckTime <= lastMessageTime) {
                            this.PopUp(formatMessage({id: "global.newMesage"}), 2);
                        }
                    }

                    this.setLastCheckTime(now);

                    const lastCheckTimeValue = this.getTimeByTimeZone(
                        now.format("YYYY-MM-DD HH:mm:ss Z"),
                        "YYYY-MM-DD HH:mm:ss Z",
                        "YYYY-MM-DD HH:mm:ss Z",
                        false
                    );
                    this.setState({lastCheckTime: lastCheckTimeValue});
                });
        }
    }

    setLastCheckTime = (checkTimeMoment) => {
        const checkTime = checkTimeMoment.format("YYYY-MM-DD HH:mm:ss Z");
        this
            .post(
                checkTime,
                "Configuration/SetAnnouncementLastCheckTime", true, true
            )
            .done((reply) => {
                if (reply.isError) {
                    console.error(reply);
                } else {
                    const userProfileDataRefreshed = [...this.state.userProfileData];
                    const checkTimeState = userProfileDataRefreshed.find(a => a.Name === this.lastCheckTimeConfigKey);

                    if (Boolean(checkTimeState)) {
                        checkTimeState.Value = checkTime;
                    } else {
                        const newLastCheckTime = {
                            Name: this.lastCheckTimeConfigKey,
                            Value: checkTime
                        }

                        userProfileDataRefreshed.push(newLastCheckTime);
                    }

                    this.setState({
                        lastCheckTimeValue: checkTime,
                        userProfileData: userProfileDataRefreshed
                    });
                }
            })
            .fail((reply) => {
                console.error(reply);
            });
    }

    getUserMessage() {
        return this.post({}, "Notifications/GetUserMessages", true)
            .done((result) => {
                if (result != null) {
                    if (result != undefined && result?.length > 0) {
                        var language = localStorage.getItem("locale") || "tr";

                        var result = result.sort((a, b) =>
                            moment(a.MarketCreateDate, "YYYY-MM-DDTHH:mm:ssZ") >
                            moment(b.MarketCreateDate, "YYYY-MM-DDTHH:mm:ssZ")
                                ? -1
                                : 1
                        );

                        const filteredResult = result.filter(
                            (x) => x.Language == language.toUpperCase()
                        );

                        this.setState({userMessages: filteredResult});
                    } else {
                        this.setState({userMessages: []});
                    }
                }

                // this.PopUp(formatMessage(), 1);
            })
            .fail((result) => {
                //  this.PopUp(formatMessage(result), 2);
            });
    }

    getUserMessageDetail(announcementid) {
        this.post(
            {AnnouncementId: announcementid},
            "Notifications/GetUserMessagesLong"
        )
            .done((result) => {
                if (result != null) {
                    this.setState({userMessagesDetail: result, openDetailPopup: true});
                }
            })
            .fail((result) => {
                // this.PopUp(formatMessage(result), 2);
            });
    }

    handleChange = (e, p) => {
        this.setState({
            page: p,
        });
    };

    decodeEntities = (encodedString) => {
        const translate_re = /&(nbsp|amp|quot|lt|gt);/g;
        const translate = {
            "nbsp": " ",
            "amp": "&",
            "quot": "\"",
            "lt": "<",
            "gt": ">"
        };

        const regexForStripHTML = /(<([^>]+)>)/ig;

        return encodedString
            .replace(translate_re, function (match, entity) {
                return translate[entity];
            })
            .replace(/&#(\d+);/gi, function (match, numStr) {
                const num = parseInt(numStr, 10);
                return String.fromCharCode(num);
            })
            .replace(regexForStripHTML, "");
    }

    render() {
        //set paging count
        let dataCount = 1;
        if (this.state.userMessages) {
            dataCount = this.state.userMessages.length;
        }

        let count = Math.ceil(dataCount / this.PER_PAGE);

        //split data into current page
        const pageNumber = Math.max(1, this.state.page);
        const newPage = Math.min(pageNumber, count);

        const begin = (newPage - 1) * this.PER_PAGE;
        const end = begin + this.PER_PAGE;

        const haveMessage = this.state.userMessages.length > 0;

        const {formatMessage} = this.props.intl;

        return (
            <div>
                <Tooltip title={formatMessage({"id": "global.announcements"})}>
                    <IconButton
                        size="small"
                        id={"menu-help-button"}
                        key={"menu-help-button"}
                        onClick={() =>
                            this.setState({ShowDialog: true, unReadMessageCount: 0})
                        }
                    >
                        <Badge badgeContent={this.state.unReadMessageCount} color="error">
                            <Icons.MailOutline sx={{color: theme => theme.palette.text.secondary}}/>
                        </Badge>
                    </IconButton>
                </Tooltip>

                <Modal
                    style={{
                        position: "absolute",
                        top: "7%",
                        left: "55%",
                        maxHeight: "600px",
                        width: "33vw", //44
                    }}
                    open={Boolean(this.state.ShowDialog)}
                    onClose={() => {
                        this.setState({
                            ShowDialog: false,
                            messageIsReaded: true,
                        });
                    }}
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "center",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "center",
                    }}
                >
                    <Fade in={Boolean(this.state.ShowDialog)}>
                        <Card
                            style={{width: "100%", maxHeight: "650px", overflow: "auto"}}
                        >
                            <Grid sx={{
                                display: "flex",
                                alignItems: "center",
                                background: (theme) => theme.palette.background.neutral
                            }} container>
                                <Grid item md={6}>
                                    <Box
                                        style={{
                                            marginLeft: "13px",
                                            float: "left",
                                            left: 0,
                                            fontSize: "11px",
                                        }}
                                    >
                                        <FormattedMessage
                                            id="global.lastupdate"
                                            defaultMessage="Last Update"
                                        />{" "}
                                        : {this.state.lastCheckTime}
                                    </Box>
                                </Grid>
                                <Grid item md={6}>
                                    <IconButton
                                        style={{float: "right"}}
                                        id={"menu-close-button"}
                                        key={"menu-close-button"}
                                        color={"warning"}
                                        onClick={() =>
                                            this.setState({
                                                ShowDialog: false,
                                                unReadMessageCount: 0,
                                            })
                                        }
                                    >
                                        <Icons.Close/>
                                    </IconButton>
                                </Grid>
                            </Grid>

                            <CardContent style={{padding: 0}}>
                                <FormControl style={{display: "flex", alignItems: "center"}}>
                                    {haveMessage &&
                                        this.state.userMessages.map((item, index) => {
                                            if (index >= end) return false;
                                            return (
                                                index >= begin &&
                                                index < end && (
                                                    <>
                                                        <Box
                                                            sx={{
                                                                padding: "13px",
                                                                width: "28vw",
                                                                height: "auto",
                                                                border: (theme) => theme.palette.text.primary,
                                                                backgroundColor: (theme) =>
                                                                    !this.state.messageIsReaded &&
                                                                    item.isNew == true
                                                                        ? "#162105"
                                                                        : index % 2 === 0
                                                                            ? theme.palette.table.row.background.even
                                                                            : theme.palette.table.row.background.odd,
                                                                // backgroundColor:  index%2==0 ? '#f6f6f6': '#ffffff',

                                                                "&:hover": {
                                                                    backgroundColor: (theme) => theme.palette.action.hover,
                                                                    // opacity: (theme) => theme.palette.action.hoverOpacity,
                                                                },
                                                            }}
                                                        >
                                                            <Grid container style={{marginBottom: "7px"}}>
                                                                <Grid
                                                                    style={{
                                                                        display: "flex",
                                                                        alignItems: "center",
                                                                    }}
                                                                    item
                                                                    md={8}
                                                                >
                                                                    <div
                                                                        style={{
                                                                            marginRight: "4px",
                                                                            display: "flex",
                                                                        }}
                                                                    >
                                                                        <ForumIcon/>
                                                                    </div>
                                                                    {" "}
                                                                    {item.Title}
                                                                </Grid>
                                                                <Grid item style={{color: "#b4b4b4"}} md={4}>
                                                                    <div
                                                                        style={{
                                                                            color: "#b4b4b4",
                                                                            float: "right",
                                                                            fontSize: "11px",
                                                                            whiteSpace: "nowrap",
                                                                        }}
                                                                    >
                                                                        <FormattedMessage id="global.area"/>:{" "}
                                                                        {String(item.Area).toUpperCase()} /{" "}
                                                                        <FormattedMessage id="global.sources"/>:{" "}
                                                                        {item.Source}
                                                                    </div>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid container>
                                                                <Grid
                                                                    style={{
                                                                        marginBottom: "15px",
                                                                        textAlign: "justify",
                                                                        color: "#74787f",
                                                                    }}
                                                                    item
                                                                    md={12}
                                                                >
                                                                    {this.decodeEntities(item.ShortText)}
                                                                </Grid>

                                                                {item.Status == "L" && (
                                                                    <Grid
                                                                        style={{display: "", padding: "0"}}
                                                                        item
                                                                        md={12}
                                                                    >
                                                                        <Button
                                                                            size="small"
                                                                            variant="outlined"
                                                                            onClick={() => {
                                                                                this.getUserMessageDetail(
                                                                                    item.AnnouncementId
                                                                                );
                                                                            }}
                                                                            style={{float: "right", margin: "0"}}
                                                                        >
                                                                            <FormattedMessage id="global.getMore"/>
                                                                        </Button>
                                                                    </Grid>
                                                                )}
                                                            </Grid>

                                                            <Grid container>
                                                                <Grid
                                                                    style={{fontSize: "11px", color: "#b4b4b4"}}
                                                                    item
                                                                    md={12}
                                                                >
                                                                    <FormattedMessage id="global.starttime"/>:{" "}
                                                                    {this.getTimeByTimeZone(
                                                                        item.StartDate,
                                                                        "YYYY-MM-DDTHH:mm:ssZ",
                                                                        "DD.MM.YYYY HH:mm:ss ([UTC] Z)",
                                                                        false
                                                                    )}{" "}
                                                                    <br/>{" "}
                                                                    <FormattedMessage id="global.endtime"/>:{" "}
                                                                    {this.getTimeByTimeZone(
                                                                        item.EndDate,
                                                                        "YYYY-MM-DDTHH:mm:ssZ",
                                                                        "DD.MM.YYYY HH:mm:ss ([UTC] Z)",
                                                                        false
                                                                    )}
                                                                </Grid>
                                                            </Grid>
                                                        </Box>
                                                    </>
                                                )
                                            );
                                        })}

                                    {!haveMessage && (
                                        <div style={{textAlign: "center", marginTop: "20px"}}>
                                            <SpeakerNotesOffIcon
                                                style={{fontSize: "40px", color: "#b4b4b4"}}
                                            />
                                            <div style={{marginTop: "10px"}}>
                                                <FormattedMessage id="global.nonewmessage"/>
                                            </div>
                                        </div>
                                    )}
                                </FormControl>
                            </CardContent>
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "flex-end",
                                    height: "35px",
                                    background: (theme) => theme.palette.background.neutral
                                }}
                            >

                                <div style={{float: "right", right: 0}}>
                                    {haveMessage && (
                                        <Pagination
                                            count={count}
                                            page={newPage}
                                            onChange={this.handleChange}
                                            shape="rounded"
                                        />
                                    )}
                                </div>
                            </Box>
                        </Card>
                    </Fade>
                </Modal>

                <Modal
                    style={{
                        position: "absolute",
                        top: "2%",
                        left: "12%",
                        maxHeight: "600px",
                        width: "77vw",
                    }}
                    open={Boolean(this.state.openDetailPopup)}
                    onClose={() => {
                        this.setState({
                            openDetailPopup: false,
                        });
                    }}
                    anchorOrigin={{
                        vertical: "center",
                        horizontal: "center",
                    }}
                    transformOrigin={{
                        vertical: "center",
                        horizontal: "center",
                    }}
                >
                    <Card style={{width: "100%", maxHeight: "650px", overflow: "auto"}}>
                        <IconButton
                            style={{position: "absolute", top: 3, right: 30}}
                            id={"menu-close-button"}
                            key={"menu-close-button"}
                            color={"warning"}
                            onClick={() =>
                                this.setState({
                                    openDetailPopup: false,
                                })
                            }
                        >
                            <Icons.Close/>
                        </IconButton>
                        <CardContent>
                            <FormControl style={{width: "95%"}}>
                                <p style={{textAlign: "center"}}>
                                    {this.decodeEntities(this.state.userMessagesDetail[0]?.LongText ?? "")}
                                </p>
                            </FormControl>
                        </CardContent>
                    </Card>
                </Modal>
            </div>
        );
    }
}

export default injectIntl(Announcement);
