import equal from "fast-deep-equal";
import { IGipPlanningTickerInfo, INamedDepthInfo, INamedMyProposal, INamedContractDetail } from "../../api/types/gipPlanningWebSocketTypes";
import _ from "lodash";
import { PayloadAction } from "@reduxjs/toolkit";
import { GipPlanningActionKeys } from "./gipPlanningActionKeys";
const tickerLimit = 250;
const defaultTickerValue :IGipPlanningTickerInfo[] = [];
const defaultDepthValue :INamedDepthInfo = {};
const defaultMyProposalsValue: INamedMyProposal = {};
const defaultTradesValue : INamedContractDetail = {};

interface CommonAction {
  contractDataKey: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any;
}

export const gipPlanningTickersReducer = (state = defaultTickerValue, action) => {
  switch (action.type) { 
      case GipPlanningActionKeys.GIP_PLANNING_TICKER_ADD:
          return [action.payload, ...state.slice(0, tickerLimit)];
      case GipPlanningActionKeys.GIP_PLANNING_TICKER_ADD_ARRAY:
            return [...action.payload, ...state.slice(0, tickerLimit)];
      case GipPlanningActionKeys.GIP_PLANNING_TICKER_CLEAR:
            return [];
      default:
          return state;
  }
};


export const gipPlanningDepthInfoReducer = (state = defaultDepthValue, action: PayloadAction<CommonAction>):INamedDepthInfo => {
  switch (action.type) { 
      case GipPlanningActionKeys.GIP_PLANNING_DEPTH_INFO_UPDATE:
            return {...state, [action.payload.contractDataKey]: [...action.payload.data]};
      case GipPlanningActionKeys.GIP_PLANNING_DEPTH_INFO_CLEAR:
        return {} as INamedDepthInfo;
      case GipPlanningActionKeys.GIP_PLANNING_DEPTH_INFO_FROM_KEY_CLEAR:
        return _.omit(state, action.payload.contractDataKey);
      default:
          return state;
  }
};


export const gipPlanningMyProposalsReducer = (state = defaultMyProposalsValue, action: PayloadAction<CommonAction>): INamedMyProposal => {
  switch (action.type) { 
      case GipPlanningActionKeys.GIP_PLANNING_MY_PROPOSALS_UPDATE:
        if(!equal(state[action.payload.contractDataKey], action.payload.data))
          return {...state, [action.payload.contractDataKey]: [...action.payload.data]};
        else
          return state;
      case GipPlanningActionKeys.GIP_PLANNING_MY_PROPOSALS_CLEAR:
        return {} as INamedMyProposal;
      case GipPlanningActionKeys.GIP_PLANNING_MY_PROPOSALS_FROM_KEY_CLEAR:
        return _.omit(state, action.payload.contractDataKey);
      default:
          return state;
  }
};

export const gipPlanningTradesReducer = (state = defaultTradesValue, action: PayloadAction<CommonAction>): INamedContractDetail => {
  switch (action.type) { 
      case GipPlanningActionKeys.GIP_PLANNING_TRADES_UPDATE:
        return {...state, [action.payload.contractDataKey]: [...action.payload.data]};  
      case GipPlanningActionKeys.GIP_PLANNING_TRADES_CLEAR:
        return {} as INamedContractDetail;
      case GipPlanningActionKeys.GIP_PLANNING_TRADES_FROM_KEY_CLEAR:
        return _.omit(state, action.payload.contractDataKey);
      default:
          return state;
  }
};
