import { IContractTradeDetail, IDepthInfo, IGipPlanningTickerInfo, IMyProposal } from "../../api/types/gipPlanningWebSocketTypes";
import store from '../../stores';
import { GipPlanningActionKeys } from "./gipPlanningActionKeys";

export const setGipPlanningTicker = (ticker: IGipPlanningTickerInfo) => {
  store.dispatch({
    type: GipPlanningActionKeys.GIP_PLANNING_TICKER_ADD,
    payload:ticker
  });
};


export const setGipPlanningTickers = (ticker:IGipPlanningTickerInfo[]) => {
  store.dispatch({
    type: GipPlanningActionKeys.GIP_PLANNING_TICKER_ADD_ARRAY,
    payload:ticker
  });
};

export const clearGipPlanningTickers = () => {
  store.dispatch({
    type: GipPlanningActionKeys.GIP_PLANNING_TICKER_CLEAR,
  });
};

export const getGipPlanningTickers = () => {
  const currentState = store.getState();
  return currentState.gipPlanningTickers??[];
};


export const updateGipPlanningMyProposals = (contractDataKey:string, list:IMyProposal[]) => {
  store.dispatch({
    type: GipPlanningActionKeys.GIP_PLANNING_MY_PROPOSALS_UPDATE,
    payload:{
      contractDataKey,
      data:list
    }
  });
};


export const updateGipPlanningDepthInfo = (contractDataKey:string, list:IDepthInfo[]) => {
  store.dispatch({
    type: GipPlanningActionKeys.GIP_PLANNING_DEPTH_INFO_UPDATE,
    payload:{
      contractDataKey,
      data:list
    }
  });
};

export const updateGipPlanningTrades= (contractDataKey:string, list:IContractTradeDetail[]) => {
  store.dispatch({
    type: GipPlanningActionKeys.GIP_PLANNING_TRADES_UPDATE,
    payload:{
      contractDataKey,
      data: list
    }
  });
};


export const clearGipPlanningMyProposals = () => {
  store.dispatch({
    type: GipPlanningActionKeys.GIP_PLANNING_MY_PROPOSALS_CLEAR,
    payload:{  }
  });
};
export const clearGipPlanningMyProposalsFromKey = (contractDataKey:string) => {
  store.dispatch({
    type: GipPlanningActionKeys.GIP_PLANNING_MY_PROPOSALS_FROM_KEY_CLEAR,
    payload:{  
      contractDataKey:contractDataKey
    }
  });
};


export const clearGipPlanningDepthInfo = () => {
  store.dispatch({
    type: GipPlanningActionKeys.GIP_PLANNING_DEPTH_INFO_CLEAR,
    payload:{ 
      
    }
  });
};

export const clearGipPlanningDepthInfoFromKey = (contractDataKey:string) => {
  store.dispatch({
    type: GipPlanningActionKeys.GIP_PLANNING_DEPTH_INFO_FROM_KEY_CLEAR,
    payload:{ 
      contractDataKey:contractDataKey
    }
  });
};

export const clearGipPlanningTrades= () => {
  store.dispatch({
    type: GipPlanningActionKeys.GIP_PLANNING_TRADES_CLEAR,
    payload:{ 
    }
  });
};

export const clearGipPlanningTradesFromKey = (contractDataKey:string)=> {
  store.dispatch({
    type: GipPlanningActionKeys.GIP_PLANNING_TRADES_FROM_KEY_CLEAR,
    payload:{ 
      contractDataKey:contractDataKey
    }
  });
};