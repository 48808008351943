const es = {
    general: {
        logout: 'Cerrar Sesión',
        docs: 'Documentación',
        notallowedip: 'No se puede acceder desde IP no autorizada!',
        confirm: 'Confirmar',
        cancel: 'Cancelar',
    },
    global: {
        notvalidcode: 'codigo invalido',
    },
    menu: {
        menu: 'Menú',
        home: 'Hogar'
    },
    pages: {
        home: 'Página principal',
        settings: 'Configuraciones',
        profile: 'Perfil Configuraciones'
    },
    settings: {
        darkMode: 'Modo oscuro',
        layout: 'Disposición'
    },
    login: {
        welcome: 'Bienvenido!',
        signInToPortal: 'Iniciar sesión en el Portal smartPulse',
        meetUs: 'Conócenos!',
        onBoarding: 'smartPulse encabeza una nueva era en los mercados energéticos al combinar el poder de los dispositivos IoT con algoritmos de IA. ¡Experimente la nueva era de la gestión del comercio de energía con smartPulse.',
        joinUs: 'Convertirse en miembro de la familia smartPulse?',
        userName: 'Nombre de Usuario',
        password: 'Contraseña',
        showPasswordTooltip: 'Mostrar Contraseña',
        hidePasswordTooltip: 'Contraseña Oculta',
        login: 'Iniciar sesión',
        usernamewrong: "Usuario incorrecto",
        passwordwrong: "La contraseña es incorrecta",
        usernameorpasswordwrong: "Nombre de Usuario o Contraseña incorrectos!",
        verifyCaptchaError: "¡El código de validar es incorrecto!",
        validation: {
            userName: 'Se requiere nombre de usuario',
            password: 'Se requiere contraseña'
        },
        centralAccount: "Cuenta Central",
        submit: "Enviar",
        rememberMe: "Recordarme",
        validate: "Validar",
        external: {
            user: "Utilice el botón 'CUENTA CENTRAL' para iniciar sesión",
        }
    },
    contracts: {
        contract: 'Contrato',
        contractId: 'ID De Contrato',
    },
};

export default es;
