import {useState, useEffect} from 'react';
// @mui
import {
    Box,
    Tooltip,
    Divider,
    IconButton,
    Typography,
    ListItemText,
    ListItemAvatar,
    ListItemButton,
    Switch,
    FormControlLabel,
    List,
    ListSubheader,
    Badge,
    Button,
    Stack,
} from '@mui/material';

// utils
import {IBasePageProps} from 'src/old/src/base/basepage';
import {FormattedMessage, injectIntl} from 'react-intl';
import * as NotificationService from '../../../services/notifications/notifications.service';
import * as Utils from '../../../utils';
import {NotificationModel} from '../../../models/notifications.model';
import {Site} from '../../../utils/site';
import _ from 'lodash';

// components
import MenuPopover from '../../../components/MenuPopover';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Iconify from '../../../components/Iconify';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import {MarkNotificationReadIcon} from 'src/old/src/components/icons';
import NotificationsPausedIcon from '@mui/icons-material/NotificationsPaused';
import Scrollbar from 'src/components/Scrollbar';
import store from "../../../old/src/redux/stores";
import {ESocketState} from 'src/enums/socket-state.enum';

// const host = location.host;
// const webSocketSubscribeUrl = host.includes('smartpulse.io') 
//   ? 'wss://realtime-webapi.staging.svc.cluster.local/api/Notifications/Subscribe'
//   : 'wss://realtime-webapi.staging.smartpulse.io/api/Notifications/Subscribe';
// const pingTime = 50000;
// const webSocketReopenTime = 10000;

function NotificationsPopover({intl}: IBasePageProps) {
    const {formatMessage} = intl;

    const [notifications, setNotifications] = useState<NotificationModel[]>([]);
    const [open, setOpen] = useState<HTMLElement | null>(null);

    // const allState = store.getState();
    const intervalConfig = import.meta.env.VITE_USER_NOTIFICATIONS_INTERVAL;

    useEffect(() => {
        getUserNotifications();

        let intervalValue: number | undefined = 5000;

        if (intervalConfig) {
            intervalValue = Number(intervalConfig);

            if (intervalValue === -1) {
                intervalValue = undefined;
                console.warn("User notifications auto refresh is disabled by dev env configuration!");
            }
        }

        let intervalId: NodeJS.Timer | undefined;

        if (intervalValue) {
            intervalId = setInterval(() => {
                getUserNotifications();
            }, intervalValue);
        }


        // let webSocket = new WebSocket(`${webSocketSubscribeUrl}?userId=${allState.common.appuserid}`);

        // webSocket.onopen = (e: Event) => {};
        // webSocket.onerror = (e: Event) => {};

        // webSocket.onmessage = (e: MessageEvent) => {
        //   getUserNotifications();
        // };

        // webSocket.onclose = (e: CloseEvent) => {
        //   setTimeout(() => {
        //     webSocket = new WebSocket(`${webSocketSubscribeUrl}?userId=${allState.common.appuserid}`);
        //   }, webSocketReopenTime);
        // };

        // const intervalId = setInterval(() => {
        //   if (webSocket.readyState == ESocketState.Open) {
        //     webSocket.send("ping");
        //   }
        // }, pingTime);

        return () => {
            // if(webSocket !== null && (webSocket.readyState == ESocketState.Open || webSocket.readyState == ESocketState.Connecting)){
            //   webSocket.close();
            // }
            if (intervalId) {
                clearInterval(intervalId);
            }
        };
    }, []);

    const getUserNotifications = () => {
        NotificationService.getNotifications()
            .then((response) => {
                if (response.isError) {
                    Utils.showErrorMessage('Error');
                } else {
                    setNotifications(response.Data?.Notifications || []);
                }
            })
            .catch((error) => Utils.showErrorMessage(error));
    };

    const readNotifications = (notificationId: string) => {
        NotificationService.readNotifications(notificationId)
            .then((response) => {
                if (response.isError) {
                    Utils.showErrorMessage('Error');
                } else {
                    const readedNotification = notifications.find((x) => x.Id === notificationId);
                    readedNotification!.Status = 1;
                    const updatedNotifications = _.cloneDeep(notifications);
                    setNotifications(updatedNotifications);
                }
            })
            .catch((error) => Utils.showErrorMessage(error));
    };

    const readAll = () => {
        NotificationService.readAll()
            .then((response) => {
                if (response.isError) {
                    Utils.showErrorMessage('Error');
                }
            })
            .catch((error) => Utils.showErrorMessage(error));
    };

    const pushNotificationEnabled = (Site?.ServiceWorker?.Subscription || null) !== null;

    const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
        setOpen(event.currentTarget);
    };

    const handleClose = () => {
        setOpen(null);
    };

    const getMessageForDescription = (desc: any) => {
        try {
            const {formatMessage, formatDate} = intl;
            const res = JSON.parse(desc);
            return formatMessage(
                {id: res.Text},
                {startDate: formatDate(res.Params[0]), endDate: formatDate(res.Params[1])}
            );
        } catch {
            return desc;
        }
    };

    const unreadedcount = notifications.filter((notification) => notification.Status === 0).length;

    const listItemButtonProps = {
        py: 1.5,
        px: 2.5,
        mt: '1px',
    };

    return (
        <>
            <Tooltip title={formatMessage({id: 'global.notifications'})}>
                <IconButton
                    color={open ? 'primary' : 'default'}
                    onClick={handleOpen}
                    sx={{width: 40, height: 40}}
                >
                    <Badge badgeContent={unreadedcount} color="error">
                        <NotificationsIcon width={20} height={20}/>
                    </Badge>
                </IconButton>
            </Tooltip>

            <MenuPopover
                arrow="top-center"
                open={Boolean(open)}
                anchorEl={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                sx={{width: 400, p: 0, mt: 4}}
            >
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        py: 2,
                        px: 2,
                        width: '100%',
                    }}
                >
                    <Button
                        sx={{fontSize: 12}}
                        onClick={() => {
                            readAll();
                        }}
                        startIcon={<MarkNotificationReadIcon color="primary"/>}
                        variant="outlined"
                    >
                        {formatMessage({id: 'global.readall.upper'})}
                    </Button>
                    <FormControlLabel
                        label={formatMessage({id: 'global.pushnotifications'})}
                        labelPlacement={'end'}
                        style={{margin: 0}}
                        control={
                            <Switch
                                size="small"
                                color="primary"
                                defaultChecked={pushNotificationEnabled}
                                onChange={(event: any) => {
                                    if (event.target.checked) {
                                        //@ts-ignore
                                        Site?.ServiceWorker?.RegisterDevice();
                                    } else {
                                        //@ts-ignore
                                        Site.ServiceWorker.RevokePermission();
                                    }
                                }}
                            />
                        }
                    />
                </Box>

                <Divider sx={{borderStyle: 'dashed'}}/>

                <Scrollbar style={{maxHeight: 800}}>
                    <List style={{maxHeight: '800px'}} sx={{height: {xs: 340, sm: 'auto'}}}>
                        <List
                            disablePadding
                            subheader={
                                <ListSubheader disableSticky sx={{py: 1, px: 2.5, typography: 'body1'}}>
                                    {formatMessage({id: 'global.new'})}
                                </ListSubheader>
                            }
                        >
                            {(notifications || [])
                                .filter((notification) => notification.Status === 0)
                                .map((notification) => (
                                    <ListItemButton
                                        sx={listItemButtonProps}
                                        onClick={() => {
                                            readNotifications(notification.Id);
                                        }}
                                        key={notification.Id}
                                    >
                                        <ListItemAvatar>
                                            <NotificationsActiveIcon/>
                                        </ListItemAvatar>

                                        <ListItemText
                                            primary={
                                                <Typography variant="body2">
                                                    {getMessageForDescription(notification.Description)}
                                                </Typography>
                                            }
                                            secondary={
                                                <Typography
                                                    variant="body2"
                                                    sx={{
                                                        mt: 0.5,
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        color: 'text.disabled',
                                                    }}
                                                >
                                                    <Iconify
                                                        icon="eva:clock-outline"
                                                        sx={{mr: 0.5, width: 16, height: 16}}
                                                    />
                                                    {notification.CreateDate}
                                                </Typography>
                                            }
                                        />
                                    </ListItemButton>
                                ))}
                        </List>

                        {unreadedcount === 0 && (
                            <Stack
                                direction="row"
                                justifyContent={'center'}
                                alignItems={'center'}
                                spacing={1}
                                padding={1}
                            >
                                <NotificationsPausedIcon sx={{textAlign: 'center'}}/>
                                <Typography variant={'body2'}>
                                    <FormattedMessage
                                        id="global.nonewnotification"
                                        defaultMessage="No New Notifications."
                                    />
                                </Typography>
                            </Stack>
                        )}

                        {notifications.length > 0 && (
                            <>
                                <Divider sx={{borderStyle: 'dashed'}}/>
                                <List
                                    disablePadding
                                    subheader={
                                        <ListSubheader disableSticky sx={{py: 1, px: 2.5, typography: 'body1'}}>
                                            {formatMessage({id: 'global.beforethat'})}
                                        </ListSubheader>
                                    }
                                >
                                    {(notifications || [])
                                        .filter((notification) => notification.Status === 1)
                                        .map((notification) => (
                                            <ListItemButton sx={listItemButtonProps} key={notification.Id}>
                                                <ListItemAvatar>
                                                    <NotificationsNoneIcon/>
                                                </ListItemAvatar>

                                                <ListItemText
                                                    primary={
                                                        <Typography variant="body2">
                                                            {getMessageForDescription(notification.Description)}
                                                        </Typography>
                                                    }
                                                    secondary={
                                                        <Typography
                                                            variant="caption"
                                                            sx={{
                                                                mt: 0.5,
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                color: 'text.disabled',
                                                            }}
                                                        >
                                                            <Iconify
                                                                icon="eva:clock-outline"
                                                                sx={{mr: 0.5, width: 16, height: 16}}
                                                            />
                                                            {notification.CreateDate}
                                                        </Typography>
                                                    }
                                                />
                                            </ListItemButton>
                                        ))}
                                </List>{' '}
                            </>
                        )}
                    </List>
                </Scrollbar>
            </MenuPopover>
        </>
    );
}

export default injectIntl(NotificationsPopover);
