import moment from 'moment';
import {
  PF_DEFAULT_MEASURE_UNIT,
  PF_DEFAULT_PERIOD,
  PF_LOCK_UNIT_THRESHOLD,
} from 'src/pages/ProductionForecast/constants';
import {
  EnabledPeriods,
  ICellInfo,
  IChangeSummary,
  IDelivery,
  ILockedCell,
  ILockProgressInfo,
  IPowerPlantData,
  IPowerPlantPredictionLocks,
  IPredictionHistory,
  IPredictionHistoryDetails,
  IProductionForecastData,
  IProvider,
  IRowData,
  IUnitLockInfo,
  MeasureUnit,
  PfPopup,
} from 'src/pages/ProductionForecast/types';
import { getColName, isSameDelivery } from 'src/pages/ProductionForecast/utils';

import { createSlice } from '@reduxjs/toolkit';

import { IUploadedFile } from '../../pages/ProductionForecast/types';

import type { PayloadAction } from '@reduxjs/toolkit';
import { IProgressInfo } from 'src/components/ProgressList/types';
export interface IProductionForecastState {
  selectedDate: Date;
  selectedPowerPlants: number[];
  measureUnit: MeasureUnit;
  period: EnabledPeriods;
  providers: IProvider[];
  initialRowData: IRowData[];
  powerPlantData: IPowerPlantData[];
  emptyCells: ICellInfo[];

  /**
   * Originally fetched data from the backend. (Production Forecast Original)
   */
  pfo: IProductionForecastData;
  /**
   * Distinct deliveries,
   * since the backend returns a list of predictions for each power plant
   * and power plants might have different TZs.
   * We store distinct deliveries here to setup AgGrid.
   */
  deliveries: IDelivery[];

  // Locks
  predictionLocks: IPowerPlantPredictionLocks[];
  lockedCellsProvider: ILockedCell[];
  lockedCellsUser: ILockedCell[];
  lockProgressInfo: ILockProgressInfo;

  // History
  historyValues: IPredictionHistoryDetails[];
  historyDeliveryKey: string | null;
  historyCell: string | null;
  historyProviderKey: string | null;
  historyClickTimestamp: number | null;
  lastPredictionBeforeLock?: IPredictionHistoryDetails;

  // Loading
  disabledGrid: boolean;
  hideGridLoader: boolean;

  // KWh Warning MessageBox
  isKWhOk: boolean;

  // Popovers
  anchorEl: any;
  activePopup: PfPopup;
  uploadedFiles: IUploadedFile[];
  lastUpdateClickTimestamp: number | null;
  changeSummaries: IChangeSummary[];

  // Lock Settings
  autoLockOnSave: boolean;
  autoLockTime: Date | null;
  autoLockGopOnSave: boolean;
  autoLockGopTime: Date | null;
  autoLockGroupOnSave: boolean;
  autoLockGroupStartTime: Date | null;
  autoLockGroupEndTime: Date | null;
  autoLockGroupIntradayTime: number;
  selectedProviderLockPowerPlants: number[];
}

const initialState: IProductionForecastState = {
  selectedDate: moment().add(1, 'day').toDate(),
  selectedPowerPlants: [],
  initialRowData: [],
  pfo: {} as IProductionForecastData,
  deliveries: [],
  providers: [],
  measureUnit: PF_DEFAULT_MEASURE_UNIT,
  period: PF_DEFAULT_PERIOD,
  powerPlantData: [],
  emptyCells: [],

  // Locks
  predictionLocks: [],
  lockedCellsProvider: [],
  lockedCellsUser: [],
  lockProgressInfo: { actionType: 'lock', list: [] } as ILockProgressInfo,

  // History
  historyValues: [],
  historyDeliveryKey: null,
  historyProviderKey: null,
  historyCell: null,
  historyClickTimestamp: null,

  // Loading
  disabledGrid: true,
  hideGridLoader: false,

  // KWh Warning MessageBox
  isKWhOk: false,

  // Popovers
  anchorEl: null,
  activePopup: PfPopup.None,
  uploadedFiles: [],
  lastUpdateClickTimestamp: null,
  changeSummaries: [],

  // Lock Settings
  autoLockOnSave: false,
  autoLockTime: null,
  autoLockGopOnSave: false,
  autoLockGopTime: null,
  autoLockGroupOnSave: false,
  autoLockGroupStartTime: null,
  autoLockGroupEndTime: null,
  autoLockGroupIntradayTime: 0,
  selectedProviderLockPowerPlants: [],
};

export const productionForecastSlice = createSlice({
  name: 'production-forecast',
  initialState,
  reducers: {
    setSelectedPowerPlants: (state, action: PayloadAction<number[]>) => {
      state.selectedPowerPlants = action.payload;
    },
    setSelectedDate: (state, action: PayloadAction<Date>) => {
      state.selectedDate = action.payload;
      state.historyClickTimestamp = null;
      state.emptyCells = [];
    },
    triggerUpdateData(state) {
      state.lastUpdateClickTimestamp = Date.now();
      state.historyClickTimestamp = null;
      state.emptyCells = [];
    },
    setMeasureUnit: (state, action: PayloadAction<MeasureUnit>) => {
      state.measureUnit = action.payload;
    },
    setPeriod: (state, action: PayloadAction<EnabledPeriods>) => {
      state.period = action.payload;
    },
    setProviderLockedCells: (
      state,
      action: PayloadAction<{
        ppLockInfo: IUnitLockInfo;
      }>
    ) => {
      const { ppLockInfo } = action.payload;

      const fieldName = getColName(ppLockInfo.unitNo, 'prediction');
      for (const dl of ppLockInfo.deliveryLocks) {
        if (
          dl.lockState === 1 &&
          !state.lockedCellsProvider.some(
            (e) => e.fieldName === fieldName && isSameDelivery(e.delivery, dl)
          )
        ) {
          state.lockedCellsProvider.push({
            fieldName,
            delivery: {
              deliveryStart: dl.deliveryStart,
              deliveryEnd: dl.deliveryEnd,
              deliveryEndOffset: dl.deliveryEndOffset,
              deliveryStartOffset: dl.deliveryStartOffset,
              period: moment(dl.deliveryEnd).diff(moment(dl.deliveryStart), 'minutes'),
            },
          });
        } else if (dl.lockState === 0) {
          state.lockedCellsProvider = state.lockedCellsProvider.filter(
            (e) => (e.fieldName === fieldName && isSameDelivery(e.delivery, dl)) === false
          );
        }
      }
    },
    setupProductionForecast: (
      state,
      action: PayloadAction<{
        pfo: IProductionForecastData;
        deliveries: IDelivery[];
        powerPlants: IPowerPlantData[];
        rows: IRowData[];
        lockedCellsProvider: ILockedCell[];
        lockedCellsUser: ILockedCell[];
      }>
    ) => {
      const { pfo, deliveries, powerPlants, rows, lockedCellsProvider, lockedCellsUser } =
        action.payload;

      if (typeof pfo.measureUnit === 'string') {
        pfo.measureUnit = MeasureUnit[pfo.measureUnit as keyof typeof MeasureUnit];
      }
      state.deliveries = deliveries;
      state.pfo = pfo;
      state.predictionLocks = pfo.forecastData.map((p) => ({
        powerPlantId: p.powerPlantId,
        deliveryLocks: p.deliveryLocks,
      }));
      state.initialRowData = structuredClone(rows);
      state.lockedCellsProvider = lockedCellsProvider;
      state.lockedCellsUser = lockedCellsUser;
      state.powerPlantData = powerPlants;
      state.providers = pfo.providers;
      state.disabledGrid = false;
      state.measureUnit = pfo.measureUnit;

      // Lock Settings
      state.autoLockOnSave = Boolean(pfo.expiration);
      state.autoLockTime = pfo.expiration ? new Date(pfo.expiration) : null;
      state.autoLockGopOnSave = pfo.gopExpiration !== null;
      state.autoLockGopTime = pfo.gopExpiration ? new Date(pfo.gopExpiration) : null;
      state.autoLockGroupOnSave = pfo.groupAutoLockSettings;
      state.autoLockGroupStartTime = pfo.groupLockStart ? new Date(pfo.groupLockStart) : null;
      state.autoLockGroupEndTime = pfo.groupLockEnd ? new Date(pfo.groupLockEnd) : null;
      state.autoLockGroupIntradayTime = pfo.groupLockIntradayTime ? pfo.groupLockIntradayTime : 0;
    },
    setHistoryValues: (
      state,
      action: PayloadAction<{
        historyProviderKey: string;
        historyDelivery: string;
        historyCell: string;
        history: IPredictionHistory | undefined;
      }>
    ) => {
      action.payload.history?.history.sort(
        (a, b) => new Date(b.createDate).getTime() - new Date(a.createDate).getTime()
      );

      state.historyProviderKey = action.payload.historyProviderKey;
      state.historyDeliveryKey = action.payload.historyDelivery;
      state.historyCell = action.payload.historyCell;
      state.historyValues = action.payload.history?.history ?? [];
      state.lastPredictionBeforeLock =
        action.payload.history?.lastPredictionBeforeLock ?? undefined;
    },
    showHistoryValues: (
      state,
      action: PayloadAction<{
        anchorEl: any;
        isRefetch: boolean;
      }>
    ) => {
      state.anchorEl = action.payload.anchorEl;
      state.activePopup = PfPopup.PredictionHistory;
      if (action.payload.isRefetch) state.historyClickTimestamp = Date.now();
    },
    showUploadedFiles: (
      state,
      action: PayloadAction<{
        anchorEl: any;
        uploadedFiles: IUploadedFile[];
      }>
    ) => {
      action.payload.uploadedFiles.sort(
        (a, b) => new Date(b.CreateDate).getTime() - new Date(a.CreateDate).getTime()
      );

      state.anchorEl = action.payload.anchorEl;
      state.activePopup = PfPopup.PredictionUploads;
      state.uploadedFiles = action.payload.uploadedFiles;
    },
    showChangeSummary: (
      state,
      action: PayloadAction<{
        changeSummaries: IChangeSummary[];
      }>
    ) => {
      state.activePopup = PfPopup.ChangeSummary;
      state.changeSummaries = action.payload.changeSummaries;
    },
    /**
     * Use **showHistoryValues** instead for prediction history popup
     *
     * Use **showUploadedFiles** instead for prediction uploads popup
     *
     * Use **showChangeSummary** instead for change summary popup
     * @param state
     * @param action
     */
    openPopup: (
      state,
      action: PayloadAction<{
        anchorEl: any;
        popupType: PfPopup.LockSettings | PfPopup.TemplateDownload | PfPopup.LockProgress;
      }>
    ) => {
      state.anchorEl = action.payload.anchorEl;
      state.activePopup = action.payload.popupType;
    },
    closeActivePopup: (state) => {
      state.anchorEl = null;
      state.activePopup = PfPopup.None;

      // clear various popup states
      state.uploadedFiles = [];
    },
    setLockProgressInfo: (state, action: PayloadAction<ILockProgressInfo>) => {
      state.lockProgressInfo = action.payload;
      state.activePopup = PfPopup.LockProgress;
    },
    updateLockProgressInfo: (
      state,
      action: PayloadAction<Omit<IProgressInfo, 'title' | 'progress'>>
    ) => {
      const index = state.lockProgressInfo.list.findIndex(
        (e) => e.id === action.payload.id
      );

      if (index >= 0) {
        state.lockProgressInfo.list[index].state = action.payload.state;
        state.lockProgressInfo.list[index].message = action.payload.message;
      }
    },
    handleLockProgressEnd: (state, action: PayloadAction<{ forceClear?: boolean } | undefined>) => {
      const clear = () => {
        if (state.activePopup === PfPopup.LockProgress) {
          state.activePopup = PfPopup.None;
          state.anchorEl = null;
        }
        state.lockProgressInfo.list = [];
      };

      if (action.payload?.forceClear) clear();
      if (
        !state.lockProgressInfo.list.some((lsi) => ['error', 'inProgress'].includes(lsi.state)) &&
        state.lockProgressInfo.list.length <= PF_LOCK_UNIT_THRESHOLD
      )
        clear();
    },
    disableGrid: (state, action: PayloadAction<{ hideGridLoader?: boolean } | undefined>) => {
      state.disabledGrid = true;
      state.hideGridLoader = action.payload?.hideGridLoader ?? false;

      // keep lock progress popup open
      if (state.activePopup !== PfPopup.LockProgress) state.activePopup = PfPopup.None;
    },
    enableGrid: (state) => {
      state.disabledGrid = false;
      state.hideGridLoader = false;
    },
    setIsKwhOk: (state, action: PayloadAction<boolean>) => {
      state.isKWhOk = action.payload;
    },
    setSelectedProviderLockPowerPlants: (state, action: PayloadAction<number[]>) => {
      state.selectedProviderLockPowerPlants = action.payload;
    },
    setAutoLock: (
      state,
      action: PayloadAction<{
        autoLockOnSave: boolean;
        autoLockTime: Date | null;
      }>
    ) => {
      state.autoLockOnSave = action.payload.autoLockOnSave;
      state.autoLockTime = action.payload.autoLockTime;
    },
    setGopAutoLock: (
      state,
      action: PayloadAction<{
        autoLockGopOnSave: boolean;
        autoLockGopTime: Date;
      }>
    ) => {
      state.autoLockGopOnSave = action.payload.autoLockGopOnSave;
      state.autoLockGopTime = action.payload.autoLockGopTime;
    },
    setGroupAutoLock: (
      state,
      action: PayloadAction<{
        autoLockGroupOnSave: boolean;
        autoLockGroupStartTime: Date | null;
        autoLockGroupEndTime: Date | null;
        autoLockGroupIntradayTime: number;
      }>
    ) => {
      state.autoLockGroupOnSave = action.payload.autoLockGroupOnSave;
      state.autoLockGroupStartTime = action.payload.autoLockGroupStartTime;
      state.autoLockGroupEndTime = action.payload.autoLockGroupEndTime;
      state.autoLockGroupIntradayTime = action.payload.autoLockGroupIntradayTime;
    },
    setEmptyCells: (state, action: PayloadAction<ICellInfo[]>) => {
      state.emptyCells = action.payload;
    },
    removeEmptyCell: (state, action: PayloadAction<Omit<ICellInfo, 'powerPlantId'>>) => {
      const index = state.emptyCells.findIndex(
        (ec) => ec.cellKey === action.payload.cellKey && ec.rowId === action.payload.rowId
      );
      state.emptyCells.splice(index, 1);
    },
    cleanup: (state) => {
      return {
        ...initialState,
        // selectedDate: state.selectedDate,
        selectedPowerPlants: state.selectedPowerPlants,
        measureUnit: state.measureUnit,
      };
    },
  },
});

export const {
  setSelectedDate,
  triggerUpdateData,
  setMeasureUnit,
  setPeriod,
  setSelectedPowerPlants,
  setupProductionForecast,
  setEmptyCells,
  removeEmptyCell,

  // Loading
  enableGrid,
  disableGrid,

  // Locks
  setProviderLockedCells,
  setSelectedProviderLockPowerPlants,

  // Popovers
  openPopup,
  closeActivePopup,
  showChangeSummary,
  showUploadedFiles,
  setIsKwhOk,
  setHistoryValues,
  showHistoryValues,

  // Locks
  setAutoLock,
  setGopAutoLock,
  setGroupAutoLock,
  setLockProgressInfo,
  updateLockProgressInfo,
  handleLockProgressEnd,

  // Cleanup
  cleanup,
} = productionForecastSlice.actions;

export default productionForecastSlice.reducer;
