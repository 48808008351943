import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Box, BoxProps, Tooltip } from '@mui/material';
import useSettings from 'src/hooks/useSettings';
import { getHostEnvironmentOverrides } from 'src/utils/hostEnvironment';
import { useEffect } from 'react';

// ----------------------------------------------------------------------

interface Props extends BoxProps {
  disabledLink?: boolean;
  toLink?: string;
}

export default function Logo({ disabledLink = false, sx, toLink = "/home" }: Props) {
  const { themeMode, themeLayout, onToggleMode, onToggleLayout } = useSettings();

  const testFeatHandler = (e: any) => {
    if (!getHostEnvironmentOverrides().testFeats?.isThemeLogoShortcutEnabled || !e.shiftKey) return;

    e.preventDefault();
    if (e.ctrlKey) onToggleMode();
    else if (e.altKey) onToggleLayout();
  };

  useEffect(() => {
    if (!getHostEnvironmentOverrides().testFeats?.isThemeLogoShortcutEnabled) return;
    const logoEl = document.getElementById('smartpulse-portal-logo');

    logoEl?.addEventListener('click', testFeatHandler);
    return () => {
      logoEl?.removeEventListener('click', testFeatHandler);
    };
  }, [themeMode, themeLayout]);

  const logo = (
    <Tooltip
      title={getHostEnvironmentOverrides().logo.tooltipText}
    >
      <Box id="smartpulse-portal-logo" sx={{ height: 45, ...sx }}>
        <img src={getHostEnvironmentOverrides().logo.src} style={{ height: 45 }} />
      </Box>
    </Tooltip>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to={toLink}>{logo}</RouterLink>;
}
