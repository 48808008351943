// Example locale file for English, give this to your locale team to translate

export const AG_GRID_LOCALE_TR = {
    // Set Filter
    selectAll: '(Tümünü seç)',
    selectAllSearchResults: '(Tüm Arama Sonuçlarını Seç)',
    searchOoo: 'Ara...',
    blanks: 'Boş',
    noMatches: 'Eşleşme Yok',

    // Number Filter & Text Filter
    filterOoo: 'Filtrele...',
    equals: 'Eşittir',
    notEqual: 'Eşit değil',
    blank: 'Boş',
    notBlank: 'Boş değil',
	empty: 'Seçiniz',
	
	// Number Filter
    lessThan: 'Küçüktür',
    greaterThan: 'Büyüktür',
    lessThanOrEqual: 'Küçük eşit',
    greaterThanOrEqual: 'Büyük eşit',
    inRange: 'Aralık',
    inRangeStart: 'Başlangıç',
    inRangeEnd: 'Bitiş',

    // Text Filter
    contains: 'İçeriyorsa',
    notContains: 'İçermiyorsa',
    startsWith: 'Başlıyorsa',
    endsWith: 'Bitiyorsa',

    // Date Filter
    dateFormatOoo: 'yyyy-mm-dd',

    // Filter Conditions
    andCondition: 'VE',
    orCondition: 'VEYA',

    // Filter Buttons
    applyFilter: 'Uygula',
    resetFilter: 'Sıfırla',
    clearFilter: 'Temizle',
    cancelFilter: 'İptal',

    // Filter Titles
    textFilter: 'Metin Filtresi',
    numberFilter: 'Rakam Filtresi',
    dateFilter: 'Tarih Filtresi',
    setFilter: 'Küme Filtresi',

    // Side Bar
    columns: 'Sütunlar',
    filters: 'Filtreler',

    // columns tool panel
    pivotMode: 'Pivot-Modu',
    groups: 'Gruplar',
    rowGroupColumnsEmptyMessage: 'Sütunları gruba sürükle',
    values: 'Değerler',
    valueColumnsEmptyMessage: 'Toplamak için sütunları sürükleyin',
    pivots: 'Pivotlar',
    pivotColumnsEmptyMessage: 'Pivot için buraya sürükleyin',

    // Header of the Default Group Column
    group: 'Grup',

    // Row Drag
    rowDragRows:'satırlar',

    // Other
    loadingOoo: 'Yükleniyor...',
    noRowsToShow: 'Gösterilecek veri yok.',
    enabled: 'Etkin',

    // Menu
    pinColumn: 'Sütunu sabitle',
    pinLeft: 'Sola yasla <<',
    pinRight: 'Sağa yasla >>',
    noPin: 'Yaslama <>',
    valueAggregation: 'Değer',
    autosizeThiscolumn: 'Sığdır',
    autosizeAllColumns: 'Sütunları otomatik boyutlandır',
    groupBy: 'Grupla',
    ungroupBy: 'Gruplama',
    addToValues: 'Değerlere ekle ${variable}',
    removeFromValues: 'Değerlerden çıkart ${variable}',
    addToLabels: 'Etiketlere ekle ${variable}',
    removeFromLabels: 'Etiketlerden çıkart ${variable}',
    resetColumns: 'Sütunları sıfırla',
    expandAll: 'Hepsini aç',
    collapseAll: 'Hepsini kapat',
    copy: 'Kopyala',
    ctrlC: 'Ctrl C',
    copyWithHeaders: 'Başlığı kopyala',
    copyWithGroupHeaders: 'Başlık ile kopyala',
    paste: 'Yapıştır',
    ctrlV: 'Ctrl V',
	toolPanel: 'Araç paneli',
    export: 'Dosyaya Aktar',
    csvExport: 'CSV olarak aktar',
    excelExport: 'Excel olarak aktar',

    // enterprise menu aggregation and status panel
    sum: 'Sum',
    min: 'En az',
    max: 'En çok',
    first: 'İlk',
    last: 'Son',
    none: 'Yok',
    count: 'Say',
    average: 'Ortalama',
    filteredRows: 'Filtrelenmiş',
    selectedRows: 'Seçili',
    totalRows: 'Toplam Satır',
    totalAndFilteredRows: 'Satırlar',
    more: 'Devamı',
    to: 'To',
    of: '/',
    page: 'Sayfa',
    next: 'İleri',
    previous: 'Sonraki',

    // Pivoting
    pivotColumnGroupTotals: 'Toplam',

    // Enterprise Menu (Charts)
    pivotChartAndPivotMode: 'Pivot Grafik ve Pivot Modu',
    pivotChart: 'Pivot Grafik',
    chartRange: 'Grafik Aralığı',

    columnChart: 'Sütun',
    groupedColumn: 'Gruplanmış',
    stackedColumn: 'Bindirilmiş',
    normalizedColumn: '100% Bindirilmiş',

    barChart: 'Çubuk',
    groupedBar: 'Gruplanmış',
    stackedBar: 'Bindirilmiş',
    normalizedBar: '100% Bindirilmiş',

    pieChart: 'Pasta',
    pie: 'Pasta',
    doughnut: 'Donut',

    line: 'Çizgi',

    xyChart: 'X Y (Dağıtık)',
    scatter: 'Dağıtık',
    bubble: 'Balon',

    areaChart: 'Alan',
    area: 'Alan',
    stackedArea: 'Bindirilmiş',
    normalizedArea: '100% Bindirilmiş',

    histogramChart: 'Histogram',

    combinationChart: 'Kombinasyon',
    columnLineCombo: 'Sütun & Çizgi',
    AreaColumnCombo: 'Alan & Sütun',

    // Charts
    pivotChartTitle: 'Pivot Grafik',
    rangeChartTitle: 'Aralık Grafiği',
    settings: 'Ayarlar',
    data: 'Veri',
    format: 'Biçim',
    categories: 'Kategoriler',
    defaultCategory: '(Hiçbiri)',
    series: 'Seriler',
    xyValues: 'X Y Değeleri',
    paired: 'Esşlenmiş Mod',
    axis: 'Eksen',
    navigator: 'Gezgin',
    color: 'Renk',
    thickness: 'Kalınlık',
    xType: 'X Tipi',
    automatic: 'Otomatik',
    category: 'Kategori',
    number: 'Sayı',
    time: 'Zaman',
    xRotation: 'X Dönme Miktarı',
    yRotation: 'Y Dönme Miktarı',
    ticks: 'Çizgiler',
    width: 'Genişlik',
    height: 'Yükseklik',
    length: 'Uzunluk',
    padding: 'İç Boşluk',
    spacing: 'Boşluk',
    chart: 'Grafik',
    title: 'Başlık',
    titlePlaceholder: 'Grafik Başlığı - Düzenlemek için çift tıklayın',
    background: 'Artalan',
    font: 'Font',
    top: 'Üst',
    right: 'Sağ',
    bottom: 'Alt',
    left: 'Sol',
    labels: 'Etiketler',
    size: 'Ebat',
    minSize: 'Asgari Ebat',
    maxSize: 'Azami Ebat',
    legend: 'Lejant',
    position: 'Pozisyon',
    markerSize: 'İşaretleyici Boyu',
    markerStroke: 'İşaretleyici Tipi',
    markerPadding: 'İşaretleyici Boşluğu',
    itemSpacing: 'Eleman Aralığı',
    itemPaddingX: 'Eleman X Boşluğu',
    itemPaddingY: 'Eleman Y Boşluğu',
    layoutHorizontalSpacing: 'Yatay Boşluk',
    layoutVerticalSpacing: 'Dikey Boşluk',
    strokeWidth: 'Çerçeve Kalınlığı',
    offset: 'Ofset',
    offsets: 'Ofsetler',
    tooltips: 'İpuçları',
    callout: 'Duyuru',
    markers: 'İşaterçiler',
    shadow: 'Gölge',
    blur: 'Bulanıklık',
    xOffset: 'X Ofset',
    yOffset: 'Y Ofset',
    lineWidth: 'Çizgi Kalınlığı',
    normal: 'Normal',
    bold: 'Kalın',
    italic: 'Yatık',
    boldItalic: 'Kalın Yatık',
    predefined: 'Öntnaımlı',
    fillOpacity: 'Doldurma Geçirgenliği',
    strokeOpacity: 'Çizgi Geçirgenliği',
    histogramBinCount: 'İçerik sayısı',
    columnGroup: 'Sütun',
    barGroup: 'Çubuk',
    pieGroup: 'Pasta',
    lineGroup: 'Çizgi',
    scatterGroup: 'X Y (Dağıtık)',
    areaGroup: 'Alan',
    histogramGroup: 'Histogram',
    combinationGroup: 'Kombinasyon',
    groupedColumnTooltip: 'Gruplanmış',
    stackedColumnTooltip: 'Bindirilmiş',
    normalizedColumnTooltip: '100% Bindirilmiş',
    groupedBarTooltip: 'Gruplanmış',
    stackedBarTooltip: 'Bindirilmiş',
    normalizedBarTooltip: '100% Bindirilmiş',
    pieTooltip: 'Pasta',
    doughnutTooltip: 'Donut',
    lineTooltip: 'Çizgi',
    groupedAreaTooltip: 'Alan',
    stackedAreaTooltip: 'Bindirilmiş',
    normalizedAreaTooltip: '100% Bindirilmiş',
    scatterTooltip: 'Dağıtık',
    bubbleTooltip: 'Balon',
    histogramTooltip: 'Histogram',
    columnLineComboTooltip: 'Sütun & Çizgi',
    areaColumnComboTooltip: 'Alan & Sütun',
    customComboTooltip: 'Özel Kombinasyon',
    noDataToChart: 'Grafik oluşturmak için gerekli veri yok.',
    pivotChartRequiresPivotMode: 'Pivot Grafik, Pivot Modunun aktif olmasını gerektirir.',
    chartSettingsToolbarTooltip: 'Menü',
    chartLinkToolbarTooltip: 'Grid\'e bağla',
    chartUnlinkToolbarTooltip: 'Grid Bağlantısını kopar',
    chartDownloadToolbarTooltip: 'Grafiği İndir',
    seriesChartType: 'Seri Tablosu Tipi',
    seriesType: 'Seri Tipi',
    secondaryAxis: 'İkincil Eksen',

    // ARIA
    ariaHidden: 'gizli',
    ariaVisible: 'görünür',
    ariaChecked: 'etkin',
    ariaUnchecked: 'pasif',
    ariaIndeterminate:'belirsiz',
    ariaDefaultListName: 'Liste',
    ariaColumnSelectAll: 'Tüm Kolonları Seç/Kaldır',
    ariaInputEditor: 'Giriş Düzenleyici',
    ariaDateFilterInput: 'Tarih Filtre Girişi',
    ariaFilterList: 'Filtre Listesi',
    ariaFilterInput: 'Filtre Girişi',
    ariaFilterColumnsInput: 'Sütun Sizgeçi Girişi',
    ariaFilterValue: 'Filtre Değeri',
    ariaFilterFromValue: 'Filtre başlangıç değeri',
    ariaFilterToValue: 'Filtre bitiş değeri',
    ariaFilteringOperator: 'Filtreleme Operatörü',
    ariaColumn: 'Sütun',
    ariaColumnList: 'Sütun Listesi',
    ariaColumnGroup: 'Sütun Grubu',
    ariaRowSelect: 'Bu satırı seçmek için BOŞLUK tuşuna basın',
    ariaRowDeselect: 'Seçimi kaldırmak için BOŞLUK tuşuna basın',
    ariaRowToggleSelection: 'Satır seçimini aktifleştirmek için boşluk tuşuna basın',
    ariaRowSelectAll: 'Tüm satırları seçmek/seçimi kaldırmak için boşluk tuşuna basın',
    ariaToggleVisibility: 'Görünürlülüğü değiştirmek için BOŞLUK tuşuna basın',
    ariaSearch: 'Ara',
    ariaSearchFilterValues: 'Filtre değerlerini ara',

    ariaRowGroupDropZonePanelLabel: 'Satır Grupları',
    ariaValuesDropZonePanelLabel: 'Değerler',
    ariaPivotDropZonePanelLabel: 'Sütun Etiketleri',
    ariaDropZoneColumnComponentDescription: 'Silmek için DELETE tuşuna basınız',
    ariaDropZoneColumnValueItemDescription: 'Toplama türünü değiştirmek için ENTER tuşuna basın',

    // ARIA LABEL FOR DIALOGS
    ariaLabelColumnMenu: 'Sütun Menüsü',
    ariaLabelCellEditor: 'Hücre Düzenleyici',
    ariaLabelDialog: 'Dialog',
    ariaLabelSelectField: 'Alan Seçimi',
    ariaLabelTooltip: 'İpucu',
    ariaLabelContextMenu: 'İçerik Menüsü',
    ariaLabelSubMenu: 'Alt Menü',
    ariaLabelAggregationFunction: 'Birleştirme Fonksiyonu',

    // Number Format (Status Bar, Pagination Panel)
    thousandSeparator: '.',
    decimalSeparator: ','

}