import actionType from "./actionType";

const defaultValues = {
    appusername: "",
    appuserid: 0,
    userfullname: "",
    useravatar: "",
    IsUserLoggedIn: false,
    permissions: {
        companies: [],
        powerplants: [],
        groups: [],
        sources: [],
        portfolios: [],
        globalUnitId: undefined,
    },
    roles: [],
    eligibility: {
        applications: [],
        functions: []
    },
    userProfileData: [],
    userSettings: [],
    userRegion: "",
    userRegionTimezone: "",
    userCompanies: [],
    userPlants: [],
    userGroups: [],

};

const common = (state = defaultValues, action: any) => {
    switch (action.type) {
        case actionType.SET_IS_USER_LOGGED_IN:
            return { ...state, IsUserLoggedIn: action.IsUserLoggedIn };
        case actionType.SET_USER_PERMISSONS:
            return { ...state, permissions: action.permissions, roles: action.permissions.roles, eligibility: action.permissions.eligibility};
        case actionType.SET_APP_USER_INFO:
            return { ...state, appuserid: action.appuserid, appusername: action.appusername, userfullname: action.userfullname, useravatar: action.useravatar };
        case actionType.SET_IS_DRAWER_OPEN:
            return { ...state, IsDrawerOpen: action.IsDrawerOpen };
        case actionType.SET_CONFIRM_LEAVING_PAGE:
            return { ...state, ShouldConfirmLeavingPage: action.ShouldConfirmLeavingPage, LeavingPageConfirmMessage: action.ConfirmMessage };
        case actionType.SET_USER_PROFILEDATA:
            return { ...state, userProfileData: action.userProfileData };
        case actionType.SET_USER_SETTINGS: 
            return { ...state, userSettings: action.userSettings }
        case actionType.GET_USER_SETTINGS: 
            return { ...state, userSettings: action.userSettings }    
        case actionType.USER_REGION: 
            return { ...state, userRegion: action.userRegion }
        case actionType.USER_REGION_TIMEZONE: 
            return { ...state, userRegionTimezone: action.userRegionTimezone }
        case actionType.USER_COMPANIES:
            return { ...state, userCompanies: action.userCompanies }
        case actionType.USER_PLANTS:
            return { ...state, userPlants: action.userPlants }
        case actionType.USER_GROUPS:
            return { ...state, userGroups: action.userGroups }
        default:
            return state;
    }
};

export default common;