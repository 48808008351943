export const GipPlanningActionKeys = {
    GIP_PLANNING_TICKER_ADD:'GIP_PLANNING_TICKER_ADD',
    GIP_PLANNING_TICKER_ADD_ARRAY:'GIP_PLANNING_TICKER_ADD_ARRAY',
    GIP_PLANNING_TICKER_CLEAR:'GIP_PLANNING_TICKER_CLEAR',
    GIP_PLANNING_DEPTH_INFO_UPDATE:'GIP_PLANNING_DEPTH_INFO_UPDATE',
    GIP_PLANNING_DEPTH_INFO_CLEAR:'GIP_PLANNING_DEPTH_INFO_CLEAR',
    GIP_PLANNING_MY_PROPOSALS_UPDATE:'GIP_PLANNING_MY_PROPOSALS_UPDATE',
    GIP_PLANNING_MY_PROPOSALS_CLEAR:'GIP_PLANNING_MY_PROPOSALS_CLEAR',
    GIP_PLANNING_MY_PROPOSALS_FROM_KEY_CLEAR:'GIP_PLANNING_MY_PROPOSALS_FROM_KEY_CLEAR',
    GIP_PLANNING_DEPTH_INFO_FROM_KEY_CLEAR:'GIP_PLANNING_DEPTH_INFO_FROM_KEY_CLEAR',
    GIP_PLANNING_TRADES_UPDATE:'GIP_PLANNING_TRADES_UPDATE',
    GIP_PLANNING_TRADES_CLEAR:'GIP_PLANNING_TRADES_CLEAR',
    GIP_PLANNING_TRADES_FROM_KEY_CLEAR:'GIP_PLANNING_TRADES_FROM_KEY_CLEAR',
}