import React from 'react';
import store from '../redux/stores';
import { API } from './backend';
import equals from "fast-deep-equal";
import _ from "lodash";
import reduxStore from "../redux/stores";
import * as CommonActions from '../redux/common/action';

import moment from "moment-timezone";
import { IPermissions } from "./BaseTypes";

import { changeFeedbackLanguage as changeFeedbackLanguageExternal } from './Feedback';

import { ApplicationContext, Severity } from "../components/ApplicationContext/index";

import { Buffer } from "buffer";
import messages from "../pages/planttracking/messages";
import MessageBox from "../components/smartPulse/MessageBox";

import { IntlShape, MessageDescriptor } from 'react-intl';

export interface IBasePageProps {
    intl: IntlShape,
    classes?: any,
}

export interface InjectedProps {
    intl: IntlShape,
    location?: any,
    classes?: any
}

export enum NotificationTypes {
    Success = 1,
    Info = 2,
    Warning = 3,
    Error = 4
}

export type NotificationMethod = (message: string, type: NotificationTypes) => void;

export type IBasePageState = {
    ShouldConfirmLeavingPage?: boolean,
    LeavingPageConfirmMessage?: string
} & IBasePageInjectedState

export interface IBasePageInjectedState {
    eligibility: any,
    permissions: IPermissions,

    /**
     * User id
     */
    Id: number,
    Username: string,
    NameSurname: string,
    roles: string[],
}

export class BasePage<Props extends IBasePageProps, State extends IBasePageState> extends React.Component<Props, State> {
    static contextType = ApplicationContext;

    private storeSubscription: any;

    constructor(props: Props, context: any) {
        super(props, context);
        // @ts-ignore
        this.state = {};
        this.context = context;
    }

    ShowLoader() {
        $("#loader").addClass("whirl");
    }

    HideLoader() {
        $("#loader").removeClass("whirl");
    }

    //type: 1:screen, 2:function
    isEligible(operationId: any, type = 1) {
        let eligibilityInfo = this.state.eligibility;

        if (eligibilityInfo === undefined || eligibilityInfo === null) {
            return false;
        } else {
            const operations =
                type === 1 ? eligibilityInfo.applications : eligibilityInfo.functions;

            if (operations !== null && operations.length > 0) {
                return operations.some(e => e === operationId);
            }
            return false;
        }
    }

    getReduxStore(name: string | string[] | null = null) {
        const store = reduxStore.getState();

        if (name === null) {
            return store;
        } else if (Array.isArray(name)) {
            return name.map(e => store[e]);
        } else {
            return store[name];
        }
    }

    ContractIdToInstrumentDate(contractid: any) {
        const year = "20" + contractid.substr(2, 2);
        const month = contractid.substr(4, 2);
        const day = contractid.substr(6, 2);
        const hours = contractid.substr(8, 2);
        var date = `${year}-${month}-${day}T${hours}:00:00`;
        if (hours === "24") {
            date = `${year}-${month}-${day}T00:00:00`;
            var result = moment(date, "YYYY-MM-DDTHH:mm:ss").add(1, 'days').format("YYYY-MM-DDTHH:mm:ss");
            return result;
        } else {
            return date;
        }
    }


    getUserProfileData(key: any) {
        let commonStore = this.getReduxStore("common");
        if (commonStore === undefined || commonStore === null) {
            return;
        } else {
            var data = commonStore.userProfileData.find((a: any) => a.Name === key);

            if (data !== undefined && data !== null) {
                return data.Value;
            }
            return;
        }
    }

    getUserSettingsPromise(settingKey: string): Promise<any> {
        return new Promise((resolve, reject) => {
            this.post({ name: settingKey }, "Configuration/GetUserSettings", true, true)
                .done((reply) => {
                    if (reply.isError) {
                        reject(reply);
                    } else {
                        resolve(reply);
                    }
                })
                .fail((reply) => {
                    reject(reply);
                });
        });
    }

    getUserSettings(settingKey: string) {
        this.post({ name: settingKey }, "Configuration/GetUserSettings", true, true).done((reply) => {
            if (reply.isError) {

            } else {
                return reply;
            }
        }).fail((reply) => {
        });
    }

    setUserSettings() {
        this.post({}, "Configuration/SetUserSettings", true, true).done((reply) => {
            if (reply.isError) {

            } else {
                return reply;
            }
        }).fail((reply) => {
        });
    }

    getTheme() {
        let themeMode = 'light;'
        const settingsjson = localStorage.getItem('settings')
        if (settingsjson) {
            const settings = JSON.parse(settingsjson);
            themeMode = settings.themeMode;
        }

        return themeMode;
    }

    setUserProfileData(storageData: any[], key: any, value: any) {
        var temp = storageData;

        if (temp.length > 0) {
            var storage = temp.find(a => a.Name === key);

            if (storage != undefined && storage != null) {
                storage.Value = value;
            } else {
                var newData = {
                    Name: key,
                    Value: value
                }

                temp.push(newData);
            }
        } else {
            var newData = {
                Name: key,
                Value: value
            }
            temp.push(newData);
        }

        this.post(temp, "Configuration/SetUserProfileData", true, true).done((reply) => {
            if (reply.isError) {
            } else {
                CommonActions.setUserProfileData(temp);
            }
        }).fail((reply) => {
        });
    }


    /**
     * @deprecated Please use either PopUp* methods, or alternatives
     * @param type 1: success 2-info 3-warning 4-fail
     * @param message message to display
     */
    PopUp(message: string, type = 1) {
        const { showUserMessage } = this.context;

        switch (type) {
            case 1:
                showUserMessage(message, Severity.success);
                break;
            case 2:
                showUserMessage(message, Severity.info);
                break;
            case 3:
                showUserMessage(message, Severity.warning);
                break;
            default:
                showUserMessage(message, Severity.error);
                break;
        }
    }

    PopUpSuccess(message: string) {
        this.PopUp(message, 1);
    }

    PopUpInfo(message: string) {
        this.PopUp(message, 2);
    }

    PopUpWarning(message: string) {
        this.PopUp(message, 3);
    }

    PopUpError(message: string) {
        this.PopUp(message, 4);
    }

    ShowMessage(message: string, type: NotificationTypes) {
        if (type == NotificationTypes.Error) this.PopUpError(message);
        else if (type == NotificationTypes.Info) this.PopUpInfo(message);
        else if (type == NotificationTypes.Success) this.PopUpSuccess(message);
        else if (type == NotificationTypes.Warning) this.PopUpWarning(message);
        else
            this.PopUpError(message);
    }

    TryParseFloat(value: any) {
        const { formatNumber } = this.props.intl;
        const formattedText = formatNumber(1.2);
        const decimalSeparator = formattedText.substr(1, 1);
        const newValue = parseFloat(String(value).replace(decimalSeparator, "."));

        return newValue;
    }

    post(JSONdata: any, Action: any, first = false, last = false) {
        return API.PostAPI(JSONdata, Action, first, last);
    }

    convertTrTimeZone(date: any, format = "DD.MM.YYYY HH:mm:ss", returnFormat = "DD.MM.YYYY HH:mm:ss") {
        const timeZone = localStorage.getItem('UserTimeZoneId') || 'Europe/Istanbul';
        const utcTime = moment.tz(date, format, timeZone).utc();
        const localTime = utcTime.tz('Europe/Istanbul').format(returnFormat);
        return localTime;

    }


    getTimeByTimeZone(date: any, format = "DD.MM.YYYY HH:mm:ss", convertFormat = ("DD.MM.YYYY HH:mm:ss ([UTC] Z)"), isUtc = false) {
        const timeZone = localStorage.getItem('UserTimeZoneId') || 'Europe/Istanbul';

        const convertedTime = moment(date, format).utc(isUtc).tz(timeZone);

        return convertedTime.format(convertFormat);

    }

    getTimeByRegionTimezone(date: any, format = "DD.MM.YYYY HH:mm:ss", convertFormat = ("DD.MM.YYYY HH:mm:ss ([UTC] Z)"), isUtc = false) {

        const timeZone = CommonActions.getUserRegionTimezone();
        const convertedTime = moment(date, format).utc(isUtc).tz(timeZone);
        return convertedTime.format(convertFormat);

    }

    getLanguages() {
        return [
            { Name: "Türkçe", Key: "tr" },
            { Name: "English", Key: "en" },
            { Name: "Español", Key: "es" },
        ];
    }

    getCurrentLocale() {
        let localeParam = localStorage.getItem("locale");

        if (localeParam === null) {
            const search = new URLSearchParams(this.getPageSearch());

            localeParam = search.get("locale");
        }
        if (localeParam === null) {
            localeParam = "tr";
        }
        if (this.getLanguages().some(e => e.Key === localeParam)) {
            return localeParam;
        }

        return "tr";
    }

    getPagePath() {
        const pageUrl = new URL(window.location.toString().replace("/#/", "/"));

        return `${pageUrl.pathname}`;
    }

    getPageSearch() {
        const pageUrl = new URL(window.location.toString().replace("/#/", "/"));

        return `${pageUrl.search}`;
    }

    switchLocale(locale: string) {
        if (locale != this.getCurrentLocale()) {
            const searchParams = new URLSearchParams(this.getPageSearch());

            searchParams.set("locale", locale);
            window.location.hash = this.getPagePath() + "?" + searchParams.toString();
        }
        setTimeout(() => {
            this.changeFeedbackLanguage();
        }, 300);

    }

    changeFeedbackLanguage() {
        const { formatMessage } = this.props.intl;
        changeFeedbackLanguageExternal(formatMessage);
    }

    navigate(url: string) {
        if (
            !this.state.ShouldConfirmLeavingPage ||
            confirm(this.state.LeavingPageConfirmMessage)
        ) {
            CommonActions.SetConfirmLeavingPage(false, "");

            if (url.indexOf("?") > 0) {
                window.location.hash = url + "&locale=" + this.getCurrentLocale();
            } else {
                window.location.hash = url + "?locale=" + this.getCurrentLocale();
            }
        }
    }

    connect(stores: string[] = []) {
        this.storeSubscription = store.subscribe(
            //@ts-ignore
            this.subscribeToStore.bind(this, stores)
        );
        this.setInitialState(stores);
    }

    getSavedState() {
        return store.getState();
    }

    subscribeToStore(stores = []) {
        var state = store.getState();
        var storeobjects = {};
        for (let i = 0; i < stores.length; i++)
            storeobjects = _.assign({}, storeobjects, { ...state[stores[i]] });

        if (!equals(this.state, storeobjects)) this.setState({ ...storeobjects });
    }

    setInitialState(stores: string[]) {
        let state = store.getState();
        let storeobjects = {};
        for (let i = 0; i < stores.length; i++)
            storeobjects = _.assign({}, storeobjects, { ...state[stores[i]] });
        //@ts-ignore
        this.state = { ...storeobjects };
    }

    componentWillUnmount() {
        if (this.storeSubscription) this.storeSubscription();
    }

    initDateTimePicker(onDateChange: any, selectedDate: any, format: any, startdate: any, enddate: any) {
        if (!format) {
            format = "DD MMMM YYYY dddd";
        }
        window
            // @ts-ignore
            .$("#datetimepicker1")
            .datetimepicker({
                icons: {
                    time: "fa fa-clock-o",
                    date: "fa fa-calendar",
                    up: "fa fa-chevron-up",
                    down: "fa fa-chevron-down",
                    previous: "fa fa-chevron-left",
                    next: "fa fa-chevron-right",
                    today: "fa fa-crosshairs",
                    clear: "fa fa-trash",
                },
                defaultDate: selectedDate,
                format: format,
                locale: "tr",
                minDate: enddate,
                /* See http://eonasdan.github.io/bootstrap-datetimepicker/Options/ for other customization options */
            })
            .on("dp.change", onDateChange);

        window
            // @ts-ignore
            .$("#datetimepicker2")
            .datetimepicker({
                icons: {
                    time: "fa fa-clock-o",
                    date: "fa fa-calendar",
                    up: "fa fa-chevron-up",
                    down: "fa fa-chevron-down",
                    previous: "fa fa-chevron-left",
                    next: "fa fa-chevron-right",
                    today: "fa fa-crosshairs",
                    clear: "fa fa-trash",
                },
                defaultDate: selectedDate,
                format: format,
                locale: "tr",
                minDate: startdate,
                /* See http://eonasdan.github.io/bootstrap-datetimepicker/Options/ for other customization options */
            })
            .on("dp.change", onDateChange);
    }

    formatDate(dateObj: any) {
        return moment(dateObj).format("DD/MM/YYYY");
    }

    formatDateToMidnight(dateObj: any) {
        var originalDate = new Date(dateObj);

        originalDate.setHours(0);
        originalDate.setMinutes(0);
        originalDate.setSeconds(0);
        originalDate.setMilliseconds(0);

        return originalDate.toString();
    }

    getDateText = (dateValue: any, format: any, isUtc = false, includeSeconds = true) => {
        if (dateValue) {
            let convertionFormat = format || "DD.MM.YYYY HH:mm:ss";
            var currentDate = new Date().getDate();

            if (includeSeconds) {
                convertionFormat = "DD.MM.YYYY HH:mm:ss ([UTC] Z)";
            }

            if (Number(moment(dateValue, format).format("DD")) === Number(currentDate)) {
                convertionFormat = "HH:mm:ss ([UTC] Z)";
                if (isUtc) {
                    const utcmoment = moment(dateValue, format).utc(true);
                    return this.getTimeByTimeZone(utcmoment, format, convertionFormat);
                }
                return this.getTimeByTimeZone(dateValue, format, convertionFormat);
            }

            if (isUtc) {
                convertionFormat = "DD.MM.YYYY HH:mm:ss ([UTC] Z)";
                const utcmoment = moment(dateValue, format).utc(true);
                return this.getTimeByTimeZone(utcmoment, format, convertionFormat);
            }

            return this.getTimeByTimeZone(dateValue, format, convertionFormat);

        } else {
            return "";
        }
    }

    getDateTextLocal(dateValue: any, format: string, isUtc = false) {
        if (dateValue) {
            var currentDate = new Date().getDate();

            let momentDate: moment.Moment | null = null;

            if (isUtc) {
                momentDate = moment.utc(dateValue, format);
            } else {
                momentDate = moment(dateValue, format);
            }

            let convertionFormat = "DD.MM.YY HH:mm:ss";

            if (momentDate.toDate().getDate() === currentDate) {
                convertionFormat = "HH:mm:ss";
            }

            return momentDate.local().format(convertionFormat);
        } else {
            return "";
        }
    }

    filterValue(valueIn: string, allowPercentChar = false, allownegative = false) {
        let strValue = "";
        if (allowPercentChar) {
            if (allownegative) {
                strValue = valueIn.replace(/[^0-9-.%]/g, "").toString();
            } else {
                strValue = valueIn.replace(/[^0-9.%]/g, "").toString();
            }
        } else {
            if (allownegative) {
                strValue = valueIn.replace(/[^0-9.-]/g, "").toString();
            } else {
                strValue = valueIn.replace(/[^0-9.]/g, "").toString();
            }
        }

        if (strValue.startsWith(".")) strValue = strValue.substring(1);
        if (
            strValue.startsWith("0") &&
            strValue.length > 1 &&
            strValue.substring(1, 2) !== "."
        )
            strValue = strValue.substring(1);

        const dotGroups = strValue.split(".");
        if (dotGroups.length >= 2) {
            if (dotGroups.length > 2) {
                strValue = strValue.replace(/\.+$/, "");
            } else {
                if (dotGroups[1].length > 2) {
                    strValue = dotGroups[0] + "." + dotGroups[1].substring(0, 2);
                }
            }
        }
        if (strValue.endsWith(".")) {
            strValue = strValue.substring(0, strValue.length - 1);
        }

        if (allowPercentChar) {
            return strValue;
        } else {
            return +strValue;
        }
    }

    setCookie(key: string, value: string) {
        const expires = new Date();

        expires.setTime(expires.getTime() + 3000 * 24 * 60 * 60 * 1000);
        document.cookie =
            key + "=" + value + "; expires=" + expires.toUTCString() + "; path=/;";
    }

    setTempCookie(key: string, value: string) {
        document.cookie = key + "=" + value + "; path=/;";
    }

    getCookie(key: string) {
        const keyValue = document.cookie.match("(^|;) ?" + key + "=([^;]*)(;|$)");

        return keyValue ? keyValue[2] : null;
    }

    base64Encode(data: string) {
        return Buffer.from(encodeURIComponent(data)).toString("base64");
    }



    // noinspection DuplicatedCode
    protected askYesNo = (
        message: string | JSX.Element | { id: string, defaultMessage: string },
        dialogTitle?: string | { id: string, defaultMessage: string }): Promise<boolean> => {

        function isMessageDescriptor(messageLike: any): messageLike is MessageDescriptor {
            return (messageLike as MessageDescriptor).id !== undefined;
        }

        // @ts-ignore
        const { formatMessage } = this.props.intl;

        let title = formatMessage(messages.global.question);

        if (dialogTitle) {
            title = (typeof dialogTitle === "string")
                ? dialogTitle
                : formatMessage(dialogTitle);
        }

        const body = isMessageDescriptor(message)
            ? formatMessage(message)
            : message; // string | Element

        const buttons = [
            formatMessage(messages.global.yes),
            formatMessage(messages.global.no)
        ];

        const promise = new Promise<boolean>((accept, reject) => {
            MessageBox.Show({
                title: title,
                body: body,
                buttons: buttons,
                icon: "question",
                onClick: index => {
                    if (index == 0) accept(true);
                    if (index == 1) accept(false);

                    reject("Unknown index: " + index);
                }
            });
        });

        return promise;
    }

    /*addSaltToString(data: string, saltKey: string, saltPosition: number) {
        var saltBuffer = Buffer.from(saltKey);
        const steps = Math.ceil(data.length / saltPosition);

        var dataBuffer = Buffer.from(data);
        var targetBuffer = Buffer.alloc(data.length + (steps * saltKey.length));
        

        for (let step = 0, pos = 0; step < steps; step++) {
            dataBuffer.copy(targetBuffer, pos, step * saltPosition, saltPosition);
            //targetBuffer.write()
            targetBuffer.write(saltKey)
        }


        for (let i = saltPosition; i < data.length; i += saltPosition) {
            saltBuffer.copy(dataBuffer, i);
            //data.slice(0, i) + saltKey + data.slice(i);
        }

        data = dataBuffer.toString();
        data = "=" + data;
        data += "=";

        return data;
    }*/
}

export default BasePage;
