import { createTheme, SimplePaletteColorOptions } from "@mui/material";
import { Red } from "../colors/index";

declare module "@mui/material/styles" {
    interface Palette {
        smartGray: SimplePaletteColorOptions;
        smartHighlight: SimplePaletteColorOptions;
    }
    interface PaletteOptions {
        smartGray?: SimplePaletteColorOptions;
        smartHighlight?: SimplePaletteColorOptions;
    }
}

declare module "@mui/material/Button" {
    interface ButtonPropsColorOverrides {
        smartGray: true;
        smartHighlight: true;
    }
}

export const theme = createTheme({
    palette: {
        primary: {
            main: "#5d9910",
            light: "#fff",
        },
        secondary: Red,
        smartGray: {
            dark: "#585b62",
            light: "#8b8e95",
            main: "#6E727A",
            contrastText: "#ebebed",
        },
        smartHighlight: {
            dark: "#c2c3c4",
            light: "#f5f6f7",
            main: "#F3F4F5",
            contrastText: "#6E727A",
        },
    },
    typography: {
        htmlFontSize: 12.5,
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                form: {
                    width: "100%", // Fix IE 11 issue.
                    margin: 0,
                },
            },
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    padding: "8px",
                },
                elevation: {
                    padding: "0px",
                },
            },
        },
        MuiTextField: {
            defaultProps: {
                variant: "standard",
            },
        },
        MuiFormControl: {
            defaultProps: {
                variant: "standard",
            },
            styleOverrides: {
                root: {
                    margin: "8px",
                    paddingRight: "16px",
                },
            },
        },
        MuiSelect: {
            defaultProps: {
                variant: "standard",
            },
        },
        MuiList: {
            defaultProps: {
                dense: true,
            },
        },
        MuiAutocomplete: {
            styleOverrides: {
                option: {
                    paddingLeft: 0,
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    margin: "8px",
                },
                text: {
                    color: " #4E585A",
                },

                outlined: {
                    color: " #4E585A",
                },
            },
        },
        MuiFab: {
            styleOverrides: {
                root: {
                    margin: "8px",
                },
            },
        },

        MuiTableCell: {
            styleOverrides: {
                root: {
                    paddingTop: "10px",
                    paddingBottom: "10px",
                },
            },
        },
    },
});

export default theme;
