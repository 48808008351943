const defaultValues = { 
    configs: [
        {
            CompanyName:"",
            CompanyId:0,
            PowerPlantLimits:[
                {
                    PowerPlantName:"",
                    PowerPlantId:0,
                    Limit:0
                }        
            ]
        }
    ]
};

const powerplantlimits = (state = defaultValues, action) => {
    switch(action.type) {
        case 'GET_LIMITS':
            return {...state, configs:action.CompanyPowerPlantConfigurationsModel};
        default:
            return state;
    }
}

export default powerplantlimits;