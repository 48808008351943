const defaultValues = {
    KgupDate: new Date(1980,1,1), 
    selectedId:0,
    KgupData:[],
    KgupRows:[]
};

const kgupsending  = (state = defaultValues , action) => {
    switch (action.type) { 
        case 'SET_SELECTED_ID':
            return {...state, selectedId: action.selectedId};
        case 'SET_KGUP_DATE':
            return {...state, KgupDate: action.date};
        case 'HANDLE_GET_KGUP_RESPONSE':
            return {...state, KgupData: action.KgupData,KgupRows:action.rows, ShowSantralTuketimList: action.ShowSantralTuketimList, Reply: action.reply};
        case 'HANDLE_SAVE_KGUP_RESPONSE':
            return {...state, saveOk: action.reply.saveOk};
        case 'HANDLE_SAVE_SEND_KGUP_RESPONSE':
            return {...state, saveOk: action.reply.saveOk};
        case 'HANDLE_COPYDATA_TO_KGUP' :
            let nKgupRows = [...state.KgupRows];
            let santralIndex = action.santralIndex;

            for (let k = 0; k < nKgupRows.length; k++){
                let eakColName = 'eak' + santralIndex;
                let kgupColName = 'kgup' + santralIndex;

                nKgupRows[k][kgupColName] = nKgupRows[k][eakColName];
            }

            var RandomNumber = Math.random();

            return {...state, KgupRows: nKgupRows, RandomNumber: RandomNumber};
        default:
            return state;
    }
}

export default kgupsending;