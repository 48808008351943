import { initializeApp } from 'firebase/app';
import { getMessaging, getToken } from 'firebase/messaging';
import $ from 'jquery';

export const Site = {
    BaseUrl: "",
    ServiceWorker: {
        PublicKey: "BLdlqtm3ZbXcXD-4I7-fkAJpWP_-c2n54RYV0Qp_tUrETzOPjTLIw8Ebc6q08ozlCvIE0XzryBA2zOSaOW6KCvY",
        Registration: null,
        Subscription: null,
        FirebaseMessaging: null,
        Init() {
            if ('serviceWorker' in navigator) {
                try {
                    navigator.serviceWorker.ready.then((registration) => {
                        Site.ServiceWorker.Registration = registration;

                        if ('Notification' in window) {
                            if (Site.ServiceWorker.FirebaseMessaging == null) {
                                const firebaseConfig = {
                                    apiKey: "AIzaSyCgAPa1y7V3ifmR1oxZRRalRmxFHj0OtU0",
                                    authDomain: "smartpulse-mobile.firebaseapp.com",
                                    databaseURL: "https://smartpulse-mobile.firebaseio.com",
                                    projectId: "smartpulse-mobile",
                                    storageBucket: "smartpulse-mobile.appspot.com",
                                    messagingSenderId: "121316942813",
                                    appId: "1:121316942813:web:3efc3ef86468615864cc34",
                                    measurementId: "G-QFNGBEZWH4"
                                };

                                const app = initializeApp(firebaseConfig);
                                Site.ServiceWorker.FirebaseMessaging = getMessaging(app);
                            }

                            Site.ServiceWorker.Registration.pushManager.getSubscription()
                                .then((subscription) => {
                                    if (subscription != null) {
                                        Site.ServiceWorker.Subscribe(subscription);
                                    } else if (Framework.Cookie.getCookie("notificationunregistered") !== "true") {
                                        Site.ServiceWorker.RegisterDevice();
                                    }
                                })
                                .catch((err) => {
                                    console.log(`Error during Init.getSubscription: ${  err}`);
                                });
                        }
                    });
                } catch (ex) {
                    console.log(ex);
                }
            }
        },
        RegisterDevice() {
            Site.ServiceWorker.Subscription = null;

            if (Notification.permission === "default") {
                Site.ServiceWorker.AskPermission();
            } else if (Notification.permission === "granted") {
                Site.ServiceWorker.Registration.pushManager.subscribe({
                    userVisibleOnly: true,
                    applicationServerKey: Site.ServiceWorker.PreparePublicKey()
                }).then((sub) => {
                    if (sub != null) {
                        Site.ServiceWorker.Subscribe(sub);
                    }
                }).catch((err) => {
                    console.log(`Error during RegisterDevice.subscribe: ${  err}`);
                });
            }
        },
        AskPermission() {
            if (!("Notification" in window)) {
                return;
            }

            if (Site.ServiceWorker.Registration == null) {
                alert("Please try again later!");
            }

            Framework.Layout.Block();

            Notification.requestPermission().then((permission) => {
                    Framework.Layout.Unblock();

                    if (permission === "granted") {
                        Site.ServiceWorker.Registration.pushManager.subscribe({
                            userVisibleOnly: true,
                            applicationServerKey: Site.ServiceWorker.PreparePublicKey()
                        }).then((sub) => {
                            Site.ServiceWorker.Subscribe(sub);
                        }).catch((err) => {
                            console.log(err);
                        });
                    } else if (permission === "denied") { /* empty */ } else if (permission === "default") { /* empty */ }
                })
                .catch((err) => {
                    console.log(err);
                    Framework.Layout.Unblock();
                });
        },
        RevokePermission() {
            if (Site.ServiceWorker.Subscription != null) {
                Site.ServiceWorker.Subscription.unsubscribe().then(() => {
                    Site.ServiceWorker.Unsubscribe();
                }).catch((err) => {
                    console.log(err);
                });
            }
        },
        Subscribe(sub) {
            Site.ServiceWorker.Subscription = sub;

            let deviceUniqueId = Framework.Cookie.getCookie("deviceuid");

            if (deviceUniqueId == null) {
                Framework.Cookie.setCookie("deviceuid", window.G);
                deviceUniqueId = window.G;
            }

            getToken(Site.ServiceWorker.FirebaseMessaging, {
                vapidKey: Site.ServiceWorker.PublicKey,
                serviceWorkerRegistration: Site.ServiceWorker.Registration
            }).then((firebaseToken) => {
                if (firebaseToken) {
                    const subStr = JSON.stringify(sub);
                    const subPrs = JSON.parse(subStr);
                    const jsonData = `{ "NewDeviceToken": "${  sub.endpoint  }", "DeviceUniqueId": "${  deviceUniqueId  }", "DeviceTypeId": 1, "DeviceKey": "${  subPrs.keys.p256dh  }", "DeviceAuth": "${  subPrs.keys.auth  }", "IntegrationToken": "${  firebaseToken  }", "IntegrationType": 1 }`;

                    Framework.Cookie.setCookie("notificationunregistered", "false");
                    const nodeEnv = import.meta.env.MODE;
                    const isProduction = nodeEnv === "production";
                    // eslint-disable-next-line no-undef
                    $.ajax({
                        url: `${isProduction?'':'/s'}/DeviceInfo/RegisterDeviceInfo`,
                        data: jsonData,
                        contentType: "application/json; charset=utf-8",
                        type: "POST",
                        xhrFields: {
                            withCredentials: true
                        },
                        success(resultData) {}
                    });
                } else {
                    console.log('FCM token is empty!');
                }
            }).catch((err) => {
                // retrieving token failed, analyze the error
                console.error('Retrieving token failed', err);
            });


        },
        Unsubscribe() {
            let jsonData = "";

            if (Site.ServiceWorker.Subscription != null) {
                const subStr = JSON.stringify(Site.ServiceWorker.Subscription);
                const subPrs = JSON.parse(subStr);

                let deviceUniqueId = Framework.Cookie.getCookie("deviceuid");

                if (deviceUniqueId == null) {
                    Framework.Cookie.setCookie("deviceuid", window.G);
                    deviceUniqueId = window.G;
                }

                jsonData = `{ "CurrentDeviceToken": "${  Site.ServiceWorker.Subscription.endpoint  }", "DeviceUniqueId": "${  deviceUniqueId  }", "DeviceKey": "${  subPrs.keys.p256dh  }", "DeviceTypeId": 1 }`;
                const nodeEnv = import.meta.env.MODE;
                const isProduction = nodeEnv === "production";
                // eslint-disable-next-line no-undef
                $.ajax({
                    url: `${isProduction?'':'/s'}DeviceInfo/UnregisterDeviceInfo`,
                    data: jsonData,
                    contentType: "application/json; charset=utf-8",
                    type: "POST",
                    xhrFields: {
                        withCredentials: true
                    },
                    success(resultData) {
                        if (resultData) {
                            Site.ServiceWorker.Subscription = null;

                            Framework.Cookie.setCookie("notificationunregistered", "true");
                        }
                    }
                });
            }
        },
        PreparePublicKey() {
            const padding = '='.repeat((4 - Site.ServiceWorker.PublicKey.length % 4) % 4);
            const base64 = (Site.ServiceWorker.PublicKey + padding)
                .replace(/-/g, '+')
                .replace(/_/g, '/');
            const rawData = window.atob(base64);

            const outputArray = new Uint8Array(rawData.length);

            // eslint-disable-next-line no-plusplus
            for (let i = 0; i < rawData.length; ++i) {
                outputArray[i] = rawData.charCodeAt(i);
            }
            return outputArray;
        }
    },
    Tooltip: {
        IsLoaded: false,
        CurrentTimeout: null,
        Init() {
            if (!Site.Tooltip.IsLoaded) {
                Site.Tooltip.IsLoaded = true;
                // eslint-disable-next-line no-undef
                $(document).off("mouseover", ".fw-tooltip-deprecated");
                // eslint-disable-next-line no-undef
                $(document).on("mouseover", ".fw-tooltip-deprecated", function(e) {
                    // eslint-disable-next-line no-undef
                    if (!$(this).hasClass("tooltip-open")) {
                        Site.Tooltip.ClearTooltip();

                        // eslint-disable-next-line no-undef
                        const currentElement = $(this);
                        const tooltipText = currentElement.attr("data-tooltip-deprecated");

                        if (tooltipText != null && tooltipText !== "") {
                            // eslint-disable-next-line no-undef
                            $(this).addClass("tooltip-open");

                            let tooltipDelay = 0;

                            // eslint-disable-next-line no-undef
                            const tooltipDelayData = $(this).attr("data-tooltip-delay");

                            // eslint-disable-next-line no-restricted-globals
                            if (tooltipDelayData != null && !isNaN(tooltipDelayData)) {
                                tooltipDelay = parseInt(tooltipDelayData, 10);
                            }

                            Site.Tooltip.CurrentTimeout = setTimeout(() => {
                                let tooltipContainerClass = "tooltip-container";

                                if (currentElement.hasClass("no-bg")) {
                                    tooltipContainerClass += " no-bg";
                                }

                                if (currentElement.hasClass("disable-auto-close")) {
                                    tooltipContainerClass += " disable-auto-close";
                                }

                                // eslint-disable-next-line no-undef
                                $("body").append(`<div class='${  tooltipContainerClass  }'>${  tooltipText  }</div>`);

                                let tooltipLocation = "bottom";

                                const attr = currentElement.attr('data-tooltip-location');

                                if (typeof attr !== typeof undefined && attr !== false) {
                                    tooltipLocation = attr;
                                }

                                let topPosition = 0;
                                let leftPosition = 0;

                                if (tooltipLocation === "bottom") {
                                    // eslint-disable-next-line no-undef
                                    if (currentElement.offset().top - $(window).scrollTop() > ($(window).height() * 6) / 10) {
                                        // eslint-disable-next-line no-undef
                                        topPosition = currentElement.offset().top - $(".tooltip-container").outerHeight() - 5;
                                    } else {
                                        topPosition = currentElement.offset().top + currentElement.outerHeight() + 15;
                                    }

                                    if (currentElement.hasClass("disable-auto-close")) {
                                        topPosition -= 20;
                                    }

                                    // eslint-disable-next-line no-undef
                                    leftPosition = currentElement.offset().left - ($(".tooltip-container").outerWidth() / 2) + (currentElement.innerWidth() / 2);
                                } else if (tooltipLocation === "right") {
                                    topPosition = currentElement.offset().top;
                                    leftPosition = currentElement.offset().left + currentElement.innerWidth();
                                } else if (tooltipLocation === "center-right") {
                                    // eslint-disable-next-line no-undef
                                    topPosition = currentElement.offset().top + ((currentElement.outerHeight() - $(".tooltip-container").outerHeight()) / 2);
                                    leftPosition = currentElement.offset().left + currentElement.innerWidth() + 5;
                                }

                                // eslint-disable-next-line no-undef
                                const tooltipBackgroundColor = $(this).attr("data-tooltip-background-color");
                                // eslint-disable-next-line no-unused-expressions, no-undef
                                tooltipBackgroundColor && $(".tooltip-container").css("background", tooltipBackgroundColor);
                                // eslint-disable-next-line no-undef
                                $(".tooltip-container").css("left", `${parseInt(leftPosition, 10)  }px`);
                                // eslint-disable-next-line no-undef
                                $(".tooltip-container").css("top", `${parseInt(topPosition, 10)  }px`);
                                // eslint-disable-next-line no-undef
                                $(".tooltip-container").css("opacity", "1");
                            }, tooltipDelay);
                        }
                    }
                });

                // eslint-disable-next-line no-undef
                $(document).on("click", ".fw-tooltip-close", (e) => {
                    Site.Tooltip.ClearTooltip();
                });

                // eslint-disable-next-line no-undef
                $(document).on("mouseleave", ".fw-tooltip-deprecated:not(.disable-auto-close)", (e) => {
                    Site.Tooltip.ClearTooltip();
                });
                // eslint-disable-next-line no-undef
                $(document).on("mouseleave", "#theadInstruments", (e) => {
                    Site.Tooltip.ClearTooltip();
                });
                // eslint-disable-next-line no-undef
                $(document).on("mouseout", "#theadInstruments", (e) => {
                    Site.Tooltip.ClearTooltip();
                });
                // eslint-disable-next-line no-undef
                $(document).on("click", (e) => {
                    // eslint-disable-next-line no-undef
                    if (!$(e.target).closest(".fw-tooltip-container, .fw-tooltip-deprecated").length) {
                      Site.Tooltip.ClearTooltip();
                    }
                  });
                  // eslint-disable-next-line no-undef
                  $(window).on("scroll", () => {
                    Site.Tooltip.ClearTooltip();
                });
                // eslint-disable-next-line no-undef
                $(window).on("scroll",".tooltip-container:not(.disable-auto-close),.fw-tooltip-deprecated:not(.disable-auto-close)", () => {
                    Site.Tooltip.ClearTooltip();
                });
                // eslint-disable-next-line no-undef
                $(window).on("scroll",".tooltip-container,.fw-tooltip-deprecated", () => {
                    Site.Tooltip.ClearTooltip();
                });
                  // eslint-disable-next-line no-undef
                  $(document).on("mouseleave", ".tooltip-container", (e) => {
                    Site.Tooltip.ClearTooltip();
                });            
        }
    },
        ClearTooltip() {
            // eslint-disable-next-line no-undef
            $(".tooltip-container").remove();
            // eslint-disable-next-line no-undef
            $(".fw-tooltip-deprecated").removeClass("tooltip-open");

            if (Site.Tooltip.CurrentTimeout) {
                clearTimeout(Site.Tooltip.CurrentTimeout);
            }
        }
    }
};

const Framework = {
    Layout: {
        Block() {
            // eslint-disable-next-line no-undef
            $("body").append("<div id='background-overlay'></div>");

            // eslint-disable-next-line no-undef
            $("#background-overlay").addClass("shown");
        },
        Unblock() {
            // eslint-disable-next-line no-undef
            $("#background-overlay").remove();
        }
    },
    Cookie: {
        setCookie(key, value) {
            const expires = new Date();
            expires.setTime(expires.getTime() + (3000 * 24 * 60 * 60 * 1000));
            document.cookie = `${key  }=${  value  }; expires=${  expires.toUTCString()  }; path=/;`;
        },
        setTempCookie(key, value) {
            document.cookie = `${key  }=${  value  }; path=/;`;
        },
        getCookie(key) {
            const keyValue = document.cookie.match(`(^|;) ?${  key  }=([^;]*)(;|$)`);
            return keyValue ? keyValue[2] : null;
        }
    }
};