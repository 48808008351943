import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import equal from "fast-deep-equal";
import _ from "lodash";
import { EContractType } from "src/enums/contract-type.enum";

export interface IContractSelectOption {
   _id: string;
   locked: boolean;
   contractKey: string;
   connectedBoardWidgetId: string;
   useOpenContractIndex: boolean;
   openContractIndex: number;
   diffFromWall?: boolean;
   openContractChangeType?: EContractType;
}

export interface ContractSelect {
   contractSelectData: {
      [widgetKey: string]: IContractSelectOption;
   };
}

const initialState: ContractSelect = {
   contractSelectData: {},
};

const contractSelectSlice = createSlice({
   name: "contractSelectData",
   initialState,
   reducers: {
      setContractSelect: (state, action: PayloadAction<{ widgetId: string; newData: IContractSelectOption }>) => {
         const { newData, widgetId } = action.payload;
         state.contractSelectData = {
            ...state.contractSelectData,
            [widgetId]: newData,
         };
      },
      onLockChange: (
         state,
         action: PayloadAction<{ newValue: boolean; widgetId: string; newActiveContractIndex: number }>
      ) => {
         const { newValue, widgetId, newActiveContractIndex } = action.payload;
         if (!state.contractSelectData[widgetId]) return;

         const selector = { ...state.contractSelectData[widgetId] };
         selector.locked = newValue;
         selector.openContractIndex = newActiveContractIndex;
         state.contractSelectData[widgetId] = selector;
      },
      onDeleteSelector: (state, action: PayloadAction<{ widgetId: string; selectorId: string }>) => {
         const { widgetId } = action.payload;
         if (!state.contractSelectData[widgetId]) return;

         delete state.contractSelectData[widgetId];
      },
      onSelectedContractChange: (
         state,
         action: PayloadAction<{
            widgetId: string;
            newSelectedContractKey: string;
            newActiveContractIndex?: number;
            useOpenContractIndex?: boolean;
            locked?: boolean;
            diffFromWall?: boolean;
            openContractChangeType?: EContractType;
         }>
      ) => {
         const {
            widgetId,
            newSelectedContractKey,
            newActiveContractIndex,
            useOpenContractIndex,
            locked,
            diffFromWall,
            openContractChangeType,
         } = action.payload;

         if (!state.contractSelectData[widgetId]) return;

         const selector = _.cloneDeep(state.contractSelectData[widgetId]);
         selector.contractKey = newSelectedContractKey;
         if (
            !_.isUndefined(useOpenContractIndex) &&
            _.isNumber(newActiveContractIndex) &&
            newActiveContractIndex !== -1
         ) {
            selector.useOpenContractIndex = useOpenContractIndex;
            selector.openContractIndex = newActiveContractIndex;
         }
         if (!_.isUndefined(locked)) selector.locked = locked;

         if (!_.isUndefined(diffFromWall)) selector.diffFromWall = diffFromWall;

         if (
            (!_.isUndefined(openContractChangeType) &&
               !equal(openContractChangeType, selector.openContractChangeType)) ||
            (selector.openContractChangeType && _.isUndefined(openContractChangeType))
         )
            selector.openContractChangeType = openContractChangeType;

         if (!equal(state.contractSelectData[widgetId], selector)) {
            state.contractSelectData = {
               ...state.contractSelectData,
               [widgetId]: selector,
            };
         }
      },
      onSelectedBoardChange: (
         state,
         action: PayloadAction<{
            widgetId: string;
            newSelectedBoardId: string;
         }>
      ) => {
         const { widgetId, newSelectedBoardId } = action.payload;
         if (!state.contractSelectData[widgetId]) return;

         const selector = { ...state.contractSelectData[widgetId] };
         selector.connectedBoardWidgetId = newSelectedBoardId;

         state.contractSelectData = {
            ...state.contractSelectData,
            [widgetId]: selector,
         };
      },
      changeContractsOfConnectedBoard: (
         state,
         action: PayloadAction<{ boardWidgetId: string; newContract: string }>
      ) => {
         const prevContracts = Object.entries(state.contractSelectData);
         const { boardWidgetId, newContract } = action.payload;
         const newValue = prevContracts
            .map(([widgetId, selectData]) => {
               if (
                  selectData.connectedBoardWidgetId !== boardWidgetId ||
                  selectData.locked ||
                  selectData.useOpenContractIndex
               )
                  return { widgetId, selectData };
               selectData.contractKey = newContract;
               return { widgetId, selectData };
            })
            .reduce(
               (prev, curr) => {
                  prev[curr.widgetId] = curr.selectData;
                  return prev;
               },
               {} as typeof state.contractSelectData
            );
         if (!equal(state.contractSelectData, newValue)) {
            state.contractSelectData = newValue;
         }
      },
   },
});

const { actions, reducer } = contractSelectSlice;

export const {
   setContractSelect,
   onSelectedContractChange,
   onSelectedBoardChange,
   onDeleteSelector,
   onLockChange,
   changeContractsOfConnectedBoard,
} = actions;

export default reducer;
