import { BaseComponent } from "./baseComponent";

import { IValuedComponentProps, IValuedComponentState } from "./IValuedComponent";

export class BaseValuedComponent<V, P = {}, S = {}, SS = any> extends BaseComponent<IValuedComponentProps<V> & P, S | IValuedComponentState<V>, SS> {
    state: IValuedComponentState<V> = {
        selectedValue: null,
    }

    async setValueState(newValue: V | null) {
        const storedValue = (this.state.selectedValue instanceof Date) ? this.state.selectedValue.getTime() : this.state.selectedValue;
        const targetValue = (newValue instanceof Date) ? newValue.getTime() : newValue;

        if (storedValue !== targetValue) {

            if (this.props.onChanging instanceof Function) {
                const changingHandle = await this.props.onChanging(this.state.selectedValue, newValue);

                if (!changingHandle?.allowChange) {
                    return;
                }
            }

            this.setState({ selectedValue: newValue }, () => {
                if (this.props.onChange instanceof Function) {
                    this.props.onChange(newValue);
                }
            });
        }
    }

    componentDidUpdate(prevProps: Readonly<IValuedComponentProps<V>>, prevState: Readonly<IValuedComponentState<V>>, snapshot: SS) {
        if (this.props.value !== prevProps.value) {
            if (this.props.value === undefined) {
                // @ts-ignore
                this.setValueState(null);
            } else {
                // @ts-ignore
                this.setValueState(this.props.value);
            }
        }
        if (super.componentDidUpdate !== undefined) {
            super.componentDidUpdate(prevProps as any, prevState as any, snapshot);
        }
    }
}
