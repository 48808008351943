import {useEffect} from 'react';
import {useLocation} from 'react-router-dom';
// @mui
import {styled, useTheme} from '@mui/material/styles';
import {Box, Stack, Drawer} from '@mui/material';
// hooks
import useResponsive from '../../../hooks/useResponsive';
import useCollapseDrawer from '../../../hooks/useCollapseDrawer';
// utils
import cssStyles from '../../../utils/cssStyles';
// config
import {NAVBAR} from '../../../config';
// components
import Logo from '../../../components/Logo';
import Scrollbar from '../../../components/Scrollbar';
import {NavSectionVertical} from '../../../components/nav-section';
//
import navConfig from './NavConfig';
import LogoSmall from 'src/components/LogoSmall';

import {
    IApplicationDefinition,
    ExperimentalAppId
} from '../../../../src/old/src/containers/authenticated/applicationDefinitions';
import {
    MasterMenu,
    IMenuItem,
} from '../../../../src/old/src/containers/authenticated/menuDefinitions';
import {useStore} from 'react-redux';
import useSettings from 'src/hooks/useSettings';
import {VerticalMenuViewOption} from 'src/components/settings/type';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({theme}) => ({
    [theme.breakpoints.up('lg')]: {
        flexShrink: 0,
        transition: theme.transitions.create('width', {
            duration: theme.transitions.duration.shorter,
        }),
    },
}));

// ----------------------------------------------------------------------

type Props = {
    onCloseSidebar: VoidFunction;
};

export default function NavbarVertical({onCloseSidebar}: Props) {
    const theme = useTheme();
    const store = useStore();
    const {pathname} = useLocation();

    const isDesktop = useResponsive('up', 'lg');

    const {verticalMenuViewOption} = useSettings();

    const allowExperimentalApp = (appId: number): boolean => {
        const experimentalEnv = import.meta.env["VITE_EXPERIMENTAL_DEV"];
        return appId == ExperimentalAppId && experimentalEnv && experimentalEnv === "1"
            && import.meta.env.DEV
            && import.meta.env.MODE === "development"
            ;
    };

    const {
        isCollapse,
        collapseClick,
        collapseHover,
        onToggleCollapse,
        changeCollapse,
        onHoverEnter,
        onHoverLeave,
    } = useCollapseDrawer();

    const getAccessibleMenuItems = (
        menuItems: IMenuItem[],
        permittedScreenIds: number[]
    ): IMenuItem[] => {
        const accessibleMenuItems: IMenuItem[] = [];

        for (const menuItem of menuItems) {
            const clonedMenuItem = {...menuItem};

            if (clonedMenuItem.platform === 'web') {
                if (clonedMenuItem.type === 'application') {
                    clonedMenuItem.details = {...menuItem.details};

                    const applicationDetail = clonedMenuItem.details as IApplicationDefinition;

                    const isAllowedApp = permittedScreenIds.some((e) => e === applicationDetail.id)
                        || allowExperimentalApp(applicationDetail.id);

                    if (isAllowedApp) {
                        accessibleMenuItems.push(clonedMenuItem);
                    }
                } else {
                    clonedMenuItem.details = getAccessibleMenuItems(
                        clonedMenuItem.details as IMenuItem[],
                        permittedScreenIds
                    );
                    if ((clonedMenuItem.details as IMenuItem[]).length !== 0) {
                        accessibleMenuItems.push(clonedMenuItem);
                    }
                }
            }
        }

        return accessibleMenuItems;
    };

    useEffect(() => {
        const accessibleMenuItems = getAccessibleMenuItems(
            MasterMenu || [],
            store.getState().common?.eligibility?.applications || []
        );
        navConfig[0].items = navConfig[0].items.slice(0, 1);

        for (let i = 1; i < accessibleMenuItems.length; i++) {
            const accessibleMenuItem = accessibleMenuItems[i];

            const menuItem = {
                title: accessibleMenuItem.textResourceId,
                children: [] as any,
                icon: accessibleMenuItem.iconName,
                path: '',
                tooltip: accessibleMenuItem.tooltipResourceId
            };

            if (Array.isArray(accessibleMenuItem.details)) {
                for (let j = 0; j < (accessibleMenuItem.details as any).length; j++) {
                    const detail = accessibleMenuItem.details[j];
                    menuItem.children.push({
                        title: detail.textResourceId,
                        path: (detail.details as IApplicationDefinition).routeInfos[0].path,
                        tooltip: detail.tooltipResourceId
                    });
                }
            } else {
                const detail = accessibleMenuItem.details as IApplicationDefinition;
                menuItem.path = detail.routeInfos[0].path;
            }

            navConfig[0].items.push(menuItem as any);
        }
    }, []);

    useEffect(() => {
        switch (verticalMenuViewOption) {
            case 0:
            case 1:
                changeCollapse(true);
                break;
            case 2:
                changeCollapse(false);
                break;
            default:
                break;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname, verticalMenuViewOption]);

    const renderContent = (
        <Scrollbar
            sx={{
                height: 1,
                '& .simplebar-content': {height: 1, display: 'flex', flexDirection: 'column'},
            }}
        >
            <Stack
                spacing={3}
                sx={{
                    pt: 3,
                    pb: 2,
                    px: 2.5,
                    flexShrink: 0,
                    ...(isCollapse && {alignItems: 'center'}),
                }}
            >
                <Stack direction="row" alignItems="center" justifyContent="center">
                    {isCollapse && <LogoSmall/>}
                    {!isCollapse && <Logo/>}
                    {/* {
            isDesktop && !isCollapse && (
              <CollapseButton onToggleCollapse={onToggleCollapse} collapseClick={collapseClick} />
            )
          } */}
                </Stack>
            </Stack>

            <NavSectionVertical navConfig={navConfig} isCollapse={isCollapse}/>

            <Box sx={{flexGrow: 1}}/>
        </Scrollbar>
    );

    return (
        <RootStyle
            sx={{
                width: {
                    lg:
                        verticalMenuViewOption === VerticalMenuViewOption.Closed
                            ? 0
                            : isCollapse
                                ? NAVBAR.DASHBOARD_COLLAPSE_WIDTH
                                : NAVBAR.DASHBOARD_WIDTH,
                },
                ...(collapseClick && {
                    position: 'absolute',
                }),
            }}
        >
            {!isDesktop && (
                <Drawer
                    open={verticalMenuViewOption !== VerticalMenuViewOption.Closed}
                    onClose={onCloseSidebar}
                    PaperProps={{sx: {width: NAVBAR.DASHBOARD_WIDTH}}}
                >
                    {renderContent}
                </Drawer>
            )}

            {isDesktop && (
                <Drawer
                    open={verticalMenuViewOption !== VerticalMenuViewOption.Closed}
                    variant="persistent"
                    onMouseEnter={onHoverEnter}
                    onMouseLeave={onHoverLeave}
                    PaperProps={{
                        sx: {
                            width: NAVBAR.DASHBOARD_WIDTH,
                            borderRightStyle: 'dashed',
                            bgcolor: 'background.default',
                            transition: (theme) =>
                                theme.transitions.create('width', {
                                    duration: theme.transitions.duration.standard,
                                }),
                            ...(isCollapse && {
                                width:
                                    verticalMenuViewOption === VerticalMenuViewOption.Closed
                                        ? 0
                                        : NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
                            }),
                            ...(collapseHover && {
                                ...cssStyles(theme).bgBlur(),
                                boxShadow: (theme) => theme.customShadows.z24,
                            }),
                        },
                    }}
                >
                    {renderContent}
                </Drawer>
            )}
        </RootStyle>
    );
}
