import ErrorStackParser from 'error-stack-parser';
import { ReactNode, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import Icons from 'src/old/src/components/icons';
import messages from 'src/old/src/locales/_messages';
import StackFrame from 'stackframe';

import {
  AppBar,
  Button,
  Card,
  CardActions,
  CircularProgress,
  Collapse,
  Dialog,
  DialogContent,
  Divider,
  IconButton,
  IconButtonProps,
  Stack,
  Toolbar,
  Typography,
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import baseService from 'src/services/base.service';

interface ErrorBoundaryProps {
  error: Error;
  resetErrorBoundary: (...args: any[]) => void;
}

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

const CommonFallback = (props: ErrorBoundaryProps) => {
  const { formatMessage } = useIntl();
  const theme = useTheme();

  const [logId, setLogId] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [expanded, setExpanded] = useState(false);

  const renderFrames = (frames: StackFrame[]): ReactNode => {
    return frames.map((f, _) => {
      let url = f.fileName + '&line=' + f.lineNumber + '&column=' + f.columnNumber;
      let text = f.fileName;

      return (
        <div key={url}>
          <div style={{ fontWeight: 'bold' }}>{f.functionName}</div>
          <div>
            <span
              style={{ fontSize: 'smaller', color: 'black', textDecoration: 'none' }}
              href={url}
            >
              {text}
            </span>
          </div>
        </div>
      );
    });
  };

  let frames: StackFrame[] = [];
  let parseError: Error | null = null;
  try {
    frames.push(...ErrorStackParser.parse(props.error));
  } catch (e) {
    parseError = new Error('Error parsing previous error');
  }

  useEffect(() => {
    const logError = async () => {
      if ((props.error as any).logRequest === undefined) {
        (props.error as any).logRequest = baseService.post('Support/LogClientError', {
          message: props.error.message,
          name: props.error.name,
          stack: props.error.stack,
          path: window.location.hash,
        });
      }

      const response = await (props.error as any).logRequest;
      if (response.status !== 200 || response.data.isError || response.data.isAuthorizationError) {
        console.error('Error logging error: logging request failed.');
      }

      setLogId(response.data.ReplyObject);
      setIsLoading(false);
    };

    logError();
  }, []);

  return (
    <Dialog open={true}>
      <AppBar sx={{ position: 'relative', backgroundColor: (theme) => theme.palette.error.main }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => props.resetErrorBoundary('close', logId)}
            aria-label="close"
          >
            <Icons.Close />
          </IconButton>
          <Typography variant="h6" component="div">
            {formatMessage(messages.global.error)}
          </Typography>
          <div style={{ flex: 1 }}>&nbsp;</div>
          <Button
            color="inherit"
            onClick={() => {
              props.resetErrorBoundary('home', logId);
            }}
          >
            <Icons.Home style={{ marginRight: '5px', color: 'white' }} />
            {formatMessage(messages.menu.appTitleHomepage)}
          </Button>
        </Toolbar>
      </AppBar>
      <DialogContent>
        <h3>{formatMessage(messages.global.errorOccurred)}</h3>
        <br />
        <Card
          sx={{
            overflow: 'auto',
          }}
        >
          <Stack
            height={48}
            padding={2}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{
              userSelect: 'none',
              ':hover': {
                cursor: 'pointer',
                backgroundColor: (theme) => theme.palette.action.hover,
              },
            }}
            onClick={() => setExpanded(!expanded)}
          >
            <Typography variant="subtitle1" fontWeight="bold">
              {formatMessage(messages.global.showMore)}
            </Typography>
            <Icons.ExpandMore />
          </Stack>
          <Collapse sx={{ p: 1 }} in={expanded} timeout="auto" unmountOnExit>
            {(parseError !== null && (
              <div key={0}>
                <div>{parseError.message}</div>
              </div>
            )) || (
              <>
                <Stack
                  direction="row"
                  alignItems="center"
                  spacing={1}
                  sx={{ fontWeight: 'bold', fontSize: 'larger' }}
                >
                  {props.error.message}
                  <IconButton
                    size="small"
                    onClick={() => {
                      let text =
                        props.error.name + '\n' + props.error.message + '\n\n' + props.error.stack;
                      if (logId !== null) text += '\n\nError Log Id: ' + logId;
                      navigator.clipboard.writeText(text);
                    }}
                  >
                    <Icons.ContentCopy />
                  </IconButton>
                </Stack>
                <br />
                {renderFrames(frames)}
              </>
            )}
          </Collapse>
          {isLoading ? (
            <CircularProgress size={16} />
          ) : (
            <>
              <Divider orientation="horizontal" />
              <Stack direction="row" padding={2} spacing={1}>
                {logId !== null ? (
                  <Icons.Info style={{ color: theme.palette.info.main }} />
                ) : (
                  <Icons.Error style={{ color: theme.palette.error.main }} />
                )}
                <Typography variant="subtitle1" fontWeight="bold">
                  {logId !== null ? (
                    <>
                      {formatMessage(messages.global.errorOccurred_ORG)}
                      <Typography
                        variant="subtitle1"
                        fontWeight="bolder"
                        sx={{ color: (theme) => theme.palette.info.main }}
                      >
                        {logId}
                        <IconButton
                          size="small"
                          onClick={() => {
                            navigator.clipboard.writeText(logId);
                          }}
                        >
                          <Icons.ContentCopy />
                        </IconButton>
                      </Typography>
                    </>
                  ) : (
                    formatMessage(messages.global.errorOccurred)
                  )}
                </Typography>
              </Stack>
            </>
          )}
        </Card>
      </DialogContent>
    </Dialog>
  );
};

export default CommonFallback;
