import React from "react";

import { ThemeProvider } from "@mui/material/styles";

import { IntlProvider } from "react-intl";
import { useLocation } from "react-router-dom";

import ApplicationContextProvider from "../ApplicationContext/index";

import localeData from "../../locales/data.json";
import theme from "./theme";
interface IApplicationWithRoutedState {
    language: string;
    resourceStrings?: any;
    handleOnHashChange: () => void;
}

interface ApplicationWithRoutedProps {
    location: any;
}

declare global {
    var __smartPulse: any;
}

class ApplicationWithRouted extends React.PureComponent<ApplicationWithRoutedProps, IApplicationWithRoutedState> {
    state: IApplicationWithRoutedState = {
        language: "tr",
        resourceStrings: localeData.tr,
        handleOnHashChange: this.onHashChange.bind(this)
    }

    getHashUrlParams(): URLSearchParams {
        const pageUrl = new URL(window.location.toString().replace("/#/", "/"));

        return new URLSearchParams(pageUrl.search);
    }

    onHashChange() {
        let languageParameter = this.getHashUrlParams();
        let languageParameterValue = languageParameter.get("locale");

        if (languageParameterValue === "tr" || languageParameterValue === "en" || languageParameterValue === "es") {
            if (languageParameterValue !== this.state.language) {
                switch (languageParameterValue) {
                    case "tr":
                        this.setState({language: "tr", resourceStrings: localeData.tr}, () => localStorage.setItem("locale", "tr"));
                        break;
                    case "en":
                        this.setState({language: "en", resourceStrings: localeData.en}, () => localStorage.setItem("locale", "en"));
                        break;
                    case "es":
                        this.setState({language: "es", resourceStrings: localeData.es}, () => localStorage.setItem("locale", "es"));
                        break;
                    default:
                        this.setState({language: "tr", resourceStrings: localeData.tr}, () => localStorage.setItem("locale", "tr"));
                        break;
                }
            }
        }else {
            let languageStorageParameter = localStorage.getItem("locale");
            
            if ((languageStorageParameter || "") === "" || (languageStorageParameter || "") === "undefined") {
                languageParameter.set("locale", "tr");
            }else {
                languageParameter.set("locale", languageStorageParameter as string);
            }
            window.location.hash = (new URL(window.location.href.replace("/#/", "/"))).pathname + "?" + languageParameter.toString();
        }
    }

    componentDidMount() {
        if (globalThis.__smartPulse === undefined || globalThis.__smartPulse === null) {
            globalThis.__smartPulse = {};
        }
        window.addEventListener("hashchange", this.state.handleOnHashChange);

        let languageParameter = this.getHashUrlParams();

        if ((languageParameter.get("locale") || "") === "") {
            let languageStorageParameter = localStorage.getItem("locale");
            
            if ((languageStorageParameter || "") === "") {
                languageParameter.set("locale", "tr");
                localStorage.setItem("locale", "tr");
            }else {
                languageParameter.set("locale", languageStorageParameter as string);
            }
            window.location.hash = (new URL(window.location.href.replace("/#/", "/"))).pathname + "?" + languageParameter.toString();
        }
        window.location.hash = "";
        window.location.hash = (new URL(window.location.href.replace("/#/", "/"))).pathname + "?" + languageParameter.toString();
    }

    componentWillUnmount() {
        window.removeEventListener("hashchange", this.state.handleOnHashChange);
    }

    render() {
        return (<IntlProvider locale={this.state.language} messages={this.state.resourceStrings} defaultLocale="tr">
            <ThemeProvider theme={theme}>
                <ApplicationContextProvider>
                    {this.props.children}
                </ApplicationContextProvider>
            </ThemeProvider>
        </IntlProvider>);
    }
}

export default ApplicationWithRouted;
