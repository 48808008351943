import {
  CircularProgress,
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
  List,
  Popover,
  Tooltip,
  Box,
} from '@mui/material';
import { FormattedMessage, injectIntl, defineMessages, IntlShape } from 'react-intl';
import { BaseComponent } from '../../baseComponent';
import {
  RunningIcon,
  DisconnectedIcon,
  ErrorIcon,
  AccountConnectedIcon,
  AccountDisconnectedIcon,
  ConnectedIcon,
} from '../../icons';
import store from '../../../redux/stores';
import { CompanyConnections, ServiceStatus, SystemHealth } from '../types';
import Subheader from './subheader';
import { connect } from 'react-redux';
import { Description } from '@mui/icons-material';
import theme from '../../Application/theme';
import React from 'react';

const messages = defineMessages({
  connected: {
    id: 'global.warning.connected',
    defaultMessage: 'Connected',
  },
  noConnection: {
    id: 'global.warning.noconnection',
    defaultMessage: 'No Connection',
  },
  lastUpdate: {
    id: 'global.lastupdate',
    defaultMessage: 'Last Update',
  },
});

const titleFontWeight = 500;
const listItemStyle = { width: '350px' };

interface SystemHealthPopupState {
  companies: any;
  serviceStatuses?: ServiceStatus[];
}

interface SystemHealthPopupProps {
  intl: IntlShape;
  anchorEl: Element | null;
  systemHealth: SystemHealth | null;
  isFetching?: boolean;
  hideLastUpdateDate?: boolean;
  lastUpdateDate: Date | null;
  onClose: () => void;
}

class SystemHealthPopup extends BaseComponent<SystemHealthPopupProps, SystemHealthPopupState> {
  certificateInfo: any;
  isOmie: any;
  constructor(props: SystemHealthPopupProps) {
    super(props);

    const state = store.getState();
    this.state = {
      companies: state.common.permissions.companies,
    };
  }

  batchCompanyConnections = (services: ServiceStatus[]): CompanyConnections => {
    const companyConnections: CompanyConnections = {
      connectedCompanies: [],
      rejectedCompanies: [],
    };

    for (const service of services) {
      if (!service.companyConnections) continue;
      const { connectedCompanies, rejectedCompanies } = service.companyConnections;
      companyConnections.connectedCompanies.push(
        ...connectedCompanies.map((cc) => ({
          ...cc,
          companyName: cc.companyName ?? this.state.companies.find((c: any) => cc.id == c.id)?.name,
        }))
      );
      companyConnections.rejectedCompanies.push(
        ...rejectedCompanies.map((rc) => ({
          ...rc,
          companyName: rc.companyName ?? this.state.companies.find((c: any) => rc.id == c.id)?.name,
        }))
      );
    }
    companyConnections.connectedCompanies.sort((a, b) =>
      a.companyName ? a.companyName!.localeCompare(b.companyName!) : 0
    );
    companyConnections.rejectedCompanies.sort((a, b) =>
      a.companyName ? a.companyName!.localeCompare(b.companyName!) : 0
    );

    return companyConnections;
  };

  render() {
    if (!this.props.systemHealth) return null;

    const { formatMessage } = this.props.intl;
    this.certificateInfo = null;
    this.isOmie = false;

    const companyConnections = this.batchCompanyConnections(this.props.systemHealth.services);

    return (
      <Popover
        anchorEl={this.props.anchorEl}
        open={Boolean(this.props.anchorEl)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onClose={this.props.onClose}
        slotProps={{
          paper: {
            sx: {
              maxHeight: "85vh",
              display: "flex",
              flexDirection: "column", 
              overflowY: "hidden",
              pt: 1,
            }
          }
        }}
      >
        {/* Last Update Info */}
        {!this.props.hideLastUpdateDate && this.props.lastUpdateDate && (
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="end"
            spacing={1}
            sx={{ px: 2 }}
          >
            <Typography variant="caption" fontWeight="bold" color="GrayText">
              <FormattedMessage id="global.lastupdate" defaultMessage="Last Update" />
              {': ' +
                this.props.intl.formatTime(this.props.lastUpdateDate, {
                  hour: 'numeric',
                  minute: 'numeric',
                  second: 'numeric',
                  timeZoneName: 'short',
                  hourCycle: 'h24',
                  timeZone: localStorage.getItem('UserTimeZoneId') ?? undefined,
                })}
            </Typography>
            {this.props.isFetching && <CircularProgress key={"SystemHealthPopupCircularProgress"} size={12} />}
          </Stack>
        )}

        {/* Service Statuses */}
        <List dense disablePadding key={"systemstatus-list"}>
          <Typography
            fontWeight="bold"
            paddingLeft={'1vw'}
            sx={{ color: (theme) => theme.palette.success.main }}
          >
            <FormattedMessage id="global.servicesinfo" />
          </Typography>

          {this.props.systemHealth.services.map((service) => {
            if (service.title == 'Omie') {
              this.isOmie = true;
              const pureCertificate = this.getUserProfileData('CertificateInfo');
              this.certificateInfo = pureCertificate ? JSON.parse(pureCertificate) : null;
            }
            return (
                <ListItem key={'systemstatus_' + service.title} style={listItemStyle}>
                  <ListItemIcon>
                    {service.isConnected ? (
                      <Tooltip title={formatMessage(messages.connected)}>
                        <ConnectedIcon sx={{ color: (theme) => theme.palette.success.main }} />
                      </Tooltip>
                    ) : (
                      <Tooltip title={formatMessage(messages.noConnection)}>
                        <DisconnectedIcon sx={{ color: (theme) => theme.palette.error.main }} />
                      </Tooltip>
                    )}
                    <Divider orientation="vertical" variant="middle" sx={{ px: 0.5 }} />
                    {service.isHealthy ? (
                      <Tooltip
                        title={formatMessage(
                          { id: 'global.serviceishealthy' },
                          { message: service.message }
                        )}
                      >
                        <RunningIcon sx={{ color: (theme) => theme.palette.success.main }} />
                      </Tooltip>
                    ) : (
                      <Tooltip
                        title={formatMessage(
                          { id: 'global.serviceisunhealthy' },
                          { message: service.message }
                        )}
                      >
                        <ErrorIcon sx={{ color: (theme) => theme.palette.error.main }} />
                      </Tooltip>
                    )}
                  </ListItemIcon>
                  <ListItemText
                    primary={formatMessage({
                      id: 'systemstatus.service-' + service.title,
                      defaultMessage: service.title,
                    })}
                    primaryTypographyProps={{ fontWeight: titleFontWeight, variant: 'body1' }}
                  />
                </ListItem>
            );
          })}
        </List>
        {this.isOmie && (
          <>
            <Subheader
              icon={<Description sx={{ color: (theme) => theme.palette.success.main }} />}
              text={
                <Typography
                  fontWeight="bold"
                  sx={{
                    color: (theme) =>
                      this.certificateInfo != null
                        ? theme.palette.success.main
                        : theme.palette.error.main,
                  }}
                >
                  <FormattedMessage
                    id="global.certificateInfo"
                    defaultMessage="Certificate Information"
                  />
                </Typography>
              }
            />

            <ListItem style={listItemStyle}>
              <ListItemText>
                <span>
                  <span style={{ fontWeight: titleFontWeight }}>
                    <FormattedMessage id="global.connection" />
                  </span>
                  :
                  <span
                    style={{
                      color:
                        this.certificateInfo != null
                          ? theme.palette.success.main
                          : theme.palette.error.main,
                    }}
                  >
                    {' '}
                    {(companyConnections.connectedCompanies.length == 0 &&
                      companyConnections.rejectedCompanies.length > 0) ||
                    (this.certificateInfo == null && this.isOmie) ? (
                      <FormattedMessage id="global.passive" />
                    ) : (
                      <FormattedMessage id="global.active" />
                    )}
                  </span>
                </span>
              </ListItemText>
            </ListItem>
            {this.certificateInfo && (
              <>
                <ListItem style={listItemStyle}>
                  <ListItemText>
                    <span>
                      <span style={{ fontWeight: titleFontWeight }}>
                        <FormattedMessage id="global.certificate" defaultMessage="Certificate" />
                      </span>

                      <span
                        style={{
                          color:
                            this.certificateInfo != null
                              ? theme.palette.success.main
                              : theme.palette.error.main,
                        }}
                      >
                        {' '}
                        {this.certificateInfo != null && this.certificateInfo.certificateId}{' '}
                      </span>
                    </span>
                  </ListItemText>
                </ListItem>

                <ListItem style={listItemStyle}>
                  <ListItemText>
                    <span>
                      <span style={{ fontWeight: titleFontWeight }}>
                        <FormattedMessage
                          id="global.certificateExpireDate"
                          defaultMessage="Certificate Expire Date"
                        />
                      </span>

                      <span
                        style={{
                          color:
                            this.certificateInfo != null
                              ? theme.palette.success.main
                              : theme.palette.error.main,
                        }}
                      >
                        {' '}
                        {this.certificateInfo != null && this.certificateInfo.certificateDate}{' '}
                      </span>
                    </span>
                  </ListItemText>
                </ListItem>
              </>
            )}
          </>
        )}
        <List disablePadding sx={{ overflowY: 'auto', flex: 1 }}>
          {/* Rejected Companies */}
          {companyConnections.rejectedCompanies.length > 0 && (
            <Subheader
              icon={<AccountDisconnectedIcon sx={{ color: (theme) => theme.palette.error.main }} />}
              text={
                <Typography fontWeight="bold" sx={{ color: (theme) => theme.palette.error.main }}>
                  <FormattedMessage
                    id="intradayplanning.disconnectedcompanies"
                    defaultMessage="Disconnected Companies"
                  />
                </Typography>
              }
            />
          )}
          {companyConnections.rejectedCompanies.map((rc) => {
            return (
              <ListItem key={'systemstatusrejectedcompanies_' + rc.id} style={listItemStyle}>
                <ListItemText
                  primary={rc.companyName}
                  primaryTypographyProps={{ fontWeight: titleFontWeight }}
                  secondary={rc.message}
                />
              </ListItem>
            );
          })}

          {/* Connnected Companies */}
          {companyConnections.connectedCompanies.length > 0 && (
            <Subheader
              icon={<AccountConnectedIcon sx={{ color: (theme) => theme.palette.success.main }} />}
              text={
                <Typography fontWeight="bold" sx={{ color: (theme) => theme.palette.success.main }}>
                  <FormattedMessage
                    id="intradayplanning.connectedcompanies"
                    defaultMessage="Connected Companies"
                  />
                </Typography>
              }
            />
          )}
          {companyConnections.connectedCompanies.map((cc) => {
            return (
              <ListItem key={'systemstatusconnectedcompanies_' + cc.id} style={listItemStyle}>
                <ListItemText
                  primary={cc.companyName}
                  primaryTypographyProps={{ fontWeight: titleFontWeight }}
                  secondary={
                    formatMessage({ id: 'intradayplanning.connectiontime' }) +
                    ': ' +
                    this.props.intl.formatDate(cc.connectionTime, {
                      year: 'numeric',
                      month: 'numeric',
                      day: 'numeric',
                      hour: 'numeric',
                      minute: 'numeric',
                      second: 'numeric',
                      timeZoneName: 'short',
                      hourCycle: 'h24',
                      timeZone: localStorage.getItem('UserTimeZoneId') ?? undefined,
                    })
                  }
                />
              </ListItem>
            );
          })}
        </List>
      </Popover>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    systemHealth: state.systemhealth.systemHealth,
    lastUpdateDate: state.systemhealth.lastUpdateDate,
  };
};

export default injectIntl(connect(mapStateToProps)(SystemHealthPopup));
