const defaultValues = {
    DayAheadNominationDate: new Date(1980,1,1),
    DayAheadNominationCompanyId: 0,
    NominationRows: [],
    ImportPriceData:[],
    PriceIntervalGroupDefinitions: [],
    FirstPtfSmfGroupColDefinitions: [],
    SecondPtfSmfGroupColDefinitions: [],
    SelectedPriceGroupName: '',
    SelectedFirstPtfSmfGroupIndex: 0,
    SelectedSecondPtfSmfGroupIndex: 0,
    NetPositionColumnsInPriceIntervals: [],
    groupExpanded: false,
    saveOk: false ,
    deepChange:false,
    ChangedValues: []
};

const dayaheadnomination  = (state = defaultValues , action) => {
    const getTargetColumnsToUpdate = function(medianColName, medianColIndex, colPrefix, dir, priceIntervalGroupDefinitions, selectedGroupName){
        let targetCols = [medianColName]
        if(dir === 'L'){
            let x = medianColIndex - 1
            while( x >= 0){
                targetCols.push(colPrefix + x);
                x--
            }
            //targetCols = [PrcIntGrp_MySelectedGroup_3,PrcIntGrp_MySelectedGroup_2,PrcIntGrp_MySelectedGroup_1,PrcIntGrp_MySelectedGroup_0]
        }else if (dir === 'R'){
            const selectedPriceGroup = priceIntervalGroupDefinitions.find(e => e.GroupName === selectedGroupName) || null;
            let lastColIndex = selectedPriceGroup.PriceIntervals.length - 1;
            let x = medianColIndex + 1;
            while( x <= lastColIndex){
                targetCols.push(colPrefix + x);
                x++
            }
            //targetCols = [PrcIntGrp_MySelectedGroup_3,PrcIntGrp_MySelectedGroup_4,PrcIntGrp_MySelectedGroup_5,....PrcIntGrp_MySelectedGroup_12]
        }

        return targetCols;
    }

    const calculateNextValue = function (strCurrValue, dir, isPercent, updateVal) {
        let nextVal = 0
        const currValue = parseFloat(strCurrValue);
        if (isPercent) {
            // Yüzdeli değişim:
            var numUpdateVal = parseFloat(currValue * (+updateVal / 100.0))
            //if (currValue < 0)
            //  numUpdateVal = numUpdateVal * (-1);
            if (dir === 'L') {
                nextVal = currValue - numUpdateVal
                // if (currValue >= 0 && nextVal <= 0) {
                //     nextVal = 0;
                // }
            } else if (dir === 'R') {
                nextVal = currValue + numUpdateVal;
                // if (currValue <= 0 && nextVal >= 0) {
                //     nextVal = 0;
                // }
            }
        } else {
            // Net değerli değişim
            const numUpdateVal = +updateVal
            if (dir === 'L') {
                nextVal = currValue - numUpdateVal;
                // if(currValue>=0 && nextVal<=0){
                //     nextVal=0;
                // } 
            } else if (dir === 'R') {
                nextVal = currValue + numUpdateVal;
                // if(currValue<=0 && nextVal>=0){
                //     nextVal=0;
                // }
            }
        }

        return nextVal;
    }

    switch (action.type) {
        case 'SET_DAY_AHEAD_NOMINATION_DATE':
            return {...state, DayAheadNominationDate: action.date};
        case 'SET_DAY_AHEAD_NOMINATION_COMPANY':
            return {...state, DayAheadNominationCompanyId: action.companyId};
        case 'SET_DAY_AHEAD_NOMINATION_COMPANY_AND_DATE':
            return {...state, DayAheadNominationCompanyId: action.companyId, DayAheadNominationDate: action.date};
        case 'HANDLE_GET_DAY_AHEAD_NOMINATION_RESPONSE':
            var selectedPriceGroupName = "";

            if(action.PriceIntervalGroupDefinitions.length>0){
                selectedPriceGroupName = action.PriceIntervalGroupDefinitions[0].GroupName;
            }   
            window.__volt_currentPageControl.Utils.number.createAutoTextProperties(action.rows);
            return {
                ...state, 
                NominationRows:  action.rows,
                mwState: action.mwState, 
                PriceIntervalGroupDefinitions: action.PriceIntervalGroupDefinitions,
                SelectedPriceGroupName:selectedPriceGroupName,
                FirstPtfSmfGroupColDefinitions: action.FirstPtfSmfGroupColDefinitions,
                SecondPtfSmfGroupColDefinitions: action.SecondPtfSmfGroupColDefinitions,
                NetPositionColumnsInPriceIntervals: [],
                CostValueDefinitions:action.CostValueDefinitions
            };
        case 'HANDLE_IMPORT_PRICE_DATA': 
            window.__volt_currentPageControl.Utils.number.createAutoTextProperties(action.rows);
            return {
                ...state, 
                ImportPriceData:  action.rows              
            };
        case 'SET_NET_POSITIONS_TO_PRICE_INTERVALS':
            //Action ile gelen netPositionColumnsInPriceIntervals dizisindeki her bir netPositionVal değerini
            // currentRow satırındaki priceIntervalColName adlı field'a yaz:
            
            let updatedNominationRows = state.NominationRows;
            let ChangedValues = [];
            for (let j = 0; j < action.netPositionColumnsInPriceIntervals.length; j++){
                const currIntervalData = action.netPositionColumnsInPriceIntervals[j];
                let currNominationData = updatedNominationRows[currIntervalData.currentRow];

                currNominationData[currIntervalData.priceIntervalColName] = currIntervalData.netPositionVal;

                ChangedValues.push({ rowIndex: currIntervalData.currentRow, priceIntervalColName: currIntervalData.priceIntervalColName });
            }

            return {
                ...state, 
                NominationRows:  updatedNominationRows, 
                NetPositionColumnsInPriceIntervals: action.netPositionColumnsInPriceIntervals,
                deepChange: !state.deepChange,
                ChangedValues: ChangedValues,
                selectedGroup: action.selectedGroup
            };
        case 'UPDATE_PRICES_ON_ROW':
            let updatedNominationRows2 = state.NominationRows;
            let netPositionColumnsInPriceIntervals = action.netPositionColumnsInPriceIntervals

            let currNominationData = updatedNominationRows2[action.rowIndex];
            const currData = action.netPositionColumnsInPriceIntervals.find(e => e.currentRow === action.rowIndex);
            //const currData = state.NetPositionColumnsInPriceIntervals[action.rowIndex];

            
            const medianColName = currData.priceIntervalColName; //PrcIntGrp_MySelectedGroup_3
            const colPrefix = medianColName.substring(0, medianColName.lastIndexOf('_') + 1 ); // PrcIntGrp_MySelectedGroup_
            const medianColIndex = (medianColName.substring( medianColName.lastIndexOf('_') + 1) * 1)  // 3

            if(!medianColName || medianColName.length === 0 || colPrefix.length === 0 || isNaN(medianColIndex)){
                  return state;
            }

            // dir: R iken targetCols = [PrcIntGrp_MySelectedGroup_3,PrcIntGrp_MySelectedGroup_4,PrcIntGrp_MySelectedGroup_5,....PrcIntGrp_MySelectedGroup_12]
            let targetCols = getTargetColumnsToUpdate(medianColName, medianColIndex, colPrefix, action.dir, state.PriceIntervalGroupDefinitions, state.SelectedPriceGroupName)
            let ChangedValues2 = state.ChangedValues;

            const isPercentageUpdate = (action.updateVal.indexOf && action.updateVal.indexOf('%') >= 0);
            const updateVal = isPercentageUpdate ? (action.updateVal.replace('%', '') * 1) : (action.updateVal * 1)
            
            for (let k = 1; k < targetCols.length; k++){
                // dir : R, updateVal : 10, targetCols[0] : PrcIntGrp_MySelectedGroup_3, currNominationData.PrcIntGrp_MySelectedGroup_3: 17 iken
                // currNominationData.PrcIntGrp_MySelectedGroup_4 = 17 + 10 olmalı. 
                // currNominationData.PrcIntGrp_MySelectedGroup_5 = 27 + 10 olmalı
                currNominationData[targetCols[k]] = calculateNextValue(currNominationData[targetCols[k-1]], action.dir, isPercentageUpdate, updateVal);
                
                if(!ChangedValues2.some(e => e.priceIntervalColName === targetCols[k] && e.rowIndex === action.rowIndex)) {
                    ChangedValues2.push({ rowIndex: action.rowIndex, priceIntervalColName: targetCols[k] });
                }
              }
            
            // for (let k = 1; k < targetCols.length; k++){
            //     console.log("Col:" + targetCols[k]+" Normal : " +currNominationData[targetCols[k]] + " Edited : " + Number(currNominationData[targetCols[k]].toFixed(1)) );
            //     currNominationData[targetCols[k]] = Number(currNominationData[targetCols[k]].toFixed(1));
            // }
            
            return {
                ...state, 
                NominationRows:  updatedNominationRows2,
                deepChange: !state.deepChange,
                ChangedValues: ChangedValues2,
                NetPositionColumnsInPriceIntervals: action.netPositionColumnsInPriceIntervals
            };
        case 'HANDLE_SAVE_DAY_AHEAD_NOMINATION_RESPONSE':
            return { ...state, saveOk: action.reply.saveOk };
        case 'TOGGLE_GROUP_EXPAND_STATUS':
            return { ...state, groupExpanded: action.newStatus };
        case 'SET_SELECTED_PRICE_GROUP': 
            return { ...state, SelectedPriceGroupName: action.groupName };
        case 'SET_SELECTED_FIRST_PTF_SMF_GROUP': 
            return { ...state, SelectedFirstPtfSmfGroupIndex: action.groupIndex};
        case 'SET_SELECTED_SECOND_PTF_SMF_GROUP': 
            return { ...state, SelectedSecondPtfSmfGroupIndex: action.groupIndex };
        case 'SET_CHANGED_VALUES':
             return { ...state, ChangedValues: action.changedValues };
        default:
            return state;
    }
}

export default dayaheadnomination