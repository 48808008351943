const defaultValues = {
    alertPopoverState: false,
};

const headerbuttons = (state = defaultValues, action) => {
    switch (action.type) {
        case "SET_OPEN_ALERT_POPOVER":
            return { ...state, openAlertPopover: action.alertPopoverState  };
        default:
            return state;
    }
};

export default headerbuttons;