import {IDictionary} from "../old/src/base/BaseTypes";

export const KnownFlagNames = {
    NewSsoFlow: "--smart-use-new-sso-flow"
};

class FlagsUtil {

    private _flags: IDictionary<string> = {};

    set(flags: IDictionary<string>) {
        this._flags = flags;
    }

    getBool(name: string): boolean | undefined {
        const value = this._flags[name];
        if (value === 'True' || value === 'true'
            || value === 'Yes' || value === 'yes'
            || value === 'On' || value === 'on'
            || value === '1'
        ) return true;

        if (value === 'False' || value === 'false'
            || value === 'No' || value === 'No'
            || value === 'Off' || value === 'off'
            || value === '0'
        ) return false;

        return undefined;
    }

    isNewSsoFlow(): boolean {
        return this.getBool(KnownFlagNames.NewSsoFlow) ?? false;
    }
}

export const Flags = new FlagsUtil();