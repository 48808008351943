export class ConsumptionForecastModel {
  forecastDay!: Date;
  period!: number;
  measureUnit!: number;
  applyFinalForecast!: boolean;
  predictionData!: PredictionModel[];
  unitNo!: number;
  groupId!: number;
  userId!: number;
  interval!: number;
}

export class PredictionModel {
  deliveryStart!: Date;
  value!: number;
  providerKey!: string;
}

export class GetConsumptionForecastsQueryModel {
  public unitType!: string;
  public unitNo!: number;
  public providerKey!: string;
  public from!: Date;
  public to!: Date;
  public period!: number;
  public groupId!: number;
  public interval: number = 1;
}

export class GetMultipleConsumptionForecastsQueryByFacilityDto {
  public unitType!: number;
  // public unitNoList: number[] = [];
  public unitNoWithProviderKey: UnitNoWithProviderKeyModel[] = [];
  public providerKey!: string;
  public from!: Date;
  public to!: Date;
  public period!: number;
  public groupId!: number;
  public interval: number = 1;
}

export class DeleteConsumptionFacilityDto {
  public id!: number;
  public userId!: number;
}

export class ExportExcelModel {
  public startDate!: Date;
  public endDate!: Date;
  public predictionKey!: string;
  public unitNoList: number[] = [];
  public saveAsGop: boolean = false;
  public saveAsFinalForecast: boolean = false;
  public addFinalForecast: boolean = false;
}

export class UnitNoWithProviderKeyModel {
  public unitNo!: number;
  public providerKey!: string;
}

export class GetConsumptionForecastRequest {
  public fromDate!: string;
  public referenceDate!: string;
  public showReferenceDate!: boolean;
  public groupId!: number;
  public userId!: number;
  public interval!: number;
  public period!: number;
  public unitType!: number;
  public units: UnitNoProviderKeyDto[] = [];
}

export class UnitNoProviderKeyDto {
  public unitNo!: number;
  public providerKey!: string;
}

export class GetConsumptionProvidersRequest {
  public units: UnitNoProviderKeyDto[] = [];
}

export class SaveConsumptionForecastResponse {
  public unitType!: string;
  public unitNo!: number;
  public providerKey!: string;
  public total!: number;
  public isUpdated!: boolean;
  public forecasts: ForecastDto[] = [];
  public references: GetForecastReferenceDto[] = [];
}

export class ForecastDto {
  public isUpdated!: boolean;
  public deliveryStart!: Date;
  public deliveryEnd!: Date;
  public deliveryStartOffset!: number;
  public deliveryEndOffset!: number;
  public value!: number;
  public locked!: boolean;
}

export class GetForecastReferenceDto {
  public deliveryStart!: Date;
  public deliveryEnd!: Date;
  public deliveryStartOffset!: number;
  public deliveryEndOffset!: number;
  public value!: number;
}

export class SaveConsumptionForecastRequest {
  public groupId!: number;
  public userId!: number;
  public period!: number;
  public interval!: number;
  public forecastDataList: SaveConsumptionForecastData[] = [];
}

export class SaveConsumptionForecastData {
  public unitType!: string;
  public unitNo!: number;
  public providerKey!: string;
  public isUpdated: boolean = false;
  public forecastDay!: string;
  public forecasts: ForecastDto[] = [];
}
