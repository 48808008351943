export enum WidgetSettingsEnum {
   SelectedColumnsKey = "intraday_selectedColumnIds",
   SelectedFiltersKey = "intraday_selectedFilters",
   DepthWidgetViewOptionsKey = "selected_depthWidgetViewOptionsKey",
   DepthWidgetHideGraphKey = "selected_depthWidgetHideGraphKey",
   HistoryWidgetHourlyViewOptionsKey = "selected_historyWidgetHourlyViewOptionsKey",
   HistoryWidgetHistoryViewOptionsKey = "selected_historyWidgetHistoryViewOptionsKey",
   RowPaginationKey = "selected_rowPaginationKey",
   ContractSelectOptionKey = "selected_contractInfo",
   HistoryWidgetGridViewOptionsKey = "selected_historyWidgetGridViewOptionsKey",
}
