// noinspection SpellCheckingInspection

import globalMessages from "./messages.global.json";
import optionsMessages from "./messages.options.json";
import scheduleMessages from "./messages.schedule.json";
import epiasMessages from "./messages.epias.json";
import alertingMessages from "./messages.alerting.json";
import alertingflowMessages from "./messages.alertingflow.json";
import buttontitleMessages from "./messages.buttontitle.json";
import productionpredictionnotsaveMessages from "./messages.productionpredictionnotsave.json";
import timercanbeselectedaloneMessages from "./messages.timercanbeselectedalone.json";
import manuelcanbeselectedaloneMessages from "./messages.manuelcanbeselectedalone.json";
import divMessages from "./messages.div.json";
import golbalMessages from "./messages.golbal.json";
import loginMessages from "./messages.login.json";
import menuMessages from "./messages.menu.json";
import homeMessages from "./messages.home.json";
import intradayplanningMessages from "./messages.intradayplanning.json";
import intradayplaninngMessages from "./messages.intradayplaninng.json";
import bulkofferMessages from "./messages.bulkoffer.json";
import smartbotMessages from "./messages.smartbot.json";
import dynamiccolumnsMessages from "./messages.dynamiccolumns.json";
import dataterminalMessages from "./messages.dataterminal.json";
import systemstatusMessages from "./messages.systemstatus.json";
import schedulerMessages from "./messages.scheduler.json";
import systemhealthMessages from "./messages.systemhealth.json";
import customeronboardingMessages from "./messages.customeronboarding.json";
import tableMessages from "./messages.table.json";
import pleaseselectmaxtwoitemsMessages from "./messages.pleaseselectmaxtwoitems.json";
import pleaseselectitemMessages from "./messages.pleaseselectitem.json";
import mustselectforsaveMessages from "./messages.mustselectforsave.json";
import screentimeoutMessages from "./messages.screentimeout.json";
import licensingMessages from "./messages.licensing.json";
import uploadfileMessages from "./messages.uploadfile.json";
import contractMessages from "./messages.contract.json";
import dayaheadnominationMessages from "./messages.dayaheadnomination.json";
import damplanningMessages from "./messages.damplanning.json";
import goppredictionMessages from "./messages.gopprediction.json";
import forecastMessages from "./messages.forecast.json";
import instantofferMessages from "./messages.instantoffer.json";
import powerplanthourlyquestionMessages from "./messages.powerplanthourlyquestion.json";
import powerplantlimitsMessages from "./messages.powerplantlimits.json";
import usermanagementMessages from "./messages.usermanagement.json";
import kguprevisionMessages from "./messages.kguprevision.json";
import definecompaniesMessages from "./messages.definecompanies.json";
import pfkofferdataMessages from "./messages.pfkofferdata.json";
import pfknominationMessages from "./messages.pfknomination.json";
import sfkofferdataMessages from "./messages.sfkofferdata.json";
import dgpofferMessages from "./messages.dgpoffer.json";
import extrapositionupdateMessages from "./messages.extrapositionupdate.json";
import powerplantbalancinginfoMessages from "./messages.powerplantbalancinginfo.json";
import hotjarMessages from "./messages.hotjar.json";
import planttrackingMessages from "./messages.planttracking.json";
import profileMessages from "./messages.profile.json";
import gqlMessages from "./messages.gql.json";
import gopplanningMessages from "./messages.gopplanning.json";
import sfkfinalobligationMessages from "./messages.sfkfinalobligation.json";
import pfkfinalobligationMessages from "./messages.pfkfinalobligation.json";
import autosendingplantMessages from "./messages.autosendingplant.json";
import autosendingcompanyMessages from "./messages.autosendingcompany.json";
import updatedataMessages from "./messages.updatedata.json";
import templateMessages from "./messages.template.json";
import passwordMessages from "./messages.password.json";
import ftpMessages from "./messages.ftp.json";
import ftpfileeditorMessages from "./messages.ftpfileeditor.json";
import errorsMessages from "./messages.errors.json";
import idleMessages from "./messages.idle.json";
import idlelogoutMessages from "./messages.idlelogout.json";
import errorMessages from "./messages.error.json";
import externalMessages from "./messages.external.json";
import kgupsendingMessages from "./messages.kgupsending.json";
import eaksendingMessages from "./messages.eaksending.json";
import groupmanagementMessages from "./messages.groupmanagement.json";
import bilateralagreementMessages from "./messages.bilateralagreement.json";
import fileMessages from "./messages.file.json";
import expertpredictionMessages from "./messages.expertprediction.json";
import aggreementeditorMessages from "./messages.aggreementeditor.json";
import pricesetdefinitionMessages from "./messages.pricesetdefinition.json";
import portfoliomanagementMessages from "./messages.portfoliomanagement.json";
import gipkrsMessages from "./messages.gipkrs.json";
import dgpinstructiontracingMessages from "./messages.dgpinstructiontracing.json";
import intradaylimitMessages from "./messages.intradaylimit.json";
import gipkrssettingsMessages from "./messages.gipkrssettings.json";
import newintradayMessages from "./messages.newintraday.json";
import intradayMessages from "./messages.intraday.json";
import gloalMessages from "./messages.gloal.json";
import servicerunlogMessages from "./messages.servicerunlog.json";
import omiemarketservicesMessages from "./messages.omiemarketservices.json";
import gipplannningMessages from "./messages.gipplannning.json";
import intradayplanning_newMessages from "./messages.intradayplanning_new.json";
const messages = {
	global: globalMessages,
	options: optionsMessages,
	schedule: scheduleMessages,
	epias: epiasMessages,
	alerting: alertingMessages,
	alertingflow: alertingflowMessages,
	buttontitle: buttontitleMessages,
	productionpredictionnotsave: productionpredictionnotsaveMessages,
	timercanbeselectedalone: timercanbeselectedaloneMessages,
	manuelcanbeselectedalone: manuelcanbeselectedaloneMessages,
	div: divMessages,
	golbal: golbalMessages,
	login: loginMessages,
	menu: menuMessages,
	home: homeMessages,
	intradayplanning: intradayplanningMessages,
	intradayplaninng: intradayplaninngMessages,
	bulkoffer: bulkofferMessages,
	smartbot: smartbotMessages,
	dynamiccolumns: dynamiccolumnsMessages,
	dataterminal: dataterminalMessages,
	systemstatus: systemstatusMessages,
	scheduler: schedulerMessages,
	systemhealth: systemhealthMessages,
	customeronboarding: customeronboardingMessages,
	table: tableMessages,
	pleaseselectmaxtwoitems: pleaseselectmaxtwoitemsMessages,
	pleaseselectitem: pleaseselectitemMessages,
	mustselectforsave: mustselectforsaveMessages,
	screentimeout: screentimeoutMessages,
	licensing: licensingMessages,
	uploadfile: uploadfileMessages,
	contract: contractMessages,
	dayaheadnomination: dayaheadnominationMessages,
	damplanning: damplanningMessages,
	gopprediction: goppredictionMessages,
	forecast: forecastMessages,
	instantoffer: instantofferMessages,
	powerplanthourlyquestion: powerplanthourlyquestionMessages,
	powerplantlimits: powerplantlimitsMessages,
	usermanagement: usermanagementMessages,
	kguprevision: kguprevisionMessages,
	definecompanies: definecompaniesMessages,
	pfkofferdata: pfkofferdataMessages,
	pfknomination: pfknominationMessages,
	sfkofferdata: sfkofferdataMessages,
	dgpoffer: dgpofferMessages,
	extrapositionupdate: extrapositionupdateMessages,
	powerplantbalancinginfo: powerplantbalancinginfoMessages,
	hotjar: hotjarMessages,
	planttracking: planttrackingMessages,
	profile: profileMessages,
	gql: gqlMessages,
	gopplanning: gopplanningMessages,
	sfkfinalobligation: sfkfinalobligationMessages,
	pfkfinalobligation: pfkfinalobligationMessages,
	autosendingplant: autosendingplantMessages,
	autosendingcompany: autosendingcompanyMessages,
	updatedata: updatedataMessages,
	template: templateMessages,
	password: passwordMessages,
	ftp: ftpMessages,
	ftpfileeditor: ftpfileeditorMessages,
	errors: errorsMessages,
	idle: idleMessages,
	idlelogout: idlelogoutMessages,
	error: errorMessages,
	external: externalMessages,
	kgupsending: kgupsendingMessages,
	eaksending: eaksendingMessages,
	groupmanagement: groupmanagementMessages,
	bilateralagreement: bilateralagreementMessages,
	file: fileMessages,
	expertprediction: expertpredictionMessages,
	aggreementeditor: aggreementeditorMessages,
	pricesetdefinition: pricesetdefinitionMessages,
	portfoliomanagement: portfoliomanagementMessages,
	gipkrs: gipkrsMessages,
	dgpinstructiontracing: dgpinstructiontracingMessages,
	intradaylimit: intradaylimitMessages,
	gipkrssettings: gipkrssettingsMessages,
	newintraday: newintradayMessages,
	intraday: intradayMessages,
	gloal: gloalMessages,
	servicerunlog: servicerunlogMessages,
	omiemarketservices: omiemarketservicesMessages,
	gipplannning: gipplannningMessages,
	intradayplanning_new: intradayplanning_newMessages
};

export default messages;