import { createSlice } from '@reduxjs/toolkit';

const intradaySettingsSlice = createSlice({
    name: 'intradaySettingsData',
    initialState: { intradaySettingsData: {} },
    reducers: {
        setIntradaySettings(state, action) {
            state.intradaySettingsData = action.payload;
        },
        clearIntradaySettings(state) {
            state.intradaySettingsData = {}
        }
    }
});

const { actions, reducer } = intradaySettingsSlice;

export const { setIntradaySettings, clearIntradaySettings } = actions;

export default reducer;