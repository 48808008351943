const defaultValues = {
  isAutoUpdateEnabled: false,
};

const planttracking = (state = defaultValues , action) => {
  switch (action.type) { 
      case 'SET_AUTOUPDATE':
          return {...state, isAutoUpdateEnabled: action.isAutoUpdateEnabled};
      default:
          return state;
  }
};

export default planttracking;