const defaultValues = {
    ParamDate: new Date(1980, 1, 1),
    ParamCompanyId: 0,
    ParamPowerPlantId: 0,
    WeatherSourceDate: new Date(1980, 1, 1),
    WeatherSourcePowerPlantId: 0,
    WeatherSources: []
};

const viewWeatherSource  = (state = defaultValues , action) => {
    switch (action.type) {
        case 'SET_WEATHER_SOURCE_PARAM_DATE':
            return {...state, ParamDate: action.date};
        case 'SET_WEATHER_SOURCE_PARAM_COMPANY':
            return {...state, ParamCompanyId: action.companyId};
        case 'SET_WEATHER_SOURCE_PARAM_POWER_PLANT':
            return {...state, ParamPowerPlantId: action.powerPlantId};
        case 'HANDLE_GET_WEATHER_SOURCE_RESPONSE':
            return {
                ...state, 
                WeatherSourceDate: action.startDateTime,
                WeatherSourcePowerPlantId: action.powerPlantId, 
                WeatherSources: action.WeatherSources
            };
        default:
            return state;
    }
};

export default viewWeatherSource;