import { ListSubheader, Stack, ListItemIcon, ListItemText, Divider } from "@mui/material";
import { injectIntl } from "react-intl";
import { BaseComponent } from "../../baseComponent";

interface SubheaderProps {
    icon: any;
    text: any;
}

class Subheader extends BaseComponent<SubheaderProps, {}> {
    render() {
        return (
            <ListSubheader sx={{ p: 0 }}>
                <Stack direction="row" alignItems="center" justifyContent="center" padding={2}>
                    <ListItemIcon>{this.props.icon}</ListItemIcon>
                    <ListItemText>{this.props.text}</ListItemText>
                </Stack>
                <Divider variant="middle" />
            </ListSubheader>
        );
    }
}

export default injectIntl(Subheader);
