import { BaseReply } from "src/old/src/base/ServiceBase";
import { UserProfileDataModel, UserProfileModel } from "../../models/user-profile.model";
import baseService from "../base.service";
import { IUserProfileData } from "src/models/profile/IUserProfileData";
import { AxiosResponse } from "axios";
import * as Utils from "src/utils";

export const getTimeZoneList = () => {
    return baseService
        .post("/Configuration/GetTimeZoneDefinitions")
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
};

export const getUserProfile = () => {
    return baseService
        .post("/Configuration/GetUserProfile")
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
};

export const saveUserProfileData = (data: UserProfileDataModel[]) => {
    return baseService
        .post("Configuration/SetUserProfileData", data)
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        })
}

export const postUserProfile = (data: UserProfileModel) => {
    return baseService
        .post("/Configuration/SetUserProfile", data)
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
};

export const getUserProfileDataByKey = async (key: string) => {
    const url = `/Configuration/GetUserProfileDataByKey/${key}`;
    const response = await baseService.get<
        BaseReply<IUserProfileData | null>,
        AxiosResponse<BaseReply<IUserProfileData | null>>,
        null
    >(url);
    const data = Utils.replyPropsConverter(response.data);
    return data;
};

export const setUserProfileDataByKey = async (data: IUserProfileData) => {
    const url = "/Configuration/SetUserProfileDataByKey";
    const response = await baseService.post<BaseReply, AxiosResponse<BaseReply>, IUserProfileData>(url, data);
    return response.data;
};
