const defaultValues = {};

const gopplanning = (state = defaultValues, action) => {
    switch (action.type) {
        case 'SET_PLANNING_FILTER_CONTEXT':
            return {...state, filterContext: action.filterContext};
        case "SET_PLANNING_HOURLY_VERSION":
            return {...state, hourlyOfferCurrentVersion: action.version};
        case "SET_PLANNING_BLOCK_VERSION":
            return {...state, blockOfferCurrentVersion: action.version};
        case "SET_PLANNING_FLEXIBLE_VERSION":
            return {...state, flexibleOfferCurrentVersion: action.version};
        default:
            return state
    }
};

export default gopplanning;