export enum ETickerType {
  IdmMessage = 'IdmMessage',
  GlobalError = 'GlobalError',
  MCP = 'MCP',
  SystemDirection = 'SystemDirection',
  NetPosition = 'NetPosition',
  BiliteralAggreementQuantity = 'BiliteralAggreementQuantity',
  Loading = 'Loading',
  Deloading = 'Deloading',
  DayAheadDefaultQuantity = 'DayAheadDefaultQuantity',
  DayAheadBlockQuantity = 'DayAheadBlockQuantity',
  IntradayDefaultQuantity = 'IntradayDefaultQuantity',
  IntradayBlockQuantity = 'IntradayBlockQuantity',
  ProductionPrediction = 'ProductionPrediction',
  ActualProduction = 'ActualProduction',
  RetailQuantity = 'RetailQuantity',
  BuyContractMyPrice = 'BuyContractMyPrice',
  BuyContractBestQuantity = 'BuyContractBestQuantity',
  BuyContractBestPrice = 'BuyContractBestPrice',
  SellContractMyPrice = 'SellContractMyPrice',
  SellContractBestQuantity = 'SellContractBestQuantity',
  SellContractBestPrice = 'SellContractBestPrice',
  TradeLogs = 'TradeLogs',
  SmartBot = 'SmartBot',
}
