import { EWidgetType } from 'src/enums/widget-type.enum';

type ResizeHandle = "s" | "w" | "e" | "n" | "sw" | "nw" | "se" | "ne";

export class GridWorkspaceModel {
  id!:string;
  name!: string;
  isDefault!: boolean;
  layouts!: GridLayoutModel[];
  /**
   * **YYYY-MM-DDTHH:mm:ssZ**
   */
  createdDate!: string;
}

export class GridLayoutModel {
  i!: string;
  x!: number;
  y!: number;
  w!: number;
  h!: number;
  // type!: string | undefined;
  // widgetType!: EWidgetType;
  widgets!: GridWidgetModel[];
  isShowTabs?: boolean;
  static!: boolean | undefined;
  isDraggable!: boolean;
  isResizable!: boolean;
  isBounded!: boolean | undefined;
  minW!: number | undefined;
  minH!: number | undefined;
  maxW!: number | undefined;
  maxH!: number | undefined;
  resizeHandles!: ResizeHandle[] | undefined;
  moved!: boolean | undefined;
}

export class GridWidgetModel {
  id!: string;
  widgetType!: EWidgetType;
  name?: string;
  order!: number;
  isBaseWidget!: boolean;
  settings?: GridWidgetSettingsModel;
  isOpenPopUp?: boolean;
}

export class GridWidgetSettingsModel {
  [id: string] : any;
}
