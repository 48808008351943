import React, {CSSProperties} from "react";
import {FormattedMessage, injectIntl, WrappedComponentProps} from "react-intl";
import SmartPulseSelectWithSearch from "./SmartPulseSelectWithSearch";
import {withStyles} from "@mui/styles";
import {ICompany} from "../../base/BaseTypes";
import {useStyles} from "./Styles";
import { Tooltip } from "@mui/material";

export type ISmartPulseCompanyPickerOptions = {
    companies: ICompany[],
    selectedCompanyId?: number,
    onChange?: (selected: ICompany | undefined) => void
    style?: CSSProperties,
    allowAllCompanies?: boolean,
    label?: string,
    isDisabled?: boolean
};

interface ISmartPulseCompanyPickerState {
    selectedCompanyId?: number,
    selectedCompanyName: string
}

class SmartPulseCompanyPicker extends React.PureComponent<ISmartPulseCompanyPickerOptions & WrappedComponentProps, ISmartPulseCompanyPickerState> {
    readonly allCompaniesItemValue: number = -1;

    constructor(props: ISmartPulseCompanyPickerOptions & WrappedComponentProps) {
        super(props);

        const {selectedCompanyId} = props;
        let selectedCompanyName: string =
            this.findCompanyById(props.selectedCompanyId ?? this.allCompaniesItemValue)?.fullname ?? "";

        this.state = {
            selectedCompanyId,
            selectedCompanyName
        };
    }

    findCompanyById(companyId: number): ICompany | undefined {
        if (companyId === this.allCompaniesItemValue && this.props.allowAllCompanies) {
            const entry = this.getAllCompaniesEntry();

            return {
                id: entry.value,
                fullname: entry.label,
                name: entry.label,
                powerplants: []
            }
        }

        return this.props.companies.find(company => company.id === companyId);
    }

    componentDidUpdate(
        prevProps: Readonly<ISmartPulseCompanyPickerOptions & WrappedComponentProps>,
        prevState: Readonly<ISmartPulseCompanyPickerState>,
        snapshot?: any) {

        const restorePrevSelectionCompany = this.props.selectedCompanyId !== prevProps.selectedCompanyId ||
            // Seçim sonrası bir önceki seçime dönülmesi gereken durumlar için..
            (this.props.selectedCompanyId === prevProps.selectedCompanyId &&
               this.state.selectedCompanyId !== this.props.selectedCompanyId);

        if (restorePrevSelectionCompany) {
            let selectedCompanyName: string =
                this.findCompanyById(this.props.selectedCompanyId ?? this.allCompaniesItemValue)?.fullname ?? "";

            this.setState({
                selectedCompanyId: this.props.selectedCompanyId,
                selectedCompanyName: selectedCompanyName
            })
        }
    }

    private getAllCompaniesEntry = (): { value: number, label: string } => {
        const {formatMessage} = this.props.intl;

        const entry = ({
            value: this.allCompaniesItemValue,
            label: formatMessage({id: "global.allcompanies", defaultMessage: "All Companies"})
        });

        return entry;
    }

    render() {
        const {allowAllCompanies = false} = this.props;

        let companyList = this.props.companies.map(company => ({
            value: company.id,
            label: company.fullname
        }));
        if (allowAllCompanies)
            companyList.unshift(this.getAllCompaniesEntry());

        return (
            <Tooltip title={this.state.selectedCompanyName.length > 25 ? this.state.selectedCompanyName : "" }>
                <div>
                    <SmartPulseSelectWithSearch
                        multiple={false}
                        Label={<FormattedMessage id="global.company" defaultMessage="Company"/>}
                        style={this.props.style}
                        disableClear={true}
                        options={companyList}
                        value={{
                            value: this.state.selectedCompanyId,
                            label: this.state.selectedCompanyName
                        }}
                        label={this.props.label}
                        onChange={(values: any) => {
                            const allCorpItem = this.getAllCompaniesEntry();
                            this.setState({
                                    selectedCompanyId: values?.value ?? allCorpItem.value,
                                    selectedCompanyName: values?.label ?? allCorpItem.label
                                },
                                () => {
                                    if (this.props.onChange) {
                                        let currentCompany: (ICompany | undefined);
                                        if (values?.value || values?.value !== this.allCompaniesItemValue) {
                                            currentCompany = this.findCompanyById(values?.value);
                                        }
                                        this.props.onChange(currentCompany);
                                    }
                                }
                            );
                        }}
                        isDisabled={this.props.isDisabled}
                    />
                </div>
            </Tooltip>
        );
    }

}


export default withStyles(useStyles, {withTheme: true})(injectIntl(SmartPulseCompanyPicker));