// components
import SvgIconStyle from '../../../components/SvgIconStyle';
import HomeIcon from '@mui/icons-material/Home'

// ----------------------------------------------------------------------

const getIcon = (name: string) => (
  <SvgIconStyle src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
  user: getIcon('ic_user'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  home: getIcon('ic_home')
};

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: null,
    items: [
      { title: 'menu.app.text.homepage', path: '/home', icon: <HomeIcon />, tooltip: "menu.app.title.homepage" }
    ],
  }
];

export default navConfig;
