const defaultValues = {
    MkudDate: new Date(1980,1,1), 
    MkudDataRows:[],
    MkudData:[],
    statu:false,
    selectedId:0
};

const mkudsending  = (state = defaultValues , action) => {
     switch (action.type) { 
        case 'SET_STATUS':
            return {...state, status: action.status};
        case 'SET_SELECTED_ID':
            return {...state, selectedId: action.selectedId};
        case 'HANDLE_GET_MKUD_RESPONSE':
            return {...state, MkudDataRows: action.rows,MkudData:action.MkudData, plants: action.plants};
        case 'SET_MKUD_DATE':
            return {...state, MkudDate: action.date};   
        case 'HANDLE_SAVE_MKUD_RESPONSE':
            return {...state, saveOk: action.reply.saveOk};
        case 'HANDLE_SAVE_SEND_MKUD_RESPONSE':
            return {...state, saveOk: action.reply.saveOk};
        default:
            return state;
    }
}

export default mkudsending;