export const extendedPositionThreshold = 1600;
export const pingerInterval = 60000;
export const webSocketReopenTime = 10000;
export const tickerLimit = 500;
export const tickerDeleteAmount = 150;

export const serverDateTimeFormat = 'YYYY-MM-DDTHH:mm:ss';
export const dateRangeDateFormat = 'DD.MM.YY HH:mm';
export const keepAliveSubject = 'ping';
export const gipPlanningWSSubject = 'walldata';
export const selectContractSubject = 'selectcontract';
export const productionForeCastWSSubject = 'powerplantdata';
export const tickerSubject = 'ticker,version-listeners';

export const intradayWebSocketUrl = '/api/RealtimeGip?compress=permessagedeflate';
export const intradayPlanningSocketHandlerName = 'smartpulse/v2/intradayplanning';
export const intradayPlanningContractDetailSocketHandlerName =
  'smartpulse/v2/intradayplanningcontract';
export const keepAliveTo = 'smartpulse/pinglistener';
export const tickerTo = 'volt/alerting';

export const services: Readonly<Record<string, string>> = {
  Omie: 'Omie',
  Epias: 'Epias',
  Nordpool: 'Nordpool',
  MSeven: 'MSeven',
};

export const messages = {
  checkConnection: {
    id: 'global.warning.checkconnection',
    defaultMessage:
      'The request is not sent due to deactivated connection. Please check your connection and try again.',
  },
  connectionError: {
    id: 'global.warning.connectionerror',
    defaultMessage: 'Connection error! Working on the solution.',
  },
  showAllDataOfCompanies: {
    id: 'intradayplanning.showalldataofcompanies',
    defaultMessage: 'Display the data for all the companies within the group.',
  },
  noConnection: {
    id: 'global.warning.noconnection',
    defaultMessage: 'No Connection',
  },
  connecting: {
    id: 'global.warning.connecting',
    defaultMessage: 'Connecting',
  },
  connected: {
    id: 'global.warning.connected',
    defaultMessage: 'Connected',
  },
  systemhealthproblem: {
    id: 'global.warning.systemhealthproblem',
    defaultMessage: 'System Health',
  },
  forecast: {
    id: 'global.forecast',
    defaultMessage: 'Forecast',
  },
  realized: {
    id: 'global.realized',
    defaultMessage: 'Realized',
  },
  fdpp: {
    id: 'global.fdpp',
    defaultMessage: 'FDPP',
  },
  delta: {
    id: 'global.delta',
    defaultMessage: 'Delta',
  },
  fdppdelta: {
    id: 'intradayplanning.fdppdelta',
    defaultMessage: 'FDPP Delta',
  },
  marketInformation: {
    id: 'intradayplanning.marketinformation',
    defaultMessage: 'Market Information',
  },
  systemDirection: {
    id: 'intradayplanning.systemdirection',
    defaultMessage: 'System Direction',
  },
  bid: {
    id: 'global.bid',
    defaultMessage: 'Bid',
  },
  ask: {
    id: 'global.ask',
    defaultMessage: 'Ask',
  },
  mcp: {
    id: 'global.mcp',
    defaultMessage: 'MCP',
  },
  price: {
    id: 'global.price',
    defaultMessage: 'Price',
  },
  amount: {
    id: 'global.amount',
    defaultMessage: 'Amount',
  },
  hour: {
    id: 'global.hour',
    defaultMessage: 'Hour',
  },
  waitRefresh: {
    id: 'intradayplanning.waitrefresh',
    defaultMessage: 'Please wait {seconds} seconds before this operation.',
  },
  waitrefreshtip: {
    id: 'intradayplanning.waitrefreshtip',
    defaultMessage: 'Refresh dynamic columns',
  },
  dephtooltip: {
    id: 'global.depthTooltip',
    defaultMessage:
      'The boxes in the depth display show the volumes of 10 MWh and the distance of the prices of these volumes from the MCP. The details of the price ranges are reflected on the screen when the boxes are hovered.',
  },
};

export const colors = {};

export const chartOptions = {
  responsive: true,
  maintainAspectRatio: true,
  animation: {
    duration: 0,
  },
  hover: {
    animationDuration: 0,
  },
  responsiveAnimationDuration: 0,
  legend: {
    display: false,
  },
};

export const rgbToHex = (r: number, g: number, b: number) =>
  '#' +
  [r, g, b]
    .map((x) => {
      const hex = x.toString(16);
      return hex.length === 1 ? '0' + hex : hex;
    })
    .join('');

export const generateInstrumentRowId = (instrument: string) => {
  return 'intradayrow_' + instrument.replaceAll(':', '_');
};

//https://stackoverflow.com/a/2117523/6453371
export const uuidv4 = () => {
  return (([1e7] as any) + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c: any) =>
    (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16)
  );
};

export enum ProposalStatus {
  /** NONE */ NONE = 1,
  /** ACTIVE */ A = 2,
  /** PASSIVE */ P = 4,
  /** HIBERNATED */ H = 5,
  /** MATCHED (FINALIZED) */ M = 8,
  /** PARTIALLY MATCHED */ PM = 16,
  /** CANCELLED (FINALIZED) */ C = 32,
  /** EXPIRED (FINALIZED) */ E = 64,
  /** REALIZED (FINALIZED) */ R = 128,
  /** REJECTED (FINALIZED) */ REJ = 256,
}

export enum LevelStatus {
  /** ACTIVE */ A = 2,
  /** PASSIVE */ P = 4,
  /** MATCHED (FINALIZED) */ M = 8,
  /** EXPIRED (FINALIZED) */ E = 16,
}

export enum ExecutionCondition {
  /** NONE */ None = 0,
  /** IMMIDIATE OR CANCEL */ IoC = 1,
  /** FILL OR KILL */ FoK = 2,
}

export enum ProposalTradeType {
  Unknown = 0,
  Buy = 2,
  Sell = 4,
}

export enum ProposalOptionType {
  Normal = 0,
  PriceLeveled = 1,
  TimeLeveled = 2,
  Iceberg = 3,
  IOC = 4,
  FOK = 5,
}
