import React from "react";

import { injectIntl, MessageDescriptor, WrappedComponentProps } from "react-intl";

import {
    Stack,
    Snackbar,
    Alert,
    Slide
} from "@mui/material";

import PopUp from "./PopUp";

var _messageCounter = 0;

export enum Severity {
    error = "error",
    warning = "warning",
    info = "info",
    success = "success"
}

export type ShowUserMessage = (message: string | MessageDescriptor, severity: Severity|number) => void;

interface IUserMessage {
    messageText: string | null;
    open: boolean;
    severity: Severity;
    key: string;
    showedTime: Date;
}

export type ApplicationContextComponentState = {
    userMessages: IUserMessage[];
    showUserMessage: ShowUserMessage;
    showSuccessPopupMessage: (message: string | MessageDescriptor) => void;
    showInformationPopupMessage: (message: string | MessageDescriptor) => void;
    showWarningPopupMessage: (message: string | MessageDescriptor) => void;
    showErrorPopupMessage: (message: string | MessageDescriptor) => void;
};

type ApplicationContextComponentProps = {

}

export const ApplicationContext = React.createContext<ApplicationContextComponentState>({
    userMessages: [],
    showUserMessage: (message: string | MessageDescriptor, severity: Severity|number = Severity.info) => {}
});

const numberToSeverity = (severity: number) => {
    switch (severity) {
        case 4:
            return Severity.error;
        case 3:
            return Severity.warning;
        case 2:
            return Severity.info;
        case 1:
            return Severity.success;
        default:
            return Severity.info;
    }
}
  
class ApplicationContextProvider extends React.PureComponent<ApplicationContextComponentProps & WrappedComponentProps, ApplicationContextComponentState> {
  // private refMessages: React.RefObject<HTMLInputElement>;

    // constructor(props:any) {
    //     super(props);
    //     this.refMessages = React.createRef();
    //  //   const ref = React.createRef();

    // }


    static defaultProps: ApplicationContextComponentProps = {
    }

    state: ApplicationContextComponentState = {
        userMessages: [],
        showUserMessage: this.showUserMessage.bind(this),
    }

    getMessageId() : string {
        _messageCounter++;

        return `message_${_messageCounter}`;
    }

    getResourceString(resourceId: string | MessageDescriptor) {
        const { formatMessage } = this.props.intl;

        if (typeof resourceId === "string") {
            return formatMessage({id: resourceId as string, defaultMessage: resourceId as string});
        }else {
            return formatMessage(resourceId);
        }
    }

    showUserMessage(message: string | MessageDescriptor, severity: Severity|number = Severity.info) {
        let severityType = Severity.info;
        if(typeof severity === "number"){
            severityType = numberToSeverity(severity);
        }else{
            severityType = severity;
        }
        

        const newMessage : IUserMessage = {
            key: this.getMessageId(),
            severity: severityType,
            messageText: this.getResourceString(message),
            showedTime: new Date(),
            open: true
        }
        const newMessages = this.state.userMessages.filter(e => e.open).map(e => e);

        newMessages.push(newMessage);
        this.setState({ userMessages: newMessages });
    }



    showSuccessPopupMessage = (message) => {
        this.showUserMessage(message, 1);
    }

    showInformationPopupMessage = (message) => {
        this.showUserMessage(message, 2);
    }

    showWarningPopupMessage = (message) => {
        this.showUserMessage(message, 3);
    }

    showErrorPopupMessage = (message) => {
        this.showUserMessage(message, 4);
    }

    render() {
    
        return (<ApplicationContext.Provider 
            value={{
                ...this.state, showUserMessage: this.showUserMessage.bind(this)
            }}>
            {this.props.children}

            <PopUp messages={this.state.userMessages}/>

        </ApplicationContext.Provider>);
    }
}

export default injectIntl(ApplicationContextProvider);