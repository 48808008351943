


export class API {



    static PostAPI(JSONdata: any, Action: string, first: boolean = false, last: boolean = false) {
        const nodeEnv = import.meta.env.MODE;
        const isDevelopment = nodeEnv === "development";
        if (!first)
            $("#loader").addClass("whirl");
        const actionUrl = (isDevelopment?'/s/':'') + Action;
        const defer = $.Deferred();
        const jqxhr = $.ajax({
            url: actionUrl,
            contentType: 'application/json; charset=utf-8',
            dataType: 'json',
            xhrFields: {
                withCredentials: true
            },
            type: "POST",
            crossDomain: true,
            cache: false,
            data: JSON.stringify(JSONdata)
        }).done((data: any) => {
            if (!last)
                $("#loader").removeClass("whirl");
            if (data.isError) {
                if (data.errorCode) {
                    defer.reject({ message: data.ErrorMessage || data.errorMessage, code: data.errorCode, args: data.errorArguments });
                } else if (data.replyObject || data.ReplyObject) {
                    defer.reject({
                        message: data.ErrorMessage || data.errorMessage, code: data.errorCode,
                        args: data.errorArguments, errorInfo: data.replyObject || data.ReplyObject
                    });
                } else {
                    defer.reject(data.ErrorMessage || data.errorMessage);
                }

                //ShowMessage(data.ErrorMessage);
                //defer.reject(data.ErrorMessage || data.errorMessage);
            } else {
                defer.resolve(data);
            }
        }).fail((data: any) => {
            if (!last)
                $("#loader").removeClass("whirl");
            defer.reject(data);
        });
        defer.promise(jqxhr);

        return defer;
    }

}