import axios from 'axios';
import { Constants } from '../constants';
const nodeEnv = import.meta.env.MODE;
const isProduction = nodeEnv === "production";
const baseService = axios.create({
    baseURL: isProduction?'':'/s/',
    timeout: Constants.requests.timeout,
    headers: {
        "content-type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept"
    },
    withCredentials: true
})

baseService.interceptors.request.use(
    config => {
        if (config.headers !== undefined) {
            const token = localStorage.getItem("token");
            config.headers.Authorization = `bearer ${token}`;
        }
        return config;
    },
    error => {
        Promise.reject(error);
    }
);

baseService.interceptors.response.use(response => {
    return response;
}, error => {
    if (error.response?.status === 401) {
        // TODO unauthorised access, do something

    }
    return error;
});

export default baseService;