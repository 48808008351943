// @mui
import { Box, Card, Container, FormControlLabel, Stack, Switch, Typography } from '@mui/material';

// components
import Page from '../../components/Page';
import { useEffect } from 'react';
import * as Utils from '../../utils'

import 'dockview/dist/styles/dockview.css';

// ----------------------------------------------------------------------

import {
    DockviewReact,
    DockviewReadyEvent,
    PanelCollection,
    IDockviewPanelProps,
    IDockviewPanelHeaderProps,
} from 'dockview';
import useLocales from '../../hooks/useLocales';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';

const DockView = () => {

    const { translate } = useLocales();
    const themeStretch = false

    const components: PanelCollection<IDockviewPanelProps> = {
        default: (props: IDockviewPanelProps<{ someProps: string }>) => {
            return <div style={{ color: 'white' }}>{props.params.someProps}</div>;
        },
    };

    const headers: PanelCollection<IDockviewPanelHeaderProps> = {
        customTab: (props: IDockviewPanelHeaderProps) => {
            return (
                <div>
                    <span>{props.api.title}</span>
                    <span onClick={() => props.api.close()}>{'[x]'}</span>
                </div>
            );
        },
    };

    const onReady = (event: DockviewReadyEvent) => {
        event.api.addPanel({
            id: 'panel1',
            component: 'default',
            params: {
                someProps: '1',
            },
        });
        event.api.addPanel({
            id: 'panel2',
            component: 'default',
            params: {
                someProps: '2',
            },
            position: { referencePanel: 'panel1', direction: 'right' },
        });
        event.api.addPanel({
            id: 'panel3',
            component: 'default',
            params: {
                someProps: '3',
            },
            position: { referencePanel: 'panel2', direction: 'right' },
        });
        event.api.addPanel({
            id: 'panel4',
            component: 'default',
            params: {
                someProps: '4',
            },
            position: { referencePanel: 'panel1', direction: 'below' },
        });
        event.api.addPanel({
            id: 'panel5',
            component: 'default',
            params: {
                someProps: '5',
            },
            position: { referencePanel: 'panel3', direction: 'below' },
        });
        event.api.addPanel({
            id: 'panel6',
            component: 'default',
            params: {
                someProps: '6',
            },
            position: { referencePanel: 'panel3', direction: 'within' },
        });

        console.log(event.api.toJSON())
    };

    return (
        // <Page title={"Dock View"}>
            <Container maxWidth={themeStretch ? false : 'lg'}>
                <HeaderBreadcrumbs
                    heading={"Test"}
                    links={[
                        { name: translate('pages.home'), href: '/home' },
                        { name: "Dock View" }
                    ]}
                />
                <Box>
                    <Card sx={{ p: 3, height: 600 }}>
                        <DockviewReact
                            className="dockview-theme-abyss"
                            components={components}
                            tabComponents={headers}
                            onReady={onReady}
                        />
                    </Card>
                </Box>
            </Container>
        // </Page>
    )
};

export default DockView;