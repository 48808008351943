export enum ERegion {
    Tr = 1,
    Es = 2,
    Pt = 3,
    Pl = 4,
    Bg = 5,
    Hr = 6,
    At = 7,
    Fr = 8,
    Ro = 9
}