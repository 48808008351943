import React, { Component } from 'react';
import {
    Stack,
    Snackbar,
    Alert,
    Slide
} from "@mui/material";


export default class PopUp extends Component {

      
    handleCloseUserMessage(key) {
        const userMessages = this.state.userMessages.filter(e => e.open).map(e => e);
        const userMessage = userMessages.find(e => e.key === key) || null;
    
        if (userMessage !== null) {
            userMessage.open = false;
        }
    
        this.setState({userMessages: userMessages});
    }
    
    render() {

        this.state = {
            userMessages:this.props.messages.filter(e => e.open).map(e => e)
        }

        const userMessages = this.state.userMessages.filter(e => e.open)

        return <div style={{position: "fixed", right: "9px", bottom: "9px", zIndex: 99999999}}>
                <Stack spacing={2}>
                    {userMessages.map(e => {
                        return (<Snackbar
                            key={e.key} 
                            TransitionComponent={Slide} 
                            open={e.open}
                            autoHideDuration={3000}
                            sx={{position: "static", justifyContent: "flex-end"}}
                            onClose={(event, reason) => {
                                if (reason === "clickaway") {
                                    return;
                                }
                                this.handleCloseUserMessage(e.key);
                                
                            }}>
                            <Alert style={{maxWidth:"420px", wordBreak:"break-word"}}
                                elevation={6} 
                                variant="filled" 
                                severity={e.severity}
                                onClose={() => this.handleCloseUserMessage(e.key)}>
                                {e.messageText}
                            </Alert>
                        </Snackbar>);
                    })}
                </Stack>
            </div>
    }
}
