import { NotificationModel } from "src/models/notifications.model";
import baseService from "../base.service";

export const getNotifications = () => {
    return baseService
        .post("/Notifications/List")
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
};

export const readNotifications = (notificaitonId: string) => {
    return baseService
        .post("/Notifications/ReadNotification", {Id: notificaitonId})
        .then((response) => response?.data)
        .catch((error) => {
            throw error;
        });
};

export const unreadNotificationCount = () => {
    return baseService
        .post("/Notifications/UnreadNotificationCount")
        .then((response) => response?.data)
        .catch((error) => {
            throw error;
        });
};

export const readAll = () => {
    return baseService
        .post("/Notifications/ReadAll")
        .then((response) => response?.data)
        .catch((error) => {
            throw error;
        });
};
