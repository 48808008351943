import thisMessages from "../../locales/messages.planttracking.json"
// @ts-ignore
import idMessages from "../../locales/messages.intradayplanning.json"
import globalMessages from "../../locales/messages.global.json"
import gql from "../../locales/messages.gql.json"
import {FormattedMessage} from "react-intl";

const messages = {
    plantTracking: thisMessages,
    intraDay: idMessages,
    global: globalMessages,
    GQL: gql
};

export default messages;

/*
type FormatMessageArgs = {
    id: string,
    defaultMessage: string,
    params?: any[]
}

export function AsMessage(msg: FormatMessageArgs) {
    return (<FormattedMessage id={msg.id} defaultMessage={msg.defaultMessage} /> );
}

*/