export enum commonActionType {
    SET_IS_USER_LOGGED_IN = "SET_IS_USER_LOGGED_IN",
    SET_USER_PERMISSONS = "SET_USER_PERMISSONS",
    SET_APP_USER_INFO = "SET_APP_USER_INFO",
    SET_VIEW_PLATFORM = "SET_VIEW_PLATFORM",
    SET_IS_DRAWER_OPEN = "SET_IS_DRAWER_OPEN",
    SET_CONFIRM_LEAVING_PAGE = "SET_CONFIRM_LEAVING_PAGE",
    SET_USER_PROFILEDATA = "SET_USER_PROFILEDATA",
    SET_USER_SETTINGS = "SET_USER_SETTINGS",
    GET_USER_SETTINGS = "GET_USER_SETTINGS",
    USER_REGION = "USER_REGION",
    USER_REGION_TIMEZONE = "USER_REGION_TIMEZONE",
    USER_COMPANIES = "USER_COMPANIES",
    USER_GROUPS = "USER_GROUPS",
    USER_PLANTS = "USER_PLANTS",
}

export default commonActionType;