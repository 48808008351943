import { useState } from 'react';

const ConsumptionSource = ({ providerKey, onChange, unitExtraProviders, unitProviders }) => {
  const [selectedProviderKey, setSelectedProviderKey] = useState(providerKey || '0');

  const handleSelectChange = (event) => {
    setSelectedProviderKey(event.target.value);
    onChange(event.target.value);
  };

  return (
    <>
      <select
        name="consumptionSources"
        id="consumptionSources"
        style={{ width: '100%' }}
        value={selectedProviderKey}
        onChange={handleSelectChange}
      >
        <option value="0">Seç</option>
        {unitProviders && unitProviders.map((provider) => (
          <option key={provider.providerId} value={provider.providerName}>
            {provider.providerName}
          </option>
        ))}
        {unitExtraProviders && unitExtraProviders.map((provider) => (
          <option key={provider.ProviderId} value={provider.ProviderName}>
            {provider.ProviderName}
          </option>
        ))}
      </select>
    </>
  );
};

export default ConsumptionSource;
